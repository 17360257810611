import { connect } from 'react-redux'

import ItemNFTDetailPage from './TicketDetailPage'

import { RootState } from '../../../modules/reducer'
import {  getTicketDetail, getTicketDetailLoading } from '../../../modules/ticket/selectors'
import { fetchSingleTicketRequest } from '../../../modules/ticket/actions'
import { getWallet } from '../../../modules/wallet/selectors'

import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps
} from './TicketDetailPage.types'

const mapState = (state: RootState): MapStateProps => ({
  ticketDetail: getTicketDetail(state),
  loading: getTicketDetailLoading(state),
  wallet: getWallet(state)
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  fetchSingleTicket: query => dispatch(fetchSingleTicketRequest(query)),
})

export default connect(mapState, mapDispatch)(ItemNFTDetailPage)
