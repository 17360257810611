import { connect } from 'react-redux'
import {
  enableWalletRequest,
  disconnectWallet
} from '@wiicamp/decentraland-dapps/dist/modules/wallet/actions'
import {
  checkUserExistedRequest,
  getUserSignMessageRequest,
  setUserSignMetamaskRequest
} from '../../../modules/authentication/actions'
import {
  getError,
  isEnabling,
  isConnecting
} from '@wiicamp/decentraland-dapps/dist/modules/wallet/selectors'

import ConnectWalletModal from './ConnectWalletModal'

import { RootState } from '../../../modules/reducer'
import {
  getMessage,
  getUser,
} from '../../../modules/authentication/selectors'

import {
  MapStateProps,
  MapDispatchProps,
  MapDispatch
} from './ConnectWalletModal.types'

const mapState = (state: RootState): MapStateProps => ({
  hasError: !!getError(state),
  isLoading: isEnabling(state) || isConnecting(state),
  userConnectWithWallet: getUser(state),
  message: getMessage(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onConnect: providerType => dispatch(enableWalletRequest(providerType)),
  onSignOut: () => dispatch(disconnectWallet()),
  dispatchGetUserSignMessageRequest: (payload: any) => dispatch(getUserSignMessageRequest(payload)),
  setUserSignMetamaskRequest: (payload: any) => dispatch(setUserSignMetamaskRequest(payload)),
  checkUserExisted: (payload: any) => dispatch(checkUserExistedRequest(payload)),
})

export default connect(mapState, mapDispatch)(ConnectWalletModal)
