import { action } from 'typesafe-actions'

import {
  FetchMyItemsQueryType,
  FetchTransactionHistoriesItemNFTQuery,
  ItemNFTType,
  ItemUserCreationType,
  RarityNFTType, TransactionHistoryItemNFTType
} from './types'

export const FETCH_ITEMS_NFT_REQUEST = '[Request] Fetch items NFT'
export const FETCH_ITEMS_NFT_SUCCESS = '[Success] Fetch items NFT'
export const FETCH_ITEMS_NFT_FAILURE = '[Failure] Fetch items NFT'

export const fetchItemsNFTRequest = (options: any) => action(FETCH_ITEMS_NFT_REQUEST, options)
export const fetchItemsNFTSuccess = (items: ItemNFTType[], isRefreshed: boolean) => action(FETCH_ITEMS_NFT_SUCCESS, { items, isRefreshed })
export const fetchItemsNFTFailure = (error: string) => action(FETCH_ITEMS_NFT_FAILURE, { error })

export type FetchItemsNFTRequestAction = ReturnType<typeof fetchItemsNFTRequest>
export type FetchItemsNFTSuccessAction = ReturnType<typeof fetchItemsNFTSuccess>
export type FetchItemsNFTFailureAction = ReturnType<typeof fetchItemsNFTFailure>

// Fetch Resold items nft
export const FETCH_RESOLD_ITEMS_NFT_REQUEST = '[Request] Fetch resold items NFT'
export const FETCH_RESOLD_ITEMS_NFT_SUCCESS = '[Success] Fetch resold items NFT'
export const FETCH_RESOLD_ITEMS_NFT_FAILURE = '[Failure] Fetch resold items NFT'

export const fetchResoldItemsNFTRequest = (options: any) => action(FETCH_RESOLD_ITEMS_NFT_REQUEST, options)
export const fetchResoldItemsNFTSuccess = (items: ItemNFTType[], isRefreshed: boolean) => action(FETCH_RESOLD_ITEMS_NFT_SUCCESS, { items, isRefreshed })
export const fetchResoldItemsNFTFailure = (error: string) => action(FETCH_RESOLD_ITEMS_NFT_FAILURE, { error })

export type FetchResoldItemsNFTRequestAction = ReturnType<typeof fetchResoldItemsNFTRequest>
export type FetchResoldItemsNFTSuccessAction = ReturnType<typeof fetchResoldItemsNFTSuccess>
export type FetchResoldItemsNFTFailureAction = ReturnType<typeof fetchResoldItemsNFTFailure>

// Categories
export const FETCH_CATEGORIES_ITEMS_NFT_REQUEST = '[Request] Fetch categories items NFT'
export const FETCH_CATEGORIES_ITEMS_NFT_SUCCESS = '[Success] Fetch categories items NFT'
export const FETCH_CATEGORIES_ITEMS_NFT_FAILURE = '[Failure] Fetch categories items NFT'

export const fetchCategoriesItemsNFTRequest = ({ type }: { type?: any }) => action(FETCH_CATEGORIES_ITEMS_NFT_REQUEST, { type })
export const fetchCategoriesItemsNFTSuccess = (items: any) => action(FETCH_CATEGORIES_ITEMS_NFT_SUCCESS, { items })
export const fetchCategoriesItemsNFTFailure = (error: any) => action(FETCH_CATEGORIES_ITEMS_NFT_FAILURE, { error })

export type FetchCategoriesItemsNFTRequestAction = ReturnType<typeof fetchCategoriesItemsNFTRequest>
export type FetchCategoriesItemsNFTSuccessAction = ReturnType<typeof fetchCategoriesItemsNFTSuccess>
export type FetchCategoriesItemsNFTFailureAction = ReturnType<typeof fetchCategoriesItemsNFTFailure>

// Detail ItemNFT
export const FETCH_ITEM_NFT_DETAIL_REQUEST = '[Request] Fetch detail item NFT'
export const FETCH_ITEM_NFT_DETAIL_SUCCESS = '[Success] Fetch detail item NFT'
export const FETCH_ITEM_NFT_DETAIL_FAILURE = '[Failure] Fetch detail item NFT'

export const fetchItemNFTDetailRequest = (id: string) => action(FETCH_ITEM_NFT_DETAIL_REQUEST, { id })
export const fetchItemNFTDetailSuccess = (item: any) => action(FETCH_ITEM_NFT_DETAIL_SUCCESS, { item })
export const fetchItemNFTDetailFailure = (error: any) => action(FETCH_ITEM_NFT_DETAIL_FAILURE, { error })

export type FetchItemNFTDetailRequestAction = ReturnType<typeof fetchItemNFTDetailRequest>
export type FetchItemNFTDetailSuccessAction = ReturnType<typeof fetchItemNFTDetailSuccess>
export type FetchItemNFTDetailFailureAction = ReturnType<typeof fetchItemNFTDetailFailure>

// Fetch user creator items
export const FETCH_USER_CREATION_ITEMS_REQUEST = '[Request] Fetch user creation items'
export const FETCH_USER_CREATION_ITEMS_SUCCESS = '[Success] Fetch user creation items'
export const FETCH_USER_CREATION_ITEMS_FAILURE = '[Failure] Fetch user creation items'

export const fetchUserCreationItemsRequest = (options: any) => action(FETCH_USER_CREATION_ITEMS_REQUEST, options)
export const fetchUserCreationItemsSuccess = (items: ItemNFTType[], isRefreshed: boolean) => action(FETCH_USER_CREATION_ITEMS_SUCCESS, { items, isRefreshed })
export const fetchUserCreationItemsFailure = (error: string) => action(FETCH_USER_CREATION_ITEMS_FAILURE, { error })

export type FetchUserCreationItemsRequestAction = ReturnType<typeof fetchUserCreationItemsRequest>
export type FetchUserCreationItemsSuccessAction = ReturnType<typeof fetchUserCreationItemsSuccess>
export type FetchUserCreationItemsFailureAction = ReturnType<typeof fetchUserCreationItemsFailure>

// Buy item user creator
export const BUY_ITEM_USER_CREATION_REQUEST = '[Request] Buy item user creator'
export const BUY_ITEM_USER_CREATION_SUCCESS = '[Success] Buy item user creator'
export const BUY_ITEM_USER_CREATION_FAILURE = '[Failure] Buy item user creator'

export const buyItemUserCreationRequest = (item: any) => action(BUY_ITEM_USER_CREATION_REQUEST, item)
export const buyItemUserCreationSuccess = () => action(BUY_ITEM_USER_CREATION_SUCCESS)
export const buyItemUserCreationFailure = (error: string) => action(BUY_ITEM_USER_CREATION_FAILURE, { error })

export type BuyItemUserCreationRequestAction = ReturnType<typeof buyItemUserCreationRequest>
export type BuyItemUserCreationSuccessAction = ReturnType<typeof buyItemUserCreationSuccess>
export type BuyItemUserCreationFailureAction = ReturnType<typeof buyItemUserCreationFailure>

// Buy resold item
export const BUY_RESOLD_ITEM_REQUEST = '[Request] Buy resold item'
export const BUY_RESOLD_ITEM_SUCCESS = '[Success] Buy resold item'
export const BUY_RESOLD_ITEM_FAILURE = '[Failure] Buy resold item'

export const buyResoldItemRequest = (item: any) => action(BUY_RESOLD_ITEM_REQUEST, item)
export const buyResoldItemSuccess = (item: any, toWalletAddress: string) => action(BUY_RESOLD_ITEM_SUCCESS, { item, toWalletAddress })
export const buyResoldItemFailure = (error: string) => action(BUY_RESOLD_ITEM_FAILURE, { error })

export type BuyResoldItemRequestAction = ReturnType<typeof buyResoldItemRequest>
export type BuyResoldItemSuccessAction = ReturnType<typeof buyResoldItemSuccess>
export type BuyResoldItemFailureAction = ReturnType<typeof buyResoldItemFailure>

// Fetch my items
export const FETCH_MY_ITEMS_REQUEST = '[Request] Fetch my items'
export const FETCH_MY_ITEMS_SUCCESS = '[Success] Fetch my items'
export const FETCH_MY_ITEMS_FAILURE = '[Failure] Fetch my items'

export const fetchMyItemsRequest = (query: FetchMyItemsQueryType) => action(FETCH_MY_ITEMS_REQUEST, query)
export const fetchMyItemsSuccess = (items: ItemNFTType[], isRefreshed: boolean) => action(FETCH_MY_ITEMS_SUCCESS, { items, isRefreshed })
export const fetchMyItemsFailure = (error: string) => action(FETCH_MY_ITEMS_FAILURE, { error })

export type FetchMyItemsRequestAction = ReturnType<typeof fetchMyItemsRequest>
export type FetchMyItemsSuccessAction = ReturnType<typeof fetchMyItemsSuccess>
export type FetchMyItemsFailureAction = ReturnType<typeof fetchMyItemsFailure>

// Detail ItemNFT Listing
export const FETCH_ITEM_NFT_LISTING_DETAIL_REQUEST = '[Request] Fetch detail item NFT listing'
export const FETCH_ITEM_NFT_LISTING_DETAIL_SUCCESS = '[Success] Fetch detail item NFT listing'
export const FETCH_ITEM_NFT_LISTING_DETAIL_FAILURE = '[Failure] Fetch detail item NFT listing'

export const fetchItemNFTListingDetailRequest = (id: string, contractAddress: string) => action(FETCH_ITEM_NFT_LISTING_DETAIL_REQUEST, { id, contractAddress })
export const fetchItemNFTListingDetailSuccess = (item: any) => action(FETCH_ITEM_NFT_LISTING_DETAIL_SUCCESS, { item })
export const fetchItemNFTListingDetailFailure = (error: any) => action(FETCH_ITEM_NFT_LISTING_DETAIL_FAILURE, { error })

export type FetchItemNFTListingDetailRequestAction = ReturnType<typeof fetchItemNFTListingDetailRequest>
export type FetchItemNFTListingDetailSuccessAction = ReturnType<typeof fetchItemNFTListingDetailSuccess>
export type FetchItemNFTListingDetailFailureAction = ReturnType<typeof fetchItemNFTListingDetailFailure>

// List for sale item
export const LIST_FOR_SALE_ITEM_REQUEST = '[Request] List for sale item'
export const LIST_FOR_SALE_ITEM_SUCCESS = '[Success] List for sale item'
export const LIST_FOR_SALE_ITEM_FAILURE = '[Failure] List for sale item'

export const listForSaleItemRequest = (item: any, price: any) => action(LIST_FOR_SALE_ITEM_REQUEST, { item, price })
export const listForSaleItemSuccess = (item: any, price: number) => action(LIST_FOR_SALE_ITEM_SUCCESS, { item, price })
export const listForSaleItemFailure = (error: any) => action(LIST_FOR_SALE_ITEM_FAILURE, { error })

export type ListForSaleItemRequestAction = ReturnType<typeof listForSaleItemRequest>
export type ListForSaleItemSuccessAction = ReturnType<typeof listForSaleItemSuccess>
export type ListForSaleItemFailureAction = ReturnType<typeof listForSaleItemFailure>

// transfer item
export const TRANSFER_ITEM_REQUEST = '[Request] Transfer item'
export const TRANSFER_ITEM_SUCCESS = '[Success] Transfer item'
export const TRANSFER_ITEM_FAILURE = '[Failure] Transfer item'

export const transferItemRequest = (item: any, toWalletAddress: string) => action(TRANSFER_ITEM_REQUEST, { item, toWalletAddress })
export const transferItemSuccess = (item:any, toWalletAddress: string) => action(TRANSFER_ITEM_SUCCESS, { item, toWalletAddress })
export const transferItemFailure = (error: any) => action(TRANSFER_ITEM_FAILURE, { error })

export type TransferItemRequestAction = ReturnType<typeof transferItemRequest>
export type TransferItemSuccessAction = ReturnType<typeof transferItemSuccess>
export type TransferItemFailureAction = ReturnType<typeof transferItemFailure>

// cancel sale item
export const CANCEL_SALE_ITEM_REQUEST = '[Request] Cancel sale item'
export const CANCEL_SALE_ITEM_SUCCESS = '[Success] Cancel sale item'
export const CANCEL_SALE_ITEM_FAILURE = '[Failure] Cancel sale item'

export const cancelSaleItemRequest = (item: any, callback: () => void) => action(CANCEL_SALE_ITEM_REQUEST, { item, callback })
export const cancelSaleItemSuccess = (item:any) => action(CANCEL_SALE_ITEM_SUCCESS, { item })
export const cancelSaleItemFailure = (error: any) => action(CANCEL_SALE_ITEM_FAILURE, { error })

export type CancelSaleItemRequestAction = ReturnType<typeof cancelSaleItemRequest>
export type CancelSaleItemSuccessAction = ReturnType<typeof cancelSaleItemSuccess>
export type CancelSaleItemFailureAction = ReturnType<typeof cancelSaleItemFailure>

// sale item user creation
export const SALE_ITEM_USER_CREATION_REQUEST = '[Request] Sale item user creation item'
export const SALE_ITEM_USER_CREATION_SUCCESS = '[Success] Sale item user creation item'
export const SALE_ITEM_USER_CREATION_FAILURE = '[Failure] Sale item user creation item'

export const saleItemUserCreationRequest = (item: ItemUserCreationType, price: number) => action(SALE_ITEM_USER_CREATION_REQUEST, { item, price })
export const saleItemUserCreationSuccess = (item: ItemUserCreationType) => action(SALE_ITEM_USER_CREATION_SUCCESS, { item})
export const saleItemUserCreationFailure = (error: any, item: any) => action(SALE_ITEM_USER_CREATION_FAILURE, { error, item })

export type SaleItemUserCreationRequestAction = ReturnType<typeof saleItemUserCreationRequest>
export type SaleItemUserCreationSuccessAction = ReturnType<typeof saleItemUserCreationSuccess>
export type SaleItemUserCreationFailureAction = ReturnType<typeof saleItemUserCreationFailure>

// Fet Rarities
export const FETCH_RARITIES_ITEMS_NFT_REQUEST = '[Request] Fetch rarities items NFT'
export const FETCH_RARITIES_ITEMS_NFT_SUCCESS = '[Success] Fetch rarities items NFT'
export const FETCH_RARITIES_ITEMS_NFT_FAILURE = '[Failure] Fetch rarities items NFT'

export const fetchRaritiesItemsNFTRequest = () => action(FETCH_RARITIES_ITEMS_NFT_REQUEST)
export const fetchRaritiesItemsNFTSuccess = (rarities: RarityNFTType[]) => action(FETCH_RARITIES_ITEMS_NFT_SUCCESS, { rarities })
export const fetchRaritiesItemsNFTFailure = (error: any) => action(FETCH_RARITIES_ITEMS_NFT_FAILURE, { error })

export type FetchRaritiesItemsNFTRequestAction = ReturnType<typeof fetchRaritiesItemsNFTRequest>
export type FetchRaritiesItemsNFTSuccessAction = ReturnType<typeof fetchRaritiesItemsNFTSuccess>
export type FetchRaritiesItemsNFTFailureAction = ReturnType<typeof fetchRaritiesItemsNFTFailure>

// Fetch Transaction histories item nft
export const FETCH_TRANSACTION_HISTORIES_ITEM_NFT_REQUEST = '[Request] Fetch transaction histories item NFT'
export const FETCH_TRANSACTION_HISTORIES_ITEM_NFT_SUCCESS = '[Success] Fetch transaction histories item NFT'
export const FETCH_TRANSACTION_HISTORIES_ITEM_NFT_FAILURE = '[Failure] Fetch transaction histories item NFT'

export const fetchTransactionHistoriesItemNFTRequest = (query: FetchTransactionHistoriesItemNFTQuery) => action(FETCH_TRANSACTION_HISTORIES_ITEM_NFT_REQUEST, query)
export const fetchTransactionHistoriesItemNFTSuccess = (histories : TransactionHistoryItemNFTType[], isRefreshed: boolean) => action(FETCH_TRANSACTION_HISTORIES_ITEM_NFT_SUCCESS, { histories, isRefreshed })
export const fetchTransactionHistoriesItemNFTFailure = (error: any) => action(FETCH_TRANSACTION_HISTORIES_ITEM_NFT_FAILURE, { error })

export type FetchTransactionHistoriesItemNFTRequestAction = ReturnType<typeof fetchTransactionHistoriesItemNFTRequest>
export type FetchTransactionHistoriesItemNFTSuccessAction = ReturnType<typeof fetchTransactionHistoriesItemNFTSuccess>
export type FetchTransactionHistoriesItemNFTFailureAction = ReturnType<typeof fetchTransactionHistoriesItemNFTFailure>
