import React, { useEffect, useMemo, useCallback } from 'react'

import LayoutPage from '../Layout/LayoutPage/LayoutPage'

import Main from './Main'

import useWatchNetwork from '../../../customHooks/useWatchNetwork'

import { Section } from '../../../modules/vendor/decentraland/routing/types'
import { VendorName } from '../../../modules/vendor/types'
import { View } from '../../../modules/ui/types'
import { AssetType } from '../../../modules/asset/types'
import { HomepageView } from '../../../modules/ui/asset/homepage/types'
import { SortBy } from '../../../modules/routing/types'

import { locations } from '../../../modules/routing/locations'

import { Props } from './MarketplacePage.types'

const MarketplacePage = (props: Props) => {
  const {
    homepage,
    homepageLoading,
    onNavigate,
    onFetchAssetsFromRoute,
    onFetchNFT,
    onFetchItem,
    toggleModalDetailEstate,
    onFetchCollectionItems,
    categoriesItemNFT,
  } = props
  const vendor = VendorName.DECENTRALAND
  const sections: Partial<Record<View, Section>> = useMemo(
    () => ({
      [View.HOME_ITEMS]: Section.WEARABLES,
      [View.HOME_WEARABLES]: Section.WEARABLES,
      [View.HOME_LAND]: Section.LAND,
      [View.HOME_ENS]: Section.ENS
    }),
    []
  )
  const assetTypes: Partial<Record<View, AssetType>> = useMemo(
    () => ({
      [View.HOME_ITEMS]: AssetType.ITEM,
      [View.HOME_WEARABLES]: AssetType.NFT,
      [View.HOME_LAND]: AssetType.NFT,
      [View.HOME_ENS]: AssetType.NFT
    }),
    []
  )
  const views = Object.keys(homepage) as HomepageView[]

  const handleViewAll = useCallback(
    (view: View) => {
      const section = sections[view]
      const assetType = assetTypes[view]

      if (Section.LAND === section) {
        onNavigate(locations.landsLOM())
      } else {
        onNavigate(locations.browseLOM({ section, assetType }))
      }
    },
    [sections, assetTypes, onNavigate]
  )

  useEffect(() => {
    const view: HomepageView = View.HOME_LAND
    const assetType = assetTypes[view]
    const section = sections[view]

    onFetchAssetsFromRoute({
      vendor,
      section,
      view,
      assetType,
      sortBy: SortBy.RECENTLY_LISTED,
      page: 1,
      onlyOnSale: true
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onFetchAssetsFromRoute])

  // useEffect(() => {
  //   onFetchCollectionItems({})
  // }, [onFetchCollectionItems])

  useWatchNetwork()

  return (
    <LayoutPage viewMode="white">
      <Main
        homepage={homepage}
        homepageLoading={homepageLoading}
        views={views}
        handleViewAll={handleViewAll}
        onFetchNFT={onFetchNFT}
        onFetchItem={onFetchItem}
        toggleModalDetailEstate={toggleModalDetailEstate}
        categoriesItemNFT={categoriesItemNFT}
        onFetchCollectionItems={onFetchCollectionItems}
      />
    </LayoutPage>
  )
}

export default React.memo(MarketplacePage)
