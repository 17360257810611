import { connect } from 'react-redux'
import { enableWalletRequest, disconnectWallet } from '@wiicamp/decentraland-dapps/dist/modules/wallet/actions'
import { getError, isEnabling, isConnecting } from '@wiicamp/decentraland-dapps/dist/modules/wallet/selectors'


import MenuBarRight from './MenuMobile'

import { getWallet } from '../../../modules/wallet/selectors'
import { getToken } from '../../../modules/authentication/selectors'

import {
  MapStateProps,
  MapDispatchProps,
  MapDispatch, } from './MenuMobile.types'

import { RootState } from '../../../modules/reducer'

const mapState = (state: RootState): MapStateProps => ({
  token: getToken(state),
  wallet: getWallet(state),
  hasError: !!getError(state),
  isLoading: isEnabling(state) || isConnecting(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onConnect: providerType => dispatch(enableWalletRequest(providerType)),
  onSignOut: () => dispatch(disconnectWallet())
})

export default connect(mapState, mapDispatch)(MenuBarRight)
