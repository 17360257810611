import {
  loadingReducer,
  LoadingState
} from '@wiicamp/decentraland-dapps/dist/modules/loading/reducer'
import cookies from 'js-cookie'

import {
  CHECK_USER_EXISTED_REQUEST,
  CHECK_USER_EXISTED_SUCCESS,
  CHECK_USER_EXISTED_FAILURE,

  GET_USER_SIGN_MESSAGE_REQUEST,
  GET_USER_SIGN_MESSAGE_SUCCESS,
  GET_USER_SIGN_MESSAGE_FAILURE,

  SET_USER_SIGN_METAMASK_REQUEST,
  SET_USER_SIGN_METAMASK_SUCCESS,
  SET_USER_SIGN_METAMASK_FAILURE,

  SET_VERIFY_WALLET_TO_ACCOUNT_REQUEST,
  SET_VERIFY_WALLET_TO_ACCOUNT_SUCCESS,
  SET_VERIFY_WALLET_TO_ACCOUNT_FAILURE,

  SET_UPDATE_USER_PASSWORD_REQUEST,
  SET_UPDATE_USER_PASSWORD_SUCCESS,
  SET_UPDATE_USER_PASSWORD_FAILURE,

  SET_LOGOUT_WEBSITE_REQUEST,

  UPDATE_EMAIL_AND_USERNAME_OF_USER_REQUEST,
  UPDATE_EMAIL_AND_USERNAME_OF_USER_SUCCESS,
  UPDATE_EMAIL_AND_USERNAME_OF_USER_FAILURE,
} from './actions'

import { LOCALSTORAGE_KEYS } from '../../constants/localStorageKeys'

export const USER_EXISTED_STATUS = {
  EXISTED: 'EXISTED',
  NOT_YET: 'NOT_YET',
  UNKNOWN: 'UNKNOWN'
}

export type AuthenticationStateType = {
  error: string

  isLoadingCheckUserExisted: LoadingState
  userExistedStatus: string

  isLoadingGetUserSignMessage: LoadingState
  user: any
  message: string
  token: string

  isLoadingSetUserSignMetamask: LoadingState
  signature: string

  isLoadingSetVerifyWalletToAccount: LoadingState

  isLoadingUpdateUserPassword: LoadingState
  isLoadingUpdateEmailAndUsernameOfUser: LoadingState
}

export const INITIAL_STATE: AuthenticationStateType = {
  error: '',

  isLoadingCheckUserExisted: [],
  userExistedStatus: USER_EXISTED_STATUS.UNKNOWN,

  isLoadingGetUserSignMessage: [],
  user: null,
  message: '',
  token: '',

  isLoadingSetUserSignMetamask: [],
  signature: '',

  isLoadingSetVerifyWalletToAccount: [],

  isLoadingUpdateUserPassword: [],
  isLoadingUpdateEmailAndUsernameOfUser: [],
}

export function authenticationReducer(state = INITIAL_STATE, action: any): any {
  switch (action.type) {
    case CHECK_USER_EXISTED_REQUEST: {
      return {
        ...state,
        isLoadingCheckUserExisted: loadingReducer(
          state.isLoadingCheckUserExisted,
          action
        ),
        user: null,
        message: null,
        token: null,
        signature: null,
      }
    }

    case CHECK_USER_EXISTED_SUCCESS: {
      const {
        userExistedStatus,
        user = null,
        message = null,
        token = null
      } = action.payload;

      return {
        ...state,
        isLoadingCheckUserExisted: loadingReducer(
          state.isLoadingCheckUserExisted,
          action
        ),
        userExistedStatus,
        user,
        message,
        token,
      }
    }

    case CHECK_USER_EXISTED_FAILURE: {
      return {
        ...state,
        isLoadingCheckUserExisted: loadingReducer(
          state.isLoadingCheckUserExisted,
          action
        ),
        userExistedStatus: USER_EXISTED_STATUS.UNKNOWN,
      }
    }

    case GET_USER_SIGN_MESSAGE_REQUEST: {
      return {
        ...state,
        isLoadingGetUserSignMessage: loadingReducer(
          state.isLoadingGetUserSignMessage, action
        )
      }
    }

    case GET_USER_SIGN_MESSAGE_SUCCESS: {
      return {
        ...state,
        isLoadingGetUserSignMessage: loadingReducer(
          state.isLoadingGetUserSignMessage, action
        ),
        message: action.payload.message
      }
    }

    case GET_USER_SIGN_MESSAGE_FAILURE: {
      return {
        ...state,
        isLoadingGetUserSignMessage: loadingReducer(
          state.isLoadingGetUserSignMessage, action
        ),
        error: action.payload.error,
      }
    }

    case SET_USER_SIGN_METAMASK_REQUEST:
    case SET_USER_SIGN_METAMASK_FAILURE: {
      return {
        ...state,
        isLoadingSetUserSignMetamask: loadingReducer(
          state.isLoadingSetUserSignMetamask, action
        )
      }
    }

    case SET_USER_SIGN_METAMASK_SUCCESS: {
      return {
        ...state,
        isLoadingSetUserSignMetamask: loadingReducer(
          state.isLoadingSetUserSignMetamask, action
        ),
        signature: action.payload.signature,
      }
    }

    case SET_VERIFY_WALLET_TO_ACCOUNT_REQUEST:
    case SET_VERIFY_WALLET_TO_ACCOUNT_FAILURE: {
      return {
        ...state,
        isLoadingSetVerifyWalletToAccount: loadingReducer(
          state.isLoadingSetVerifyWalletToAccount, action
        )
      }
    }

    case SET_VERIFY_WALLET_TO_ACCOUNT_SUCCESS: {
      const { user, token } = action.payload

      return {
        ...state,
        isLoadingSetVerifyWalletToAccount: loadingReducer(
          state.isLoadingSetVerifyWalletToAccount, action
        ),
        user,
        token,
        userExistedStatus: USER_EXISTED_STATUS.EXISTED,
      }
    }

    case SET_UPDATE_USER_PASSWORD_REQUEST:
    case SET_UPDATE_USER_PASSWORD_FAILURE:
    case SET_UPDATE_USER_PASSWORD_SUCCESS: {
      return {
        ...state,
        isLoadingUpdateUserPassword: loadingReducer(
          state.isLoadingUpdateUserPassword, action
        )
      }
    }

    case SET_LOGOUT_WEBSITE_REQUEST: {
      cookies.remove(LOCALSTORAGE_KEYS.SIGNED_TOKEN)
      cookies.remove(LOCALSTORAGE_KEYS.SIGNED_METAMASK_ADDRESS)

      return {
        ...INITIAL_STATE
      }
    }

    // Update email and username of user
    case UPDATE_EMAIL_AND_USERNAME_OF_USER_FAILURE:
    case UPDATE_EMAIL_AND_USERNAME_OF_USER_REQUEST: {
      return {
        ...state,
        isLoadingUpdateEmailAndUsernameOfUser: loadingReducer(
          state.isLoadingUpdateEmailAndUsernameOfUser, action
        )
      }
    }
    case UPDATE_EMAIL_AND_USERNAME_OF_USER_SUCCESS: {
      const { email, username } = action.payload
      const newUser = {...state.user }
      newUser.email = email
      newUser.username = username

      return {
        ...state,
        user: newUser,
        isLoadingUpdateEmailAndUsernameOfUser: loadingReducer(
          state.isLoadingUpdateEmailAndUsernameOfUser, action
        )
      }
    }

    default:
      return state
  }
}
