import {
  loadingReducer,
  LoadingState
} from '@wiicamp/decentraland-dapps/dist/modules/loading/reducer'

import {
  // Create new item nft
  CREATE_NEW_ITEM_NFT_REQUEST,
  CREATE_NEW_ITEM_NFT_FAILURE,
  CREATE_NEW_ITEM_NFT_SUCCESS,
  CreateNewItemNFTRequestAction,
  CreateNewItemNFTFailureAction,
  CreateNewItemNFTSuccessAction,

  // Get items nft from localStorage
  GET_ITEMS_NFT_FROM_LOCALSTORAGE_REQUEST,
  GET_ITEMS_NFT_FROM_LOCALSTORAGE_FAILURE,
  GET_ITEMS_NFT_FROM_LOCALSTORAGE_SUCCESS,
  GetItemsNFTFromLocalStorageRequestAction,
  GetItemsNFTFromLocalstorageFailureAction,
  GetItemsNFTFromLocalstorageSuccessAction,

  // Fetch my items creation
  FETCH_MY_ITEMS_CREATION_REQUEST,
  FETCH_MY_ITEMS_CREATION_SUCCESS,
  FETCH_MY_ITEMS_CREATION_FAILURE,
  FetchMyItemsCreationRequestAction,
  FetchMyItemsCreationSuccessAction,
  FetchMyItemsCreationFailureAction,

  // Delete item user creation
  DELETE_ITEM_USER_CREATION_REQUEST,
  DELETE_ITEM_USER_CREATION_SUCCESS,
  DELETE_ITEM_USER_CREATION_FAILURE,
  DeleteItemUserCreationRequestAction,
  DeleteItemUserCreationSuccessAction,
  DeleteItemUserCreationFailureAction,

  // Set item user creation edit
  SET_ITEM_USER_CREATION_EDIT_REQUEST,
  SetItemUserCreationRequestAction,

  // Edit item user creation
  EDIT_ITEM_USER_CREATION_REQUEST,
  EDIT_ITEM_USER_CREATION_SUCCESS,
  EDIT_ITEM_USER_CREATION_FAILURE,
  EditItemUserCreationRequestAction,
  EditItemUserCreationSuccessAction,
  EditItemUserCreationFailureAction,

  // Fetch item user creation edit
  FETCH_ITEM_USER_CREATION_EDIT_REQUEST,
  FETCH_ITEM_USER_CREATION_EDIT_SUCCESS,
  FETCH_ITEM_USER_CREATION_EDIT_FAILURE,
  FetchItemUserCreationEditRequestAction,
  FetchItemUserCreationEditSuccessAction,
  FetchItemUserCreationEditFailureAction,
} from './actions'

import { SaleItemUserCreationSuccessAction, SALE_ITEM_USER_CREATION_SUCCESS } from '../itemNFT/actions'

import { getObjectFromApiCategories } from '../itemNFT/utils'

import { ItemUserCreationType } from '../itemNFT/types'

export type UploadItemStateType = {
  data: any[],
  itemUserCreationEdit: ItemUserCreationType | null
  isLoadingFetchMyItemsCreation: boolean

  isLoadingCreateNewItemNFT: LoadingState
  isLoadingDeleteItemUserCreation: LoadingState
  isLoadingEditItemUserCreation: LoadingState
  isLoadingFetchItemUserCreationEdit: LoadingState
  isLoadingGetItemsNFTFromLocalStorage: LoadingState
  error: any
}

export const INITIAL_STATE: UploadItemStateType = {
  data: [],
  itemUserCreationEdit: null,

  isLoadingFetchMyItemsCreation: true,

  isLoadingCreateNewItemNFT: [],
  isLoadingDeleteItemUserCreation: [],
  isLoadingEditItemUserCreation: [],
  isLoadingFetchItemUserCreationEdit: [],
  isLoadingGetItemsNFTFromLocalStorage: [],

  error: null,
}

type UploadItemReducerAction =
  | CreateNewItemNFTRequestAction
  | CreateNewItemNFTSuccessAction
  | CreateNewItemNFTFailureAction
  | GetItemsNFTFromLocalStorageRequestAction
  | GetItemsNFTFromLocalstorageFailureAction
  | GetItemsNFTFromLocalstorageSuccessAction
  | FetchMyItemsCreationRequestAction
  | FetchMyItemsCreationFailureAction
  | FetchMyItemsCreationSuccessAction
  | DeleteItemUserCreationRequestAction
  | DeleteItemUserCreationSuccessAction
  | DeleteItemUserCreationFailureAction
  | SetItemUserCreationRequestAction
  | EditItemUserCreationRequestAction
  | EditItemUserCreationSuccessAction
  | EditItemUserCreationFailureAction
  | FetchItemUserCreationEditRequestAction
  | FetchItemUserCreationEditSuccessAction
  | FetchItemUserCreationEditFailureAction
  | SaleItemUserCreationSuccessAction

export function uploadItemReducer(
  state = INITIAL_STATE,
  action: UploadItemReducerAction
): UploadItemStateType {
  switch (action.type) {
    case CREATE_NEW_ITEM_NFT_REQUEST: {
      return {
        ...state,
        isLoadingCreateNewItemNFT: loadingReducer(state.isLoadingCreateNewItemNFT, action),
      }
    }

    case CREATE_NEW_ITEM_NFT_SUCCESS: {
      return {
        ...state,
        isLoadingCreateNewItemNFT: loadingReducer(state.isLoadingCreateNewItemNFT, action),
        data: [...state.data, ...action.payload.items],
      }
    }

    case CREATE_NEW_ITEM_NFT_FAILURE: {
      return {
        ...state,
        isLoadingCreateNewItemNFT: loadingReducer(state.isLoadingCreateNewItemNFT, action),
        error: action.payload.error,
      }
    }

    case GET_ITEMS_NFT_FROM_LOCALSTORAGE_REQUEST: {
      return {
        ...state,
        isLoadingGetItemsNFTFromLocalStorage: loadingReducer(state.isLoadingGetItemsNFTFromLocalStorage, action),
      }
    }

    case GET_ITEMS_NFT_FROM_LOCALSTORAGE_SUCCESS: {
      // TODO: add isLoadMore
      return {
        ...state,
        isLoadingGetItemsNFTFromLocalStorage: loadingReducer(state.isLoadingGetItemsNFTFromLocalStorage, action),
        data: action.payload.items
      }
    }

    case GET_ITEMS_NFT_FROM_LOCALSTORAGE_FAILURE: {
      return {
        ...state,
        isLoadingGetItemsNFTFromLocalStorage: loadingReducer(state.isLoadingGetItemsNFTFromLocalStorage, action),
        error: action.payload.error,
      }
    }

    // Fetch my items creation
    case FETCH_MY_ITEMS_CREATION_REQUEST: {
      return {
        ...state,
        isLoadingFetchMyItemsCreation: true,
      }
    }

    case FETCH_MY_ITEMS_CREATION_SUCCESS: {
      const { items, categories, isRefreshed } = action.payload

      const newData = items.map((item: any) => {
        const { category, subCategory } = getObjectFromApiCategories(categories, item.category, item.assetType)

        return {
          ...item,
          category,
          subCategory,
        }
      })

      if (isRefreshed) {
        return {
          ...state,
          isLoadingFetchMyItemsCreation: false,
          data: newData
        }
      }

      return {
        ...state,
        isLoadingFetchMyItemsCreation: false,
        data: [...state.data, ...newData]
      }
    }

    case FETCH_MY_ITEMS_CREATION_FAILURE: {
      return {
        ...state,
        isLoadingFetchMyItemsCreation: false,
        error: action.payload.error,
      }
    }

    // Delete item user creation
    case DELETE_ITEM_USER_CREATION_REQUEST:
    case DELETE_ITEM_USER_CREATION_FAILURE: {
      return {
        ...state,
        isLoadingDeleteItemUserCreation: loadingReducer(state.isLoadingDeleteItemUserCreation, action),
      }
    }

    case DELETE_ITEM_USER_CREATION_SUCCESS: {
      const { id } = action.payload
      const newData = [...state.data].filter((item) => item._id !== id)

      return {
        ...state,
        data: newData,
        isLoadingDeleteItemUserCreation: loadingReducer(state.isLoadingDeleteItemUserCreation, action),
      }
    }

    // Set item user creation edit
    case SET_ITEM_USER_CREATION_EDIT_REQUEST: {
      const { item } = action.payload

      return {
        ...state,
        itemUserCreationEdit: item
      }
    }

    // Edit item user creation
    case EDIT_ITEM_USER_CREATION_REQUEST:
    case EDIT_ITEM_USER_CREATION_FAILURE: {
      return {
        ...state,
        isLoadingEditItemUserCreation: loadingReducer(state.isLoadingEditItemUserCreation, action),
      }
    }

    case EDIT_ITEM_USER_CREATION_SUCCESS: {
      const { form,categories } = action.payload

      const { category, subCategory } = getObjectFromApiCategories(categories, form.category, form.subCategory)

      const itemUserCreation = {
        ...state.itemUserCreationEdit,
        name: form.name,
        description: form.description,
        image: form.image[0] as string,
        rarity: form.rarity.toString(),
        assetType: form.subCategory.toString(),
        category,
        subCategory,
      }

      return {
        ...state,
        itemUserCreationEdit: itemUserCreation as ItemUserCreationType,
        isLoadingEditItemUserCreation: loadingReducer(state.isLoadingEditItemUserCreation, action),
      }
    }

    // Fetch item user creation edit
    case FETCH_ITEM_USER_CREATION_EDIT_REQUEST:
    case FETCH_ITEM_USER_CREATION_EDIT_FAILURE: {
      return {
        ...state,
        isLoadingFetchItemUserCreationEdit: loadingReducer(state.isLoadingFetchItemUserCreationEdit, action),
      }
    }

    case FETCH_ITEM_USER_CREATION_EDIT_SUCCESS: {
      const { item, categories } = action.payload

      const { category, subCategory } = getObjectFromApiCategories(categories, item.category, item.assetType)

      const itemUserCreation = {
        ...item,
        category,
        subCategory,
      }

      return {
        ...state,
        itemUserCreationEdit: itemUserCreation,
        isLoadingFetchItemUserCreationEdit: loadingReducer(state.isLoadingFetchItemUserCreationEdit, action),
      }
    }

    // Sale item user creation success
    case SALE_ITEM_USER_CREATION_SUCCESS: {
      const { item } = action.payload
      const newData = [...state.data].filter(i => i._id !== item._id)

      return {
        ...state,
        data: newData
      }
    }

    default:
      return state
  }
}
