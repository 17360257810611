// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../../../images/search.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TextFilter .text-input input {\n  font-size: 17px;\n  line-height: 20px;\n  background: none;\n  color: white;\n  font-weight: 600;\n  font-family: var(--font-family);\n  border: none;\n  border-radius: 6px;\n  padding: 6px 6px 6px 36px;\n  outline: none;\n  width: 100%;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-position-y: center;\n  background-position-x: 8px;\n}\n\n.TextFilter .text-input input::placeholder {\n  color: var(--secondary-text);\n  font-weight: 400;\n}\n\n@media (max-width: 768px) {\n  .TextFilter {\n    margin-bottom: 4px;\n  }\n\n  .TextFilter .ui.sub.header.name {\n    font-weight: normal;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/LOM/Vendor/NFTFilters/TextFilter/TextFilter.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;EAChB,+BAA+B;EAC/B,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,WAAW;EACX,yDAAuD;EACvD,4BAA4B;EAC5B,6BAA6B;EAC7B,0BAA0B;AAC5B;;AAEA;EACE,4BAA4B;EAC5B,gBAAgB;AAClB;;AAEA;EACE;IACE,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".TextFilter .text-input input {\n  font-size: 17px;\n  line-height: 20px;\n  background: none;\n  color: white;\n  font-weight: 600;\n  font-family: var(--font-family);\n  border: none;\n  border-radius: 6px;\n  padding: 6px 6px 6px 36px;\n  outline: none;\n  width: 100%;\n  background-image: url(../../../../../images/search.svg);\n  background-repeat: no-repeat;\n  background-position-y: center;\n  background-position-x: 8px;\n}\n\n.TextFilter .text-input input::placeholder {\n  color: var(--secondary-text);\n  font-weight: 400;\n}\n\n@media (max-width: 768px) {\n  .TextFilter {\n    margin-bottom: 4px;\n  }\n\n  .TextFilter .ui.sub.header.name {\n    font-weight: normal;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
