import { useCallback, useEffect, useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Container, Responsive, Button } from '@wiicamp/decentraland-ui'
import { t } from '@wiicamp/decentraland-dapps/dist/modules/translation/utils'
import { Loader } from '@wiicamp/decentraland-ui'

import { View } from '../../../modules/ui/types'
// import { Section } from '../../../modules/vendor/decentraland'
// import { AssetType } from '../../../modules/asset/types'
import { locations } from '../../../modules/routing/locations'

import { Row } from '../../Layout/Row'
import { Column } from '../../Layout/Column'
import { NFTFilters } from '../Vendor/NFTFilters'
import { NFTSidebar } from '../Vendor/NFTSidebar'

import { Atlas } from '../Atlas'
// import { AccountSidebar } from '../AccountSidebar'
import { VendorStrip } from '../VendorStrip'
import { AssetList } from '../AssetList'

import { Props } from './AssetBrowse.types'

import { ToggleBox } from './ToggleBox'

import { ItemNFTCard } from '../ItemNFTPage/ItemNFTCard'
import { ItemNFTType } from '../../../modules/itemNFT/types'

import { MarketplaceType } from '../ItemNFTPage/ItemNFTCard/ItemNFTCard.types'

import useSearchParams from '../../../customHooks/useSearchParams'

import './AssetBrowse.scss'
import cssStyles from './style.module.scss'
import { UploadedNFTList } from './UploadedNFTList'

// const hasPrimarySales = (section?: Section) => {
//   switch (section) {
//     case Section.WEARABLES:
//     case Section.WEARABLES_HEAD:
//     case Section.WEARABLES_EYEBROWS:
//     case Section.WEARABLES_EYES:
//     case Section.WEARABLES_FACIAL_HAIR:
//     case Section.WEARABLES_HAIR:
//     case Section.WEARABLES_MOUTH:
//     case Section.WEARABLES_UPPER_BODY:
//     case Section.WEARABLES_LOWER_BODY:
//     case Section.WEARABLES_FEET:
//     case Section.WEARABLES_ACCESORIES:
//     case Section.WEARABLES_EARRING:
//     case Section.WEARABLES_EYEWEAR:
//     case Section.WEARABLES_HAT:
//     case Section.WEARABLES_HELMET:
//     case Section.WEARABLES_MASK:
//     case Section.WEARABLES_TIARA:
//     case Section.WEARABLES_TOP_HEAD: {
//       return true
//     }
//     default:
//       return false
//   }
// }

let fetched = false;

export const VIEW_MODE = {
  CREATIONS: 'creations',
  ESTATES: 'estates',
  ITEMS: 'items',
}

const defaultType = VIEW_MODE.ESTATES
const defaultPage = { currentPage: 2, isLastPage: false }
const limitItemOfPage = 20

const AssetBrowse = (props: Props) => {
  const {
    vendor,
    view,
    isMap,
    isFullscreen,
    address,
    onSetView,
    onFetchAssetsFromRoute,
    // onBrowse,
    section,
    sections,
    // assetType,
    onlyOnSale,
    viewInState,
    // mapHeight,

    // LOM NFT Items
    nftItems,
    nftItemsLoading,
    fetchMyItemsCreation,

    // Account my items
    fetchMyItems,
    myItemsLoading,
    myItems,
  } = props

  const [page, setPage] = useState({...defaultPage})

  const history = useHistory()
  const searchParams = useSearchParams()

  const viewMode = searchParams.get('type')

  const buttonShowMore = useMemo(() => {
    switch (viewMode) {
      case VIEW_MODE.CREATIONS:
        return {
          isDisable: nftItems.length < limitItemOfPage || page.isLastPage,
          isLoading: nftItemsLoading,
        }

      // case VIEW_MODE.ESTATES:
      //   return {
      //     isDisable: true,
      //     isLoading: true,
      //   }

      case VIEW_MODE.ITEMS:
        return {
          isDisable: myItems.length < limitItemOfPage || page.isLastPage,
          isLoading: myItemsLoading,
        }

      default:
        return {
          isDisable: true,
          isLoading: true,
        }
    }
  },[nftItems, nftItemsLoading, myItems, myItemsLoading, viewMode, page])

  const onClickChangeViewMode = useCallback((newValue) => () => {
    history.push(locations.currentAccountLOM({ type: newValue }))
    setPage({...defaultPage})
  }, [])

  const onLoadMore = useCallback(() => {
    const callback = (array: ItemNFTType[]) => {
      setPage((prevState) => ({
        currentPage: prevState.currentPage + 1,
        isLastPage: array.length < limitItemOfPage,
      }));
    }

    const query = {
      page: page.currentPage,
      limit: limitItemOfPage,
      isRefreshed: false,
      callback,
    }

    switch (viewMode) {
      case VIEW_MODE.CREATIONS:
        fetchMyItemsCreation(query)
        break;
      case VIEW_MODE.ESTATES:
        break;
      case VIEW_MODE.ITEMS:
        fetchMyItems(query)
        break;
      default:
        break;
    }
  }, [viewMode, page, fetchMyItemsCreation, fetchMyItems])

  useEffect(() => {
    if(!viewMode) {
      history.push(locations.currentAccountLOM({ type: defaultType }))
    }
  }, [searchParams]);

  // Kick things off
  useEffect(() => {
    onSetView(view)
  }, [onSetView, view])

  useEffect(() => {
    // Prevent fetching more than once while browsing
    if (fetched) return;

    if (viewInState !== view) return;

    fetched = true;

    onFetchAssetsFromRoute({
      vendor,
      view,
      section,
      address,
      onlyOnSale
    }, () => {
      fetched = false;
    });
  }, [
    view,
    vendor,
    section,
    address,
    onlyOnSale,
    viewInState,
    onFetchAssetsFromRoute,
  ])

  useEffect(() => {
    if (viewMode === VIEW_MODE.CREATIONS) {
      fetchMyItemsCreation({ limit: limitItemOfPage })
    }
  }, [searchParams, viewMode, fetchMyItemsCreation])

  useEffect(() => {
    if (viewMode === VIEW_MODE.ITEMS) {
      fetchMyItems({ limit: limitItemOfPage })
    }
  }, [searchParams, viewMode, fetchMyItems])

  // const handleBrowseItems = useCallback(
  //   () => onBrowse({ assetType: AssetType.ITEM }),
  //   [onBrowse]
  // )

  // const handleBrowse = useCallback(
  //   () => onBrowse({ assetType: AssetType.NFT }),
  //   [onBrowse]
  // )

  // classes
  let classes = ['AssetBrowse']
  if (isMap) {
    classes.push('is-map')
  }

  const RenderListMyItems = useCallback(() => (
    myItems.length > 0 ?
      <>
        {myItems.map((item: ItemNFTType) => (
          <ItemNFTCard
            key={`${item.id}-${item.name}`}
            {...item}
            className="mt-8"
            type={MarketplaceType.MY_ITEMS}
          />
        ))}
      </>
      : <div className="empty text-center w-full">{t('nft_list.empty')}</div>
  ), [myItems])

  return (
    <Row className={`${cssStyles.assetBrowserPage} AssetBrowse`}>
      <Column align="right" grow={true} className="w-full">
        <div className={cssStyles.assetFilter}>
          <ToggleBox
            className={`result-type-toggle ${cssStyles.filterByType}`}
            items={[
              {
                title: t(
                  view === View.ACCOUNT
                    ? 'account_page.primary_market_title'
                    : 'browse_page.primary_market_title'
                ),
                active: viewMode === VIEW_MODE.CREATIONS,
                description: t(
                  view === View.ACCOUNT
                    ? 'account_page.primary_market_subtitle'
                    : 'browse_page.primary_market_subtitle'
                ),
                onClick: onClickChangeViewMode(VIEW_MODE.CREATIONS)
              },
              {
                title: t(
                  view === View.ACCOUNT
                    ? 'account_page.secondary_market_title'
                    : 'browse_page.secondary_market_title'
                ),
                active: viewMode === VIEW_MODE.ESTATES,
                description: t(
                  view === View.ACCOUNT
                    ? 'account_page.secondary_market_subtitle' // [Todo] fix translations
                    : 'browse_page.secondary_market_subtitle' // [Todo] fix translations
                ),
                onClick: onClickChangeViewMode(VIEW_MODE.ESTATES),
              },
              {
                title: t(
                  view === View.ACCOUNT
                    ? 'account_page.tertiary_market_title'  // [Todo] fix translations
                    : 'browse_page.tertiary_market_title' // [Todo] fix translations
                ),
                active: viewMode === VIEW_MODE.ITEMS,
                description: t(
                  view === View.ACCOUNT
                    ? 'account_page.tertiary_market_subtitle' // [Todo] fix translations
                    : 'browse_page.tertiary_market_subtitle'  // [Todo] fix translations
                ),
                onClick: onClickChangeViewMode(VIEW_MODE.ITEMS),
              }
            ]}
          />

          {/* TODO: Remove false when have more than 1 vendor */}
          {false && view === View.ACCOUNT && !isFullscreen ? (
            <VendorStrip address={address!} />
          ) : null}

          {isMap && isFullscreen ? (
            <div className="blur-background">
              <Container>
                <NFTFilters isMap={isMap} />
              </Container>
            </div>
          ) : (
            <div className={cssStyles.searchWrapper}>
              <NFTFilters isMap={Boolean(isMap)} />
            </div>
          )}

          <Responsive minWidth={Responsive.onlyTablet.minWidth}>
            {view === View.ACCOUNT ? (
              null
              // TODO: Remove comment when have more than 1 vendors
              // <AccountSidebar address={address!} />
            ) : (
              <div className={cssStyles.categoriesWrapper}>
                <NFTSidebar section={section} sections={sections} />
              </div>
            )}
          </Responsive>
        </div>

        {viewMode === VIEW_MODE.CREATIONS
          ? <UploadedNFTList />
          : viewMode === VIEW_MODE.ESTATES
            ? (
              isMap ? (
                <div className="Atlas mt-10">
                  <Atlas
                    withNavigation
                    withPopup
                    showOnSale={onlyOnSale}
                    height={500}
                  />
                  {/* <div className="fullscreen-button" onClick={handleSetFullscreen} /> */}
                </div>
              ) : (
                <div className="mt-16">
                  <AssetList />
                </div>
              )
            ) : ( // VIEW MODE === ITEMS
              <div className="mt-16">
                <div className="relative -mx-4 -mt-8 Marketplace-Estates__grid">
                  {myItemsLoading ? (
                    <div className="mt-56">
                      <div className="overlay bg-transparent" />
                      <Loader size="massive" active />
                    </div>
                  ) : (
                    <RenderListMyItems />
                  )}
                </div>
              </div>
            )
        }

        {!buttonShowMore.isLoading && (
          <div className="flex justify-center mt-16 mb-10">
            <Button
              className="LOM-submit-btn"
              onClick={onLoadMore}
              disabled={buttonShowMore.isDisable}
              loading={buttonShowMore.isLoading}
            >
              Load More
            </Button>
          </div>
        )}
      </Column>
    </Row>
  )
}

export default AssetBrowse
