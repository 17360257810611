import React from 'react'
import { fromWei } from 'web3x-es/utils'
import { Item } from '@dcl/schemas'
import { Page } from '@wiicamp/decentraland-ui'
import { Wallet } from '@wiicamp/decentraland-dapps/dist/modules/wallet/types'

import { NFT } from '../../../modules/nft/types'
import { isOwnedBy } from '../../../modules/asset/utils'
import { Order } from '../../../modules/order/types'
import { Asset, AssetType } from '../../../modules/asset/types'

import { Wallet as WalletProvider } from '../Wallet'

import { AssetProviderPage } from '../AssetProviderPage'

import { BuyNFTModal } from './BuyNFTModal'
import { BuyItemModal } from './BuyItemModal'

import useWatchNetwork from '../../../customHooks/useWatchNetwork'

import { Props } from './BuyPage.types'
import './BuyPage.scss'

const BuyPage = (props: Props) => {
  const { type } = props

  const isInsufficientMANA = (
    wallet: Wallet,
    asset: Asset,
    order: Order | null
  ) =>
    !!order &&
    wallet.networks[asset.network].mana < +fromWei(order.price, 'ether')

  useWatchNetwork()

  return (
    <>
      {/* <Navbar isFullscreen /> */}
      <Page className="BuyPage">
        <WalletProvider>
          {wallet => (
            <AssetProviderPage type={type}>
              {(asset, order) => {
                const modalProps = {
                  wallet: wallet,
                  isOwner: isOwnedBy(asset, wallet),
                  hasInsufficientMANA: isInsufficientMANA(wallet, asset, order)
                }

                return type === AssetType.NFT ? (
                  <BuyNFTModal
                    nft={asset as NFT}
                    order={order}
                    {...modalProps}
                  />
                ) : type === AssetType.ITEM ? (
                  <BuyItemModal item={asset as Item} {...modalProps} />
                ) : null
              }}
            </AssetProviderPage>
          )}
        </WalletProvider>
      </Page>
      {/* <Footer /> */}
    </>
  )
}

export default React.memo(BuyPage)
