import { connect } from 'react-redux'

import UploadedNFTList from './UploadedNFTList'

import { RootState } from '../../../../modules/reducer'
import { isLoadingType } from '@wiicamp/decentraland-dapps/dist/modules/loading/selectors'
import { getIsLoadingDeleteItemUserCreation, getIsLoadingFetchMyItemsCreation, getTotalNFTItems } from '../../../../modules/uploadItem/selectors'
import { getItemsUserCreationOnSaleNeedLoading, getSaleItemUserCreationLoading } from '../../../../modules/itemNFT/selectors'
import { saleItemUserCreationRequest, SALE_ITEM_USER_CREATION_REQUEST } from '../../../../modules/itemNFT/actions'

import { MapDispatch, MapStateProps, MapDispatchProps } from './UploadedNFTList.types'
import { deleteItemUserCreationRequest, DELETE_ITEM_USER_CREATION_REQUEST, setItemUserCreationEditRequest } from '../../../../modules/uploadItem/actions'

const mapState = (state: RootState): MapStateProps => ({
  nftList: getTotalNFTItems(state),
  saleItemUserCreationLoading: isLoadingType(getSaleItemUserCreationLoading(state), SALE_ITEM_USER_CREATION_REQUEST),
  itemsUserCreationOnSaleNeedLoading: getItemsUserCreationOnSaleNeedLoading(state),
  isLoadingFetchMyItemsCreation: getIsLoadingFetchMyItemsCreation(state),
  isLoadingDeleteItemUserCreation: isLoadingType(getIsLoadingDeleteItemUserCreation(state), DELETE_ITEM_USER_CREATION_REQUEST),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  setItemUserCreationEdit: (item) => dispatch((setItemUserCreationEditRequest(item))),
  saleItemUserCreation: (item: any, price: number) => dispatch((saleItemUserCreationRequest(item, price))),
  deleteItemUserCreation: (id: string, callback: Function) => dispatch((deleteItemUserCreationRequest(id, callback))),
})

export default connect(mapState, mapDispatch)(UploadedNFTList)
