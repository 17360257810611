import React from 'react'
import { Link } from 'react-router-dom'

import { Section } from '../../../../modules/vendor/decentraland'
import { getContract } from '../../../../modules/contract/utils'
import { locations } from '../../../../modules/routing/locations'

import { Props } from './WearableCollection.types'
import styles from './WearableCollection.module.scss'

const WearableCollection = (props: Props) => {
  const { type, asset } = props
  const contract = getContract({ address: asset.contractAddress })

  return (
    <Link
      className={styles.detailCollectionTitle}
      to={locations.browseLOM({
        assetType: type,
        contracts: [contract.address],
        section: Section.WEARABLES
      })}
    >
      {contract.name}
    </Link>
  )
}

export default React.memo(WearableCollection)
