// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_badge__1-kz2 svg {\n  display: inline-block;\n  height: 16px;\n  width: 8px;\n  margin-right: 0.416rem;\n  fill: #A4A4A4;\n}\n", "",{"version":3,"sources":["webpack://src/components/LOM/Coordinate/styles.module.scss"],"names":[],"mappings":"AAAA;EAEI,qBAAqB;EACrB,YAAY;EACZ,UAAU;EACV,sBAAsB;EACtB,aAAa;AAAA","sourcesContent":[".badge {\n  svg {\n    display: inline-block;\n    height: 16px;\n    width: 8px;\n    margin-right: 0.416rem;\n    fill: #A4A4A4;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badge": "styles_badge__1-kz2"
};
export default ___CSS_LOADER_EXPORT___;
