import { connect } from 'react-redux'
import {
  enableWalletRequest,
  disconnectWallet
} from '@wiicamp/decentraland-dapps/dist/modules/wallet/actions'
import { getError, isEnabling, isConnecting } from '@wiicamp/decentraland-dapps/dist/modules/wallet/selectors'

import { RootState } from '../../../modules/reducer'
import { getWallet } from '../../../modules/wallet/selectors'

import Wallet from './Wallet'

import { MapStateProps, MapDispatchProps, MapDispatch } from './Wallet.types'

const mapState = (state: RootState): MapStateProps => ({
  hasError: !!getError(state),
  isLoading: isEnabling(state) || isConnecting(state),
  wallet: getWallet(state)
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onConnect: providerType => dispatch(enableWalletRequest(providerType)),
  onSignOut: () => dispatch(disconnectWallet())
})

export default connect(mapState, mapDispatch)(Wallet)
