import React from 'react'
import { Page } from '@wiicamp/decentraland-ui'

import { AssetType } from '../../../modules/asset/types'

import { Wallet } from '../Wallet'
import { AssetProviderPage } from '../AssetProviderPage'

import { SellModal } from './SellModal'

import useWatchNetwork from '../../../customHooks/useWatchNetwork'

import { Props } from './SellPage.types'
import './SellPage.scss'

const SellPage = (props: Props) => {
  const {
    authorizations,
    isLoading,
    isCreatingOrder,
    onNavigate,
    onCreateOrder
  } = props

  useWatchNetwork()

  return (
    <Page className="SellPage">
      <Wallet>
        {wallet => (
          <AssetProviderPage type={AssetType.NFT}>
            {(nft, order) => (
              <SellModal
                nft={nft}
                order={order}
                wallet={wallet}
                authorizations={authorizations}
                isLoading={isLoading}
                isCreatingOrder={isCreatingOrder}
                onNavigate={onNavigate}
                onCreateOrder={onCreateOrder}
              />
            )}
          </AssetProviderPage>
        )}
      </Wallet>
    </Page>
  )
}

export default React.memo(SellPage)
