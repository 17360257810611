import React from 'react'

import LayoutPage from '../Layout/LayoutPage/LayoutPage';

import { AssetBrowse } from '../AssetBrowse'
import Breadcrumb from '../Breadcrumb'
// import { ModalDetailEstate } from '../Modal/ModalDetailEstate'

import { Props } from './BrowsePage.types'
import { VendorName } from '../../../modules/vendor/types'
import { View } from '../../../modules/ui/types'
import { Section } from '../../../modules/vendor/decentraland'

import { isVendor } from '../../../modules/vendor/utils'
import useWatchNetwork from '../../../customHooks/useWatchNetwork'

// import mapImg from '../../../asset/images/marketplace/map.png'

const BrowserPage = (props: Props) => {
  const { isFullscreen, section } = props

  const vendor = isVendor(props.vendor) ? props.vendor : VendorName.DECENTRALAND

  // const [isOpen, setIsOpen] = useState(false)

  const breadcrumb = [
    { name: 'Home', link: '/' },
    { name: 'Marketplace', link: '/marketplace' },
    { name: 'Browse', active: true, icon: true, isBreakHere: true }
  ]

  // const onToggle = () => {
  //   setIsOpen(!isOpen)
  // }

  useWatchNetwork()

  return (
    <LayoutPage viewMode="white">
      <div className="flex-grow min-h-full overflow-auto Marketplace__background dark bg-black text-white">
        <div className="Page__header mt-16 lg:mt-32 mb-14 lg:mb-20">
          <Breadcrumb breadcrumb={breadcrumb} viewMode="dark" />
        </div>

        {/* <div className="pb-16 px-8 lg:px-0 border-b-2 border-gray">
          <img src={mapImg} alt="map" className="w-full" />
          <p className="text-xs-sm pl-8 mt-4">
            * New settlement - York colony - will be available in 2022. Please
            follow our Newsfeed for more information.
          </p>
        </div> */}

        <div className="pb-16 border-b-2 border-gray mt-16">
          {/* <div className="flex flex-col sm:flex-row justify-between mt-10 mb-12">
            <h2 className="uppercase text-4xl font-extrabold">
              estates <span className="text-2xl font-bold"> (281 results) </span>
            </h2>
            <div className="flex justify-end mt-8 sm:mt-0">
              <button
                style={{ height: 'fit-content' }}
                className="border border-gray text-gray uppercase px-4 pb-1 pt-2 mr-12 border-white tracking-2widest"
              >
                Price
                <img
                  src={dropdownImg}
                  alt="dropdown-icon"
                  className="h-2 inline ml-4 mt-2 align-top"
                />
              </button>
            </div>
          </div> */}

          <AssetBrowse
            vendor={vendor}
            isFullscreen={Boolean(isFullscreen)}
            view={View.MARKET}
            section={section}
            sections={[Section.WEARABLES, Section.ENS]}
          />

          {/* <ModalDetailEstate open={isOpen} onToggle={onToggle} /> */}
        </div>
      </div>
    </LayoutPage>
  )
}

export default React.memo(BrowserPage)
