import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'

import { MapStateProps, Params } from './StoryPage.types'
import StoryPage from './StoryPage'
import { RootState } from "../../../modules/reducer";

const mapState = (
  _: RootState,
  ownProps: RouteComponentProps<Params>
): MapStateProps => {
  const { slug } = ownProps.match.params

  return {
    slug: slug.toLowerCase()
  }
}

export default connect(mapState)(StoryPage)
