import { useState, memo, useMemo, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Modal } from '@wiicamp/decentraland-ui'

import { BrandAnimation } from '../../BrandAnimation'

import { Props } from './Home.types'

import { MENU } from '../../../../lib/constant'

import TeaserImage from '../../../../asset/images/video-thumbnail.png'
import { ReactComponent as IconPlay } from '../../../../images/ic-play-button.svg'

import './styles.scss'

const HomePageLOM = (props: Props) => {
  const { isShowMenu, isRendered } = props
  const [isShowModal, setIsShowModal] = useState(false)
  const isDevelopmentMode = useMemo(() => process.env.REACT_APP_ENVIRONMENT === 'development', [])

  const toggleShowingModal = useCallback(() => {
    setIsShowModal(prevState => !prevState)
  }, [])

  return (
    <div className="lg:px-4 flex flex-col h-full relative">
      <div
        className={`
          mx-auto w-max Home__brand__copyright
          ${isRendered && 'rendered'}
        `}
      >
        <span className={`lg:block hidden ${isRendered ? 'show' : 'hide'}`}>
          | Copyright © 2021 | {isDevelopmentMode ? 'Universe' : 'TB'} Labs |
        </span>
      </div>
      <div className="Home__brand lg:h-1/2">
        <div
          className={`
          Home__brand__p lg:block hidden
          ${isRendered && 'rendered'}
          `}
        >
          <p className={`${isShowMenu ? 'show' : 'hide'}`}>START MY</p>
        </div>

        <BrandAnimation isRendered={isRendered} />
      </div>
      <div
        className={`
        Home__menu h-1/2 lg:block hidden
        ${isRendered && 'rendered'}
        `}
      >
        <ol className={isShowMenu ? 'show' : 'hide'}>
          {MENU.map((item, index) => (item.isOnlyDevelopment && !isDevelopmentMode && <div />) || (
            <li key={`menu-${index}`}>
              <Link to={item.link}>{item.name.toUpperCase()}</Link>
            </li>
          ))}
        </ol>
      </div>

      <div
        className={`Home__video__btn
        ${isRendered && !isShowModal && isShowMenu && 'is-active'}`}
        onClick={toggleShowingModal}
      >
        <div className="Home__video__btn__text">
          game <br /> trailer
        </div>
        <div className="Home__video__btn__img">
          <img src={TeaserImage} alt="" className="Home__video__btn__teaser" />

          <div className="Home__video__btn__icon">
            <IconPlay />
          </div>
        </div>
      </div>
      <div className="Home__video__modal">
        <Modal
          open={isShowModal}
          className="Home__video__container"
          onClose={toggleShowingModal}
        >
          <Modal.Content>
            <video
              src="https://gateway.pinata.cloud/ipfs/QmPuRDQdmwXMERp1BU9X6ragWNyexWq9z3Vx9Wgj4LStuL"
              controls
              autoPlay
            />
          </Modal.Content>
        </Modal>
      </div>
    </div>
  )
}

export default memo(HomePageLOM)
