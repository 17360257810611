// LOM's Library

import { any } from 'prop-types'

export function customTrackHorizontal({ style = any, ...props }) {
  return (
    <div
      style={{
        ...style,
        right: 2,
        bottom: 2,
        left: 2,
        borderRadius: 4.25,
        height: 8.5
      }}
      {...props}
    />
  )
}

export function customScroll({ style = any, ...props }) {
  return (
    <div
      {...props}
      style={{
        ...style,
        backgroundColor: 'var(--color-gray)',
        borderRadius: 4.25,
        height: 8.5
      }}
    />
  )
}
