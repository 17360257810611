// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Collapsible_Collapsible__38eD4 {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.Collapsible_showMore__1L-3D {\n  margin-top: 15px;\n  justify-content: center;\n}\n\n.Collapsible_showMore__1L-3D span {\n  font-size: 15px;\n  color: var(--primary);\n  cursor: pointer;\n}\n\n.Collapsible_children__3zo7t {\n  width: 100%;\n}\n\n.Collapsible_collapsibleWrapper__3hFLx {\n  overflow: hidden;\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://src/components/Collapsible/Collapsible.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,WAAW;AACb","sourcesContent":[".Collapsible {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.showMore {\n  margin-top: 15px;\n  justify-content: center;\n}\n\n.showMore span {\n  font-size: 15px;\n  color: var(--primary);\n  cursor: pointer;\n}\n\n.children {\n  width: 100%;\n}\n\n.collapsibleWrapper {\n  overflow: hidden;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Collapsible": "Collapsible_Collapsible__38eD4",
	"showMore": "Collapsible_showMore__1L-3D",
	"children": "Collapsible_children__3zo7t",
	"collapsibleWrapper": "Collapsible_collapsibleWrapper__3hFLx"
};
export default ___CSS_LOADER_EXPORT___;
