// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LayoutModal_SellModal__2klza,\n.LayoutModal_TransferModal__3ZRne,\n.LayoutModal_CancelSaleModal__1ArYj {\n  position: absolute !important;\n  top: 0;\n  left: 0;\n  right: 0;\n  z-index: 2999;\n  min-height: 100vh;\n  overflow-x: hidden;\n}\n", "",{"version":3,"sources":["webpack://src/components/LOM/ItemNFTDetailPage/LayoutModal/LayoutModal.module.scss"],"names":[],"mappings":"AAAA;;;EAGE,6BAA6B;EAC7B,MAAM;EACN,OAAO;EACP,QAAQ;EACR,aAAa;EACb,iBAAiB;EACjB,kBAAkB;AAAA","sourcesContent":[".SellModal,\n.TransferModal,\n.CancelSaleModal {\n  position: absolute !important;\n  top: 0;\n  left: 0;\n  right: 0;\n  z-index: 2999;\n  min-height: 100vh;\n  overflow-x: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SellModal": "LayoutModal_SellModal__2klza",
	"TransferModal": "LayoutModal_TransferModal__3ZRne",
	"CancelSaleModal": "LayoutModal_CancelSaleModal__1ArYj"
};
export default ___CSS_LOADER_EXPORT___;
