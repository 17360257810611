// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccountSettingsPage_wrapper__25CXF {\n  position: relative;\n}\n\n.AccountSettingsPage_wrapper__25CXF .AccountSettingsPage_content__wdz46 {\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column;\n  min-height: 100%;\n  padding: 0 75px;\n  background-color: black;\n  overflow: auto;\n  color: white;\n}\n\n.AccountSettingsPage_wrapper__25CXF .AccountSettingsPage_content__wdz46 .AccountSettingsPage_summary__sRzbZ {\n  margin: 1.333rem 0;\n  font-size: 1.083rem;\n  line-height: 1.566rem;\n}\n\n.AccountSettingsPage_wrapper__25CXF .AccountSettingsPage_content__wdz46 .AccountSettingsPage_emailBTN__2m1jf {\n  margin: 0 0 3em;\n}\n", "",{"version":3,"sources":["webpack://src/components/LOM/AccountSettingsPage/AccountSettingsPage.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AAAA;;AADpB;EAII,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,eAAe;EACf,uBAAuB;EACvB,cAAc;EACd,YAAY;AAAA;;AAXhB;EAcM,kBAAkB;EAClB,mBAAmB;EACnB,qBAAqB;AAAA;;AAhB3B;EAqBQ,eAAe;AAAA","sourcesContent":[".wrapper {\n  position: relative;\n\n  .content {\n    flex-grow: 1;\n    display: flex;\n    flex-direction: column;\n    min-height: 100%;\n    padding: 0 75px;\n    background-color: black;\n    overflow: auto;\n    color: white;\n\n    .summary {\n      margin: 1.333rem 0;\n      font-size: 1.083rem;\n      line-height: 1.566rem;\n    }\n\n    .email {\n      &BTN {\n        margin: 0 0 3em;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "AccountSettingsPage_wrapper__25CXF",
	"content": "AccountSettingsPage_content__wdz46",
	"summary": "AccountSettingsPage_summary__sRzbZ",
	"emailBTN": "AccountSettingsPage_emailBTN__2m1jf"
};
export default ___CSS_LOADER_EXPORT___;
