import { connect } from 'react-redux'
import { isLoadingType } from '@wiicamp/decentraland-dapps/dist/modules/loading/selectors'

import ItemNFTPage from './ItemNFTPage'

import { RootState } from '../../../modules/reducer'
import {
  FETCH_ITEMS_NFT_REQUEST,
  fetchItemsNFTRequest,
  FETCH_CATEGORIES_ITEMS_NFT_REQUEST,
  fetchCategoriesItemsNFTRequest,
  fetchUserCreationItemsRequest,
  fetchResoldItemsNFTRequest,
} from '../../../modules/itemNFT/actions'
import {
  getLoading,
  getItemsNFT,
  getCategoriesItemsNFTLoading,
  getCategoriesItemsNFT,
  getFetchItemsMarketplaceLoading
} from '../../../modules/itemNFT/selectors'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps
} from './ItemNFTPage.types'

const mapState = (state: RootState): MapStateProps => ({
  itemsNFTLoading: isLoadingType(getLoading(state), FETCH_ITEMS_NFT_REQUEST),
  itemsNFT: getItemsNFT(state),
  categoriesItemNFTLoading: isLoadingType(getCategoriesItemsNFTLoading(state), FETCH_CATEGORIES_ITEMS_NFT_REQUEST),
  categoriesItemNFT: getCategoriesItemsNFT(state),
  fetchItemsMarketplaceLoading: getFetchItemsMarketplaceLoading(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  fetchItemsNFT: query => dispatch(fetchItemsNFTRequest(query)),
  fetchResoldItemsNFT: query => dispatch(fetchResoldItemsNFTRequest(query)),
  fetchCategoriesItemsNFT: query => dispatch(fetchCategoriesItemsNFTRequest(query)),
  fetchUserCreationItems: query => dispatch(fetchUserCreationItemsRequest(query)),
})

export default connect(mapState, mapDispatch)(ItemNFTPage)
