import React, { useCallback } from 'react'
import {
  t,
  T
} from '@wiicamp/decentraland-dapps/dist/modules/translation/utils'
import { Page, Header, Button } from '@wiicamp/decentraland-ui'
import { useHistory } from 'react-router'

import { AssetType } from '../../../modules/asset/types'
import { locations } from '../../../modules/routing/locations'
import { getAssetName } from '../../../modules/asset/utils'
import { formatMANA } from '../../../lib/mana'

import { Wallet } from '../Wallet'
import { Mana } from '../../Mana'
import { AssetProviderPage } from '../AssetProviderPage'
import { AssetAction } from '../AssetAction'

import useWatchNetwork from '../../../customHooks/useWatchNetwork'

import { Props } from './CancelSalePage.types'
import './CancelSalePage.scss'

const CancelSalePage = (props: Props) => {
  const { isLoading, onNavigate, onCancelOrder } = props
  const history = useHistory()

  const onCheckGoBack = useCallback((nft: any) => () => {
    if (history.length === 2) {
      onNavigate(locations.nftLOM(nft.contractAddress, nft.tokenId))
    } else {
      history.goBack()
    }
  }, [history, onNavigate])

  useWatchNetwork()

  return (
    <Page className="CancelSalePage">
      <Wallet>
        {wallet => (
          <AssetProviderPage type={AssetType.NFT}>
            {(nft, order) => {
              let subtitle
              let isDisabled = false
              const name = getAssetName(nft)
              if (!order) {
                isDisabled = true
                subtitle = (
                  <T
                    id="cancel_sale_page.not_for_sale"
                    values={{ name: <b>{name}</b> }}
                  />
                )
              } else if (order.owner !== wallet.address) {
                isDisabled = true
                subtitle = (
                  <T
                    id="cancel_sale_page.invalid_owner"
                    values={{ name: <b>{name}</b> }}
                  />
                )
              } else {
                subtitle = (
                  <T
                    id="cancel_sale_page.subtitle"
                    values={{
                      name: <b>{name}</b>,
                      amount: (
                        <Mana network={nft.network} inline>
                          {formatMANA(order.price)}
                        </Mana>
                      )
                    }}
                  />
                )
              }

              return (
                <AssetAction asset={nft}>
                  <Header size="large">{t('cancel_sale_page.title')}</Header>
                  <div className="subtitle">{subtitle}</div>
                  <div className="buttons">
                    <Button className="LOM-cancel-btn" onClick={onCheckGoBack(nft)}>
                      {t('global.cancel')}
                    </Button>

                    <Button
                      className="LOM-cancel-btn"
                      primary
                      loading={isLoading}
                      disabled={isDisabled || isLoading}
                      onClick={() => onCancelOrder(order!, nft)}
                    >
                      {t('cancel_sale_page.submit')}
                    </Button>
                  </div>
                </AssetAction>
              )
            }}
          </AssetProviderPage>
        )}
      </Wallet>
    </Page>
  )
}

export default React.memo(CancelSalePage)
