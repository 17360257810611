import React, { useState, useCallback, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Profile } from '@wiicamp/decentraland-dapps/dist/containers'

import useOnClickOutside from '../../../customHooks/useOnClickOutside'
import { locations } from '../../../modules/routing/locations'
// import { formatMANA } from '../../../lib/mana'
import { Mana } from '../../Mana'

import { Props } from './MenuDropdown.types'
import cssStyles from './styles.module.scss'

import { ReactComponent as IconAssets } from '../../../images/dropdown-icon/ic-assets.svg'
import { ReactComponent as IconBell } from '../../../images/dropdown-icon/ic-bell.svg'
import { ReactComponent as IconBids } from '../../../images/dropdown-icon/ic-bids.svg'
import { ReactComponent as IconLogout } from '../../../images/dropdown-icon/ic-logout.svg'
import { ReactComponent as IconSetting } from '../../../images/dropdown-icon/ic-setting.svg'
import { ReactComponent as IconTicket } from '../../../images/dropdown-icon/ic-ticket.svg'
import { ReactComponent as IconUser } from '../../../images/dropdown-icon/ic-user.svg'
import { ReactComponent as IconLand } from '../../../images/dropdown-icon/ic-land.svg'
import { ReactComponent as IconCreate } from '../../../asset/icons/ic-create.svg'
// import { ReactComponent as IconUpload } from '../../../asset/icons/ic-upload.svg'
import { shortenAddress } from '../../../modules/wallet/utils'
import { isDevelopment } from '../../../lib/environment'

const MenuDropdown = (props: Props) => {
  const {
    wallet,
    onSignOut,
    className,
    dispatchSetLogoutWebsiteRequest,
  } = props
  const [isShowUserDropdown, setIsShowUserDropdown] = useState(false)
  const userDropdownRef = useRef(null)

  const onClickOutside = useCallback(() => {
    if (isShowUserDropdown) setIsShowUserDropdown(false)
  }, [userDropdownRef, isShowUserDropdown]) // eslint-disable-line react-hooks/exhaustive-deps

  const onClickUserDropdown = useCallback(() => {
    setIsShowUserDropdown(prevState => !prevState)
  }, [userDropdownRef]) // eslint-disable-line react-hooks/exhaustive-deps

  const onClickLogout = () => {
    onSignOut()
    dispatchSetLogoutWebsiteRequest()
    setIsShowUserDropdown(false)
  }

  useOnClickOutside(userDropdownRef, onClickOutside)

  if (wallet)
    return (
      <div className={`${cssStyles.userDropdown} ${className}`}>
        <div ref={userDropdownRef} className={cssStyles.userDropdownWrapper}>
          <div onClick={onClickUserDropdown} className={cssStyles.userDropdownAvatarWrapper}>
            <Profile
              size="large"
              address={wallet?.address || ''}
              imageOnly
              // hasPopup
              inline={false}
            />
          </div>

          <ul
            className={`${cssStyles.userDropdownList} ${isShowUserDropdown &&
              cssStyles.userDropdownListActive}`}
          >
            <li className={cssStyles.userDropdownItem}>
              <div className={cssStyles.userDropdownItemInfo}>
                <div>
                  {shortenAddress(wallet?.address || '', 5, 4)}
                </div>
                <div className="text-right">
                  {wallet?.network && (
                    <Mana network={wallet?.network} size="tiny">
                      {wallet?.networks[wallet?.network].mana || ''}
                    </Mana>
                  )}
                </div>
              </div>
            </li>
            <li className={cssStyles.userDropdownItem}>
              <Link
                to={locations.activityLOM()}
                className={cssStyles.userDropdownItemLink}
              >
                <IconBell />
                <span>Activity</span>
              </Link>
            </li>
            <li className={cssStyles.userDropdownItem}>
              <Link to={locations.currentAccountLOM()} className={cssStyles.userDropdownItemLink}>
                <IconAssets />
                <span>My Assets</span>
              </Link>
            </li>
            <li className={cssStyles.userDropdownItem}>
              <Link to={locations.myTicketsLOM(wallet?.address || '')} className={cssStyles.userDropdownItemLink}>
                <IconTicket />
                <span>My Tickets</span>
              </Link>
            </li>
            <li className={cssStyles.userDropdownItem}>
              <Link to={locations.bidsLOM()} className={cssStyles.userDropdownItemLink}>
                <IconBids />
                <span>My Bids</span>
              </Link>
            </li>
            <li className={cssStyles.userDropdownItem}>
              <Link to={locations.accountSettingsLOM()} className={cssStyles.userDropdownItemLink}>
                <IconUser />
                <span>Account</span>
              </Link>
            </li>
            <li className={cssStyles.userDropdownItem}>
              <Link to={locations.estateBuilderLOM()} className={cssStyles.userDropdownItemLink}>
                <IconLand />
                <span>Estate Builder</span>
              </Link>
            </li>
            {isDevelopment && <li className={cssStyles.userDropdownItem}>
              <a href={locations.itemCreatorLOM()} target="_blank" className={cssStyles.userDropdownItemLink} rel="noreferrer">
                <IconCreate />
                <span>Item Creator</span>
              </a>
            </li>}
            {/* <li className={cssStyles.userDropdownItem}>
              <Link to={locations.createNFTItemLOM()} className={cssStyles.userDropdownItemLink}>
                <IconUpload />
                <span>Create NFT Item</span>
              </Link>
            </li> */}
            <li className={cssStyles.userDropdownItem}>
              <Link to={locations.settingsLOM()} className={cssStyles.userDropdownItemLink}>
                <IconSetting />
                <span>Setting</span>
              </Link>
            </li>
            <li className={cssStyles.userDropdownItem}>
              <div
                className={cssStyles.userDropdownItemLink}
                onClick={onClickLogout}
              >
                <IconLogout />
                <span>Sign Out</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    )

  return null
}

export default React.memo(MenuDropdown)
