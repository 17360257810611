import React from 'react'
import { Container } from '@wiicamp/decentraland-ui'

import { getAssetName } from '../../../../modules/asset/utils'

import { PageHeader } from '../../../PageHeader'
import { Row } from '../../../Layout/Row'
import { Column } from '../../../Layout/Column'

import { AssetImage } from '../../AssetImage'
import { Title } from '../Title'
import { Owner } from '../Owner'
import { Actions } from '../Actions'
import { Network } from '../Network'
import { OrderDetails } from '../OrderDetails'

import { Props } from './PictureFrameDetail.types'
import styles from './PictureFrameDetail.module.scss'

const PictureFrameDetail = (props: Props) => {
  const { nft } = props

  return (
    <div className={styles.detail}>
      <section className={styles.sectionIntroduction}>
        <div>
          <Title>{getAssetName(nft)}</Title>
        </div>

        <Owner asset={nft} />
      </section>

      <PageHeader>
        <AssetImage asset={nft} />
      </PageHeader>

      <Container className={styles.detailMarketInformation}>
        <Row>
          <Column align="left" grow={true}>
            <Network asset={nft} />
            <OrderDetails nft={nft} />
          </Column>

          <Column align="right">
            <Actions nft={nft} />
          </Column>
        </Row>
      </Container>
    </div>
  )
}

export default React.memo(PictureFrameDetail)
