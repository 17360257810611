import { memo, useState, useMemo, useCallback, useEffect } from 'react'
import { ProviderType } from 'decentraland-connect'
import { Link } from 'react-router-dom'
import {
  T,
  t,
} from '@wiicamp/decentraland-dapps/dist/modules/translation/utils'
import {
  Button,
  LoginModal,
  LoginModalOptionType,
  Modal,
} from '@wiicamp/decentraland-ui'
import { toast } from 'react-toastify'

import LayoutPage from '../Layout/LayoutPage/LayoutPage'

import Breadcrumb from '../Breadcrumb'
import { Row } from '../../Layout/Row'
import { Column } from '../../Layout/Column'
import { ToggleBox } from '../AssetBrowse/ToggleBox'

import { TicketsList } from './TicketsList'

import { TICKET_TYPE } from './constants'
import { locations } from '../../../modules/routing/locations'

import useWatchNetwork from '../../../customHooks/useWatchNetwork'
import { ReactComponent as IconClose } from '../../../images/ic-close.svg'

import { Props } from './TicketPage.types'

import moduleStyles from './TicketPage.module.scss'

const TicketsPage = (props: Props) => {
  const {
    ticketLoading,
    isLoading,
    wallet,
    onConnect,
    hasError,
    generateTicketRequest,
    isMintAirdropBefore,
    checkWalletIsMintAirdropRequest,
    isOnClaimList,
    checkWalletIsOnClaimListRequest,
    mintTicketAirdropRequest
  } = props

  const [activeToggleTab, setActiveToggleTab] = useState(TICKET_TYPE.ENTRANCE)
  const [isReadyToStart, setIsReadyToStart] = useState(false)
  const [isShowLoginModal, setIsShowLoginModal] = useState(false)
  const [isShowWarningModal, setIsShowWarningModal] = useState(false)

  const breadcrumb = useMemo(
    () => [
      { name: 'Home', link: '/' },
      { name: 'Marketplace', link: '/marketplace' },
      { name: 'Tickets', active: true, isBreakHere: true }
    ],
    []
  )

  const onActiveToggleTab = useCallback(
    toggleKey => () => {
      if (toggleKey !== activeToggleTab) {
        setActiveToggleTab(toggleKey)
        setIsReadyToStart(false)
      }
    },
    [activeToggleTab]
  )

  const onToggleWarningModal = useCallback((newValue) => () => {
    setIsShowWarningModal(newValue)
  }, [])

  const onGenerateTicketSuccess = useCallback(() => {
    toast(
      <div className={moduleStyles.ticketNotifySuccess}>
        <h2>congratulation!</h2>

        <p>You have successfully created your ticket.</p>

        <div className="flex mt-4 justify-center">
          <Button
            as={Link}
            className="LOM-submit-btn"
            to={locations.myTicketsLOM(wallet?.address || '')}
          >
            Check the ticket
          </Button>
        </div>
      </div>
    )
  }, []) // eslint-disable-line

  const onToggleLoginModal = useCallback(
    toggleKey => () => {
      setIsShowLoginModal(toggleKey)
    },
    []
  )

  const onReadyToStart = useCallback(
    (toggleKey: boolean) => () => {
      setIsReadyToStart(toggleKey)
      onToggleWarningModal(true)()

      // if (!wallet || !wallet.address) {
      //   toast(
      //     <div>
      //       <h2 className="capitalize text-center mb-3 text-black">
      //         Action denied!
      //       </h2>

      //       <p className="my-3">
      //         You need to connect your wallet with LOM first!
      //       </p>

      //       <div className="flex justify-center">
      //         <Button className="LOM-submit-btn uppercase" onClick={onToggleLoginModal(true)}>
      //           Connect wallet
      //         </Button>
      //       </div>
      //     </div>
      //   )
      // }

      // if (nameObj) {
      //   generateTicketRequest({
      //     name: `${nameObj.firstName} ${nameObj.lastName}`,
      //     callback: onGenerateTicketSuccess
      //   })
      // }

    },
    [generateTicketRequest, onGenerateTicketSuccess, wallet, onToggleLoginModal] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const onConnectWallet = useCallback(
    (loginType: LoginModalOptionType) => () => {
      let providerType: ProviderType

      switch (loginType) {
        case LoginModalOptionType.METAMASK:
        case LoginModalOptionType.DAPPER:
        case LoginModalOptionType.SAMSUNG:
          providerType = ProviderType.INJECTED
          break
        case LoginModalOptionType.FORTMATIC:
          providerType = ProviderType.FORTMATIC
          break
        case LoginModalOptionType.WALLET_CONNECT:
          providerType = ProviderType.WALLET_CONNECT
          break
        default:
          throw new Error(`Invalid login type ${loginType}`)
      }

      onConnect(providerType)
    },
    [onConnect]
  )

  useEffect(() => {
    setIsShowLoginModal(false)

    if (wallet) {
      checkWalletIsOnClaimListRequest()
      checkWalletIsMintAirdropRequest()
    }
  }, [wallet, checkWalletIsOnClaimListRequest, checkWalletIsMintAirdropRequest])

  useWatchNetwork()

  return (
    <>
      <LayoutPage viewMode="white">
        <div
          className={`overflow-auto Marketplace__background bg-none ${moduleStyles.marketTicket}`}
        >
          <div className="Page__header mt-8">
            <Breadcrumb breadcrumb={breadcrumb} viewMode="dark" />
          </div>

          <div className="border-b-2 border-gray mb-8">
            <Row>
              <Column align="right" grow={true}>
                <div className={moduleStyles.toggleWrapper}>
                  <ToggleBox
                    className={moduleStyles.toggleBox}
                    items={[
                      {
                        title: t('tickets_page.toggle_box.entrance.title'),
                        active: activeToggleTab === TICKET_TYPE.ENTRANCE,
                        description: '',
                        onClick: onActiveToggleTab(TICKET_TYPE.ENTRANCE)
                      },
                      {
                        title: t('tickets_page.toggle_box.traveling.title'),
                        active: activeToggleTab === TICKET_TYPE.TRAVELING,
                        description: '',
                        onClick: onActiveToggleTab(TICKET_TYPE.TRAVELING),
                        disabled: true
                      },
                      {
                        title: t('tickets_page.toggle_box.special.title'),
                        active: activeToggleTab === TICKET_TYPE.SPECIAL,
                        description: '',
                        onClick: onActiveToggleTab(TICKET_TYPE.SPECIAL),
                        disabled: true
                      }
                    ]}
                  />

                  <div className={moduleStyles.pageContent}>
                    <div className={moduleStyles.toggleTitle}>
                      {t(`tickets_page.toggle_box.${activeToggleTab}.heading`)}
                    </div>
                    <div className={moduleStyles.toggleText}>
                      {isReadyToStart ? (
                        <T
                          id="tickets_page.toggle_box.congratulation"
                          values={{
                            link_receipt: (
                              <Link
                                to="/"
                                className="link-receipt"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                here
                              </Link>
                            )
                          }}
                        />
                      ) : (
                        t(
                          `tickets_page.toggle_box.${activeToggleTab}.description`
                        )
                      )}
                    </div>
                  </div>
                  <div>
                    <TicketsList
                      isReadyToStart={isReadyToStart}
                      onReadyToStart={onReadyToStart}
                      activedToggleTab={activeToggleTab}
                      generateTicketRequest={generateTicketRequest}
                      ticketLoading={ticketLoading}
                      isMintAirdropBefore={isMintAirdropBefore}
                      isOnClaimList={isOnClaimList}
                      mintTicketAirdropRequest={mintTicketAirdropRequest}
                    />
                  </div>
                </div>
              </Column>
            </Row>
          </div>
        </div>
      </LayoutPage>
      <LoginModal
        open={isShowLoginModal}
        // message="This is a custom persistent message"
        hasError={hasError}
        loading={isLoading}
        onClose={onToggleLoginModal(false)}
      >
        <LoginModal.Option
          type={LoginModalOptionType.METAMASK}
          onClick={onConnectWallet(LoginModalOptionType.METAMASK)}
        />
        {/* <LoginModal.Option type={LoginModalOptionType.DAPPER} />
        <LoginModal.Option type={LoginModalOptionType.SAMSUNG} />
        <LoginModal.Option type={LoginModalOptionType.FORTMATIC} />
        <LoginModal.Option type={LoginModalOptionType.WALLET_CONNECT} /> */}
      </LoginModal>
      <Modal
        open={isShowWarningModal}
        onClose={onToggleWarningModal(false)}
      >
        <div className="relative">
          <div className="absolute right-0 top-0 p-2">
            <IconClose
              fill="#fff"
              className="h-8 w-8 cursor-pointer"
              onClick={onToggleWarningModal(false)}
            />
          </div>
        </div>

        <Modal.Header>Coming soon...</Modal.Header>
        {/* <Modal.Content>
          Ticket will be on sale in May 2022
        </Modal.Content> */}
      </Modal>
    </>
  )
}

export default memo(TicketsPage)
