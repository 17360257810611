import { RootState } from '../reducer'

export const getState = (state: RootState) => state.ticket
export const getTickets = (state: RootState) => getState(state).data
export const getError = (state: RootState) => getState(state).error
export const getLoading = (state: RootState) => getState(state).loading
export const getFetchLoading = (state: RootState) => getState(state).fetchLoading
export const getTransferLoading = (state: RootState) => getState(state).transferLoading
export const getUpdateLoading = (state: RootState) => getState(state).updateLoading
export const getIsMintAirdrop = (state: RootState) => getState(state).isMintAirdropBefore
export const getIsOnClaimList = (state: RootState) => getState(state).isOnClaimList
export const getTicketDetail = (state: RootState) => getState(state).ticketDetail
export const getTicketDetailLoading = (state: RootState) => getState(state).loadingTicketDetail
