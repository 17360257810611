import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Container, Header, Stats } from '@wiicamp/decentraland-ui'
import { t } from '@wiicamp/decentraland-dapps/dist/modules/translation/utils'
import { Rarity } from '@dcl/schemas'

import { locations } from '../../../../modules/routing/locations'
import { getAssetName } from '../../../../modules/asset/utils'

import { formatMANA } from '../../../../lib/mana'

import { Row } from '../../../Layout/Row'
import { Column } from '../../../Layout/Column'
import { AssetImage } from '../../../AssetImage'
import { PageHeader } from '../../../PageHeader'
import { Mana } from '../../../Mana'

import { Title } from '../Title'
import { Description } from '../Description'
import { Owner } from '../Owner'
import { WearableHighlights } from '../WearableHighlights'
import { WearableCollection } from '../WearableCollection'
import { WearableRarity } from '../WearableRarity'

import { Network } from '../Network'

import { AssetType } from '../../../../modules/asset/types'

import { Props } from './ItemDetail.types'
import styles from './ItemDetail.module.scss'

const ItemDetail = (props: Props) => {
  const { item } = props
  const wearable = item.data.wearable!
  const canBuy = item.isOnSale && item.available > 0

  return (
    <div className={styles.detail}>
      <section className={styles.sectionIntroduction}>
        <div>
          <Title>{getAssetName(item)}</Title>

          <WearableCollection type={AssetType.ITEM} asset={item} />

          <WearableRarity type={AssetType.ITEM} wearable={wearable} />
        </div>

        <Owner asset={item} />
      </section>

      <PageHeader>
        <AssetImage asset={item} />
      </PageHeader>

      <Container className={styles.detailMarketInformation}>
        <Description text={wearable.description} />

        <Row>
          <Column align="left" grow={true}>
            <Network asset={item} />
            {item.isOnSale ? (
              <>
                <Stats title={t('asset_page.price')}>
                  <Mana network={item.network} withTooltip>
                    {formatMANA(item.price)}
                  </Mana>
                </Stats>

                <Stats title={t('asset_page.available')}>
                  {item.available > 0 ? (
                    <Header>
                      {item.available.toLocaleString()}
                      <span className={styles.supply}>
                        /{Rarity.getMaxSupply(item.rarity).toLocaleString()}
                      </span>
                    </Header>
                  ) : (
                    t('asset_page.sold_out')
                  )}
                </Stats>
              </>
            ) : null}
          </Column>

          <Column align="right">
            {canBuy ? (
              <div className={styles.detailButtonGroup}>
                <Button
                  className={styles.detailBuyButton}
                  as={Link}
                  to={locations.buyLOM(
                    AssetType.ITEM,
                    item.contractAddress,
                    item.itemId
                  )}
                  primary
                >
                  {t('asset_page.actions.buy')}
                </Button>
              </div>
            ) : null}
          </Column>
        </Row>

        <WearableHighlights type={AssetType.ITEM} wearable={wearable} />
      </Container>
    </div>
  )
}

export default React.memo(ItemDetail)
