// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../images/pin-land.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Coordinate_pin__1IxsY {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  width: 17px;\n  height: 16px;\n  background-size: 19px;\n  background-position: -2px -1px;\n}\n", "",{"version":3,"sources":["webpack://src/components/Coordinate/Coordinate.module.css"],"names":[],"mappings":"AAAA;EACE,yDAAgD;EAChD,WAAW;EACX,YAAY;EACZ,qBAAqB;EACrB,8BAA8B;AAChC","sourcesContent":[".pin {\n  background-image: url(../../images/pin-land.svg);\n  width: 17px;\n  height: 16px;\n  background-size: 19px;\n  background-position: -2px -1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pin": "Coordinate_pin__1IxsY"
};
export default ___CSS_LOADER_EXPORT___;
