import { memo, useCallback, useRef, useState, useMemo, useEffect } from 'react'
import { Rarity } from '@dcl/schemas'
import { Modal, Button, Field, Loader } from '@wiicamp/decentraland-ui'
import { useLocation } from 'react-router-dom'

import { ReactComponent as IconRarity } from '../../../../asset/icons/ic-rarity.svg'
import { ReactComponent as CloseIcon } from '../../../../images/ic-close.svg'
import { ReactComponent as DownloadIcon } from '../../../../images/ic-download.svg'
import { ReactComponent as FacebookIcon } from '../../../../images/ic-social-facebook.svg'
import { ReactComponent as TelegramIcon } from '../../../../images/ic-social-telegram.svg'
import { ReactComponent as TwitterIcon } from '../../../../images/ic-social-twitter.svg'

import cssStyles from './TicketItem.module.scss'

import { Props } from './TicketItem.types'

import { downloadImage } from '../../../../constants'

const nameObjDefault = {
  firstName: '',
  lastName: '',
}

const TicketItem = (props: Props) => {
  const {
    ticketId,
    name,
    ownerName,
    description,
    image,
    className,
    isCurrentAccount,
    onTransfer,
    transferTicketLoading,
    onUpdate,
    updateTicketLoading,
  } = props

  const [isOpenModalDetail, setIsOpenModalDetail] = useState(false)
  const [isShowInputTransfer, setIsShowInputTransfer] = useState(false)
  const [isShowInputUpdate, setIsShowInputUpdate] = useState(false)
  const [walletAddress, setWalletAddress] = useState('')
  const [nameObj, setNameObj] = useState({ ...nameObjDefault })
  const imageRef = useRef<HTMLDivElement>(document.createElement(`div`))

  const rarityText = Rarity.COMMON
  const [light, dark] = Rarity.getGradient(rarityText)
  const background = `linear-gradient(-125deg,${light} 0%,${dark} 100%)`

  const cssStyle = { '--fillColor': dark } as React.CSSProperties

  const { search } = useLocation()
  const { ticketId: ticketIdFromURL } = useMemo(() => Object.fromEntries(new URLSearchParams(search)), [search])

  const onChangeState = useCallback(
    (newValue, callback) => () => {
      callback(newValue)
    }, [])

  const onChangeNameObj = useCallback(
    (key: string, newValue: string) => {
      setNameObj({ ...nameObj, [key]: newValue })
    }, [nameObj])

  const onTransferTicket = useCallback(() => {
    onTransfer({
      to: walletAddress,
      tokenId: ticketId,
      name: ownerName,
      callback: onChangeState(false, setIsOpenModalDetail)
    })
  }, [onTransfer, ticketId, walletAddress, onChangeState, ownerName])

  const onUpdateTicket = useCallback(() => {
    onUpdate({
      tokenId: ticketId,
      name: `${nameObj.firstName} ${nameObj.lastName}`,
      callback: onChangeState(false, setIsOpenModalDetail)
    })
  }, [nameObj.firstName, nameObj.lastName, ticketId, onChangeState, onUpdate])

  const onClickShareTicket = useCallback((shareKey) => () => {
    const linkTicket = `${window.location.origin}/ticket/${ticketId}`

    switch (shareKey) {
      case 'facebook':
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${linkTicket}`)
        break

      case 'twitter':
        window.open(`http://twitter.com/share?url=${linkTicket}`)
        break

      case 'telegram':
        window.open(`https://t.me/share?url=${linkTicket}`)
        break

      default:
        break
    }
  }, [ticketId])

  const onClickDownloadImage = useCallback(async () => {
    downloadImage(image, name)
  }, [image, name])

  useEffect(() => {
    if (Number(ticketIdFromURL) === Number(ticketId)) {
      setIsOpenModalDetail(true)
    }
  }, []) // eslint-disable-line

  const renderBtnGroup = () => {
    if (isShowInputTransfer) {
      return (
        <div
          className={`${cssStyles.btnGroups} flex items-center justify-evenly mt-6`}
        >
          <Button
            className="LOM-cancel-btn"
            onClick={onChangeState(false, setIsShowInputTransfer)}
          >
            Cancel Transfer
          </Button>

          <Button
            className="LOM-submit-btn"
            disabled={transferTicketLoading || !walletAddress}
            onClick={onTransferTicket}
          >
            Transfer Now
          </Button>
        </div>
      )
    }

    if (isShowInputUpdate) {
      return (
        <div
          className={`${cssStyles.btnGroups} flex items-center justify-evenly mt-6`}
        >
          <Button
            className="LOM-cancel-btn"
            onClick={onChangeState(false, setIsShowInputUpdate)}
          >
            Cancel Update
          </Button>

          <Button
            className="LOM-submit-btn"
            disabled={!nameObj.firstName || !nameObj.lastName || updateTicketLoading}
            onClick={onUpdateTicket}
          >
            Update
          </Button>
        </div>
      )
    }

    return (
      <div
        className={`${cssStyles.btnGroups} flex items-center justify-evenly mt-6`}
      >
        <Button
          className="LOM-cancel-btn"
          onClick={onChangeState(true, setIsShowInputUpdate)}
        >
          Update Ticket
        </Button>

        <Button
          className="LOM-submit-btn"
          onClick={onChangeState(true, setIsShowInputTransfer)}
        >
          Transfer Ticket
        </Button>
      </div>
    )
  }

  return (
    <>
      <div
        className={`${cssStyles.AssetCardLink} ${className}`}
        onClick={onChangeState(true, setIsOpenModalDetail)}
      >
        <div
          className={`${cssStyles.AssetCard} cursor-pointer`}
          style={cssStyle}
        >
          <div className={cssStyles.AssetCardContainer} style={{ background }}>
            <div className={cssStyles.AssetCardWrapper}>
              <div className={cssStyles.AssetCardContent}>
                <div className={cssStyles.AssetCardInfo}>
                  <div className={cssStyles.AssetCardTime}>
                    <span>
                      <div className={cssStyles.AssetCardExpired}>
                        {`#${ticketId}`}
                      </div>
                    </span>
                    <div className={cssStyles.AssetCardHolder}>
                      {/* <Profile address="" textOnly inline={false} /> */}
                      {/* <sup>[martian]</sup> */}
                    </div>
                  </div>

                  <div className={cssStyles.AssetCardName}>
                    {name.toUpperCase()}
                  </div>
                  <div className={cssStyles.AssetCardMaterial}>
                    <div>Ticket</div>
                  </div>
                </div>

                <div className={cssStyles.AssetCardImage}>
                  <div
                    ref={imageRef}
                    className="absolute top-0 left-0 w-full h-full"
                  >
                    <img src={image} alt={name} className="w-full h-full" />
                  </div>
                </div>

                <div className={cssStyles.AssetCardQuality}>
                  <div className={cssStyles.AssetCardIconMint}></div>
                  <div className={cssStyles.AssetCardValue}>
                    <span>{description}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className={cssStyles.AssetCardRarity}>
              <div className={cssStyles.AssetCardRarityWrapper}>
                <div className={cssStyles.AssetCardRarityContent}>
                  <IconRarity />
                </div>

                <div className={cssStyles.AssetCardRarityText}>
                  <span>{rarityText}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={isOpenModalDetail}
        onClose={onChangeState(false, setIsOpenModalDetail)}
      >
        <div className="relative">
          <div className="absolute right-0 top-0 p-2">
            <CloseIcon
              fill="#fff"
              className="h-8 w-8 cursor-pointer"
              onClick={onChangeState(false, setIsOpenModalDetail)}
            />
          </div>
        </div>

        <Modal.Header>Ticket: #{ticketId}</Modal.Header>

        <Modal.Content className="relative">
          {(transferTicketLoading || updateTicketLoading) && <Loader size="massive" active />}

          <div className={cssStyles.modalImageWrapper}>
            <div className={cssStyles.modalImageContent}>
              <div
                className={cssStyles.modalImageDownload}
                onClick={onClickDownloadImage}
              >
                <DownloadIcon />
              </div>

              <div
                className={cssStyles.modalImageShare}
                onClick={onClickShareTicket('facebook')}
              >
                <FacebookIcon />
              </div>
              <div
                className={cssStyles.modalImageShare}
                onClick={onClickShareTicket('twitter')}
              >
                <TwitterIcon />
              </div>
              <div
                className={cssStyles.modalImageShare}
                onClick={onClickShareTicket('telegram')}
              >
                <TelegramIcon />
              </div>
            </div>
            <img src={image} alt={name} className="w-full h-full" />
          </div>

          {isShowInputTransfer && (
            <div className={`${cssStyles.transferField} mt-6`}>
              <Field
                label="Wallet Address *"
                placeholder="/address"
                onChange={(_event, _props) =>
                  onChangeState(_props.value, setWalletAddress)()
                }
                value={walletAddress}
              />
            </div>
          )}

          {isShowInputUpdate && (
            <div className={`${cssStyles.transferField} ${cssStyles.updateField} mt-6`}>
              <Field
                label="First Name *"
                placeholder="/first name"
                onChange={(_event, props) =>
                  onChangeNameObj('firstName', props.value)
                }
                value={nameObj.firstName}
              />

              <Field
                label="Last Name *"
                placeholder="/last name"
                onChange={(_event, props) =>
                  onChangeNameObj('lastName', props.value)
                }
                value={nameObj.lastName}
              />
            </div>
          )}

          {isCurrentAccount && renderBtnGroup()}
        </Modal.Content>
      </Modal>
    </>
  )
}

export default memo(TicketItem)
