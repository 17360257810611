import { put, takeEvery } from '@redux-saga/core/effects'
import { call } from 'redux-saga/effects'

import { articleApi } from '../vendor/article/api'
import {
  fetchListArticlesFailure,
  FetchListArticlesRequestAction,
  fetchListArticlesSuccess,
  FETCH_LIST_ARTICLES_REQUEST,

  fetchArticleDetailFailure,
  FetchArticleDetailRequestAction,
  fetchArticleDetailSuccess,
  FETCH_ARTICLE_DETAIL_REQUEST,
} from './actions'
import { SinglePostType } from './types'

export function* articleSaga() {
  yield takeEvery(FETCH_LIST_ARTICLES_REQUEST, handleFetchListArticlesRequest)
  yield takeEvery(FETCH_ARTICLE_DETAIL_REQUEST, handleFetchArticleDetailRequest)
}

function* handleFetchListArticlesRequest(action: FetchListArticlesRequestAction) {
  const { page = 1, limit = 10, isRefreshed } = action.payload

  try {
    const { payload: listArticles }: { payload: SinglePostType[] } = yield call(
      [articleApi, 'fetchListArticles'],
      { page, limit }
    )

    yield put(fetchListArticlesSuccess({ listArticles, isRefreshed }))

    action.payload?.callback?.(listArticles)

  } catch (error: any) {
    yield put(fetchListArticlesFailure(error.message))
  }
}

function* handleFetchArticleDetailRequest(action: FetchArticleDetailRequestAction) {
  const { slug } = action.payload

  try {
    const { payload }: { payload: SinglePostType } = yield call(
      [articleApi, 'fetchOneArticle'],
      slug
    )

    yield put(fetchArticleDetailSuccess(payload))
  } catch (error: any) {
    yield put(fetchArticleDetailFailure(error.message))
  }
}
