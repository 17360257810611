import { memo } from "react"
import { Loader } from '@wiicamp/decentraland-ui'

import styles from './Loading.module.scss'

const Loading = () => (
  <div className={styles.center}>
    <Loader active size="huge" />
  </div>
)

export default memo(Loading)
