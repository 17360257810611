import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom';
import { isLoadingType } from '@wiicamp/decentraland-dapps/dist/modules/loading/selectors';

import { RootState } from '../../../modules/reducer';

import { locations } from '../../../modules/routing/locations';

import UploadItemPage from "./UploadItemPage";

import { MapDispatch, MapDispatchProps, MapStateProps, Params } from './UploadItemPage.types';
import {
  getIsLoadingCreateNewItemNFT,
  getIsLoadingEditItemUserCreation,
  getIsLoadingFetchItemUserCreationEdit,
  getItemUserCreationEdit
} from '../../../modules/uploadItem/selectors';
import { getCategoriesItemsNFT, getRaritiesItemsNFT } from '../../../modules/itemNFT/selectors';
import { getWallet } from '../../../modules/wallet/selectors';
import {
  createNewItemNftRequest,
  CREATE_NEW_ITEM_NFT_REQUEST,
  editItemUserCreationRequest,
  EDIT_ITEM_USER_CREATION_REQUEST,
  fetchItemUserCreationEditRequest,
  FETCH_ITEM_USER_CREATION_EDIT_REQUEST
} from '../../../modules/uploadItem/actions';

const mapState = (state: RootState, ownProps: RouteComponentProps<Params>): MapStateProps => {
  const { match } = ownProps

  return {
    wallet: getWallet(state),
    // edit
    itemUserCreationEdit: getItemUserCreationEdit(state),
    isEditItemUserCreation: match.path === locations.editNFTItemLOM(),
    isLoadingEditItemUserCreation: isLoadingType(getIsLoadingEditItemUserCreation(state), EDIT_ITEM_USER_CREATION_REQUEST),
    isLoadingFetchItemUserCreationEdit: isLoadingType(getIsLoadingFetchItemUserCreationEdit(state), FETCH_ITEM_USER_CREATION_EDIT_REQUEST),

    // create
    createNewItemNFTLoading: isLoadingType(getIsLoadingCreateNewItemNFT(state), CREATE_NEW_ITEM_NFT_REQUEST),
    categoriesItemsNFT: getCategoriesItemsNFT(state),
    raritiesItemsNFT: getRaritiesItemsNFT(state),
  }
}

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  createNewItemNFT: query => dispatch(createNewItemNftRequest(query)),
  editItemUserCreation: form  => dispatch(editItemUserCreationRequest(form)),
  fetchItemUserCreationEdit: (id, onRedirect) => dispatch(fetchItemUserCreationEditRequest(id, onRedirect)),
})

export default connect(mapState, mapDispatch)(UploadItemPage);
