import { BaseAPI } from '@wiicamp/decentraland-dapps/dist/lib/api'
import { NFT_SERVER_URL } from '../decentraland/nft'

class EstateBuilderAPI extends BaseAPI {
  fetch = async (filters: any = {}): Promise<any> => {
    const queryParams = this.buildItemsQueryString(filters)
    return this.request('get', `/nfts?${queryParams}`)
  }

  private buildItemsQueryString(filters: any): string {
    const queryParams = new URLSearchParams()

    if (filters.first) {
      queryParams.append('first', filters.first.toString())
    }

    if (filters.skip) {
      queryParams.append('skip', filters.skip.toString())
    }

    if (filters.sortBy) {
      queryParams.append('sortBy', filters.sortBy)
    }

    if (filters.owner) {
      queryParams.append('owner', filters.owner)
    }

    if (filters.category) {
      queryParams.append('category', filters.category)
    }

    return queryParams.toString()
  }
}

export const estateBuilderAPI = new EstateBuilderAPI(NFT_SERVER_URL)
