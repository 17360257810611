import { useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Intercom from '@wiicamp/decentraland-dapps/dist/components/Intercom'
import { ToastContainer } from 'react-toastify'
import Web3 from 'web3';

import 'react-toastify/dist/ReactToastify.css'

import { AssetType } from '../../modules/asset/types'
import { locations } from '../../modules/routing/locations'

// LOM Screens
import { HomePage as HomePageLOM } from '../LOM/HomePage'
import { StoryPage } from '../LOM/StoryPage'
import { MarketplacePage } from '../LOM/MarketplacePage'
import { BrowsePage as BrowsePageLOM } from '../LOM/BrowsePage'
import { TokenomicPage } from '../LOM/TokenomicPage'
import { RoadmapPage } from '../LOM/RoadmapPage'
import { WhitepaperPage } from '../LOM/WhitepaperPage'
import { LandsPage as LandsPageLOM } from '../LOM/LandsPage'
import { TicketPage as TicketPageLOM } from '../LOM/TicketPage'
import { AssetPage as AssetPageLOM } from '../LOM/AssetPage'
import { BuyPage as BuyPageLOM } from '../LOM/BuyPage'
import { BidPage as BidPageLOM } from '../LOM/BidPage'
// import { NewsfeedPage as NewsfeedPageLOM } from '../LOM/NewfeedsPage'
// import { NewsfeedDetailPage as NewfeedsDetailPageLOM } from '../LOM/NewfeedsDetailPage'
import { ActivityPage as ActivityPageLOM } from '../LOM/ActivityPage'
import { AccountPage as AccountPageLOM } from '../LOM/AccountPage'
import { AccountSettingsPage as AccountSettingsPageLOM } from '../LOM/AccountSettingsPage'
import { MyBidsPage as MyBidsPageLOM } from '../LOM/MyBidsPage'
import { SettingsPage as SettingsPageLOM } from '../LOM/SettingsPage'
import { SellPage as SellPageLOM } from '../LOM/SellPage'
import { CancelSalePage as CancelSalePageLOM } from '../LOM/CancelSalePage'
import { TransferPage as TransferPageLOM } from '../LOM/TransferPage'
import { LegacyNFTPage as LegacyNFTPageLOM } from '../LOM/LegacyNFTPage'
import { MyTicketPage as MyTicketPageLOM } from '../LOM/MyTicketPage'
import { ItemNFTPage as ItemNFTPageLOM } from '../LOM/ItemNFTPage'
import { ItemNFTDetailPage as ItemNFTDetailPageLOM } from '../LOM/ItemNFTDetailPage'
import { TicketDetailPage as TicketDetailPageLOM } from '../LOM/TicketDetailPage'
import { EstateBuilderPage as EstateBuilderPageLOM } from '../LOM/EstateBuilderPage'
import { UploadItemPage as UploadItemPageLOM } from '../LOM/UploadItemPage'


import { web3Ref } from '../../constants'
// import { isDevelopment } from '../../lib/environment';
// import { USER_EXISTED_STATUS } from '../../modules/authentication/reducer';

import { CheckUserExistedSuccessArgumentsType } from '../../modules/authentication/types';
import { Props } from './Routes.types'

declare let window: any

const Routes = (props: Props) => {
  const {
    wallet,
    userConnectWithWallet,
    // token,
    // message,
    // userExistedStatus,
    fetchCategoriesItemsNFT,
    checkUserExisted,
    // setUserSignMetamaskRequest,
    fetchRaritiesItemsNFT,
    onSignOut
  } = props

  const APP_ID = process.env.REACT_APP_INTERCOM_APP_ID

  useEffect(() => {
    if (!wallet?.address) return;

    if (!web3Ref.current) {
      web3Ref.current = new Web3(window.ethereum);
    }
  }, [wallet]);

  useEffect(() => {
    fetchCategoriesItemsNFT({})
  }, [fetchCategoriesItemsNFT])

  useEffect(() => {
    fetchRaritiesItemsNFT()
  }, [fetchRaritiesItemsNFT])

  useEffect(() => {
    if (wallet && String(wallet.address).toLocaleLowerCase() !== String(userConnectWithWallet?.address).toLocaleLowerCase()) {
      checkUserExisted({
        wallet: wallet.address,
        callback: {
          success: (payload: CheckUserExistedSuccessArgumentsType) => {
            if (!payload || (payload && !payload.token)) onSignOut()
          }
        }
      })
    }
  }, [wallet]) // eslint-disable-line

  // useEffect(() => {
  //   if (userExistedStatus === USER_EXISTED_STATUS.EXISTED && !token && userConnectWithWallet?.address) {
  //     setUserSignMetamaskRequest({
  //       message,
  //       address: userConnectWithWallet?.address,
  //     })
  //   }
  // }, [token, userExistedStatus, userConnectWithWallet]) // eslint-disable-line

  return (
    <>
      <Switch>
        {/* <Route exact path={locations.lands()} component={LandsPage} />
        <Route exact path={locations.browse()} component={BrowsePage} />
        <Route
          exact
          path={locations.currentAccount()}
          component={AccountPage}
        />
        <Route exact path={locations.account()} component={AccountPage} />
        <Route exact path={locations.bids()} component={MyBidsPage} />
        <Route exact path={locations.signIn()} component={SignInPage} />
        <Route exact path={locations.sell()} component={SellPage} />
        <Route exact path={locations.bid()} component={BidPage} />
        <Route exact path={locations.cancel()} component={CancelSalePage} />
        <Route exact path={locations.transfer()} component={TransferPage} />
        <Route
          exact
          path={locations.buy(AssetType.NFT)}
          component={() => <BuyPage type={AssetType.NFT} />}
        />
        <Route
          exact
          path={locations.buy(AssetType.ITEM)}
          component={() => <BuyPage type={AssetType.ITEM} />}
        />
        <Route
          exact
          path={locations.nft()}
          component={() => <AssetPage type={AssetType.NFT} />}
        />
        <Route
          exact
          path={locations.item()}
          component={() => <AssetPage type={AssetType.ITEM} />}
        />
        <Route exact path={locations.settings()} component={SettingsPage} />
        <Route exact path={locations.partners()} component={PartnersPage} />
        <Route exact path={locations.activity()} component={ActivityPage} />
        <Route exact path={locations.root()} component={HomePage} />
        <Route exact path={locations.parcel()} component={LegacyNFTPage} />
        <Route exact path={locations.estate()} component={LegacyNFTPage} /> */}

        {/* LOM's routes */}
        <Route exact path={locations.landsLOM()} component={LandsPageLOM} />
        <Route
          exact
          path={locations.ticketsLOM()}
          component={TicketPageLOM}
        />
        <Route exact path={locations.itemsNFTPage()} component={ItemNFTPageLOM} />
        <Route exact path={locations.itemsNFTDetailPage()} component={ItemNFTDetailPageLOM} />
        <Route exact path={locations.itemsCreationDetailPage()} component={ItemNFTDetailPageLOM} />
        <Route exact path={locations.ticketDetail()} component={TicketDetailPageLOM} />
        <Route exact path={locations.browseLOM()} component={BrowsePageLOM} />
        <Route
          exact
          path={locations.currentAccountLOM()}
          component={AccountPageLOM}
        />
        <Route exact path={locations.accountLOM()} component={AccountPageLOM} />
        <Route exact path={locations.accountSettingsLOM()} component={AccountSettingsPageLOM} />
        <Route exact path={locations.estateBuilderLOM()} component={EstateBuilderPageLOM} />
        <Route exact path={locations.createNFTItemLOM()} component={UploadItemPageLOM} />
        <Route exact path={locations.editNFTItemLOM()} component={UploadItemPageLOM} />
        <Route exact path={locations.myTicketsLOM()} component={MyTicketPageLOM} />
        <Route exact path={locations.bidsLOM()} component={MyBidsPageLOM} />
        <Route exact path={locations.sellLOM()} component={SellPageLOM} />
        <Route exact path={locations.bidLOM()} component={BidPageLOM} />
        <Route
          exact
          path={locations.cancelLOM()}
          component={CancelSalePageLOM}
        />
        <Route
          exact
          path={locations.transferLOM()}
          component={TransferPageLOM}
        />
        <Route
          exact
          path={locations.buyLOM(AssetType.NFT)}
          component={() => <BuyPageLOM type={AssetType.NFT} />}
        />
        <Route
          exact
          path={locations.buyLOM(AssetType.ITEM)}
          component={() => <BuyPageLOM type={AssetType.ITEM} />}
        />
        <Route
          exact
          path={locations.nftLOM()}
          component={() => <AssetPageLOM type={AssetType.NFT} />}
        />
        <Route
          exact
          path={locations.itemLOM()}
          component={() => <AssetPageLOM type={AssetType.ITEM} />}
        />
        <Route
          exact
          path={locations.settingsLOM()}
          component={SettingsPageLOM}
        />
        <Route
          exact
          path={locations.activityLOM()}
          component={ActivityPageLOM}
        />
        <Route exact path={locations.rootLOM()} component={HomePageLOM} />
        <Route
          exact
          path={locations.parcelLOM()}
          component={LegacyNFTPageLOM}
        />
        <Route
          exact
          path={locations.estateLOM()}
          component={LegacyNFTPageLOM}
        />

        <Route exact path={locations.story()} component={StoryPage} />
        <Route
          exact
          path={locations.marketplace()}
          component={MarketplacePage}
        />
        <Route exact path={locations.tokenomics()} component={TokenomicPage} />
        <Route exact path={locations.roadmap()} component={RoadmapPage} />
        <Route exact path={locations.whitepaper()} component={WhitepaperPage} />
        {/* <Route exact path={locations.newsfeed()} component={NewsfeedPageLOM} />
        <Route
          exact
          path={locations.newsfeedDetail()}
          component={NewfeedsDetailPageLOM}
        /> */}


        <Redirect
          from="/marketplace/browse"
          to={locations.browseLOM() + window.location.search}
          push
        />
        <Redirect to={locations.rootLOM()} />
      </Switch>

      {APP_ID ? (
        <Intercom appId={APP_ID} settings={{ alignment: 'right' }} />
      ) : null}

      <ToastContainer
        closeOnClick
        closeButton
      />
    </>
  )
}

export default Routes
