import React from 'react'
import classNames from 'classnames'

import { ContentItemProps } from '../RoadmapPage.types'

const Title = (props: ContentItemProps) => {
  const { index, item } = props;

  if (!item?.value) return null;

  return (
    <div key={index} className="flex justify-center">
      <div
        className={classNames(
          "border px-2 font-bold text-2xl",
          item.className,
          item.active ? "border-green text-green" : "border-gray text-gray"
        )}
      >
        {item.value}
      </div>
    </div>
  );
};

export default React.memo(Title);
