import { memo, useRef, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Rarity, Network } from '@dcl/schemas'

import { Mana } from '../../../Mana'

import { ReactComponent as IconRarity } from '../../../../asset/icons/ic-rarity.svg'

import { locations } from '../../../../modules/routing/locations'
import { formatMANA } from '../../../../lib/mana'
import { mapNumberToRarity } from '../../../../lib/constant'

import { MarketplaceType, Props } from './ItemNFTCard.types'

import cssStyles from './ItemNFTCard.module.scss'

const ItemNFTCard = (props: Props) => {
  const {
    className,
    name,
    itemId,
    thumbnail,
    subCategory,
    price,
    available,
    type,
    contractAddress,
    category,
    rarity,
    raritiesItemsNFT,
  } = props
  const maxItem = useMemo(() => raritiesItemsNFT?.find(rarities => rarities.id === +rarity)?.maxItem || 0, [raritiesItemsNFT, rarity]);

  const location = useLocation()

  const isMarketplaceRootPath = useMemo(() => location.pathname === '/marketplace', [])

  const imageRef = useRef<HTMLDivElement>(document.createElement(`div`))

  const rarityText = useMemo(() => mapNumberToRarity[Number(rarity)], [rarity])

  const [light, dark] = Rarity.getGradient(rarityText as Rarity)
  const background = `linear-gradient(-125deg,${light} 0%,${dark} 100%)`

  const cssStyle = { '--fillColor': dark } as React.CSSProperties

  return (
    <Link
    to={type === MarketplaceType.USERS_CREATIONS || isMarketplaceRootPath
      ? locations.itemsCreationDetailPage(itemId) // item creation detail page
      : locations.itemsNFTDetailPage(itemId, contractAddress)} // item listing / resold detail page
      className={`${cssStyles.AssetCardLink} ${className}`}
    >
      <div className={`${cssStyles.AssetCard} cursor-pointer`} style={cssStyle}>
        <div className={cssStyles.AssetCardContainer} style={{ background }}>
          <div className={cssStyles.AssetCardWrapper}>
            <div className={cssStyles.AssetCardContent}>
              <div className={cssStyles.AssetCardInfo}>
                <div className={cssStyles.AssetCardTime}>
                  <span>
                    <div className={cssStyles.AssetCardExpired} />
                  </span>
                  <div className={cssStyles.AssetCardHolder}>
                    {/* <Profile address="" textOnly inline={false} /> */}
                    {/* <sup>[martian]</sup> */}
                  </div>
                </div>

                <div className={cssStyles.AssetCardName}>
                  {name.toUpperCase()}
                </div>
                <div className={cssStyles.AssetCardMaterial}>
                  <div>{subCategory?.name || category.name}</div>
                </div>
              </div>

              <div className={cssStyles.AssetCardImage}>
                <div
                  ref={imageRef}
                  className="absolute top-0 left-0 w-full h-full"
                >
                  <img src={thumbnail} alt={name} className="w-full h-full" />
                </div>
              </div>

              <div className={cssStyles.AssetCardQuality}>
                <div className={cssStyles.AssetCardIconMint}>
                  {(type === MarketplaceType.USERS_CREATIONS || type === MarketplaceType.MARKETPLACE) && (
                    <div className={cssStyles.AssetCardAvailable}>
                      <span >
                        <div className='mana'>
                          {`${maxItem ? available: 0} / ${maxItem}`}
                        </div>
                      </span>
                    </div>
                  )}
                </div>
                {type !== MarketplaceType.MY_ITEMS && (
                  <div className={cssStyles.AssetCardValue}>
                    <span>
                      <Mana network={Network.ETHEREUM} withTooltip>
                        {formatMANA(price)}
                      </Mana>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className={cssStyles.AssetCardRarity}>
            <div className={cssStyles.AssetCardRarityWrapper}>
              <div className={cssStyles.AssetCardRarityContent}>
                <IconRarity />
              </div>

              <div className={cssStyles.AssetCardRarityText}>
                <span>{rarityText}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default memo(ItemNFTCard)
