import { put, takeEvery } from '@redux-saga/core/effects'
import { call, select } from 'redux-saga/effects'
import cookies from 'js-cookie'
import { toast } from 'react-toastify'

import { web3Ref as web3 } from '../../constants'
import { authenticationApi } from '../vendor/authentication'

import { LOCALSTORAGE_KEYS, LOCALSTORAGE_LIFETIME } from '../../constants/localStorageKeys'

import {
  CHECK_USER_EXISTED_REQUEST,
  CheckUserExistedRequestAction,
  checkUserExistedFailure,
  checkUserExistedSuccess,

  GET_USER_SIGN_MESSAGE_REQUEST,
  GetUserSignMessageRequestAction,
  getUserSignMessageSuccess,
  getUserSignMessageFailure,

  SET_USER_SIGN_METAMASK_REQUEST,
  SetUserSignMetamaskRequestAction,
  setUserSignMetamaskSuccess,
  setUserSignMetamaskFailure,

  SET_VERIFY_WALLET_TO_ACCOUNT_REQUEST,
  SetVerifyWalletToAccountRequestAction,
  setVerifyWalletToAccountSuccess,
  setVerifyWalletToAccountFailure,

  SET_UPDATE_USER_PASSWORD_REQUEST,
  SetUpdateUserPasswordRequestAction,
  setUpdateUserPasswordSuccess,
  setUpdateUserPasswordFailure,

  UPDATE_EMAIL_AND_USERNAME_OF_USER_REQUEST,
  UpdateEmailAndUsernameOfUserRequestAction,
  updateEmailAndUsernameOfUserSuccess,
  updateEmailAndUsernameOfUserFailure,
} from './actions'
import { USER_EXISTED_STATUS } from './reducer'
import { getToken } from './selectors'

import { CheckUserExistedSuccessArgumentsType } from './types'

export function* authenticationSaga() {
  yield takeEvery(CHECK_USER_EXISTED_REQUEST, handleCheckUserExisted)
  yield takeEvery(GET_USER_SIGN_MESSAGE_REQUEST, handleGetUserSignMessage)
  yield takeEvery(SET_USER_SIGN_METAMASK_REQUEST, handleUserSignMetamask)
  yield takeEvery(
    SET_VERIFY_WALLET_TO_ACCOUNT_REQUEST,
    handleSetVerifyWalletToAccount
  )
  yield takeEvery(SET_UPDATE_USER_PASSWORD_REQUEST, handleUpdateUserPassword)
  yield takeEvery(UPDATE_EMAIL_AND_USERNAME_OF_USER_REQUEST, handleUpdateEmailAndUsernameOfUser)
}

function* handleUpdateEmailAndUsernameOfUser(action: UpdateEmailAndUsernameOfUserRequestAction) {
  const { email, username } = action.payload

  try {
    const token: { [key: string]: any } = yield select(getToken)

    yield call(
      [authenticationApi, 'updateEmailAndUsernameOfUser'],
      { email, username, token })

    yield put(updateEmailAndUsernameOfUserSuccess({ email, username }))

    toast.success('Update user successfully!')
  } catch (error: any) {
    toast.error(error.response.data.message)
    yield put(updateEmailAndUsernameOfUserFailure(error))
  }
}

function* handleCheckUserExisted(action: CheckUserExistedRequestAction) {
  const { wallet, callback } = action.payload

  try {
    const { payload }: { payload: any } = yield call(
      [authenticationApi, 'checkUserExisted'],
      { wallet }
    )

    if (!!payload) {
      const { address, email, message, username } = payload
      const signedToken = cookies.get(LOCALSTORAGE_KEYS.SIGNED_TOKEN)
      const signedAddress = cookies.get(LOCALSTORAGE_KEYS.SIGNED_METAMASK_ADDRESS)
      let token = null

      if (String(signedAddress).toLocaleLowerCase() === String(address).toLocaleLowerCase()) token = signedToken

      const args: CheckUserExistedSuccessArgumentsType = {
        userExistedStatus: USER_EXISTED_STATUS.EXISTED,
        message,
        user: { address, email, username },
        token,
      }

      yield put(
        checkUserExistedSuccess(args)
      )

      callback?.success?.(args)
    } else {
      yield put(
        checkUserExistedSuccess({
          userExistedStatus: USER_EXISTED_STATUS.NOT_YET
        })
      )

      callback?.success?.(null)
    }
  } catch (error) {
    // @ts-ignore-start
    yield put(checkUserExistedFailure(error))
    // @ts-ignore-end
  }
}

function* handleGetUserSignMessage(action: GetUserSignMessageRequestAction) {
  const { email, username, address, callback } = action.payload

  try {
    const { payload: message }: { payload: any } = yield call(
      [authenticationApi, 'getSignMessage'],
      { email, username }
    )

    yield put(getUserSignMessageSuccess({ message }))

    yield put({
      type: SET_USER_SIGN_METAMASK_REQUEST,
      payload: { message, address, callback }
    })
  } catch (error: any) {
    toast.error(error?.response?.data?.message)
    yield put(getUserSignMessageFailure(error))
  }
}

function* handleUserSignMetamask(action: SetUserSignMetamaskRequestAction) {
  const { message, address, callback } = action.payload

  try {
    const signature: { payload: any } = yield web3.current.eth.personal.sign(
      message,
      address,
      '',
    )

    yield put(setUserSignMetamaskSuccess({ signature }))

    yield put({
      type: SET_VERIFY_WALLET_TO_ACCOUNT_REQUEST,
      payload: {
        address,
        message,
        signature,
        callback,
      }
    })
  } catch (error) {
    // @ts-ignore-start
    yield put(setUserSignMetamaskFailure(error))

    callback?.failure?.()
    // @ts-ignore-end
  }
}

function* handleSetVerifyWalletToAccount(
  action: SetVerifyWalletToAccountRequestAction
) {
  const { address, message, signature, callback } = action.payload

  try {
    const { payload: verifyPayload }: { payload: any } = yield call(
      [authenticationApi, 'verifyUserBySignature'],
      { address, message, signature }
    )

    const { user, token } = verifyPayload

    cookies.set(LOCALSTORAGE_KEYS.SIGNED_TOKEN, token, { expires: LOCALSTORAGE_LIFETIME.SIGNED_TOKEN })
    cookies.set(LOCALSTORAGE_KEYS.SIGNED_METAMASK_ADDRESS, address, { expires: LOCALSTORAGE_LIFETIME.SIGNED_METAMASK_ADDRESS })

    yield put(setVerifyWalletToAccountSuccess({ user, token }))

    callback?.success?.()
  } catch (error) {
    yield put(setVerifyWalletToAccountFailure(error))
    callback?.failure?.()
  }
}

function* handleUpdateUserPassword(action: SetUpdateUserPasswordRequestAction) {
  const { password, callback } = action.payload
  const token: { [key: string]: any } = yield select(getToken)

  try {
    yield call(
      [authenticationApi, 'updateUserPassword'],
      { password, token }
    )

    yield put(setUpdateUserPasswordSuccess())

    toast.success('Update password successfully!')

    callback?.success?.()
  } catch (error: any) {
    // @ts-ignore-start
    yield put(setUpdateUserPasswordFailure(error))
    // @ts-ignore-end
  }
}
