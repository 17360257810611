import { memo } from 'react'
import { Field } from '@wiicamp/decentraland-ui'

import { ReactComponent as IconPassedValidate } from '../../../../asset/icons/ic-passed-validate.svg'

import { TicketInformationFieldsProps } from '../TicketPage.types'

import moduleStyles from './Ticket.module.scss'

const TicketInformationSection = (props: TicketInformationFieldsProps) => {
  const {
    // isShowFormTicket,
    firstName,
    lastName,
    onChangeFormTicketData,
  } = props

  return (
    <div
      className={`${moduleStyles.ticketInformationSection} flex flex-col items-center`}
    >
      {/* {isShowFormTicket && ( */}
        <div className={moduleStyles.ticketFormDetails}>
          <div className="form-fields">
            <Field
              label="First Name*"
              placeholder="/type"
              onChange={(_event, props) =>
                onChangeFormTicketData('firstName', props.value)
              }
              value={firstName}
            />
            <span className={`icon-validate ${firstName && 'is-passed'}`}>
              <IconPassedValidate />
            </span>
          </div>

          <div className="form-fields">
            <Field
              label="Last Name*"
              placeholder="/type"
              onChange={(_event, props) =>
                onChangeFormTicketData('lastName', props.value)
              }
              value={lastName}
            />
            <span className={`icon-validate ${lastName && 'is-passed'}`}>
              <IconPassedValidate />
            </span>
          </div>
        </div>
      {/* )} */}
    </div>
  )
}

export default memo(TicketInformationSection)
