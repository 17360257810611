import {
  loadingReducer,
  LoadingState
} from '@wiicamp/decentraland-dapps/dist/modules/loading/reducer'

import {
  GENERATE_TICKET_REQUEST,
  GENERATE_TICKET_SUCCESS,
  GENERATE_TICKET_FAILURE,

  GenerateTicketRequestAction,
  GenerateTicketSuccessAction,
  GenerateTicketFailureAction,

  FETCH_TICKETS_REQUEST,
  FETCH_TICKETS_SUCCESS,
  FETCH_TICKETS_FAILURE,

  FetchTicketsRequestAction,
  FetchTicketsSuccessAction,
  FetchTicketsFailureAction,

  TRANSFER_TICKET_REQUEST,
  TRANSFER_TICKET_SUCCESS,
  TRANSFER_TICKET_FAILURE,

  TransferTicketRequestAction,
  TransferTicketSuccessAction,
  TransferTicketFailureAction,

  UPDATE_TICKET_REQUEST,
  UPDATE_TICKET_SUCCESS,
  UPDATE_TICKET_FAILURE,

  UpdateTicketRequestAction,
  UpdateTicketSuccessAction,
  UpdateTicketFailureAction,

  // check wallet is on claim list
  CHECK_WALLET_IS_ON_CLAIM_LIST,
  CHECK_WALLET_IS_ON_CLAIM_LIST_SUCCESS,
  CHECK_WALLET_IS_ON_CLAIM_LIST_FAILURE,

  CheckWalletIsOnClaimListRequestAction,
  CheckWalletIsOnClaimListSuccessAction,
  CheckWalletIsOnClaimListFailureAction,

  // check wallet is mint(ed) airdrop before
  CHECK_WALLET_IS_MINT_AIRDROP,
  CHECK_WALLET_IS_MINT_AIRDROP_SUCCESS,
  CHECK_WALLET_IS_MINT_AIRDROP_FAILURE,

  CheckWalletIsMintAirdropRequestAction,
  CheckWalletIsMintAirdropSuccessAction,
  CheckWalletIsMintAirdropFailureAction,

  // Mint Ticket Airdrop
  MINT_TICKET_AIRDROP,
  MINT_TICKET_AIRDROP_SUCCESS,
  MINT_TICKET_AIRDROP_FAILURE,

  MintTicketAirdropRequestAction,
  MintTicketAirdropSuccessAction,
  MintTicketAirdropFailureAction,

  // Fetch single ticket
  FETCH_SINGLE_TICKET_REQUEST,
  FETCH_SINGLE_TICKET_SUCCESS,
  FETCH_SINGLE_TICKET_FAILURE,

  FetchSingleTicketRequestAction,
  FetchSingleTicketSuccessAction,
  FetchSingleTicketFailureAction,

} from './actions'
import { Ticket as TicketType } from './types'

export type TicketState = {
  data: TicketType[]
  loading: LoadingState
  error: string | null
  fetchLoading: boolean
  transferLoading: boolean
  updateLoading: boolean
  isOnClaimList: boolean
  checkIsOnClaimListLoading: boolean
  isMintAirdropBefore: boolean
  isMintAirdropBeforeLoading: boolean
  isDoneMintTicketLoading: boolean
  ticketDetail: TicketType | null,
  loadingTicketDetail: boolean,
}

export const INITIAL_STATE: TicketState = {
  data: [],
  loading: [],
  error: null,
  fetchLoading: false,
  transferLoading: false,
  updateLoading: false,
  isOnClaimList: false,
  checkIsOnClaimListLoading: false,
  isMintAirdropBefore: true, // default to true to hide button mint ticket
  isMintAirdropBeforeLoading: false,
  isDoneMintTicketLoading: false,
  ticketDetail: null,
  loadingTicketDetail: false,
}

type TicketReducerAction =
  | GenerateTicketRequestAction
  | GenerateTicketSuccessAction
  | GenerateTicketFailureAction
  | FetchTicketsRequestAction
  | FetchTicketsSuccessAction
  | FetchTicketsFailureAction
  | TransferTicketRequestAction
  | TransferTicketSuccessAction
  | TransferTicketFailureAction
  | UpdateTicketRequestAction
  | UpdateTicketSuccessAction
  | UpdateTicketFailureAction
  | CheckWalletIsOnClaimListRequestAction
  | CheckWalletIsOnClaimListSuccessAction
  | CheckWalletIsOnClaimListFailureAction
  | CheckWalletIsMintAirdropRequestAction
  | CheckWalletIsMintAirdropSuccessAction
  | CheckWalletIsMintAirdropFailureAction
  | MintTicketAirdropRequestAction
  | MintTicketAirdropSuccessAction
  | MintTicketAirdropFailureAction
  | FetchSingleTicketRequestAction
  | FetchSingleTicketSuccessAction
  | FetchSingleTicketFailureAction


export function ticketReducer(
  state = INITIAL_STATE,
  action: TicketReducerAction
): TicketState {
  switch (action.type) {
    case GENERATE_TICKET_REQUEST:
    case GENERATE_TICKET_SUCCESS: {
      return {
        ...state,
        loading: loadingReducer(state.loading, action)
      }
    }

    case GENERATE_TICKET_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error
      }
    }

    case FETCH_TICKETS_REQUEST:
      return {
        ...state,
        fetchLoading: true
      }

    case FETCH_TICKETS_SUCCESS: {
      return {
        ...state,
        data: action.payload.items,
        fetchLoading: false
      }
    }

    case FETCH_TICKETS_FAILURE: {
      return {
        ...state,
        fetchLoading: false
      }
    }

    case TRANSFER_TICKET_REQUEST:
      return {
        ...state,
        transferLoading: true
      }

    case TRANSFER_TICKET_SUCCESS: {
      return {
        ...state,
        data: state.data.filter((e: any) => e.token_id !== action.payload.tokenId),
        transferLoading: false
      }
    }

    case TRANSFER_TICKET_FAILURE:
      return {
        ...state,
        transferLoading: false
      }

    case UPDATE_TICKET_REQUEST: {
      return {
        ...state,
        updateLoading: true
      }
    }

    case UPDATE_TICKET_SUCCESS:
    case UPDATE_TICKET_FAILURE: {
      return {
        ...state,
        updateLoading: false
      }
    }

    case CHECK_WALLET_IS_ON_CLAIM_LIST: {
      return {
        ...state,
        checkIsOnClaimListLoading: false
      }
    }

    case CHECK_WALLET_IS_ON_CLAIM_LIST_SUCCESS: {
      return {
        ...state,
        checkIsOnClaimListLoading: false,
        isOnClaimList: action.payload.isOnClaimList,
      }
    }

    case CHECK_WALLET_IS_ON_CLAIM_LIST_FAILURE: {
      return {
        ...state,
        checkIsOnClaimListLoading: false,
      }
    }

    case CHECK_WALLET_IS_MINT_AIRDROP: {
      return {
        ...state,
        isMintAirdropBeforeLoading: true,
      }
    }

    case CHECK_WALLET_IS_MINT_AIRDROP_SUCCESS: {
      return {
        ...state,
        isMintAirdropBefore: action.payload.isMintAirdropBefore,
        isMintAirdropBeforeLoading: false,
      }
    }

    case CHECK_WALLET_IS_MINT_AIRDROP_FAILURE: {
      return {
        ...state,
        isMintAirdropBeforeLoading: false,
      }
    }

    case MINT_TICKET_AIRDROP: {
      return {
        ...state,
        isDoneMintTicketLoading: true,
      }
    }

    case MINT_TICKET_AIRDROP_SUCCESS: {
      return {
        ...state,
        isMintAirdropBefore: true,
        isDoneMintTicketLoading: false,
      }
    }

    case MINT_TICKET_AIRDROP_FAILURE: {
      return {
        ...state,
        isDoneMintTicketLoading: false,
      }
    }

    case FETCH_SINGLE_TICKET_REQUEST: {
      return {
        ...state,
        loadingTicketDetail: true,
        ticketDetail: null,
      }
    }

    case FETCH_SINGLE_TICKET_SUCCESS: {
      return {
        ...state,
        loadingTicketDetail: false,
        ticketDetail: action.payload.ticket
      }
    }

    case FETCH_SINGLE_TICKET_FAILURE: {
      return {
        ...state,
        loadingTicketDetail: false,
        ticketDetail: null,
      }
    }

    default:
      return state
  }
}
