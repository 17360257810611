import { connect } from 'react-redux'
import { disconnectWallet } from '@wiicamp/decentraland-dapps/dist/modules/wallet/actions'

import { RootState } from '../../modules/reducer'
import { fetchToggleModalDetailEstateRequest } from '../../modules/ui/modal/actions'
import {
  getToggleKeyModalDetailEstate,
  getIsNFTToken,
  getAssetId
} from '../../modules/ui/modal/selectors'
import { fetchCategoriesItemsNFTRequest, fetchRaritiesItemsNFTRequest } from '../../modules/itemNFT/actions'
import { getWallet } from '../../modules/wallet/selectors'
import { checkUserExistedRequest, setUserSignMetamaskRequest } from '../../modules/authentication/actions'
import { getUser, getToken, getMessage, getUserExistedStatus } from '../../modules/authentication/selectors'

import { MapStateProps, MapDispatchProps, MapDispatch } from './Routes.types'
import Routes from './Routes'

const mapState = (state: RootState): MapStateProps => ({
  isOpenModalDetailEstate: getToggleKeyModalDetailEstate(state),
  isNFTToken: getIsNFTToken(state),
  assetId: getAssetId(state),
  wallet: getWallet(state),
  userConnectWithWallet: getUser(state),
  token: getToken(state),
  message: getMessage(state),
  userExistedStatus: getUserExistedStatus(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  fetchCategoriesItemsNFT: query => dispatch(fetchCategoriesItemsNFTRequest(query)),
  fetchRaritiesItemsNFT: () => dispatch(fetchRaritiesItemsNFTRequest()),
  toggleModalDetailEstate: newModalState => dispatch(fetchToggleModalDetailEstateRequest(newModalState)),
  checkUserExisted: (payload: any) => dispatch(checkUserExistedRequest(payload)),
  setUserSignMetamaskRequest: (payload: any) => dispatch(setUserSignMetamaskRequest(payload)),
  onSignOut: () => dispatch(disconnectWallet()),
})

export default connect(mapState, mapDispatch)(Routes)
