import React from 'react'

import LayoutPage from '../Layout/LayoutPage/LayoutPage'

import { checkMobileAndTableMode } from '../../../lib/helpers'

const WhitepaperPage = () => {
  const isMobileTableMode = checkMobileAndTableMode();

  return (
    <LayoutPage viewMode="white">
      <div className="h-screen text-white bg-black3 lg:h-full w-screen lg:w-full">
        <iframe
          className={!isMobileTableMode ? "" : "hidden"}
          src={"/LOM_Whitepaper.pdf"}
          height="100%"
          width="100%"
          title="LOM_Whitepaper"
        >
          <p>
            Your web browser doesn't have a PDF plugin. Instead you can{" "}
            <a href={"/LOM_Whitepaper.pdf"} target="_blank" rel="noreferrer">
              click here to try again
            </a>
          </p>
        </iframe>

        <div
          className={`${isMobileTableMode ? "" : "hidden"
            } flex flex-col px-4 pt-10`}
        >
          <p>
            <a
              className="text-green"
              href={"/LOM_Whitepaper.pdf"}
              target="_blank"
              rel="noreferrer"
            >
              LOM_Whitepaper.pdf
            </a>
          </p>
        </div>
      </div>
    </LayoutPage>
  );
};

export default React.memo(WhitepaperPage);
