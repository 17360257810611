// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Loading_center__2gJME {\n  position: relative;\n  padding: 2rem;\n}\n", "",{"version":3,"sources":["webpack://src/components/LOM/Loading/Loading.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;AAAA","sourcesContent":[".center {\n  position: relative;\n  padding: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"center": "Loading_center__2gJME"
};
export default ___CSS_LOADER_EXPORT___;
