import React, { useCallback, useEffect, useState } from 'react'
import { t } from '@wiicamp/decentraland-dapps/dist/modules/translation/utils'

import Breadcrumb from '../Breadcrumb'
import Section from './Section'
import SectionNFT from './SectionNFT'

import styles from './Main.module.scss'

const Main = (props: any) => {
  const {
    homepage,
    homepageLoading,
    // views,
    handleViewAll,
    onFetchNFT,
    onFetchItem,
    toggleModalDetailEstate,
    onFetchCollectionItems,
    categoriesItemNFT
  } = props
  const [listItemsByCategories, setListItemsByCategories] = useState<any[]>([])

  const views = ['home_land']

  const breadcrumb = [
    { name: 'Home', link: '/' },
    { name: 'Marketplace', active: true, isBreakHere: true }
  ]

  const RenderSectionNFT = (category: any) => {
    if (listItemsByCategories[category.slug]) {
      return (
        <SectionNFT
          key={category.slug}
          title={category.name}
          slug={category.slug}
          itemsNFT={listItemsByCategories[category.slug]}
          thumbVisible
        />
      )
    }

    return null
  }

  const onFechDataForCategory = useCallback((category: any) => (items: any) => {
    if (!!items.length) {
      setListItemsByCategories(prevState => ({...prevState, [category.slug]: items}))
    }
    }, [])

  useEffect(() => {
    if (!!categoriesItemNFT?.length) {
      categoriesItemNFT.forEach((category: any) => {
        onFetchCollectionItems({
          category: category.slug,
          callback: onFechDataForCategory(category)
        })
      })
    }
  }, [categoriesItemNFT, onFetchCollectionItems, onFechDataForCategory])

  return (
    <div className="w-full min-h-screen">
      <div className="flex-grow overflow-auto Marketplace__background min-h-0">
        <div className="Page__header mt-16 lg:mt-28 mb-12">
          <Breadcrumb breadcrumb={breadcrumb} />

          <div>
            <p className="Marketplace__header__subtitle">
              This is where you help shape Mars’ economy. <br />
              Buy and sell your supplies, technology, estates or even tittles.
              <br />
              Convert Mars‘ to Earth’s currency.
            </p>
          </div>
        </div>
      </div>

      <div className="flex-grow overflow-auto Marketplace__background bg-black bg-none px-0">
        {views.map((view: any) => (
          <Section
            key={view}
            title={t(`home_page.${view}`)}
            assets={homepage[view]}
            isLoading={homepageLoading[view]}
            thumbClassName={styles.thumb}
            // thumbVisible={false}
            onViewAll={() => handleViewAll(view)}
            onFetchNFT={onFetchNFT}
            onFetchItem={onFetchItem}
            toggleModalDetailEstate={toggleModalDetailEstate}
          />
        ))}

        {!!categoriesItemNFT.length &&
          categoriesItemNFT.map((category: any) => RenderSectionNFT(category))}
      </div>
    </div>
  )
}

export default React.memo(Main)
