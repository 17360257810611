import { useEffect, useCallback, useState, useRef, memo, useMemo } from 'react'
import { useParams } from 'react-router'
import {
  Container,
  Stats,
  Loader,
  Modal,
  Button
} from '@wiicamp/decentraland-ui'
import { useLocation } from 'react-router-dom'
import { t } from '@wiicamp/decentraland-dapps/dist/modules/translation/utils'
import { Network } from '@dcl/schemas'
import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';

import { Row } from '../../Layout/Row'
import { Column } from '../../Layout/Column'
import LayoutPage from '../Layout/LayoutPage/LayoutPage'
import { Title } from '../AssetPage/Title'
import { Description } from '../AssetPage/Description'
import Breadcrumb from '../Breadcrumb'
import { Breadcrumb as BreadcrumbType } from '../Breadcrumb/Breadcrumb.types'
import { PageHeader } from '../../PageHeader'
import { Mana } from '../../Mana'
import { LayoutModal } from './LayoutModal'
import { TransactionHistoryItemNFT } from './TransactionHistoryItemNFT'

import { ReactComponent as CloseIcon } from '../../../images/ic-close.svg'
import { ReactComponent as EyeIcon } from '../../../asset/icons/ic-eye.svg'

import { formatMANA } from '../../../lib/mana'
import { isDevelopment } from '../../../lib/environment'
import { mapNumberToRarity } from '../../../lib/constant'

import { isOwnedBy } from '../../../modules/asset/utils'
import { getObjectFromApiCategories } from '../../../modules/itemNFT/utils'

import useWatchNetwork from '../../../customHooks/useWatchNetwork'

import { ModalTypes, ModalType } from './LayoutModal/LayoutModal.types'
import { TransactionHistoryItemNFTType } from '../../../modules/itemNFT/types'

import cssStyles from './ItemNFTDetailPage.module.scss'

const ItemNFTDetailPage = (props: any) => {
  const { slug, contractAddress }: { [key: string]: any } = useParams()

  const location = useLocation();
  const creationRootPath: string = useMemo(() => '/marketplace/collectibles/creation', [])
  const isCreationDetailPage = useMemo(() => location.pathname.includes(creationRootPath), [])

  const {
    fetchItemNFT,
    item,
    itemNFTLoading,
    buyItemUserCreation,
    buyItemUserCreationLoading,
    buyResoldItemLoading,
    buyResoldItem,
    wallet,
    fetchItemNFTListing,
    itemNFTListingDetailLoading,
    listForSaleItem,
    listForSaleItemLoading,
    transferItem,
    transferItemLoading,
    cancelSaleItem,
    cancelSaleItemLoading,
    transactionHistoriesItemNFT,
    fetchTransactionHistoriesItemNFT,
    transactionHistoriesItemNFTLoading,
    categoriesItemsNFT,
  } = props

  const [showNotifyOpeningDate, setShowNotifyOpeningDate] = useState(false)
  const [isShow3DModal, setIsShow3DModal] = useState(false)
  const [currentModal, setCurrentModal] = useState<ModalTypes | ''>('')
  const [page, setPage] = useState({
    currentPage: 2,
    isLastPage: false
  })

  const canvasShow3DRef = useRef<HTMLCanvasElement>(document.createElement(`canvas`))

  const isOwner = useMemo(() => item && wallet && isOwnedBy(item, wallet), [item, wallet])

  const canBuy = useMemo(() => !!wallet?.address, [wallet]);

  const rarity = useMemo(() => item?.rarity ? mapNumberToRarity[Number(item.rarity)] : '', [item])

  const maxItemTransactionHistoriesRender = useMemo(() => 10 ,[])

  const itemInformation = useMemo(() => {
    if(item && !!categoriesItemsNFT.length) {
      return getObjectFromApiCategories(categoriesItemsNFT, item.category, item.assetType)
    }
    return null
  },[item, categoriesItemsNFT])

  const breadcrumb: BreadcrumbType[] = [
    { name: 'Home', link: '/' },
    { name: 'Marketplace', link: '/marketplace' },
    {
      name: 'COLLECTIBLES',
      active: true,
      isBreakHere: true
    }
  ]

  const isHiddenDetailPage = useMemo(() => currentModal, [currentModal])

  const onShowImage3D = useCallback((newValue) => () => {
    if (!!item.image3d) {
      setIsShow3DModal(newValue)
    }
  }, [item])

  const toggleModalNotifyOpeningDate = useCallback(() => {
    setShowNotifyOpeningDate(prevState => !prevState)
  }, [])

  const handleClickBuyItem = useCallback(() => {
    if(isCreationDetailPage) {
      buyItemUserCreation({ item })
    } else {
      buyResoldItem({ item })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item])

  const onFetchMoreTransactionHistories = useCallback(() => {
    const callback = (histories: TransactionHistoryItemNFTType[]) => {
      setPage((prevState) => ({
        ...prevState,
        isLastPage: histories.length < maxItemTransactionHistoriesRender,
        currentPage: prevState.currentPage + 1
      }));
    }

    const query = {
      itemId: item.itemId,
      contractAddress: item.itemAddress,
      page: page.currentPage,
      limit: maxItemTransactionHistoriesRender,
      callback,
    }

    fetchTransactionHistoriesItemNFT(query)
  }, [fetchTransactionHistoriesItemNFT, item, page, maxItemTransactionHistoriesRender])

  // const onCloseModal = () => setCurrentModal('');
  const onUpdateState = useCallback((newValue, setValue) => () => setValue(newValue), [] )

  const createScene = useCallback((canvas, engine) => {
    var scene = new BABYLON.Scene(engine)
    var camera = new BABYLON.ArcRotateCamera("Camera", Math.PI / 2, Math.PI / 2, 2, new BABYLON.Vector3(0, 0,), scene)

    camera.attachControl(canvas, true)

    BABYLON.SceneLoader.Append(item.image3d, "", scene, function (sceneParams) {
      sceneParams.createDefaultCameraOrLight(true, true, true)
      sceneParams.createDefaultEnvironment()

      if (sceneParams.activeCamera instanceof BABYLON.ArcRotateCamera) {
        sceneParams.activeCamera.alpha += Math.PI;
      }
    })

    return scene
  }, [item])

  useEffect(() => {
    if (isCreationDetailPage) {
      fetchItemNFT(slug)
    } else {
      fetchItemNFTListing(slug, contractAddress)
    }
  }, [slug, contractAddress, fetchItemNFT])

  useEffect(() => {
    if (item && !!item.image3d && isShow3DModal) {
      let engine = new BABYLON.Engine(canvasShow3DRef.current, true)

      let scene = createScene(canvasShow3DRef.current, engine)

      engine.runRenderLoop(function () {
        scene.render();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow3DModal])

  useEffect(() => {
    if (item) {
      fetchTransactionHistoriesItemNFT({ itemId: item.itemId, contractAddress: item.itemAddress, isRefreshed: true})
    }
  }, [item, fetchTransactionHistoriesItemNFT])

  const RenderButtons = useCallback(() => {
    if (!item) return null;

    if (!canBuy) return null;

    if (isOwner && !isCreationDetailPage) {
      return (
        <div className="card-detail-btn-container">
          {item.isOnSale
            ? (
              <div className="mb-4">
                <Button
                  className="LOM-submit-btn"
                  // onClick={() => setCurrentModal(ModalType.CANCEL)}
                  onClick={isDevelopment ? onUpdateState(ModalType.CANCEL, setCurrentModal) : toggleModalNotifyOpeningDate}
                  primary
                  loading={buyItemUserCreationLoading}
                  disabled={buyItemUserCreationLoading}
                >
                  {t('asset_page.actions.cancel_sale')}
                </Button>
              </div>
            ) : (
              <>
                <div className="mb-4">
                  <Button
                    className="LOM-submit-btn"
                    // onClick={() => setCurrentModal(ModalType.SELL)}
                    onClick={isDevelopment ? onUpdateState(ModalType.SELL, setCurrentModal) : toggleModalNotifyOpeningDate}
                    primary
                    loading={buyItemUserCreationLoading}
                    disabled={buyItemUserCreationLoading}
                  >
                    {t('asset_page.actions.sell')}
                  </Button>
                </div>

                <div className="mb-4">
                  <Button
                    className="LOM-submit-btn"
                    // onClick={() => setCurrentModal(ModalType.TRANSFER)}
                    onClick={isDevelopment ? onUpdateState(ModalType.TRANSFER, setCurrentModal) : toggleModalNotifyOpeningDate}
                    primary
                    loading={buyItemUserCreationLoading}
                    disabled={buyItemUserCreationLoading}
                  >
                    {t('asset_page.actions.transfer')}
                  </Button>
                </div>
              </>
            )}
        </div>
      );
    }

    if (!item.isOnSale) return null;

    return (
      <div className="mb-4">
        <Button
          className="LOM-submit-btn"
          onClick={isDevelopment? handleClickBuyItem : toggleModalNotifyOpeningDate}
          primary
          loading={buyItemUserCreationLoading || buyResoldItemLoading}
          disabled={buyItemUserCreationLoading || buyResoldItemLoading}
        >
          {t('asset_page.actions.buy')}
        </Button>
      </div>
    );
  }, [
    item,
    canBuy,
    isOwner,
    buyItemUserCreationLoading,
    buyResoldItemLoading,
    handleClickBuyItem,
    onUpdateState,
    toggleModalNotifyOpeningDate,
    isCreationDetailPage
  ]);

  useWatchNetwork()

  return (
    <>
      <div className={[cssStyles.detailNFTPage, isHiddenDetailPage ? cssStyles.hidden : ''].join(' ')}>
        <LayoutPage viewMode="white">
          <div className="flex-grow min-h-full overflow-auto Story__background">
            <div className="Page__header mt-14 lg:mt-28 mb-14">
              <Breadcrumb breadcrumb={breadcrumb} viewMode="dark" />
            </div>

            {itemNFTLoading || itemNFTListingDetailLoading ? (
              <div className="mt-56">
                <div className="overlay bg-transparent" />
                <Loader size="massive" active />
              </div>
            ) : null}

            {!itemNFTLoading && !itemNFTListingDetailLoading && item && Object.keys(item).length > 0 ? (
              <div className="mb-10">
                <div className={cssStyles.detail}>
                  <section className={cssStyles.sectionIntroduction}>
                    <div>
                      <Title>{item.name}</Title>
                    </div>
                  </section>

                  <PageHeader className="relative">
                    <img
                      src={item.thumbnail}
                      alt=""
                      className="block h-full mx-auto object-contain"
                    />

                    {!!item.image3d && process.env.REACT_APP_ENVIRONMENT === 'development' &&
                      <div className="absolute top-0 right-0 my-4 mx-4 w-10 h-10 p-2 bg-white cursor-pointer rounded" onClick={onShowImage3D(true)}>
                        <EyeIcon className="w-full h-full block" />
                      </div>}
                  </PageHeader>

                  <Container className={cssStyles.detailMarketInformation}>
                    <Description text={item.description} />

                    <Row>
                      <Column align="left" grow={true}>
                        {/* <Network asset={item} /> */}
                        <div className={cssStyles.detailMainInformation}>
                          {item.isOnSale && (
                            <Stats title={t('asset_page.price')}>
                              <Mana network={Network.ETHEREUM} withTooltip>
                                {formatMANA(item.price)}
                              </Mana>
                            </Stats>
                          )}

                          {rarity && (
                            <Stats title={'Rarity'}>
                              {rarity}
                            </Stats>
                          )}

                          {itemInformation?.category && (
                            <Stats title={'Category'}>
                              {itemInformation.category.name}
                            </Stats>
                          )}

                          {itemInformation?.subCategory && (
                            <Stats title={'Sub Category'}>
                              {itemInformation.subCategory.name}
                            </Stats>
                          )}
                        </div>
                      </Column>

                      <Column align="right">
                        <RenderButtons />
                        <div>
                          <Button
                            className="LOM-cancel-btn"
                            onClick={toggleModalNotifyOpeningDate}
                          >
                            {t('asset_page.actions.bid')}
                          </Button>
                        </div>
                      </Column>
                    </Row>
                  </Container>
                </div>
              </div>
            ) : null}

            {itemNFTLoading || itemNFTListingDetailLoading ? null : (
              <>
                <TransactionHistoryItemNFT histories={transactionHistoriesItemNFT} />

                <div className="flex justify-center mt-4 mb-10">
                  <Button
                    className="LOM-submit-btn"
                    onClick={onFetchMoreTransactionHistories}
                    loading={transactionHistoriesItemNFTLoading}
                    disabled={page.isLastPage || transactionHistoriesItemNFTLoading || transactionHistoriesItemNFT.length < 10}
                  >
                    Load More
                  </Button>
                </div>
              </>
            )}
          </div>
        </LayoutPage>
      </div>
      <Modal
        className={cssStyles.showOpeningDate}
        open={showNotifyOpeningDate}
        onClose={toggleModalNotifyOpeningDate}
      >
        <div className="relative">
          <div className="absolute right-0 top-0 p-2">
            <CloseIcon
              fill="#fff"
              className="h-8 w-8 cursor-pointer"
              onClick={toggleModalNotifyOpeningDate}
            />
          </div>
        </div>

        <Modal.Header>Coming soon...</Modal.Header>

        {/* <Modal.Content>
          Item will be on sale in May 2022
        </Modal.Content> */}
      </Modal>
      <Modal
        open={isShow3DModal}
        onClose={onShowImage3D(false)}
        className="modal-show-3d-object"
      >
        <Modal.Content className={cssStyles.showImage3D}>
          <div className="relative">
            <div className="absolute right-0 top-0 p-2">
              <CloseIcon
                fill="#fff"
                className="h-8 w-8 cursor-pointer"
                onClick={onShowImage3D(false)}
              />
            </div>

            <canvas ref={canvasShow3DRef}></canvas>
          </div>
        </Modal.Content>
      </Modal>

      {item && currentModal &&(
        <LayoutModal
          onCloseModal={onUpdateState(null, setCurrentModal)}
          nft={item}
          layoutType={currentModal}
          isOwner={isOwner}
          // sell
          listForSaleItem={listForSaleItem}
          listForSaleItemLoading={listForSaleItemLoading}
          // transfer
          transferItem={transferItem}
          transferItemLoading={transferItemLoading}
          //cancel sale
          cancelSaleItem={cancelSaleItem}
          cancelSaleItemLoading={cancelSaleItemLoading}
        />
      )}
    </>
  )
}
export default memo(ItemNFTDetailPage)
