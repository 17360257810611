import { createRef } from 'react';

// Ref
export const web3Ref: any = createRef();

export async function downloadImage(image: any, name: any) {
  const imageFile = await fetch(image)
  const imageBlog = await imageFile.blob()
  const imageURL = URL.createObjectURL(imageBlog)

  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = imageURL
  link.download = name
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
