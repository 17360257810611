// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AspectRatio_AspectRatioContainer__z8PMm {\n  position: relative;\n  padding-top: calc(var(--height) / var(--width) * 100%);\n}\n\n.AspectRatio_AspectRatioWrapper__1yizJ {\n  display: flex;\n  height: 100%;\n  width: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n\n.AspectRatio_AspectRatioContent__12exb {\n  display: flex;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n  position: relative;\n}\n\n.AspectRatio_AspectRatioContent__12exb > img {\n  display: block;\n  height: 100%;\n  width: 100%;\n  object-fit: contain;\n}\n", "",{"version":3,"sources":["webpack://src/components/LOM/AspectRatioBox/AspectRatio.module.scss"],"names":[],"mappings":"AACE;EACE,kBAAkB;EAClB,sDAAsD;AAAA;;AAGxD;EACE,aAAa;EACb,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,MAAM;EACN,OAAO;AAAA;;AAGT;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,kBAAkB;AAAA;;AALnB;EAQG,cAAc;EACd,YAAY;EACZ,WAAW;EACX,mBAAmB;AAAA","sourcesContent":[".AspectRatio {\n  &Container {\n    position: relative;\n    padding-top: calc(var(--height) / var(--width) * 100%);\n  }\n\n  &Wrapper {\n    display: flex;\n    height: 100%;\n    width: 100%;\n    position: absolute;\n    top: 0;\n    left: 0;\n  }\n\n  &Content {\n    display: flex;\n    align-items: center;\n    height: 100%;\n    width: 100%;\n    position: relative;\n\n    > img {\n      display: block;\n      height: 100%;\n      width: 100%;\n      object-fit: contain;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AspectRatioContainer": "AspectRatio_AspectRatioContainer__z8PMm",
	"AspectRatioWrapper": "AspectRatio_AspectRatioWrapper__1yizJ",
	"AspectRatioContent": "AspectRatio_AspectRatioContent__12exb"
};
export default ___CSS_LOADER_EXPORT___;
