import { memo } from "react"
import { t } from "@wiicamp/decentraland-dapps/dist/modules/translation/utils"
import { Loader } from "@wiicamp/decentraland-ui"

import UploadedNFTCard from "./UploadedNFTCard"

import { Props } from "./UploadedNFTList.types"

const UploadedNFTList = (props: Props) => {
  const {
    nftList,
    isLoadingFetchMyItemsCreation,
    saleItemUserCreation,
    saleItemUserCreationLoading,
    itemsUserCreationOnSaleNeedLoading,
    deleteItemUserCreation,
    isLoadingDeleteItemUserCreation,
    setItemUserCreationEdit,
  } = props

  return (
    <div className="mt-16">
      <div className="relative -mx-4 -mt-8 Marketplace-Estates__grid">
        {isLoadingFetchMyItemsCreation ? (
          <div className="mt-56">
            <div className="overlay bg-transparent" />
            <Loader size="massive" active />
          </div>
        ) : (
          <>
            {nftList.map((nft: any, index: number) => (
              <UploadedNFTCard
                item={nft}
                key={index}
                saleItemUserCreation={saleItemUserCreation}
                saleItemUserCreationLoading={saleItemUserCreationLoading && itemsUserCreationOnSaleNeedLoading.includes(nft._id)}
                deleteItemUserCreation={deleteItemUserCreation}
                isLoadingDeleteItemUserCreation={isLoadingDeleteItemUserCreation}
                setItemUserCreationEdit={setItemUserCreationEdit}
              />
            ))}

            {nftList.length === 0 ? (
              <div className="empty text-center w-full text-white">{t('nft_list.empty')}</div>
            ) : null}
          </>
        )}
      </div>
    </div>
  )
}

export default memo(UploadedNFTList)
