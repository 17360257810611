// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../../images/back.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PartnerSidebar .go-back {\n  margin-top: 3px;\n  margin-bottom: 21px;\n  display: flex;\n  align-items: center;\n  color: var(--secondary-text);\n  text-transform: uppercase;\n  font-size: 10px;\n  cursor: pointer;\n}\n\n.PartnerSidebar i.back.icon {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: 6px;\n}\n", "",{"version":3,"sources":["webpack://src/components/LOM/Vendor/PartnerSidebar/PartnerSidebar.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,4BAA4B;EAC5B,yBAAyB;EACzB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,yDAAkD;EAClD,4BAA4B;EAC5B,oBAAoB;AACtB","sourcesContent":[".PartnerSidebar .go-back {\n  margin-top: 3px;\n  margin-bottom: 21px;\n  display: flex;\n  align-items: center;\n  color: var(--secondary-text);\n  text-transform: uppercase;\n  font-size: 10px;\n  cursor: pointer;\n}\n\n.PartnerSidebar i.back.icon {\n  background-image: url(../../../../images/back.svg);\n  background-repeat: no-repeat;\n  background-size: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
