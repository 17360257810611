import { AssetType } from '../asset/types'
import { getSearchParams } from './search'
import { BrowseOptions } from './types'

export const locations = {
  // LOM's Routes
  rootLOM: () => '/',
  settingsLOM: () => `/settings`,
  bidsLOM: () => '/bids',

  landsLOM: () => '/marketplace/estates',
  ticketsLOM: () => '/marketplace/tickets',
  myTicketsLOM: (address:string = ':address', ticketId?: string) => {
    return ticketId ? `/tickets/${address}?ticketId=${ticketId.toString()}` : `/tickets/${address}`
  },
  ticketDetail: (ticketId: string = ':ticketId') => `/ticket/${ticketId}`,
  itemsNFTPage: (params?: string, isItemNFTPage?: boolean) => {
    if (!params) return '/marketplace/collectibles'

    if(isItemNFTPage) return `/marketplace/collectibles?${params}`

    return `/marketplace/collectibles?category=${params}`
  },
  itemsNFTDetailPage: (slug: string = ':slug', contractAddress: string = ':contractAddress') => `/marketplace/collectibles/${contractAddress}/${slug}`, // item listing / resold detail page
  itemsCreationDetailPage: (slug: string = ':slug') => `/marketplace/collectibles/creation/${slug}`, // item creation detail page

  marketplace: () => '/marketplace',
  browseLOM: (options?: BrowseOptions) => {
    const params = getSearchParams(options)
    return params ? `/marketplace/browse?${params.toString()}` : '/marketplace/browse'
  },
  currentAccountLOM: (options?: BrowseOptions) => {
    const params = getSearchParams(options)
    return params ? `/account?${params.toString()}` : '/account'
  },
  accountSettingsLOM: () => '/account-settings',
  estateBuilderLOM: () => '/estate-builder',
  itemCreatorLOM: () => process.env.REACT_APP_CHARACTOR_BUILDER || '',
  createNFTItemLOM: () => '/create-nft-item',
  editNFTItemLOM: (id: string = ':id') => `/edit-nft-item/${id}`,
  accountLOM: (address: string = ':address', options?: BrowseOptions) => {
    const params = getSearchParams(options)
    return params
      ? `/accounts/${address}?${params.toString()}`
      : `/accounts/${address}`
  },
  nftLOM: (
    contractAddress: string = ':contractAddress',
    tokenId: string = ':tokenId'
  ) => `/marketplace/contracts/${contractAddress}/tokens/${tokenId}`,
  itemLOM: (
    contractAddress: string = ':contractAddress',
    itemId: string = ':itemId'
  ) => `/marketplace/contracts/${contractAddress}/items/${itemId}`,
  parcelLOM: (x: string = ':x', y: string = ':y') => `/marketplace/parcels/${x}/${y}/detail`,
  estateLOM: (estateId: string = ':estateId') => `/marketplace/estates/${estateId}/detail`,

  buyLOM: (
    type: AssetType,
    contractAddress: string = ':contractAddress',
    tokenId: string = ':tokenId'
  ) => `/marketplace/contracts/${contractAddress}/${getResource(type)}/${tokenId}/buy`,
  sellLOM: (
    contractAddress: string = ':contractAddress',
    tokenId: string = ':tokenId'
  ) => `/marketplace/contracts/${contractAddress}/tokens/${tokenId}/sell`,
  cancelLOM: (
    contractAddress: string = ':contractAddress',
    tokenId: string = ':tokenId'
  ) => `/marketplace/contracts/${contractAddress}/tokens/${tokenId}/cancel`,
  transferLOM: (
    contractAddress: string = ':contractAddress',
    tokenId: string = ':tokenId'
  ) => `/marketplace/contracts/${contractAddress}/tokens/${tokenId}/transfer`,
  bidLOM: (
    contractAddress: string = ':contractAddress',
    tokenId: string = ':tokenId'
  ) => `/marketplace/contracts/${contractAddress}/tokens/${tokenId}/bid`,
  activityLOM: () => `/activity`,

  story: (slug: string = ':slug') => `/story/${slug}`,
  tokenomics: () => '/tokenomics',
  roadmap: () => '/roadmap',
  whitepaper: () => '/LOM_Whitepaper.pdf',
  newsfeed: () => '/newsfeed',
  newsfeedDetail: (newsfeedSlug: string = ':slug') => `/newsfeed/${newsfeedSlug}`,

  // Decentraland's routes
  root: () => '/marketplace-release',
  signIn: () => '/sign-in',
  settings: () => '/settings-release',
  partners: () => '/partners',
  bids: () => '/bids-release',
  lands: () => '/lands',
  collectibles: () => '/collectibles',
  browse: (options?: BrowseOptions) => {
    const params = getSearchParams(options)
    return params ? `/browse-release?${params.toString()}` : '/browse-release'
  },
  currentAccount: (options?: BrowseOptions) => {
    const params = getSearchParams(options)
    return params ? `/account-release?${params.toString()}` : '/account-release'
  },
  account: (address: string = ':address', options?: BrowseOptions) => {
    const params = getSearchParams(options)
    return params
      ? `/accounts-release/${address}?${params.toString()}`
      : `/accounts-release/${address}`
  },
  nft: (
    contractAddress: string = ':contractAddress',
    tokenId: string = ':tokenId'
  ) => `/contracts/${contractAddress}/tokens/${tokenId}`,
  item: (
    contractAddress: string = ':contractAddress',
    itemId: string = ':itemId'
  ) => `/contracts/${contractAddress}/items/${itemId}`,
  parcel: (x: string = ':x', y: string = ':y') => `/parcels/${x}/${y}/detail`,
  estate: (estateId: string = ':estateId') => `/estates/${estateId}/detail`,
  buy: (
    type: AssetType,
    contractAddress: string = ':contractAddress',
    tokenId: string = ':tokenId'
  ) => `/contracts/${contractAddress}/${getResource(type)}/${tokenId}/buy`,
  sell: (
    contractAddress: string = ':contractAddress',
    tokenId: string = ':tokenId'
  ) => `/contracts/${contractAddress}/tokens/${tokenId}/sell`,
  cancel: (
    contractAddress: string = ':contractAddress',
    tokenId: string = ':tokenId'
  ) => `/contracts/${contractAddress}/tokens/${tokenId}/cancel`,
  transfer: (
    contractAddress: string = ':contractAddress',
    tokenId: string = ':tokenId'
  ) => `/contracts/${contractAddress}/tokens/${tokenId}/transfer`,
  bid: (
    contractAddress: string = ':contractAddress',
    tokenId: string = ':tokenId'
  ) => `/contracts/${contractAddress}/tokens/${tokenId}/bid`,
  activity: () => `/activity-release`
}

function getResource(type: AssetType) {
  switch (type) {
    case AssetType.NFT:
      return 'tokens'
    case AssetType.ITEM:
      return 'items'
    default:
      throw new Error(`Invalid type ${type}`)
  }
}
