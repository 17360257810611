import { useEffect, useState, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Loader, Button } from '@wiicamp/decentraland-ui'
import { t } from '@wiicamp/decentraland-dapps/dist/modules/translation/utils'

import LayoutPage from '../Layout/LayoutPage/LayoutPage'
import Breadcrumb from '../Breadcrumb'
import { Row } from '../../Layout/Row'
import { Column } from '../../Layout/Column'
import { ItemNFTFilters } from '../Vendor/decentraland/ItemNFTFilters'
import { ToggleBox } from '../AssetBrowse/ToggleBox'
import { ItemNFTCard } from './ItemNFTCard'

import useSearchParams from '../../../customHooks/useSearchParams'
import useDebounce from '../../../customHooks/useDebounce'
import { locations } from '../../../modules/routing/locations'

import { Props } from './ItemNFTPage.types'
import { ItemNFTType } from '../../../modules/itemNFT/types'
import { MarketplaceType } from './ItemNFTCard/ItemNFTCard.types'

import cssStyles from './ItemsNFTPage.module.scss'

// EXPLAIN: list values for keeping reference in useEffect
const newDefaultArr: string[] = []
const newDefaultObj = {}
const newDefaultStr = ''
const defaultType = MarketplaceType.USERS_CREATIONS
const defaultPage = { currentPage: 2, isLastPage: false }
const limitItemOfPage = 20

const ItemNFTPage = (props: Props) => {
  const {
    // fetchItemsNFT,
    itemsNFTLoading,
    itemsNFT,
    // fetchCategoriesItemsNFT,
    categoriesItemNFT,

    fetchUserCreationItems,

    fetchResoldItemsNFT,
    fetchItemsMarketplaceLoading,
  } = props
  const history = useHistory()
  const searchParams = useSearchParams()

  const [parentCategoryPicking, setParentCategoryPicking] = useState<string>(newDefaultStr)
  const [subCategoriesPicking, setSubCategoriesPicking] = useState<string[]>(newDefaultArr)
  const [attributesPicking, setAttributesPicking] = useState<({})>({ ...newDefaultObj })
  const [searchInput, setSearchInput] = useState<string>(newDefaultStr)
  const [fetchedCategoriesIcon, setFetchedCategoriesIcon] = useState<any>(null)
  const [isFetchSVG, setIsFetchSVG] = useState(false)
  const [page, setPage] = useState({...defaultPage})

  const debouncedSearchInput = useDebounce<string>(searchInput, 500)

  const itemNFTPageType: MarketplaceType = searchParams.get('type') ? searchParams.get('type') as MarketplaceType : defaultType
  const currentCategory = searchParams.get('category') || newDefaultStr

  const breadcrumb = [
    { name: 'Home', link: '/' },
    { name: 'Marketplace', link: '/marketplace' },
    {
      name: parentCategoryPicking || 'Collectibles',
      active: true,
      icon: true,
      iconSVG: fetchedCategoriesIcon?.[parentCategoryPicking] || 'none',
      isShowCategoryIcon: !!parentCategoryPicking,
      isBreakHere: true
    }
  ]

  const isHiddenShowMoreButton = useMemo(() => itemsNFTLoading || fetchItemsMarketplaceLoading || !itemsNFT.length,
  [itemsNFTLoading,fetchItemsMarketplaceLoading, itemsNFT ])

  const defaultQueryGetItemNFT = useMemo(() => ({
    page: 1,
    isRefreshed: true,
    q: debouncedSearchInput || undefined,
    limit: limitItemOfPage,
    category: currentCategory || undefined,
    subCategory: subCategoriesPicking.filter(item => !!item).toString() || undefined,
    attributes: !!Object.keys(attributesPicking).length ? attributesPicking : undefined,
  }), [subCategoriesPicking, attributesPicking, currentCategory, debouncedSearchInput])

  const onResetPage = useCallback(() => {
    setPage({...defaultPage});
  },[])

  const onUpdateMarketplaceType = useCallback((newValue: MarketplaceType) => {
    searchParams.set('type', newValue)
    searchParams.delete('category')
    history.push(locations.itemsNFTPage(searchParams.toString(), true));
    onResetPage()
  }, [history, searchParams, onResetPage]);

  const onUpdateSingleParentCategory = useCallback((newValue: string) => () => {
    searchParams.set('category', currentCategory !== newValue ? newValue : '')
    history.push(locations.itemsNFTPage(searchParams.toString(), true));
    onResetPage()
  }, [history, searchParams, currentCategory, onResetPage])

  const onUpdateMultipleCategories = useCallback(
    (
      newValue: string,
      primaryArray: string[],
      callback: React.Dispatch<React.SetStateAction<string[]>>,
      isReset: boolean = false,
    ) => () => {
      if (isReset) {
        callback([])
        return
      }

      if (primaryArray.includes(newValue)) {
        const newArr = primaryArray.filter(item => item !== newValue)
        callback(newArr)
        return
      }

      callback([newValue]) // get one item
      // callback([...primaryArray, newValue]) // get multiple items
    }, []
  )

  const onUpdateMultipleAttributes = useCallback(
    (
      slugKey: string,
      newValue: string,
      primaryState: { [key: string]: string[] },
      callback: React.Dispatch<React.SetStateAction<({ [key: string]: any })>>,
      isReset: boolean = false,
    ) => () => {
      if (isReset) {
        callback(prevState => ({...prevState, [slugKey]: undefined }))
        return
      }

      const oldArr = primaryState[slugKey] || []
      const newArr = oldArr.includes(newValue) ? oldArr.filter(item => item !== newValue) : [...oldArr, newValue]

      callback(prevState => ({...prevState, [slugKey]: !!newArr.length ? newArr : undefined }))
  }, [])

  const onChangeSearchInput = useCallback((event: any) => {
    setSearchInput(event.target.value)
  }, [])

  const onFetchCategoriesIcon = useCallback(async () => {
    let listPromises: any[] = []
    let listKeys: any[] = []
    const listCategoriesIcon: any = {}

    categoriesItemNFT.forEach((category: any) => {
      const resSVG = !!category?.icon?.length ? fetch(category.icon).then(res => res.text()) : undefined
      listPromises.push(resSVG)
      listKeys.push(category.slug)
    })

    const listFetchedIcon = await Promise.all(listPromises)
    listKeys.forEach((key, index) => listCategoriesIcon[key] = listFetchedIcon[index])

    setFetchedCategoriesIcon(listCategoriesIcon)
  }, [categoriesItemNFT])

  const onLoadMore = useCallback(() => {
    const callback = (array: ItemNFTType[]) => {
      setPage((prevState) => ({
        currentPage: prevState.currentPage + 1,
        isLastPage: array.length < limitItemOfPage,
      }));
    }

    const query = {...defaultQueryGetItemNFT, page: page.currentPage, isRefreshed: false, callback }

    switch (itemNFTPageType) {
      case MarketplaceType.USERS_CREATIONS:
        fetchUserCreationItems(query)
        break;
      case MarketplaceType.RESOLD_ITEMS:
        fetchResoldItemsNFT(query)
        break;
      default:
        break;
    }
  }, [fetchResoldItemsNFT, fetchUserCreationItems, defaultQueryGetItemNFT, page, itemNFTPageType])

  // TODO: fetch SVG Icon on first page load only.
  useEffect(() => {
    if (!!categoriesItemNFT?.length && !isFetchSVG) {
      setIsFetchSVG(true)
      onFetchCategoriesIcon()
    }
  }, [categoriesItemNFT, isFetchSVG, onFetchCategoriesIcon])

  useEffect(() => {
    if (currentCategory !== parentCategoryPicking) {
      setParentCategoryPicking(currentCategory)
      setSubCategoriesPicking(newDefaultArr)
      setAttributesPicking({ ...newDefaultObj })
      setSearchInput(newDefaultStr)
    }
  }, [searchParams]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (debouncedSearchInput === searchInput) {
      if (itemNFTPageType === MarketplaceType.RESOLD_ITEMS) {
        fetchResoldItemsNFT({...defaultQueryGetItemNFT})
      } else {
        // fetchItemsNFT({
        //   q: debouncedSearchInput || undefined,
        //   category: currentCategory || undefined,
        //   subCategory: subCategoriesPicking.filter(item => !!item).toString() || undefined,
        //   attributes: !!Object.keys(attributesPicking).length ? attributesPicking : undefined,
        // })

        fetchUserCreationItems({...defaultQueryGetItemNFT})
      }
    }

  }, [
    // fetchItemsNFT,
    searchInput,
    debouncedSearchInput,
    itemNFTPageType,
    defaultQueryGetItemNFT,
    fetchResoldItemsNFT,
    fetchUserCreationItems,
  ])

  useEffect(() => {
    if(!searchParams.get('type')) {
      searchParams.set('type', defaultType)
      history.replace({ search: searchParams.toString() });
    }
  },[])

  const RenderListItemNFTs = () => (
    itemsNFT.length > 0 ?
      <>
        {itemsNFT.map((item: ItemNFTType) => (
          <ItemNFTCard
            key={`${item.id}-${item.name}`}
            {...item}
            className="mt-8"
            type={itemNFTPageType}
          />
        ))}
      </>
      : <div className="empty text-center w-full">{t('nft_list.empty')}</div>
    )

  return (
    <LayoutPage viewMode="white">
      <div className="flex-grow min-h-full overflow-auto Marketplace__background dark bg-black text-white">
        <div className="Page__header mt-8">
          <Breadcrumb breadcrumb={breadcrumb} viewMode="dark" />
        </div>

        <div className="pb-16 border-b-2 border-gray">
          <Row className={`${cssStyles.assetBrowserPage} AssetBrowse`}>
            <Column align="right" grow={true} className="w-full">
              <div className={cssStyles.assetFilter}>
                <ToggleBox
                  className={`result-type-toggle ${cssStyles.filterByType}`}
                  items={[
                    {
                      title: 'users creations',
                      active: itemNFTPageType === MarketplaceType.USERS_CREATIONS,
                      description: '',
                      onClick: () => onUpdateMarketplaceType(MarketplaceType.USERS_CREATIONS),
                    },
                    {
                      title: 'resold items',
                      active: itemNFTPageType === MarketplaceType.RESOLD_ITEMS,
                      description: '',
                      onClick: () => onUpdateMarketplaceType(MarketplaceType.RESOLD_ITEMS),
                    }
                  ]}
                />

                <div className={cssStyles.searchWrapper}>
                  <ItemNFTFilters
                    itemsNFTLoading={itemsNFTLoading}
                    totalItems={itemsNFT.length}
                    categoriesItemNFT={categoriesItemNFT}
                    fetchedCategoriesIcon={fetchedCategoriesIcon}

                    searchInput={searchInput}
                    onChangeSearchInput={onChangeSearchInput}

                    parentCategoryPicking={parentCategoryPicking}
                    onUpdateSingleParentCategory={onUpdateSingleParentCategory}

                    subCategoriesPicking={subCategoriesPicking}
                    setSubCategoriesPicking={setSubCategoriesPicking}
                    onUpdateMultipleCategories={onUpdateMultipleCategories}

                    attributesPicking={attributesPicking}
                    setAttributesPicking={setAttributesPicking}
                    onUpdateMultipleAttributes={onUpdateMultipleAttributes}
                  />
                </div>
              </div>

              <div className="mt-16">
                <div className="relative -mx-4 -mt-8 Marketplace-Estates__grid">
                  {itemsNFTLoading || fetchItemsMarketplaceLoading ? (
                    <div className="mt-56">
                      <div className="overlay bg-transparent" />
                      <Loader size="massive" active />
                    </div>
                  ) : (
                    <RenderListItemNFTs />
                  )}
                </div>
              </div>

              {isHiddenShowMoreButton ? null : (
                <div className="flex justify-center mt-16 mb-10">
                  <Button
                    className="LOM-submit-btn"
                    onClick={onLoadMore}
                    disabled={page.isLastPage || itemsNFT.length < limitItemOfPage}
                  >
                    Load More
                  </Button>
                </div>
              )}
            </Column>
          </Row>
        </div>
      </div>
    </LayoutPage>
  )
}

export default ItemNFTPage
