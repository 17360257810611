import React, { useState, useCallback, useEffect, useRef } from 'react'
import { Button, Loader, Mana } from '@wiicamp/decentraland-ui'
import { Network } from '@dcl/schemas'

import TicketInformationSection from './TicketInformationSection'
import TicketUI from './TicketUI'

import { ReactComponent as IconStartLOM } from '../../../../asset/icons/ic-start-lom.svg'

import { initFormTicketData } from '../constants'

import { SingleTicketProps } from '../TicketPage.types'

import moduleStyles from './Ticket.module.scss'

const TicketItem = (props: SingleTicketProps) => {
  const {
    startColor,
    middleColor,
    endColor,
    backgroundTicket,
    launchSite,
    destination,
    // isReadyToStart,
    onReadyToStart,
    ticketLoading,
    isOnClaimList,
    isAbleToMint,
    mintTicketAirdropRequest
  } = props

  const [divStyle, setDivStyle] = useState({} as React.CSSProperties)
  const [isShowContentTicket, setIsShowContentTicket] = useState(false)
  const [isShowFormTicket, setIsShowFormTicket] = useState(false)
  const [onConfirmTicket, setOnConfirmTicket] = useState(false)
  const [formTicketData, setFormTicketData] = useState({
    ...initFormTicketData
  })
  const [width, setWidth] = useState(0)
  const { firstName, lastName } = formTicketData
  const ticketRef = useRef<HTMLDivElement>(document.createElement(`div`))

  const toggleShowContentTicket = useCallback(
    (toggleKey: boolean) => () => {
      setIsShowContentTicket(toggleKey)
    },
    []
  )

  // const toggleShowFormTicket = useCallback(() => {
  //   if (!isShowContentTicket) {
  //     setIsShowContentTicket(true)
  //   }

  //   setOnConfirmTicket(false)
  //   setIsShowFormTicket(prevState => !prevState)
  // }, [isShowContentTicket])

  const onChangeFormTicketData = useCallback(
    (key: string, newValue: string) => {
      if (!isShowContentTicket) {
        setIsShowContentTicket(true)
      }

      setFormTicketData({ ...formTicketData, [key]: newValue })
    },
    [isShowContentTicket, formTicketData]
  )

  // const onSubmitFormTicket = useCallback(() => {
  //   setIsShowFormTicket(false)
  //   setOnConfirmTicket(true)
  // }, [])

  const onPrintTicket = useCallback(() => { }, [])

  const onGetTicket = useCallback(() => {
    if (isOnClaimList && isAbleToMint) {
      mintTicketAirdropRequest(firstName, lastName)
    } else {
      onReadyToStart(false, { firstName, lastName })()
    }
  }, [mintTicketAirdropRequest, isOnClaimList, isAbleToMint, onReadyToStart, firstName, lastName])

  useEffect(() => {
    if (ticketRef.current.offsetHeight) {
      new ResizeObserver(entry => {
        if (entry?.[0]?.contentRect?.width) setWidth(entry[0].contentRect.width)
      }).observe(ticketRef.current)
    }
  }, [ticketRef])

  useEffect(() => {
    setDivStyle(
      (prevState: React.CSSProperties) =>
      ({
        ...prevState,
        '--startColor': startColor,
        '--middleColor': middleColor,
        '--endColor': endColor
      } as React.CSSProperties)
    )
    setIsShowContentTicket(false)
    setIsShowFormTicket(false)
    setOnConfirmTicket(false)
    setFormTicketData({ ...initFormTicketData })
  }, [startColor]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setDivStyle(
      (prevState: React.CSSProperties) =>
      ({
        ...prevState,
        '--widthOfDiv': width
      } as React.CSSProperties)
    )
  }, [width]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={moduleStyles.ticketDetails}>
      {ticketLoading?.length ? (
        <>
          <div className="overlay" />
          <Loader size="massive" active />
        </>
      ) : null}

      <TicketUI
        ref={ticketRef}
        backgroundTicket={backgroundTicket}
        launchSite={launchSite}
        destination={destination}
        divStyle={divStyle}
        isShowContentTicket={isShowContentTicket}
        onConfirmTicket={onConfirmTicket}
        firstName={firstName}
        lastName={lastName}
        toggleShowContentTicket={toggleShowContentTicket}
      />

      <div className={moduleStyles.ticketPriceWrapper}>
        <div className={moduleStyles.ticketPriceContent}>
          <div className="text-gray uppercase font-bold">
            Price
          </div>

          <Mana network={Network.ETHEREUM} className={moduleStyles.ticketPriceContentText}>
            {
              isOnClaimList && isAbleToMint
                ? (<div className="flex align-baseline">
                  <span className="mr-1">0</span>
                  <span><small><del>9,999</del></small></span>
                </div>)
                : '9,999'
            }
          </Mana>
        </div>
      </div>

      <TicketInformationSection
        firstName={firstName}
        lastName={lastName}
        isShowFormTicket={isShowFormTicket}
        onChangeFormTicketData={onChangeFormTicketData}
        onReadyToStart={onReadyToStart}
      />



      <div className={moduleStyles.ticketBtnGroup}>
        {false ? (
          <>
            <Button
              className="LOM-submit-btn start-btn"
              onClick={onPrintTicket}
            >
              <IconStartLOM />
            </Button>
          </>
        ) : (
          <>
            {/* <Button className="LOM-submit-btn" onClick={toggleShowFormTicket}>
              Ticket Details
            </Button> */}

            <Button
              className={`
                LOM-submit-btn LOM-green-btn
                ${(!firstName || !lastName) && 'LOM-disabled-btn'}`}
              onClick={onGetTicket}
              disabled={!firstName || !lastName}
            >
              {isOnClaimList && isAbleToMint
                ? 'Claim Ticket'
                : 'Get Ticket'
              }
            </Button>
          </>
        )}
      </div>
    </div>
  )
}

export default TicketItem
