import { connect } from 'react-redux'
import { getError, isEnabling, isConnecting } from '@wiicamp/decentraland-dapps/dist/modules/wallet/selectors'
import { enableWalletRequest } from '@wiicamp/decentraland-dapps/dist/modules/wallet/actions'

import {
  MapStateProps,
  MapDispatchProps,
  MapDispatch
} from './TicketPage.types'
import TicketPage from './TicketPage'

import { RootState } from '../../../modules/reducer'
import { generateTicketRequest, checkWalletIsOnClaimListRequest, checkWalletIsMintAirdropRequest, mintTicketAirdropRequest } from '../../../modules/ticket/actions'
import { getWallet } from '../../../modules/wallet/selectors'

import {
  getLoading,
  getIsMintAirdrop,
  getIsOnClaimList,
} from '../../../modules/ticket/selectors'

const mapState = (
  state: RootState,
): MapStateProps => {
  return {
    ticketLoading: getLoading(state),
    wallet: getWallet(state),
    hasError: !!getError(state),
    isLoading: isEnabling(state) || isConnecting(state),
    isMintAirdropBefore: getIsMintAirdrop(state),
    isOnClaimList: getIsOnClaimList(state)
  }
}

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onConnect: providerType => dispatch(enableWalletRequest(providerType)),
  generateTicketRequest: (params) => dispatch(generateTicketRequest(params)),
  checkWalletIsOnClaimListRequest: () => dispatch(checkWalletIsOnClaimListRequest()),
  checkWalletIsMintAirdropRequest: () => dispatch(checkWalletIsMintAirdropRequest()),
  mintTicketAirdropRequest: (firstName, lastName) => dispatch(mintTicketAirdropRequest(firstName, lastName)),
})


export default connect(mapState, mapDispatch)(TicketPage)
