import { RootState } from '../reducer'

export const getState = (state: RootState) => state.estate
export const getCurrentPage = (state: RootState) => getState(state).page

export const getEstates = (state: RootState) => getState(state).estates
export const getIsLoadingGetEstatesByWalletAddress = (state: RootState) => getState(state).isLoadingGetEstatesByWalletAddress
export const getPageEstates = (state: RootState) => getState(state).pageEstates
export const getTotalEstates = (state: RootState) => getState(state).totalEstates

export const getLands = (state: RootState) => getState(state).lands
export const getIsLoadingGetLandsByWalletAddress = (state: RootState) => getState(state).isLoadingGetLandsByWalletAddress
export const getPageLands = (state: RootState) => getState(state).pageLands
export const getTotalLands = (state: RootState) => getState(state).totalLands
