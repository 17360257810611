import { useState, useCallback, memo, useEffect } from 'react'
import { useHistory } from 'react-router'
import { Loader } from '@wiicamp/decentraland-ui'
import { ProviderType } from 'decentraland-connect'
import {
  T,
  t
} from '@wiicamp/decentraland-dapps/dist/modules/translation/utils'

import { LoginModal, LoginModalOptionType } from '@wiicamp/decentraland-ui'

import { ReactComponent as IconArrow } from '../../../asset/images/tools/arrow-right-bottom.svg'

import { Props } from './Wallet.types'

import './Wallet.scss'

const Loading = memo(() => (
  <div className="wallet-center">
    <Loader active size="huge" />
  </div>
))

const Wallet = (props: Props) => {
  const { wallet, isLoading, children, onConnect, hasError } = props
  const [isShowLoginModal, setShowLoginModal] = useState(false)
  const history = useHistory()
  const strURLNFT = window.location.href.split('/')
  const newURLNFT = strURLNFT.splice(3, strURLNFT.length - 4).join('/')

  const onToggleLoginModal = useCallback(() => {
    setShowLoginModal(prevState => !prevState)
  }, [])

  const onConnectWallet = useCallback(
    (loginType: LoginModalOptionType) => {
      let providerType: ProviderType

      switch (loginType) {
        case LoginModalOptionType.METAMASK:
        case LoginModalOptionType.DAPPER:
        case LoginModalOptionType.SAMSUNG:
          providerType = ProviderType.INJECTED
          break
        case LoginModalOptionType.FORTMATIC:
          providerType = ProviderType.FORTMATIC
          break
        case LoginModalOptionType.WALLET_CONNECT:
          providerType = ProviderType.WALLET_CONNECT
          break
        default:
          throw new Error(`Invalid login type ${loginType}`)
      }

      onConnect(providerType)
    },
    [onConnect]
  )

  const NotConnected = memo(() => (
    <div className="wallet-center">
      <p className="secondary-text">
        <T
          id="wallet.sign_in_required"
          values={{
            sign_in: (
              <span className="btn-login" onClick={onToggleLoginModal}>
                {t('wallet.sign_in')}
              </span>
            )
          }}
        />
      </p>
    </div>
  ))

  const onCheckGoBack = useCallback(() => {
    if (history.length === 2) {
      history.push(`/${newURLNFT}`)
    } else {
      history.goBack()
    }
  }, [history, newURLNFT])

  useEffect(() => {
    if (wallet && wallet.address) {
      setShowLoginModal(false)
    }
  }, [wallet])

  return (
    <>
      <button onClick={onCheckGoBack} className="go-back-arrow">
        <IconArrow className="icon-arrow" />

        <span>Go back</span>
      </button>
      {isLoading ? <Loading /> : null}
      {!wallet && !isLoading ? <NotConnected /> : null}
      {wallet && !isLoading ? children(wallet) : null}

      <LoginModal
        open={isShowLoginModal}
        // message="This is a custom persistent message"
        hasError={hasError}
        loading={isLoading}
        onClose={onToggleLoginModal}
      >
        <LoginModal.Option
          type={LoginModalOptionType.METAMASK}
          onClick={() => onConnectWallet(LoginModalOptionType.METAMASK!)}
        />
        {/* <LoginModal.Option type={LoginModalOptionType.DAPPER} />
        <LoginModal.Option type={LoginModalOptionType.SAMSUNG} />
        <LoginModal.Option type={LoginModalOptionType.FORTMATIC} />
        <LoginModal.Option type={LoginModalOptionType.WALLET_CONNECT} /> */}
      </LoginModal>
    </>
  )
}

export default memo(Wallet)
