import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { t, T } from '@wiicamp/decentraland-dapps/dist/modules/translation/utils'
import { Button, Form, Header, Modal } from '@wiicamp/decentraland-ui'
import { Network } from '@dcl/schemas'

import { fromMANA, toMANA } from '../../../../lib/mana'
import { getAssetName } from '../../../../modules/asset/utils'
// import { getDefaultExpirationDate } from '../../../../modules/order/utils'
import { Column } from '../../../Layout/Column'

import { Mana } from '../../../Mana'
import { ManaField } from '../../../ManaField'

import { Props } from './SellModal.types'

import styles from './SellModal.module.scss'

const SellModal = (props: Props) => {
  const {
    onCloseModal,
    nft,
    isOwner,
    listForSaleItem,
    listForSaleItemLoading,
  } = props

  const isUpdate = false

  const [confirmPrice, setConfirmPrice] = useState('')
  const [showConfirm, setShowConfirm] = useState(false)
  const [price, setPrice] = useState('')
  // const [expiresAt, setExpiresAt] = useState(getDefaultExpirationDate())

  // const isInvalidDate = new Date(expiresAt).getTime() < Date.now()

  const subtitle = useMemo(() => {
    if (isOwner) {
      return (
        <T
          id={isUpdate ? 'sell_page.update_subtitle' : 'sell_page.subtitle'}
          values={{ name: <b className="primary-text">{nft.name}</b> }}
        />
      )
    } else {
      return (
        <T
          id="sell_page.invalid_owner"
          values={{ name: <b>{nft.name}</b> }}
        />
      )
    }
  }, [isOwner])

  const handleSubmit = useCallback(() => {
    listForSaleItem(nft, price)
  }, [nft, price])

  // Clear confirm price when closing the confirm modal
  useEffect(() => {
    if (!showConfirm) {
      setConfirmPrice('')
    }
  }, [nft, showConfirm, setConfirmPrice])

  return (
    <Column align="right">
      <Header size="large">
        {t(isUpdate ? 'sell_page.update_title' : 'sell_page.title')}
      </Header>

      <p className={['subtitle', isOwner ? '' : styles.SubtitleError].join(' ')}>
        {subtitle}
      </p>

      <Form onSubmit={() => setShowConfirm(true)}>
        <div className="form-fields">
          <ManaField
            label={t('sell_page.price')}
            type="text"
            placeholder={toMANA(1000)}
            network={nft.network || Network.ETHEREUM}
            value={price}
            focus={true}
            onChange={(_event, props) => {
              const newPrice = fromMANA(props.value)
              setPrice(toMANA(newPrice))
            }}
            disabled={!isOwner}
          />

          {/* <Field
            label={t('sell_page.expiration_date')}
            type="date"
            value={expiresAt}
            onChange={(_event, props) =>
              setExpiresAt(props.value || getDefaultExpirationDate())
            }
            error={isInvalidDate}
            message={
              isInvalidDate ? t('sell_page.invalid_date') : undefined
            }
          /> */}
        </div>

        <div className="buttons">
          <Button as="div" className="LOM-cancel-btn" onClick={onCloseModal} >
            {t('global.cancel')}
          </Button>

          <Button
            className="LOM-submit-btn"
            type="submit"
            primary
            disabled={listForSaleItemLoading || fromMANA(price) <= 0}
            loading={listForSaleItemLoading}
          >
            {t(isUpdate ? 'sell_page.update_submit' : 'sell_page.submit')}
          </Button>
        </div>
      </Form>

      <Modal
        size="small"
        open={showConfirm}
        className='ConfirmPriceModal'
      >
        <Modal.Header>{t('sell_page.confirm.title')}</Modal.Header>

        <Form onSubmit={handleSubmit}>
          <Modal.Content>
            <T
              id="sell_page.confirm.line_one"
              values={{
                name: <b>{getAssetName(nft)}</b>,
                amount: (
                  <Mana network={nft.network || Network.ETHEREUM} inline>
                    {fromMANA(price).toLocaleString()}
                  </Mana>
                )
              }}
            />

            <br />

            <T id="sell_page.confirm.line_two" />

            <ManaField
              className="mana-input"
              label={t('sell_page.price')}
              network={nft.network || Network.ETHEREUM}
              placeholder={price}
              value={confirmPrice}
              onChange={(_event, props) => {
                const newPrice = fromMANA(props.value)
                setConfirmPrice(toMANA(newPrice))
              }}
            />
          </Modal.Content>

          <Modal.Actions>
            <div
              className="ui button LOM-cancel-btn"
              onClick={() => {
                setConfirmPrice('')
                setShowConfirm(false)
              }}
            >
              {t('global.cancel')}
            </div>

            <Button
              className="LOM-submit-btn"
              type="submit"
              primary
              disabled={listForSaleItemLoading || fromMANA(price) !== fromMANA(confirmPrice)}
              loading={listForSaleItemLoading}
            >
              {t('global.proceed')}
            </Button>
          </Modal.Actions>
        </Form>
      </Modal>
    </Column>
  )
}

export default React.memo(SellModal)
