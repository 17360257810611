import { memo } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Page, Loader } from '@wiicamp/decentraland-ui'
import { Icon } from 'semantic-ui-react'
import { Profile } from '@wiicamp/decentraland-dapps/dist/containers'
import { isMobile } from '@wiicamp/decentraland-dapps/dist/lib/utils'
import { t } from '@wiicamp/decentraland-dapps/dist/modules/translation/utils'

import { View } from '../../../modules/ui/types'

import { useTimer } from '../../../lib/timer'
import { PageHeader } from '../../PageHeader'
import { Column } from '../../Layout/Column'

import LayoutPage from '../Layout/LayoutPage/LayoutPage'
import Breadcrumb from '../Breadcrumb'
import { AssetBrowse } from '../AssetBrowse'
import { Breadcrumb as BreadcrumbType } from '../Breadcrumb/Breadcrumb.types'

import { Props } from './AccountPage.types'
import styles from './AccountPage.module.scss'

const AccountPage = (props: Props) => {
  const { address, vendor, wallet, isConnecting, isFullscreen } = props

  const breadcrumb: BreadcrumbType[] = [
    { name: 'Home', link: '/' },
    {
      name: 'Account',
      active: true,
      isBreakHere: true
    }
  ]

  const isCurrentAccount =
    address === undefined || (wallet && wallet.address === address)

  const [hasCopiedAddress, setHasCopiedAddress] = useTimer(1200)

  // Redirect to signIn if trying to access current account without a wallet
  // useEffect(() => {
  //   if (isCurrentAccount && !isConnecting && !wallet) {
  //     onRedirect(locations.signIn())
  //   }
  // }, [isCurrentAccount, isConnecting, wallet, onRedirect])

  return (
    <LayoutPage viewMode="white">
      <div className={`flex-grow min-h-full overflow-auto ActivityPage__container ${styles.assetsWrapper}`}>
        <div className="Page__header mt-14 lg:mt-28 mb-14">
          <Breadcrumb breadcrumb={breadcrumb} viewMode="dark" />
        </div>

        <div className="AccountPage mb-10">
          {isCurrentAccount ? (
            isConnecting || !wallet ? (
              <Page>
                <Loader size="massive" active />
              </Page>
            ) : (
              <AssetBrowse
                vendor={vendor}
                address={wallet.address}
                view={View.ACCOUNT}
                isFullscreen={Boolean(isFullscreen)}
              />
            )
          ) : address !== undefined ? (
            <>
              <PageHeader>
                <Column>
                  <Profile
                    address={address}
                    size="massive"
                    imageOnly
                    inline={false}
                  />

                  <div className="profile-name">
                    <Profile address={address} textOnly inline={false} />
                  </div>

                  <div className="profile-address">
                    <div className="profile-address-hash">{address}</div>

                    {!isMobile() && (
                      <div>
                        <CopyToClipboard
                          text={address}
                          onCopy={setHasCopiedAddress}
                        >
                          <Icon
                            aria-label="Copy address"
                            aria-hidden="false"
                            className="copy"
                            name="copy outline"
                          />
                        </CopyToClipboard>

                        {hasCopiedAddress && (
                          <span className="profile-copied-text-desktop copied">
                            {t('account_page.copied')}
                          </span>
                        )}
                      </div>
                    )}
                  </div>

                  {isMobile() && (
                    <div className="profile-copy-text-mobile">
                      <CopyToClipboard
                        text={address}
                        onCopy={setHasCopiedAddress}
                      >
                        {hasCopiedAddress ? (
                          <span className="copied">
                            {t('account_page.copied_capitalized')}
                          </span>
                        ) : (
                          <span className="copy">
                            {t('account_page.copy_address')}
                          </span>
                        )}
                      </CopyToClipboard>
                    </div>
                  )}
                </Column>
              </PageHeader>

              <AssetBrowse
                vendor={vendor}
                address={address}
                view={View.ACCOUNT}
                isFullscreen={Boolean(isFullscreen)}
              />
            </>
          ) : null}
        </div>
      </div>
    </LayoutPage>
  )
}

export default memo(AccountPage)
