import React from 'react'

import { Props } from './Title.types'
import styles from './Title.module.scss'

const Title = (props: Props) => {
  const { children } = props

  return (
    <h1 className={styles.detailTitle}>{children}</h1>
  )
}

export default React.memo(Title)
