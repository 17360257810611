import React from 'react'
import { Link } from 'react-router-dom'
import { Profile } from '@wiicamp/decentraland-dapps/dist/containers'
// import { t } from '@wiicamp/decentraland-dapps/dist/modules/translation/utils'

import { locations } from '../../../../modules/routing/locations'
import { BrowseOptions } from '../../../../modules/routing/types'
import { Section } from '../../../../modules/vendor/decentraland'
import { AssetType } from '../../../../modules/asset/types'
import { shortenAddress } from '../../../../modules/wallet/utils'

import { Props } from './Owner.types'
import styles from './Owner.module.scss'

const Owner = (props: Props) => {
  const { asset } = props

  // let label: string
  let address: string
  let browseOptions: BrowseOptions = {}

  if ('owner' in asset) {
    // label = t('asset_page.owner')
    address = asset.owner
    browseOptions = {
      assetType: AssetType.NFT,
      vendor: asset.vendor,
      // section: Section.ALL,
      section: Section.LAND,
    }
  } else {
    // label = t('asset_page.creator')
    address = asset.creator
    browseOptions = {
      assetType: AssetType.ITEM,
      section: Section.WEARABLES
    }
  }

  return (
    <Link to={locations.accountLOM(address, browseOptions)}>
      <div className={styles.detailSeller}>
        {/* <span>1 week ago by</span> */}
        <div className={styles.detailSellerInfor}>
          <Profile
            size="large"
            address={address}
            imageOnly
            hasPopup
            inline={false}
          />
          {/* <img
              src="https://picsum.photos/200/300"
              alt=""
              className={styles.detailSellerImage}
            /> */}
          <span className={styles.detailSellerName}>
            {/* <Profile address={address} textOnly inline={false} /> */}
            {shortenAddress(address, 5, 4)}
          </span>
          {/* <sup>[Martian]</sup> */}
        </div>
      </div>
    </Link>
  )
}

export default React.memo(Owner)
