import React from 'react'
import { useHistory } from 'react-router'

import Breadcrumb from '../../Breadcrumb'
import Navigation from '../../Navigation'
import { LazyImage } from '../../LazyImage'

import { Props } from './Introduction.types'

import thumbnailStory from '../../../../asset/images/story/introduction-1280.jpg'
import brandImg from '../../../../asset/images/brand-black.svg'

const Introduction = (props: Props) => {
  const { breadcrumb } = props

  const history = useHistory()

  return (
    <div className="flex-grow min-h-full overflow-auto Story__background">
      <div className="Page__header mt-14 lg:mt-28">
        <Breadcrumb breadcrumb={breadcrumb} />
      </div>

      <div className="mb-10">
        <LazyImage src={thumbnailStory} alt="thumbnail" />

        <div className="mt-5">
          <img
            src={brandImg}
            alt="brand-img"
            className="h-4 align-baseline inline mr-4"
          />

          <p className="inline">
            (LOM) is a blockchain-based, play-to-earn Metaverse Game where you are free to explore,
            build, craft and trade on your terms. As one of the the first
            non-specialist citizen of Mars, you start out humble, but eventually
            will gain access to high tier contents as your character grows.
          </p>
        </div>

        <p className="mt-5">
          Explore Mars’ vast wilderness and challenge the highest mountain in
          the Solar System. Train in technology to craft a cutting edge rover
          that worth millions of NFTs. Start a family and build your
          farm-to-table restaurant together. Grow your Life On Mars - the way
          you want it.
        </p>

        <ol>
          <div className="mt-5">
            <h5 className="text-black uppercase font-bold">
              GAME FEATURES
            </h5>
          </div>

          <li>
            <div className="mt-5 ml-14">
              <p>
                <b className="font-bold">The ever-growing Metaverse: </b> Our red planet is constantly improving. We aim to move toward a green planet - rich and beautiful in activities of life - with you and a big community of players, creators, developers and investors as its backbone. What you build is a part of how future Mars looks like.
              </p>
            </div>
          </li>

          <li>
            <div className="mt-5 ml-14">
              <p>
                <b className="font-bold">Mars holds many mysteries: </b> Watch out for hints while exploring the unforgiving parts of the world, you will stumble upon great secrets that Mars holds. Will you try to get to the bottom of them all, or will you be skeptical and prioritize safety?
              </p>
            </div>
          </li>

          <li>
            <div className="mt-5 ml-14">
              <p>
                <b className="font-bold">
                  Free players are welcome to build too: {' '}
                </b>
                Even though owning an estate unlocks numerous activities and challenges in Estate Mod, it doesn’t mean everyone has to own an estate from the get-go. Players starting out with just their character may still explore in Exploration Mode and use public facilities to play & earn from there. Ultimately, our goal is to inspire a community of stars-gazers!
              </p>
            </div>
          </li>

          <li>
            <div className="mt-5 ml-14">
              <p>
                <b className="font-bold">Finite lands & resources: </b> The rarity for all in-game assets is what shaping Mars ecosystem. Values of items & resources, in this aspect, will be defined by you who generate, buy and sell them.
              </p>
            </div>
          </li>

          <li>
            <div className="mt-5 ml-14">
              <p>
                <b className="font-bold">
                  Promote communication: {' '}
                </b>
                LOM is a great social platform for you to make friends and form meaningful relationships. Hanging out, sending gifts, voice chatting,... with people in the Metaverse will be a rewarding experience.
              </p>
            </div>
          </li>

          <li>
            <div className="mt-5 ml-14">
              <p>
                <b className="font-bold">Be better tomorrow than you are today: </b> Life on Mars’ will never run out of things to do, so you have to adapt to this harsh world accordingly. You will find that all the skills are intertwined with each other. As a result, leveling up is very rewarding as your playing experience will get exponentially richer.
              </p>
            </div>
          </li>

          <div className="mt-5">
            <h5 className="text-black uppercase font-bold">
              BLOCKCHAIN FEATURES
            </h5>
          </div>

          <li>
            <div className="mt-5 ml-14">
              <p>
                <b className="font-bold">Play to earn: </b> You may earn real-life value through various in-game activities. Assets like land, items & resources are rewarded in the form of non-fungible tokens (NFT). In-game currency <b className="text-green">MARC</b>, could also be converted into <b className="text-orange">LOM</b> cryptocurrency, exchangeable on the Marketplace platform. LOM offers what traditional games don’t: truly rewards players for their time and efforts.
              </p>
            </div>
          </li>

          <li>
            <div className="mt-5 ml-14">
              <p>
                <b className="font-bold">Create to earn: </b> Creators could join in on building the game world by uploading their creations on our Create Software.
              </p>
            </div>
          </li>

          <li>
            <div className="mt-5 ml-14">
              <p>
                <b className="font-bold">Decentralized Finance: </b> Play to earn from anywhere with total financial freedom. Staking, Farming, Lending, Vaults... There are multiple ways to earn without relying on any central financial intermediaries.
              </p>
            </div>
          </li>

          <li>
            <div className="mt-5 ml-14">
              <p>
                <b className="font-bold">
                  Expand and upgrade your Estate: {' '}
                </b>
                Through training and exploration, you will gain access to more material sources that help you improve or decorate your estate. You can cooperate with or even purchase and link lands from surrounding players for more ambitious projects.
              </p>
            </div>
          </li>

          <li>
            <div className="mt-5 ml-14">
              <p>
                <b className="font-bold">Estate generates revenue for the owner: </b> Activities and structures you build on your estate are capable of generating incomes for you. Users can pay to participate in your events, purchase items from your shop, or rent an advertisement space in your building.
              </p>
            </div>
          </li>

          <li>
            <div className="mt-5 ml-14">
              <p>
                <b className="font-bold">DAO: </b> Allows players to vote, propose, and help build the system they proposed.
              </p>
            </div>
          </li>


          <li>
            <div className="mt-5 ml-14">
              <p>
                <b className="font-bold">
                  Versatile exchanges: {' '}
                </b>
                Beside the NFT & Crypto trading in Marketplace. Players have the option to exchange NFT assets and send gifts to other players as well, making LOM an ideal social & business platform.
              </p>
            </div>
          </li>

          <li>
            <div className="mt-5 ml-14">
              <p>
                <b className="font-bold">P2P contracts are flexible and safe: </b> The blockchain-based contract system allows you to be creative about your deals while minimizing the risk of scams.
              </p>
            </div>
          </li>

        </ol>
      </div>

      <Navigation
        className="mb-10"
        right={{
          text: 'The Concept',
          className: 'text-xs px-2 tracking-widest',
          onClick: () => {
            history.push('/story/concept')
          },
        }}
      />
    </div>
  )
}

export default React.memo(Introduction)
