import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'

import { LeftProps } from './MenuBar.types'

import logoIcon from '../../../asset/images/logo-icon.svg'
import logoIconWhite from '../../../asset/images/logo-icon-white.svg'
import { ReactComponent as Icon1 } from '../../../images/icon-left-aside/Icon01.svg'
import { ReactComponent as Icon2 } from '../../../images/icon-left-aside/Icon02.svg'
import { ReactComponent as Icon3 } from '../../../images/icon-left-aside/Icon03.svg'
import { ReactComponent as Icon4 } from '../../../images/icon-left-aside/Icon04.svg'
import { ReactComponent as Icon5 } from '../../../images/icon-left-aside/Icon05.svg'
import { ReactComponent as Icon6 } from '../../../images/icon-left-aside/Icon06.svg'

const MenuBarLeft = (props: LeftProps) => {
  const { isShowMenu, viewMode, isRendered } = props
  return (
    <div
      className={classNames(
        'justify-between',
        'LayoutHome__menu-bar',
        'LayoutHome__bar-left',
        isRendered && isShowMenu ? 'rendered' : '',
        isShowMenu && 'show',
        viewMode
      )}
    >
      {true || isShowMenu ? (
        <>
          <div className={`LayoutHome__logo-icon ${viewMode}`}>
            <Link to="/">
              {/* <Power size={'2.6rem'} className={viewMode === 'white' ? 'text-green' : ''} /> */}
              <img src={viewMode ? logoIcon : logoIconWhite} alt="" />
            </Link>
          </div>
          <div className="LayoutHome__language">
            <ol>
              {/* <li className={`${viewMode} active`}>ENGLISH</li> */}
              {/* <li className={`${viewMode} active`}>ENGLISH</li>
            <li className={`${viewMode} `}>TIENG VIET</li>
            <li className={`${viewMode} `}>日本語</li> */}
            </ol>
          </div>
          <div className={`LayoutHome__email ${viewMode}`}>
            support@lifeonmars.org
          </div>
        </>
      ) : (
        <>
          <div className="LayoutHome__menu-bar-small">
            <Link to="/">
              {/* <Power size={'2.6rem'} className={viewMode === 'white' ? 'text-green' : ''} /> */}
              <img src={viewMode ? logoIcon : logoIconWhite} alt="" />
            </Link>
            <ul className="LayoutHome__bar-left-icon">
              <li>
                <Link to="/story/introduction">
                  <Icon1 />
                </Link>
              </li>
              <li>
                <Link to="/marketplace">
                  <Icon2 />
                </Link>
              </li>
              <li>
                <Link to="/tokenomics">
                  <Icon3 />
                </Link>
              </li>
              <li>
                <Link to="/roadmap">
                  <Icon4 />
                </Link>
              </li>
              <li>
                <Link to="/LOM_Whitepaper.pdf">
                  <Icon5 />
                </Link>
              </li>
              <li>
                <Link to="/newsfeed">
                  <Icon6 />
                </Link>
              </li>
            </ul>
          </div>
        </>
      )}
    </div>
  )
}

export default React.memo(MenuBarLeft)
