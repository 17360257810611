import {
  FETCH_TOGGLE_MODAL_DETAIL_ESTATE_REQUEST,
  FetchToggleModalDetailEstateRequestAction
} from './actions'

export type ModalUIState = {
  isOpenModalDetailEstate: boolean
  isNFTToken: boolean
  assetId: string
}

const INITIAL_STATE: ModalUIState = {
  // For ModalDetailEstate
  isOpenModalDetailEstate: false,
  isNFTToken: false,
  assetId: ''
  // For ModalXXX...
}

type ModalReducerAction = FetchToggleModalDetailEstateRequestAction

export function modalReducer(
  state = INITIAL_STATE,
  action: ModalReducerAction
) : ModalUIState {
  switch (action.type) {
    case FETCH_TOGGLE_MODAL_DETAIL_ESTATE_REQUEST: {
      const newModalState = action.payload

      return { ...state, ...newModalState }
    }

    default:
      return state
  }
}
