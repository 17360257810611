import { memo, useCallback, useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Rarity } from '@dcl/schemas'

// import { Profile } from '@wiicamp/decentraland-dapps/dist/containers'

import { Mana } from '../../Mana'
import { AssetImage } from '../AssetImage'

import { formatMANA } from '../../../lib/mana'
import { formatDistanceToNow } from '../../../lib/date'

import { Props } from './AssetCard.types'

import {
  getAssetName,
  getAssetPrice,
  getAssetUrl
} from '../../../modules/asset/utils'
import { shortenAddress } from '../../../modules/wallet/utils'

// import IconMint from '../../../asset/icons/ic-mint.svg'
import IconLand from '../../../images/icon-left-aside/Icon01.svg'
import { ReactComponent as IconRarity } from '../../../asset/icons/ic-rarity.svg'

import cssStyles from './AssetCard.module.scss'

const AssetCard = (props: Props) => {
  const { asset, order, className } = props
  const { parcel, estate, wearable } = asset.data
  const [width, setWidth] = useState(0)
  const imageRef = useRef<HTMLDivElement>(document.createElement(`div`))

  const title = getAssetName(asset)
  const url = `/marketplace${getAssetUrl(asset)}`
  const price = getAssetPrice(asset, order)

  const rarityText = asset?.data?.wearable?.rarity || Rarity.COMMON
  const [light, dark] = Rarity.getGradient(rarityText)
  const background = `linear-gradient(-125deg,${light} 0%,${dark} 100%)`

  const cssStyle = { '--fillColor': dark } as React.CSSProperties

  const classOfMana = useCallback(() => {
    switch (true) {
      case Number(price) > 999999999 * Math.pow(10, 18):
        return 'trillion-mana'

      case Number(price) > 999999 * Math.pow(10, 18):
        return 'billion-mana'

      default:
        return ''
    }
  }, [price])

  useEffect(() => {
    if (imageRef.current.offsetHeight) {
      new ResizeObserver(entry => {
        if (entry?.[0]?.contentRect?.width) setWidth(entry[0].contentRect.width)
      }).observe(imageRef.current)
    }
  }, [imageRef])

  return (
    <Link to={url} className={`${cssStyles.AssetCardLink} ${className}`}>
      <div className={cssStyles.AssetCard} style={cssStyle}>
        <div className={cssStyles.AssetCardContainer} style={{ background }}>
          <div className={cssStyles.AssetCardWrapper}>
            <div className={cssStyles.AssetCardContent}>
              <div className={cssStyles.AssetCardInfo}>
                <div className={cssStyles.AssetCardTime}>
                  <span>
                    {order && parcel && (
                      <div className={cssStyles.AssetCardExpired}>
                        {formatDistanceToNow(+order?.expiresAt || 0, {
                          addSuffix: true
                        })}
                      </div>
                    )}
                  </span>
                  <div className={cssStyles.AssetCardHolder}>
                    {/* <Profile
                      address={asset.contractAddress}
                      textOnly
                      inline={false}
                    /> */}
                    {shortenAddress(asset.contractAddress, 5, 4)}
                    {/* <sup>[martian]</sup> */}
                  </div>
                </div>

                <div className={cssStyles.AssetCardName}>
                  {title.toUpperCase()}
                </div>
                <div className={cssStyles.AssetCardMaterial}>
                  {wearable && <div>iron</div>}
                  {parcel && (
                    <>
                      <i className={cssStyles.AssetCardMaterialIcon} />
                      {`${parcel.x},${parcel.y}`}
                    </>
                  )}
                  {estate && (
                    <>
                      <i className={cssStyles.AssetCardMaterialIcon} />
                      {`${estate.parcels[0].x},${estate.parcels[0].y}`}
                    </>
                  )}
                </div>
              </div>

              <div className={cssStyles.AssetCardImage}>
                <div ref={imageRef} className="absolute top-0 left-0 w-full h-full">
                  <AssetImage
                    asset={asset}
                    showMonospace
                    mapHeight={102 * width / 135}
                  />
                </div>
              </div>

              <div className={cssStyles.AssetCardQuality}>
                <div className={cssStyles.AssetCardIconMint}>
                  {(parcel || estate) && (
                    <div>
                      <img src={IconLand} alt="" />
                    </div>
                  )}

                  {/* <div>
                    <img src={IconMint} alt="" />
                  </div>
                  <div>
                    <img src={IconMint} alt="" />
                  </div>
                  <div>
                    <img src={IconMint} alt="" />
                  </div>
                  <div>
                    <img src={IconMint} alt="" />
                  </div>
                  <div>
                    <img src={IconMint} alt="" />
                  </div>
                  <div>
                    <img src={IconMint} alt="" />
                  </div>
                  <div>
                    <img src={IconMint} alt="" />
                  </div>
                  <div>
                    <img src={IconMint} alt="" />
                  </div>
                  <div>
                    <img src={IconMint} alt="" />
                  </div> */}
                </div>
                <div className={cssStyles.AssetCardValue}>
                  <span>
                    {price && (
                      <Mana
                        network={asset.network}
                        inline
                        className={classOfMana()}
                      >
                        {formatMANA(price)}
                      </Mana>
                    )}
                  </span>
                  {/* {order && parcel && (
                    <div className={cssStyles.AssetCardExpired}>
                      {formatDistanceToNow(+order?.expiresAt || 0, {
                        addSuffix: true
                      })}
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>

          <div className={cssStyles.AssetCardRarity}>
            <div className={cssStyles.AssetCardRarityWrapper}>
              <div className={cssStyles.AssetCardRarityContent}>
                <IconRarity />
              </div>

              <div className={cssStyles.AssetCardRarityText}>
                <span>{rarityText}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default memo(AssetCard)
