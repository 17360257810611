import React from 'react'
import { Container, Header, Stats } from '@wiicamp/decentraland-ui'
import { t } from '@wiicamp/decentraland-dapps/dist/modules/translation/utils'
import { Rarity } from '@dcl/schemas'

import { getAssetName } from '../../../../modules/asset/utils'
import { AssetType } from '../../../../modules/asset/types'

import { PageHeader } from '../../../PageHeader'
import { AssetImage } from '../../AssetImage'
import { Row } from '../../../Layout/Row'
import { Column } from '../../../Layout/Column'

import { Title } from '../Title'
import { Description } from '../Description'
import { Actions } from '../Actions'
import { Owner } from '../Owner'
import { Network } from '../Network'
import { Bids } from '../Bids'
import { WearableRarity } from '../WearableRarity'
import { WearableHighlights } from '../WearableHighlights'
import { OrderDetails } from '../OrderDetails'
import { TransactionHistory } from '../TransactionHistory'
import { WearableCollection } from '../WearableCollection'

import { Props } from './WearableDetail.types'
import styles from './WearableDetail.module.scss'

const WearableDetail = (props: Props) => {
  const { nft } = props
  const wearable = nft.data.wearable!

  return (
    <div className={styles.detail}>
      <section className={styles.sectionIntroduction}>
        <div>
          <Title>{getAssetName(nft)}</Title>

          <WearableCollection type={AssetType.ITEM} asset={nft} />

          <WearableRarity type={AssetType.ITEM} wearable={wearable} />
        </div>

        <Owner asset={nft} />
      </section>

      <PageHeader>
        <AssetImage asset={nft} />
      </PageHeader>

      <Container className={styles.detailMarketInformation}>
        <Description text={wearable.description} />

        <Row>
          <Column align="left" grow={true}>
            <Network asset={nft} />
            <OrderDetails nft={nft} />
          </Column>

          <Column align="right">
            <Actions nft={nft} />
          </Column>
        </Row>

        <Row>
          {nft.issuedId ?
            <Stats title={t('global.issue_number')}>
              <Header>{Number(nft.issuedId).toLocaleString()}<span className="issue-number">/{Rarity.getMaxSupply(wearable.rarity).toLocaleString()}</span></Header>
            </Stats> : null}
        </Row>

        <WearableHighlights type={AssetType.ITEM} wearable={wearable} />

        <Bids nft={nft} />

        <TransactionHistory nft={nft} />
      </Container>
    </div>
  )
}

export default React.memo(WearableDetail)
