import { connect } from 'react-redux'
import { isLoadingType } from '@wiicamp/decentraland-dapps/dist/modules/loading/selectors'

import EstateBuilderPage from './EstateBuilderPage'

import { RootState } from '../../../modules/reducer'
import { getWallet } from '../../../modules/wallet/selectors'
import {
  getEstatesByWalletAddressRequest,
  getLandsByWalletAddressRequest,
  GET_ESTATES_BY_WALLET_ADDRESS_REQUEST,
  GET_LANDS_BY_WALLET_ADDRESS_REQUEST,
  postNewEstateRequest,
} from '../../../modules/estate/actions'
import {
  getEstates,
  getIsLoadingGetEstatesByWalletAddress,
  getPageEstates,
  getTotalEstates,

  getIsLoadingGetLandsByWalletAddress,
  getLands,
  getPageLands,
  getTotalLands,
} from '../../../modules/estate/selectors'

const mapState = (state: RootState): any => ({
  wallet: getWallet(state),

  isLoadingGetEstatesByWalletAddress: isLoadingType(getIsLoadingGetEstatesByWalletAddress(state), GET_ESTATES_BY_WALLET_ADDRESS_REQUEST),
  estates: getEstates(state),
  pageEstates: getPageEstates(state),
  totalEstates: getTotalEstates(state),

  isLoadingGetLandsByWalletAddress: isLoadingType(getIsLoadingGetLandsByWalletAddress(state), GET_LANDS_BY_WALLET_ADDRESS_REQUEST),
  lands: getLands(state),
  pageLands: getPageLands(state),
  totalLands: getTotalLands(state),
})

const mapDispatch = (dispatch: any): any => ({
  postNewEstateRequest: (payload: any) => dispatch(postNewEstateRequest(payload)),

  dispatchGetEstatesByWalletAddress: (payload: any) => dispatch(getEstatesByWalletAddressRequest(payload)),
  dispatchGetLandsByWalletAddress: (payload: any) => dispatch(getLandsByWalletAddressRequest(payload)),
})

export default connect(mapState, mapDispatch)(EstateBuilderPage)
