// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Box_box__3DyCS {\n  border: 2px solid var(--secondary);\n  border-radius: 12px;\n  display: flex;\n  flex-direction: column;\n}\n\n.Box_header__1qiO1 {\n  margin-left: 20px;\n  margin-top: 13px;\n  margin-bottom: 13px;\n  text-transform: uppercase;\n  color: #736e7d;\n  font-weight: 600;\n  font-size: 13px;\n}\n\n.Box_children__1uLE9 {\n  margin-bottom: 8px;\n}\n", "",{"version":3,"sources":["webpack://src/components/LOM/AssetBrowse/Box/Box.module.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,mBAAmB;EACnB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;EACnB,yBAAyB;EACzB,cAAc;EACd,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".box {\n  border: 2px solid var(--secondary);\n  border-radius: 12px;\n  display: flex;\n  flex-direction: column;\n}\n\n.header {\n  margin-left: 20px;\n  margin-top: 13px;\n  margin-bottom: 13px;\n  text-transform: uppercase;\n  color: #736e7d;\n  font-weight: 600;\n  font-size: 13px;\n}\n\n.children {\n  margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "Box_box__3DyCS",
	"header": "Box_header__1qiO1",
	"children": "Box_children__1uLE9"
};
export default ___CSS_LOADER_EXPORT___;
