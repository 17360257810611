import { BaseAPI } from '@wiicamp/decentraland-dapps/dist/lib/api'
import { ArticlesResponse, ArticleDetailResponse, ArticlesQueryType } from './types'

export const ARTICLE_API_URL = process.env.REACT_APP_ARTICLE_API_URL!

class ArticleAPI extends BaseAPI {
  fetchListArticles = async (
    query: ArticlesQueryType = {}
  ): Promise<ArticlesResponse> => {
    const response = await this.request('get', '/blog-posts', query)
    return response
  }

  fetchOneArticle = async (slug: string): Promise<ArticleDetailResponse> => {
    const response = await this.request('get', `/blog-posts/${slug}`)
    return response
  }
}

export const articleApi = new ArticleAPI(ARTICLE_API_URL)
