import React, { useEffect, useState } from 'react'
import { ChevronUp } from 'react-feather'

import { Button } from '../Button'

import './styles.scss'

const ScrollToTopMobile = () => {
  const [isShow, setShow] = useState(false)
  const handleShowButton = () => {
    if (window) {
      const top = window?.pageYOffset
      if (top > 1000) {
        setShow(true)
      }
      if (top < 1000) {
        setShow(false)
      }
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', handleShowButton, true)
    return () => {
      window.removeEventListener('scroll', handleShowButton, false)
    }
  }, [])

  if (!isShow) {
    return null
  }

  return (
    <div
      style={{ transform: 'translateZ(0)' }}
      className="lg:hidden custom-scroll-to-top"
      onClick={() => {
        setShow(false)
        window?.scrollTo(0, 0)
      }}
    >
      <Button color="white" className="py-4">
        <ChevronUp />
      </Button>
    </div>
  )
}

export default React.memo(ScrollToTopMobile)
