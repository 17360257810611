// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_roadmapPage__1D72l {\n  background-color: black;\n  color: white;\n}\n\n.styles_roadmapPage__1D72l [class*='Roadmap__circle-title'] {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 71px;\n  width: 71px;\n  font-size: 1.666rem;\n  font-weight: 700;\n  line-height: 1.566rem;\n}\n\n.styles_roadmapPage__1D72l [class*='Roadmap__map'] p {\n  font-size: 1.083rem;\n  line-height: 1.566rem;\n}\n\n.styles_roadmapDescription__Y71_d {\n  margin: 1.333rem 0;\n  font-size: 1.083rem;\n  line-height: 1.566rem;\n}\n", "",{"version":3,"sources":["webpack://src/components/LOM/RoadmapPage/styles.module.scss"],"names":[],"mappings":"AACE;EACE,uBAAuB;EACvB,YAAY;AAAA;;AAFb;EAKG,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,gBAAgB;EAChB,qBAAqB;AAAA;;AAZxB;EAiBK,mBAAmB;EACnB,qBAAqB;AAAA;;AAK3B;EACE,kBAAkB;EAClB,mBAAmB;EACnB,qBAAqB;AAAA","sourcesContent":[".roadmap {\n  &Page {\n    background-color: black;\n    color: white;\n\n    [class*='Roadmap__circle-title'] {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      height: 71px;\n      width: 71px;\n      font-size: 1.666rem;\n      font-weight: 700;\n      line-height: 1.566rem;\n    }\n\n    [class*='Roadmap__map'] {\n      p {\n        font-size: 1.083rem;\n        line-height: 1.566rem;\n      }\n    }\n  }\n\n  &Description {\n    margin: 1.333rem 0;\n    font-size: 1.083rem;\n    line-height: 1.566rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"roadmapPage": "styles_roadmapPage__1D72l",
	"roadmapDescription": "styles_roadmapDescription__Y71_d"
};
export default ___CSS_LOADER_EXPORT___;
