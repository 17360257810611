import React, { useEffect } from 'react'
import { Page, Loader } from '@wiicamp/decentraland-ui'
import { NFTCategory } from '@dcl/schemas'

import { locations } from '../../../modules/routing/locations'
import { VendorFactory } from '../../../modules/vendor/VendorFactory'
import { nftAPI } from '../../../modules/vendor/decentraland/nft/api'
import { VendorName } from '../../../modules/vendor/types'
import { getContract } from '../../../modules/contract/utils'

import LayoutPage from '../Layout/LayoutPage/LayoutPage'

import { Props } from './LegacyNFTPage.types'

const LegacyNFTPage = (props: Props) => {
  const { match, history } = props
  const { params } = match
  const { contractService } = VendorFactory.build(VendorName.DECENTRALAND)


  useEffect(() => {
    const { estateId, x, y } = params

    const land = getContract({ category: NFTCategory.PARCEL })
    const estates = getContract({ category: NFTCategory.ESTATE })

    if (estateId) {
      history.replace(locations.nftLOM(estates.address, estateId))
    } else if (x && y) {
      nftAPI
        .fetchTokenId(Number(x), Number(y))
        .then(tokenId => {
          history.replace(locations.nftLOM(land.address, tokenId))
        })
        .catch(() => history.replace(locations.rootLOM()))
    }
  }, [contractService, params, history])

  return (
    <LayoutPage>
      <Page className="LegacyNFTPage" isFullscreen>
        <Loader size="massive" active />
      </Page>
    </LayoutPage>
  )
}

export default React.memo(LegacyNFTPage)
