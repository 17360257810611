import { action } from 'typesafe-actions'

import { SinglePostType, QueryFilterAPIType, PayloadFetchListArticlesSuccess } from './types'

/** TODO: Fetch list articles */

export const FETCH_LIST_ARTICLES_REQUEST = '[Request] Fetch List Articles'
export const FETCH_LIST_ARTICLES_SUCCESS = '[Success] Fetch List Articles'
export const FETCH_LIST_ARTICLES_FAILURE = '[Failure] Fetch List Articles'

export const FETCH_ARTICLE_DETAIL_REQUEST = '[Request] Fetch Article Detail'
export const FETCH_ARTICLE_DETAIL_SUCCESS = '[Success] Fetch Article Detail'
export const FETCH_ARTICLE_DETAIL_FAILURE = '[Failure] Fetch Article Detail'

export const fetchListArticlesRequest = (queryFilter: QueryFilterAPIType) =>
  action(FETCH_LIST_ARTICLES_REQUEST, queryFilter)

export const fetchListArticlesSuccess = ({ listArticles, isRefreshed } : PayloadFetchListArticlesSuccess) =>
  action(FETCH_LIST_ARTICLES_SUCCESS, { listArticles, isRefreshed })

export const fetchListArticlesFailure = (error: string) =>
  action(FETCH_LIST_ARTICLES_FAILURE, { error })

export const fetchArticleDetailRequest = (slug: string) =>
  action(FETCH_ARTICLE_DETAIL_REQUEST, { slug })

export const fetchArticleDetailSuccess = (articleDetail: SinglePostType) =>
  action(FETCH_ARTICLE_DETAIL_SUCCESS, { articleDetail })

export const fetchArticleDetailFailure = (error: string) =>
  action(FETCH_ARTICLE_DETAIL_FAILURE, { error })

export type FetchListArticlesRequestAction = ReturnType<typeof fetchListArticlesRequest>
export type FetchListArticlesSuccessAction = ReturnType<typeof fetchListArticlesSuccess>
export type FetchListArticlesFailureAction = ReturnType<typeof fetchListArticlesFailure>

export type FetchArticleDetailRequestAction = ReturnType<typeof fetchArticleDetailRequest>
export type FetchArticleDetailSuccessAction = ReturnType<typeof fetchArticleDetailSuccess>
export type FetchArticleDetailFailureAction = ReturnType<typeof fetchArticleDetailFailure>
