import React, { useEffect, useState } from 'react'
import { ReactElement } from '@wiicamp/decentraland-dapps/node_modules/@types/react'

import { MenuBarRight, MenuBarLeft } from '../../MenuBar'
import { MenuMobile } from '../../MenuMobile'

import { Props } from './LayoutMain.types'
import './LayoutMain.scss'

const LayoutMain = (props: Props) => {
  const { children } = props
  const [isRendered, setRendered] = useState(false)
  const [isShowMenu, setShowMenu] = useState(true)
  // const isDevelopmentMode = useMemo(() => process.env.REACT_APP_ENVIRONMENT === 'development', [])

  const handleToggleMenu = () => {
    setShowMenu(!isShowMenu)
  }

  useEffect(() => {
    setTimeout(() => {
      setRendered(true)
    }, 7000)
  }, [])

  const childrenWithProps = React.Children.map(
    children,
    (child: ReactElement, index) => {
      return React.cloneElement(child, {
        isShowMenu: isShowMenu,
        isRendered: isRendered,
        key: index
      })
    }
  )

  return (
    <div className="LayoutHome__banner show-new-loading-background">
      <video
        className="LayoutHome__banner__video"
        autoPlay
        muted
        loop
        src="https://gateway.pinata.cloud/ipfs/QmPuRDQdmwXMERp1BU9X6ragWNyexWq9z3Vx9Wgj4LStuL"
      />

      <MenuBarLeft isShowMenu={isShowMenu} isRendered={isRendered} />
      <MenuMobile />
      <div className={'flex-auto LayoutMain__content h-screen'}>
        {childrenWithProps}
      </div>
      <MenuBarRight
        onToggle={handleToggleMenu}
        isShowMenu={isShowMenu}
        isRendered={isRendered}
      />
    </div>
  )
}

export default LayoutMain
