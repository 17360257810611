// import { ItemNFTType } from '../../../../modules/itemNFT/types'

import { cancelSaleItemRequest, listForSaleItemRequest, transferItemRequest } from "../../../../modules/itemNFT/actions"

export enum ModalType {
  SELL = 'sell',
  TRANSFER = 'transfer',
  CANCEL = 'cancel',
}

export type ModalTypes = ModalType.SELL | ModalType.TRANSFER | ModalType.CANCEL

export type Props = {
  // nft: ItemNFTType
  nft: any
  onCloseModal: () => void
  isOwner: boolean
  layoutType: '' | ModalTypes
  listForSaleItem: typeof listForSaleItemRequest
  listForSaleItemLoading: boolean
  transferItem: typeof transferItemRequest
  transferItemLoading: boolean
  cancelSaleItem: typeof cancelSaleItemRequest
  cancelSaleItemLoading: boolean
}
