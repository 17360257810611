import { ItemNFTType, RarityNFTType } from '../../../../modules/itemNFT/types'

export enum MarketplaceType {
  USERS_CREATIONS = 'users creations',
  RESOLD_ITEMS = 'resold items',
  MY_ITEMS = 'my items',
  MARKETPLACE = 'marketplace',
}

export type Props = ItemNFTType & {
  className?: string
  type?: MarketplaceType | undefined
  raritiesItemsNFT?: RarityNFTType[]
}

export type MapStateProps = Pick<Props, 'raritiesItemsNFT'>
