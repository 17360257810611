import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { BreadcrumbItem, BreadcrumbProps } from './Breadcrumb.types'
import './Breadcrumb.scss'

import iconImg from '../../../asset/images/tools/arrow-right-top.svg'

const Breadcrumb = (props: BreadcrumbProps) => {
  const { breadcrumb, viewMode } = props
  const indexOfBoldText = breadcrumb.findIndex(item => item.isBreakHere)

  const RenderIcon = (item: any) => {
    if (item.icon && item.isShowCategoryIcon) {
      return <div dangerouslySetInnerHTML={{ __html: item.iconSVG }} className="h-6 w-6 ml-1 mr-4 inline-flex items-center breadcrumb-icon-svg" />
    }

    if (item.iconSVG === 'none') {
      return null
    }

    return <img src={iconImg} alt="icon-breadcrumb" className="h-6 w-6 ml-4 mr-6 inline" />
  }

  return (
    <div className="breadcrumb">
      {breadcrumb.map((item: BreadcrumbItem, index) => {
        if (indexOfBoldText !== -1 && index >= indexOfBoldText && item.name) {
          return (
            <span key={index}>
              {item.isBreakHere && <br />}
              <span
                className={classNames(
                  'breadcrumb-item-title font-extrabold uppercase',
                  `${viewMode || ''} ${item.active ? 'active' : ''}`
                )}
              >
                {item?.icon && RenderIcon(item)}
                {item?.link ? (
                  <Link to={item.link}>{item?.name}</Link>
                ) : (
                  item?.name
                )}
              </span>
            </span>
          )
        }

        return (
          <span key={index}>
            {item.isBreakHere && <br />}
            <span
              className={`breadcrumb-item ${viewMode || ''} ${
                item.active ? 'active' : ''
              }`}
            >
              {item?.link ? (
                <Link to={item.link}>{item?.name}</Link>
              ) : (
                item?.name
              )}
            </span>
          </span>
        )
      })}
    </div>
  )
}

export default React.memo(Breadcrumb)
