import React from 'react'
import { useHistory } from 'react-router-dom'
import { AlertTriangle } from 'react-feather'

import Breadcrumb from '../../Breadcrumb'
import Navigation from '../../Navigation'
import { LazyImage } from '../../LazyImage'
import { AspectRatio } from '../../AspectRatioBox'

import { Props } from './Concept.types'

import conceptImg1 from '../../../../asset/images/story/concept_1.jpg'
import conceptImg2 from '../../../../asset/images/story/concept_2.jpg'
import conceptImg3 from '../../../../asset/images/story/concept_3.jpg'
import conceptImg4 from '../../../../asset/images/story/concept_4.jpg'
import conceptImg5 from '../../../../asset/images/story/concept_5.jpg'
import conceptImg6 from '../../../../asset/images/story/concept_6.jpg'
import conceptImg7 from '../../../../asset/images/story/concept_7.jpg'
import conceptImg8 from '../../../../asset/images/story/concept_8.jpg'
import conceptImg9 from '../../../../asset/images/story/concept_9.jpg'
import conceptImg10 from '../../../../asset/images/story/concept_10.jpg'
import conceptImg11 from '../../../../asset/images/story/concept_11.jpg'
import conceptImg12 from '../../../../asset/images/story/concept_12.jpg'
import conceptImg13 from '../../../../asset/images/story/concept_13.jpg'
import conceptImg14 from '../../../../asset/images/story/concept_14.jpg'
import conceptImg15 from '../../../../asset/images/story/concept_15.jpg'
import conceptImg16 from '../../../../asset/images/story/concept_16.png'
import conceptImg17 from '../../../../asset/images/story/concept_17.jpg'

const Concept = (props: Props) => {
  const { breadcrumb } = props
  const history = useHistory()

  return (
    <div className="flex-grow overflow-auto Story__background Concept__background min-h-full dark bg-black text-white">
      <div className="Page__header mt-16 lg:mt-28 mb-14">
        <Breadcrumb breadcrumb={breadcrumb} viewMode="dark" />

        <div>
          <h5 className="mt-5 text-xl uppercase font-bold text-white">
            I. THE MOOD
          </h5>

          <p className="Page__header__subtitle">
            The starship just landed on Central Mars dock. You touch down on the
            fine thick red dust after a seven-months-long journey. Your loyal
            rover, carrying all of your belongings, tries to follow right behind
            you. Ahead of you, a full-fledged colony, and beyond that, a vast
            unexplored Mars is waiting. Now you know for sure that Earth is
            already a distant past.
          </p>
        </div>
      </div>

      <div className="mb-8">
        <h5 className="pl-14 my-2 uppercase font-bold text-white">
          WONDERS OF MARS
        </h5>
        <div className="flex">
          <div className="flex-1">
            <AspectRatio height={992} width={719}>
              <LazyImage src={conceptImg1} alt="concept-1" />
            </AspectRatio>
          </div>
          <div className="flex-1 flex flex-col">
            <AspectRatio height={421} width={951} className="flex-1">
              <LazyImage src={conceptImg2} alt="concept-2" style={{ objectFit: 'unset' }} />
            </AspectRatio>
            <AspectRatio height={411} width={949} className="flex-1">
              <LazyImage src={conceptImg3} alt="concept-3" style={{ objectFit: 'unset' }} />
            </AspectRatio>
            <AspectRatio height={409} width={951} className="flex-1">
              <LazyImage src={conceptImg4} alt="concept-4" style={{ objectFit: 'unset' }} />
            </AspectRatio>
          </div>
        </div>
      </div>

      <div className="mb-8">
        <h5 className="pl-14 my-2 uppercase font-bold text-white">
          massive colonies
        </h5>

        <div className="flex">
          <div className="w-1/2">
            <AspectRatio height={343} width={613}>
              <LazyImage src={conceptImg10} alt="concept-10" />
            </AspectRatio>
          </div>

          <div className="w-1/2">
            <AspectRatio height={343} width={613}>
              <LazyImage src={conceptImg12} alt="concept-12" />
            </AspectRatio>
          </div>
        </div>
      </div>

      <div className="mb-8">
        <h5 className="pl-14 my-2 uppercase font-bold text-white">
          unexpected events
        </h5>

        <div className="flex">
          <div className="w-1/2">
            <AspectRatio height={388} width={919}>
              <LazyImage src={conceptImg5} alt="concept-5" />
            </AspectRatio>
          </div>

          <div className="w-1/2">
            <AspectRatio height={388} width={919}>
              <LazyImage src={conceptImg13} alt="concept-13" />
            </AspectRatio>
          </div>
        </div>
      </div>

      <div className="mb-8">
        <h5 className=" pl-14 my-2 uppercase font-bold text-white">
          in-depth npc
        </h5>

        <div className="flex">
          <div className="w-1/2">
            <AspectRatio height={33} width={50}>
              <LazyImage src={conceptImg11} alt="concept-11" />
            </AspectRatio>
          </div>

          <div className="w-1/2">
            <AspectRatio height={33} width={50}>
              <LazyImage src={conceptImg9} alt="concept-9" />
            </AspectRatio>
          </div>
        </div>
      </div>

      <div className="mb-8">
        <h5 className="pl-14 my-2 uppercase font-bold text-white">
          your best friend - rover
        </h5>

        <div className="flex">
          <div style={{ width: '42.8%' }}>
            <AspectRatio height={291} width={529}>
              <LazyImage src={conceptImg8} alt="concept-8" />
            </AspectRatio>
          </div>

          <div style={{ width: '26.9%'}}>
            <AspectRatio height={290} width={331}>
              <LazyImage src={conceptImg7} alt="concept-7" />
            </AspectRatio>
          </div>

          <div style={{ width: '30.3%'}}>
            <AspectRatio height={290} width={373}>
              <LazyImage src={conceptImg6} alt="concept-6" />
            </AspectRatio>
          </div>
        </div>
      </div>

      <div className="mb-8">
        <ol className="pl-14">
          <p>
            Where will you go? What will you do? Whose hands will you shake?
            This is your new life
          </p>
          <li>- Life On Mars.</li>
        </ol>
      </div>

      <div className="mb-8">
        <AlertTriangle
          size="1.2rem"
          className="stroke-4 inline-block align-text-top"
        />
        <b className="my-2 text-lg font-bold uppercase"> disclaimer! </b>
        All of the images used above are to help visualizing the atmosphere and
        not actual game play.
      </div>

      {/* Part II */}
      <div className="mb-8">
        <h5 className="mt-5 text-xl uppercase font-bold text-white">
          II. BRAINSTORMING
        </h5>
      </div>

      <div className="mb-8">
          <AspectRatio height={47} width={64}>
            <LazyImage src={conceptImg16} alt="concept-16" />
          </AspectRatio>
      </div>

      <div className="mb-8">
        <h5 className="mt-5 text-xl uppercase font-bold text-white">
          III. early concept
        </h5>
      </div>

      <div className="mb-8">
        <h5 className=" pl-14 my-2  uppercase font-bold text-white">
          character design
        </h5>
        <div className="lg:pr-40">
          <AspectRatio height={523} width={960}>
            <LazyImage src={conceptImg15} alt="concept-15" />
          </AspectRatio>
        </div>
      </div>

      <div className="mb-8">
        <h5 className=" pl-14 my-2  uppercase font-bold text-white">
          skills system
        </h5>

        <div className="lg:pr-40">
          <AspectRatio height={131} width={240}>
            <LazyImage src={conceptImg14} alt="concept-14" />
          </AspectRatio>
        </div>
      </div>

      <div className="mb-8">
        <h5 className=" pl-14 my-2 uppercase font-bold text-white">
          game play - estate mode
        </h5>

        <div className="">
          <AspectRatio height={237} width={413}>
            <LazyImage src={conceptImg17} alt="concept-17" />
          </AspectRatio>
        </div>
      </div>

      <div className="mb-8">
        <h5 className=" pl-14 my-2 uppercase font-bold text-white">
          to be updated...
        </h5>
      </div>

      <Navigation
        className="mb-10"
        left={{
          text: 'Introduction',
          color: 'black',
          className: 'text-xs px-2 tracking-widest',
          onClick: () => {
            history.push('/story/introduction')
          }
        }}
      />
    </div>
  )
}

export default React.memo(Concept)
