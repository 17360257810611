import React from 'react'

import Breadcrumb from '../Breadcrumb'
import LayoutPage from '../Layout/LayoutPage/LayoutPage'

import { AssetProviderPage } from '../AssetProviderPage'
import { NFTDetail } from '../Vendor/NFTDetail'
import { ItemDetail } from '../AssetPage/ItemDetail'

import useWatchNetwork from '../../../customHooks/useWatchNetwork'

import { Asset, AssetType } from '../../../modules/asset/types'
import { Breadcrumb as BreadcrumbType } from '../Breadcrumb/Breadcrumb.types'

import { Props } from './AssetPage.types'

import cssStyles from './style.module.scss'

const AssetPage = (props: Props) => {
  const { type } = props

  const breadcrumb: BreadcrumbType[] = [
    { name: 'Home', link: '/' },
    { name: 'Marketplace', link: '/marketplace' },
    {
      name: '',
      active: true,
      isBreakHere: true,
    }
  ]

  useWatchNetwork()

  return (
    <div className={cssStyles.detailNFTPage}>
      <LayoutPage viewMode="white">
        <div className="flex-grow min-h-full overflow-auto Story__background">
          <div className="Page__header mt-14 lg:mt-28 mb-14">
            <Breadcrumb breadcrumb={breadcrumb} viewMode="dark" />
          </div>

          <div className="mb-10">
            <AssetProviderPage type={type}>
              {asset => {
                return type === AssetType.NFT ? (
                  <NFTDetail nft={asset as Asset<AssetType.NFT>} />
                ) : AssetType.ITEM ? (
                  <ItemDetail item={asset as Asset<AssetType.ITEM>} />
                ) : null
              }}
            </AssetProviderPage>
          </div>
        </div>
      </LayoutPage>
    </div>
  )
}

export default React.memo(AssetPage)
