import React from 'react'

import Home from './Home/Home'

import { LayoutMain } from '../Layout/LayoutMain'

const HomePage = () => (
  <LayoutMain>
    <Home />
  </LayoutMain>
)

export default React.memo(HomePage)
