import React from 'react'
import { Loader } from '@wiicamp/decentraland-ui'
import { LazyImage } from 'react-lazy-images'

import { Props } from './AssetImage.types'

// 1x1 transparent pixel
const PIXEL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGNiYAAAAAkAAxkR2eQAAAAASUVORK5CYII='

// the purpose of this wrapper is to make the div always be square, by using a 1x1 transparent pixel
const AssetImageWrapper = (props: Props) => {
  const { className, imageUrl } = props

  let classes = 'AssetImage'
  if (className) {
    classes += ' ' + className
  }

  return (
    <div className={classes}>
      <img src={PIXEL} alt="pixel" className="pixel" />
      <div className="image-wrapper">
      <LazyImage
          src={imageUrl}
          alt={imageUrl}
          placeholder={({ ref }) => (
            <div ref={ref}>
              <Loader size="small" active />
            </div>
          )}
          actual={({ imageProps }) => (
            <img className="image" alt={imageUrl} {...imageProps} />
          )}
        />
      </div>
    </div>
  )
}

export default React.memo(AssetImageWrapper)
