import { web3Ref as web3 } from '../constants';

import LOMAbi from '../contracts/LOMAbi.json';
import TicketAbi from '../contracts/TicketAbi.json';
import LandRegistryAbi from '../contracts/LandRegistryAbi.json'
import EstateRegistryAbi from '../contracts/EstateRegistryAbi.json'
import LomItemAbi from '../contracts/LomItem.json'
import LomMarketplaceAbi from '../contracts/LomMarketplace.json'

const getContracts = () => {
  if (!web3.current) return {};

  const LOMContract = new web3.current.eth.Contract(LOMAbi, process.env.REACT_APP_MANA_ADDRESS);

  const ticketContract = new web3.current.eth.Contract(TicketAbi, process.env.REACT_APP_TICKET_ADDRESS);

  const landRegistryContract = new web3.current.eth.Contract(LandRegistryAbi, process.env.REACT_APP_LAND_REGISTRY_ADDRESS);

  const estateRegistryContract = new web3.current.eth.Contract(EstateRegistryAbi, process.env.REACT_APP_ESTATE_REGISTRY_ADDRESS);

  const lomItemContract = new web3.current.eth.Contract(LomItemAbi, process.env.REACT_APP_LOM_ITEM_ADDRESS);

  const lomMarketplaceContract = new web3.current.eth.Contract(LomMarketplaceAbi, process.env.REACT_APP_LOM_MARKETPLACE_ADDRESS);

  return {
    LOMContract,
    ticketContract,
    landRegistryContract,
    estateRegistryContract,
    lomItemContract,
    lomMarketplaceContract,
  };
};

export default getContracts;
