import { memo, useCallback, useMemo, useState } from 'react'
import {
  LoginModal,
  LoginModalOptionType,
  Modal,
  Button,
  Field
} from '@wiicamp/decentraland-ui'
import { ProviderType } from 'decentraland-connect'
import { toast } from 'react-toastify'
import Web3 from 'web3'

import { web3Ref } from '../../../constants'

import { CheckUserExistedSuccessArgumentsType } from '../../../modules/authentication/types'
import { Props } from './ConnectWalletModal.types'

declare let window: any

const LOGIN_TYPE = LoginModalOptionType.METAMASK!

const ConnectWalletModal = (props: Props) => {
  const {
    // props
    isOpen,
    handleOpen,

    // connect
    hasError,
    isLoading,
    // userConnectWithWallet,
    // message,
    onConnect,
    onSignOut,
    checkUserExisted,
    dispatchGetUserSignMessageRequest,
    setUserSignMetamaskRequest,
  } = props

  const [isShowSignForm, setShowSignFormModal] = useState(false)
  // const [isShowAlertSignMetamaskForm, setShowAlertSignMetamaskForm] = useState(false)

  const [email, setEmail] = useState('')
  const [username, setUsername] = useState('')
  const [walletTemp, setWalletTemp] = useState('')

  const PROVIDER_TYPE = useMemo(() => {
    let providerType: ProviderType

    switch (LOGIN_TYPE as LoginModalOptionType) {
      case LoginModalOptionType.METAMASK:
      case LoginModalOptionType.DAPPER:
      case LoginModalOptionType.SAMSUNG:
        providerType = ProviderType.INJECTED
        break
      case LoginModalOptionType.FORTMATIC:
        providerType = ProviderType.FORTMATIC
        break
      case LoginModalOptionType.WALLET_CONNECT:
        providerType = ProviderType.WALLET_CONNECT
        break
      default:
        throw new Error(`Invalid login type ${LOGIN_TYPE}`)
    }

    return providerType
  }, [])

  const onChangeStateByInput = useCallback((setState, value) => setState(value), [])

  const onResetFormInputAccount = useCallback(() => {
    setUsername('')
    setEmail('')
  }, [])

  const onConnectWallet = useCallback(() => {
    if (typeof window.ethereum === 'undefined') {
      toast.warn('Please install MetaMask!');
      return;
    }

    if (!window.ethereum || !window.ethereum?.isConnected()) {
      toast.warn('Please connect your wallet!');
      return;
    }

    window.ethereum.request({ method: 'eth_requestAccounts' })
      .then((accounts: string[]) => {
        web3Ref.current = new Web3(window.ethereum);

        checkUserExisted({
          wallet: accounts[0], callback: {
            success: (payload: CheckUserExistedSuccessArgumentsType) => {
              if (payload) {
                const {
                  message: messagePayload,
                  user: { address: addressPayload },
                } = payload

                setUserSignMetamaskRequest({
                  message: messagePayload,
                  address: addressPayload,
                  callback: {
                    success: () => onConnect(PROVIDER_TYPE),
                  }
                })
              } else {
                setShowSignFormModal(true)
                setWalletTemp(accounts[0])
              }
            }
          }
        })
      })
  }, [setUserSignMetamaskRequest, setShowSignFormModal, checkUserExisted, onConnect, PROVIDER_TYPE])



  const callback = useCallback((
    setState: React.Dispatch<React.SetStateAction<boolean>>,
    value: boolean,
    isSignOut?: boolean,
    customFunction?: Function
  ) => () => {
    setState(value)
    customFunction?.()

    if (isSignOut) onSignOut()
  },
    [onSignOut])


  // const onProceedSetUserSignMetamaskRequest = useCallback(() => {
  //   setUserSignMetamaskRequest({
  //     message,
  //     address: userConnectWithWallet?.address,
  //     callback: {
  //       failure: callback(setShowAlertSignMetamaskForm, false, true)
  //     }
  //   })
  // }, [userConnectWithWallet, message, setUserSignMetamaskRequest, callback])

  const onProceedFormGetUserSignMessage = useCallback(() => {
    dispatchGetUserSignMessageRequest({
      email,
      username,
      address: walletTemp,
      callback: {
        success: callback(setShowSignFormModal, false, false, () => onConnect(PROVIDER_TYPE)),
        failure: callback(setShowSignFormModal, false, true, () => onResetFormInputAccount),
      }
    })
  }, [
    email,
    username,
    walletTemp,
    PROVIDER_TYPE,
    callback,
    onConnect,
    dispatchGetUserSignMessageRequest,
    onResetFormInputAccount
  ])

  return (
    <>
      <LoginModal
        open={isOpen}
        // message="This is a custom persistent message"
        hasError={hasError}
        loading={isLoading}
        onClose={handleOpen}
      >
        <LoginModal.Option type={LoginModalOptionType.METAMASK} onClick={onConnectWallet} />
        {/* <LoginModal.Option type={LoginModalOptionType.DAPPER} />
        <LoginModal.Option type={LoginModalOptionType.SAMSUNG} />
        <LoginModal.Option type={LoginModalOptionType.FORTMATIC} />
        <LoginModal.Option type={LoginModalOptionType.WALLET_CONNECT} /> */}
      </LoginModal>

      {/* Modal request user update username and email */}
      <Modal
        open={isShowSignForm && !isLoading}
        onClose={callback(setShowSignFormModal, false, true)}
      >
        <Modal.Header>You are almost there!</Modal.Header>

        <Modal.Content>
          <div className="mb-8">Complete these fields with your email and nickname</div>

          <div>
            <Field
              label="Email"
              placeholder="/email"
              onChange={(_event, props) => onChangeStateByInput(setEmail, props.value)}
              value={email}
            />
          </div>

          <div>
            <Field
              label="Username"
              placeholder="/username"
              onChange={(_event, props) => onChangeStateByInput(setUsername, props.value)}
              value={username}
            />
          </div>
        </Modal.Content>

        <Modal.Actions>
          <Button
            primary
            className="LOM-submit-btn"
            onClick={onProceedFormGetUserSignMessage}
            disabled={!email || !username}
          >Proceed
          </Button>

          <Button
            className="ui button LOM-cancel-btn"
            onClick={callback(setShowSignFormModal, false, false, () => onResetFormInputAccount)}
          >Cancel
          </Button>
        </Modal.Actions>
      </Modal>

      {/* Modal request user sign metamask */}
      {/* <Modal
        open={isShowAlertSignMetamaskForm}
        size="small"
        onClose={callback(setShowAlertSignMetamaskForm, false)}
      >
        <Modal.Header>You are almost there!</Modal.Header>

        <Modal.Content>
          <div className="mb-8">Signature your metamask to complete!</div>
          <div className="mb-8">If you can't see metamask popup. Click try again!</div>
        </Modal.Content>

        <Modal.Actions>
          <Button
            className="LOM-submit-btn"
            primary
            onClick={onProceedSetUserSignMetamaskRequest}
          >Try again
          </Button>

          <Button
            onClick={callback(setShowAlertSignMetamaskForm, false)}
            className="ui button LOM-cancel-btn"
          >Cancel
          </Button>
        </Modal.Actions>
      </Modal> */}
    </>
  )
}

export default memo(ConnectWalletModal)
