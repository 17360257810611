export const LOCALSTORAGE_KEYS = {
  SIGNED_TOKEN: 'SIGN_TOKEN',
  SIGNED_METAMASK_ADDRESS: 'SIGNED_METAMASK_ADDRESS',
  UPLOADED_NFT_ITEMS: 'UPLOADED_NFT_ITEMS'
}

export const LOCALSTORAGE_LIFETIME = {
  SIGNED_TOKEN: 7,
  SIGNED_METAMASK_ADDRESS: 7,
}
