import { memo, useCallback, useEffect, useState } from 'react'
import { t } from '@wiicamp/decentraland-dapps/dist/modules/translation/utils'
import { Modal } from '@wiicamp/decentraland-ui'

import { ReactComponent as IconMagnifyGlass } from '../../../../../asset/icons/ic-magnifying-glass.svg'
import { ReactComponent as IconClose } from '../../../../../images/ic-close.svg'
import { ReactComponent as IconDefault } from '../../../../../asset/icons/ic-menu-spacesuits.svg'

import { Props } from './NFTFilters.types'

import cssStyles from './NFTFilters.module.scss'
import './NFTFilters.scss'

const ItemNFTFilters = (props: Props) => {
  const {
    itemsNFTLoading,
    totalItems,
    categoriesItemNFT,
    fetchedCategoriesIcon,

    searchInput,
    onChangeSearchInput,

    parentCategoryPicking,
    onUpdateSingleParentCategory,

    subCategoriesPicking,
    setSubCategoriesPicking,
    onUpdateMultipleCategories,

    attributesPicking,
    setAttributesPicking,
    onUpdateMultipleAttributes,
  } = props
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [position, setPosition] = useState(-1)

  const onToggleShowFiltersModal = useCallback((newValue: boolean) => () => {
    setShowFiltersModal(newValue)
  }, [])

  useEffect(() => {
    const newPosition = categoriesItemNFT?.findIndex(
      (item: any) => item.slug === parentCategoryPicking
    )
    setPosition(newPosition)
  }, [parentCategoryPicking, categoriesItemNFT])

  const RenderFilter = memo((renderFilterProps: any) => {
    const { isOnModal } = renderFilterProps
    return (
      <>
        {!!categoriesItemNFT.length && (
          <div className={`${cssStyles.NFTFiltersSection} ${isOnModal && cssStyles.isOnModal}`}>
            <div className={cssStyles.NFTFiltersSectionTitle}>categories</div>

            <div className={cssStyles.NFTFiltersSectionBtnGroup}>
              {categoriesItemNFT.map((parentCategory: any) => (
                <button
                  key={parentCategory.id}
                  className={
                    parentCategoryPicking === parentCategory.slug
                      ? cssStyles.actived
                      : ''
                  }
                  onClick={onUpdateSingleParentCategory(parentCategory.slug)}
                >
                  {!!fetchedCategoriesIcon?.[parentCategory.slug]?.length
                    ? <div className={cssStyles.NFTFiltersBtnCategoryIcon} dangerouslySetInnerHTML={{ __html: fetchedCategoriesIcon?.[parentCategory.slug] }} />
                    : <div className={cssStyles.NFTFiltersBtnCategoryIcon}><IconDefault /></div>}
                  <span className={cssStyles.NFTFiltersBtnContent}>
                    {parentCategory.name}
                  </span>
                </button>
              ))}

              {!!parentCategoryPicking && (
                <div
                  className={cssStyles.NFTFiltersBtnClear}
                  onClick={onUpdateSingleParentCategory('')}
                >
                  <div className={cssStyles.NFTFiltersBtnClearIcon}>
                    <IconClose />
                  </div>
                  <span>clear filters</span>
                </div>
              )}
            </div>
          </div>
        )}

        {!!categoriesItemNFT?.[position]?.subCategories?.length && (
          <>
            <div className={`${cssStyles.NFTFiltersSection} ${isOnModal && cssStyles.isOnModal}`}>
              <div className={cssStyles.NFTFiltersSectionTitle}>Pieces</div>

              <div className={cssStyles.NFTFiltersSectionBtnGroup}>
                {categoriesItemNFT[position].subCategories.map(
                  (subCategory: any) => (
                    <button
                      key={`${subCategory.id}-${subCategory.slug}`}
                      className={
                        subCategoriesPicking.includes(subCategory.slug)
                          ? cssStyles.activedBgOrange
                          : ''
                      }
                      onClick={onUpdateMultipleCategories(
                        subCategory.slug,
                        subCategoriesPicking,
                        setSubCategoriesPicking
                      )}
                    >
                      <span className={cssStyles.NFTFiltersBtnContent}>
                        {subCategory.name}
                      </span>
                    </button>
                  )
                )}

                {!!subCategoriesPicking.length && (
                  <div
                    className={cssStyles.NFTFiltersBtnClear}
                    onClick={onUpdateMultipleCategories(
                      '',
                      [],
                      setSubCategoriesPicking,
                      true
                    )}
                  >
                    <div className={cssStyles.NFTFiltersBtnClearIcon}>
                      <IconClose />
                    </div>
                    <span>clear filters</span>
                  </div>
                )}
              </div>
            </div>

            {categoriesItemNFT[position].attributes.map((mainAttribute: any) => (
              <div
                className={`${cssStyles.NFTFiltersSection} ${isOnModal && cssStyles.isOnModal}`}
                key={`mainAttribute-${mainAttribute.slug}`}
              >
                <div className={cssStyles.NFTFiltersSectionTitle}>
                  {mainAttribute.name}
                </div>

                <div className={cssStyles.NFTFiltersSectionBtnGroup}>
                  {mainAttribute.values.map((attribute: any) => (
                    <button
                      key={`attribute-${attribute.value}`}
                      className={
                        attributesPicking[mainAttribute.slug]?.includes(
                          attribute.value
                        )
                          ? cssStyles.activedBgOrange
                          : ''
                      }
                      onClick={onUpdateMultipleAttributes(
                        mainAttribute.slug,
                        attribute.value,
                        attributesPicking,
                        setAttributesPicking
                      )}
                    >
                      <span className={cssStyles.NFTFiltersBtnContent}>
                        {attribute.label}
                      </span>
                    </button>
                  ))}

                  {!!attributesPicking[mainAttribute.slug] && (
                    <div
                      className={cssStyles.NFTFiltersBtnClear}
                      onClick={onUpdateMultipleAttributes(
                        mainAttribute.slug,
                        '',
                        attributesPicking,
                        setAttributesPicking,
                        true
                      )}
                    >
                      <div className={cssStyles.NFTFiltersBtnClearIcon}>
                        <IconClose />
                      </div>
                      <span>clear filters</span>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </>
        )}
      </>
    )
  })

  return (
    <div className={cssStyles.NFTFilters}>
      <div className={`${cssStyles.NFTFiltersSection} pt-0 border-b flex`}>
        <div className={cssStyles.searchField}>
          <input type="text" placeholder={itemsNFTLoading ? "Loading" : `Search ${totalItems} results`} value={searchInput} onChange={onChangeSearchInput} />

          <button className={cssStyles.searchBtn}>
            <IconMagnifyGlass />
          </button>
        </div>

        <div
          className={cssStyles.NFTFiltersOpenModalFilter}
          onClick={onToggleShowFiltersModal(true)}
        >
          <div className="label">{t('nft_filters.filter')}</div>
          <div className={showFiltersModal ? cssStyles.NFTFiltersOpenModalFilterIconActived : cssStyles.NFTFiltersOpenModalFilterIcon} />
        </div>
      </div>

      <div className="hidden lg:block">
        <RenderFilter />
      </div>

      <Modal
        className="NFTItemFiltersModalWrapper_rf6iw4c46g"
        open={showFiltersModal}
        onClose={onToggleShowFiltersModal(false)}
      >
        <div className="relative">
          <div className="absolute right-0 top-0 p-2">
            <IconClose
              fill="#fff"
              className="h-8 w-8 cursor-pointer"
              onClick={onToggleShowFiltersModal(false)}
            />
          </div>
        </div>

        <Modal.Header>{t('nft_filters.filter')}</Modal.Header>
        <Modal.Content>
          <RenderFilter isOnModal />
        </Modal.Content>
      </Modal>
    </div>
  )
}

export default memo(ItemNFTFilters)
