import { Dispatch } from 'redux'
import { CallHistoryMethodAction } from 'connected-react-router'

import {
  getHomepage,
  getHomepageLoading
} from '../../../modules/ui/asset/homepage/selectors'
import {
  fetchAssetsFromRoute,
  FetchAssetsFromRouteAction
} from '../../../modules/routing/actions'
import {
  fetchNFTRequest,
  FetchNFTRequestAction
} from '../../../modules/nft/actions'
import {
  fetchItemRequest,
  FetchItemRequestAction
} from '../../../modules/item/actions'
import {
  fetchToggleModalDetailEstateRequest,
  FetchToggleModalDetailEstateRequestAction
} from '../../../modules/ui/modal/actions'
import {
  fetchItemsNFTRequest,
  FetchItemsNFTRequestAction
} from '../../../modules/itemNFT/actions'

import { Asset } from '../../../modules/asset/types'

//------------------------- Marketplace Page Props
export type Props = {
  homepage: ReturnType<typeof getHomepage>
  homepageLoading: ReturnType<typeof getHomepageLoading>
  onNavigate: (path: string) => void
  onFetchAssetsFromRoute: typeof fetchAssetsFromRoute
  onFetchNFT: typeof fetchNFTRequest
  onFetchItem: typeof fetchItemRequest
  toggleModalDetailEstate: typeof fetchToggleModalDetailEstateRequest
  // TODO: Fake data items NFT
  onFetchCollectionItems: typeof fetchItemsNFTRequest
  categoriesItemNFT: any[]
}

export type MapStateProps = Pick<Props, 'homepage' | 'homepageLoading' | 'categoriesItemNFT'>
export type MapDispatchProps = Pick<
  Props,
  'onNavigate' | 'onFetchAssetsFromRoute' | 'onFetchNFT' | 'onFetchItem' | 'toggleModalDetailEstate' | 'onFetchCollectionItems'
>
export type MapDispatch = Dispatch<
  | CallHistoryMethodAction
  | FetchAssetsFromRouteAction
  | FetchNFTRequestAction
  | FetchItemRequestAction
  | FetchToggleModalDetailEstateRequestAction
  | FetchItemsNFTRequestAction
>

//------------------------- Section Props
export type SectionProps = {
  title: string
  assets: Asset[]
  isSubHeader?: boolean
  isLoading: boolean
  thumbVisible?: boolean
  thumbClassName?: string
  onViewAll?: () => void
  onFetchNFT: typeof fetchNFTRequest
  onFetchItem: typeof fetchItemRequest
  toggleModalDetailEstate: typeof fetchToggleModalDetailEstateRequest
} & typeof sectionDefaultProps;

export const sectionDefaultProps = {
  thumbVisible: true,
};
