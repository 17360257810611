import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import { Props } from './BackgroundLine.types'
import './BackgroundLine.scss'

const BackgroundLine = (props: Props) => {
  const { line, timeLife, color, isPadding, repeat } = props
  const [isHide, setHide] = useState(false)

  const renderLine = () => {
    const result = []
    for (let i = 0; i < line; i++) {
      result.push(
        <div
          key={`line-${i}`}
          style={{
            animationDelay: `${Math.random() * 2}s`,
            animationIterationCount: repeat ? 'infinite' : 'forwards',
            left: `${Math.random() * 100}%`,
            borderColor: color ? 'white' : 'var(--color-green)'
          }}
        />
      )
    }
    return result
  }

  useEffect(() => {
    if (timeLife) {
      setTimeout(() => {
        setHide(true)
      }, timeLife * 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className={classNames(
        'BackgroundLine',
        isPadding ? 'is-padding' : '',
        isHide ? 'hide' : ''
      )}
    >
      {renderLine()}
    </div>
  )
}

BackgroundLine.defaultProps = {
  line: 2,
  timeLife: 3,
  color: '',
  isPadding: true,
  repeat: false
}

export default React.memo(BackgroundLine)
