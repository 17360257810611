import { memo } from 'react'

import TicketItem from './TicketItem'

import { ticketsData } from '../seedingData'

import { TicketListProps } from '../TicketPage.types'

import moduleStyles from './Ticket.module.scss'

const TicketsList = (props: TicketListProps) => {
  const {
    activedToggleTab,
    isReadyToStart,
    onReadyToStart,
    generateTicketRequest,
    ticketLoading,
    isMintAirdropBefore,
    isOnClaimList,
    mintTicketAirdropRequest,
  } = props

  const {
    startColor,
    middleColor,
    endColor,
    backgroundTicket,
    launchSite,
    destination
  } = ticketsData[activedToggleTab]

  return (
    <div className={moduleStyles.ticketList}>
      <div className={moduleStyles.splitter} />

      <div className={moduleStyles.ticketListWrapper}>
        <TicketItem
          startColor={startColor}
          middleColor={middleColor}
          endColor={endColor}
          backgroundTicket={backgroundTicket}
          launchSite={launchSite}
          destination={destination}
          isReadyToStart={isReadyToStart}
          onReadyToStart={onReadyToStart}
          generateTicketRequest={generateTicketRequest}
          ticketLoading={ticketLoading}
          isAbleToMint={!isMintAirdropBefore}
          isOnClaimList={isOnClaimList}
          mintTicketAirdropRequest={mintTicketAirdropRequest}
        />
      </div>
    </div>
  )
}

export default memo(TicketsList)
