import React, { useState, useEffect, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import classNames from 'classnames'

import MenuButton from './MenuButton'
import { MenuDropdown } from '../MenuDropdown'
import { ConnectWalletModal } from '../ConnectWalletModal'

import { Props } from './MenuMobile.types'

import './MenuMobile.scss'

import { MENU } from '../../../lib/constant'

import logoIcon from '../../../asset/images/logo-icon.svg'
import telegramIcon from '../../../asset/icons/telegram.svg'
import discordIcon from '../../../asset/icons/discord.svg'
import twitterIcon from '../../../asset/icons/twitter.svg'
import mediumIcon from '../../../asset/icons/medium.svg'
import redditIcon from '../../../asset/icons/reddit.svg'
import githubIcon from '../../../asset/icons/github.svg'

const MenuMobile = (props: Props) => {
  const {
    token,
    wallet,
    // hasError,
    // isLoading,
    onSignOut,
    // onConnect,
  } = props
  const [isOpen, setOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(false)
  const [isShowLoginModal, setShowLoginModal] = useState(false)
  const history = useHistory();

  useEffect(() => {
    if (token && wallet && wallet.address) {
      setIsLogin(true)
    } else {
      setIsLogin(false)
    }

    setShowLoginModal(false)
  }, [wallet, token])

  const onToggleLoginModal = useCallback(() => {
    if (isLogin) {
      onSignOut()
      setIsLogin(false)
    } else {
      setShowLoginModal(prevState => !prevState)
    }
  }, [isLogin, onSignOut])

  const handleToggle = () => {
    setOpen(!isOpen);
  };

  return (
    <>
      {/* ------------ */}
      {/* NAVBAR TOP */}
      <div className="MenuMobile lg:hidden w-full flex flex-col">
        <div className="MenuMobile__menu-mobile-bar flex-none flex lg:hidden w-full bg-white text-black">
          <div
            className="flex-none w-30 p-6"
            onClick={() => {
              history.push("/");
            }}
          >
            <img src={logoIcon} alt="logo" height="36px" width="31px" />
          </div>
          <div className="flex-1 flex justify-end items-center px-4">
            {isLogin ? <MenuDropdown className="mr-4" /> :
              <div onClick={onToggleLoginModal} className="LayoutHome__action-btn white mr-3">
                <button>Connect Wallet</button>
              </div>
            }
            <MenuButton isOpen={isOpen} onToggle={handleToggle} />
          </div>
        </div>
      </div>

      {/* Menu mobile */}
      <div
        className={classNames(
          "MenuMobile__menu-mobile flex-auto flex justify-end",
          " lg:hidden w-full bg-white text-black",
          isOpen && "open"
        )}
      >
        <div className="h-full w-1/2 flex-auto MenuMobile__menu-mobile__menu-left bg-white">
          <p>| support@lifeonmars.org |</p>
        </div>
        <div
          className={classNames(
            "MenuMobile__menu-mobile__menu-right ",
            "p-2 sm:p-8 flex-none flex flex-col justify-between ",
            " h-full w-1/2 bg-black text-white"
          )}
        >
          <ol>
            {MENU.map((item: any, index: number) => (
              <li key={`menu-mobile-${index}`}>
                <Link onClick={handleToggle} to={item.link}>
                  {item.name.toUpperCase()}
                </Link>
                <ol className={item?.children ? "" : "hidden"}>
                  {item?.children?.map((children: any, itemChild: any) => (
                    <li key={`children-menu-mobile-${itemChild}`}>
                      <Link onClick={handleToggle} to={children.link}>
                        {children.name}
                      </Link>
                    </li>
                  ))}
                </ol>
              </li>
            ))}
          </ol>

          <div
            className={`MenuMobile__social h-1/5 justify-end flex-col non-rotate `}
          >
            <div className="MenuMobile__action-btn">
              <a
                href="https://t.me/LifeOnMars_Global"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={telegramIcon}
                  alt="telegram-icon"
                  height="20px"
                  width="20px"
                />
              </a>
            </div>

            <div className="MenuMobile__action-btn">
              <a
                href="https://discord.com/invite/CDRvNqBSsk"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={discordIcon}
                  alt="discord-icon"
                  height="20px"
                  width="20px"
                />
              </a>
            </div>

            <div className="MenuMobile__action-btn">
              <a
                href="https://twitter.com/LifeOnMars_Org"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={twitterIcon}
                  alt="twitter-icon"
                  height="20px"
                  width="20px"
                />
              </a>
            </div>

            <div className="MenuMobile__action-btn">
              <a
                href="https://lifeonmarsorg.medium.com"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={mediumIcon}
                  alt="medium-icon"
                  height="20px"
                  width="20px"
                />
              </a>
            </div>

            <div className="MenuMobile__action-btn">
              <a
                href="https://www.reddit.com/r/LifeOnMars_Org"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={redditIcon}
                  alt="reddit-icon"
                  height="20px"
                  width="20px"
                />
              </a>
            </div>

            <div className="MenuMobile__action-btn">
              <a
                href="https://github.com/LifeOnMarsOrg"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={githubIcon}
                  alt="github-icon"
                  height="20px"
                  width="20px"
                />
              </a>
            </div>
          </div>
          {/* end MenuMobile__menu-mobile__menu-right  */}
        </div>
      </div>
      {/*end MenuMobile__menu-mobile */}

      <ConnectWalletModal
        isOpen={isShowLoginModal}
        handleOpen={onToggleLoginModal}
      />
    </>
  );
};

export default React.memo(MenuMobile);
