import React from 'react'
import { Link } from 'react-router-dom'
import { NFTCategory } from '@dcl/schemas'
import { Container } from '@wiicamp/decentraland-ui'
import { T } from '@wiicamp/decentraland-dapps/dist/modules/translation/utils'

import { getAssetName } from '../../../../modules/asset/utils'
import { locations } from '../../../../modules/routing/locations'

import { Row } from '../../../Layout/Row'
import { Column } from '../../../Layout/Column'
import { Coordinate } from '../../Coordinate'

import { PageHeader } from '../../../PageHeader'
import { AssetImage } from '../../AssetImage'

import { Owner } from '../Owner'
import { Title } from '../Title'
import { Description } from '../Description'
import { Actions } from '../Actions'
import { Network } from '../Network'
import { OrderDetails } from '../OrderDetails'
import { ProximityHighlights } from '../ProximityHighlights'
import { TransactionHistory } from '../TransactionHistory'
import { Bids } from '../Bids'
// import { JumpIn } from '../JumpIn'

import { Props } from './ParcelDetail.types'
import styles from './ParcelDetail.module.scss'

const ParcelDetail = (props: Props) => {
  const { nft } = props
  const parcel = nft.data.parcel!
  const { x, y } = parcel
  const isPartOfEstate = nft.category === NFTCategory.PARCEL && parcel.estate

  return (
    <div className={styles.detail}>
      <section className={styles.sectionIntroduction}>
        <div>
          <Title>
            {getAssetName(nft)}

            <Coordinate
              className={styles.mgL}
              x={Number(x)}
              y={Number(y)}
            />
          </Title>
        </div>

        {
          isPartOfEstate ? (
            <div className="estate-information">
              <T
                id="asset_page.part_of_estate"
                values={{
                  estate_name: (
                    <Link
                      title={parcel.estate!.name}
                      to={locations.nftLOM(nft.owner, parcel.estate!.tokenId)}
                    >
                      {parcel.estate!.name}
                    </Link>
                  )
                }}
              />
            </div>
          ) : (
            <Owner asset={nft} />
          )
        }
      </section>

      <PageHeader>
        <AssetImage
          asset={nft}
          isDraggable={true}
          withNavigation={true}
          hasPopup={true}
          zoom={1.5}
        />
      </PageHeader>

      <Container className={styles.detailMarketInformation}>
        <Description text={parcel.description} />

        <Row className="order-detail-with-button">
          <Column align="left" grow={true}>
            <Network asset={nft} />
            <OrderDetails nft={nft} />
          </Column>

          <Column align="right">
            <Actions nft={nft} />
          </Column>
        </Row>

        <ProximityHighlights nft={nft} />

        <Bids nft={nft} />

        <TransactionHistory nft={nft} />
      </Container>
    </div>
  )
}

export default React.memo(ParcelDetail)
