import { put, takeEvery } from '@redux-saga/core/effects'
import { select } from 'redux-saga/effects'
import axios from 'axios'
import { toast } from 'react-toastify'

import { getWallet } from '../wallet/selectors'
import {
  // Generate
  GENERATE_TICKET_REQUEST,
  // GENERATE_TICKET_SUCCESS,

  GenerateTicketRequestAction,

  generateTicketSuccess,
  generateTicketFailure,


  // Transfer
  TRANSFER_TICKET_REQUEST,
  // TRANSFER_TICKET_SUCCESS,

  TransferTicketRequestAction,

  transferTicketSuccess,
  transferTicketFailure,

  // Update ticket
  UPDATE_TICKET_REQUEST,
  UpdateTicketRequestAction,

  updateTicketSuccess,
  updateTicketFailure,

  // Fetch
  FETCH_TICKETS_REQUEST,
  // FetchTicketsRequestAction,
  fetchTicketsSuccess,
  fetchTicketsFailure,

  // Check wallet is on claim list
  CHECK_WALLET_IS_ON_CLAIM_LIST,
  checkWalletIsOnClaimListSuccess,
  checkWalletIsOnClaimListFailure,

  // Check wallet is mint airdrop
  CHECK_WALLET_IS_MINT_AIRDROP,
  checkWalletIsMintAirdropSuccess,
  checkWalletIsMintAirdropFailure,

  MINT_TICKET_AIRDROP,
  MintTicketAirdropRequestAction,
  mintTicketAirdropSuccess,
  mintTicketAirdropFailure,

  // Fetch single ticket
  FETCH_SINGLE_TICKET_REQUEST,
  FetchSingleTicketRequestAction,
  fetchSingleTicketSuccess,
  fetchSingleTicketFailure,

} from './actions'

import getContracts from '../../lib/contracts'

export function* ticketSaga() {
  yield takeEvery(GENERATE_TICKET_REQUEST, handleGenerateTicket)
  // yield takeEvery(GENERATE_TICKET_SUCCESS, handleGenerateTicketSuccess)

  yield takeEvery(TRANSFER_TICKET_REQUEST, handleTransferTicket)
  yield takeEvery(UPDATE_TICKET_REQUEST, handleUpdateTicket)
  // yield takeEvery(TRANSFER_TICKET_SUCCESS, handleTransferTicketSuccess)

  yield takeEvery(FETCH_TICKETS_REQUEST, handleFetchTicketsRequest)

  yield takeEvery(CHECK_WALLET_IS_ON_CLAIM_LIST, handleCheckWalletIsOnClaimList)
  yield takeEvery(CHECK_WALLET_IS_MINT_AIRDROP, handleCheckWalletIsMintAirdop)
  yield takeEvery(MINT_TICKET_AIRDROP, handleMintTicketAirdrop)
  yield takeEvery(FETCH_SINGLE_TICKET_REQUEST, handleFetchSingleTicket)
}

function* handleGenerateTicket(action: GenerateTicketRequestAction) {
  try {
    const { name, callback } = action.payload

    const wallet: ReturnType<typeof getWallet> = yield select(getWallet)

    if (!wallet) {
      throw new Error('A defined wallet is required to generate a ticket')
    }

    const { LOMContract, ticketContract } = getContracts();

    const isAllowance: string = yield LOMContract.methods
      .allowance(wallet.address, process.env.REACT_APP_TICKET_ADDRESS).call();

    if (isAllowance === '0') {
      yield LOMContract.methods
        .approve(process.env.REACT_APP_TICKET_ADDRESS, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
        .send({
          from: wallet.address,
        });
    }

    yield ticketContract.methods
      .buyTicket(name)
      .send({
        from: wallet.address,
      });

    yield put(generateTicketSuccess()) // TODO: Add data to change state
    yield callback?.()
  } catch (error: any) {
    yield put(generateTicketFailure(error.message))
  }
}

// Transfer
function* handleTransferTicket(action: TransferTicketRequestAction) {
  try {
    const { to, tokenId, name, callback } = action.payload

    const wallet: ReturnType<typeof getWallet> = yield select(getWallet)

    if (!wallet) {
      throw new Error('A defined wallet is required to transfer a ticket')
    }

    const { ticketContract } = getContracts();

    // const isAllowance: string = yield LOMContract.methods
    //   .allowance(wallet.address, process.env.REACT_APP_TICKET_ADDRESS).call();

    // if (isAllowance === '0') {
    //   yield LOMContract.methods
    //     .approve(process.env.REACT_APP_TICKET_ADDRESS, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
    //     .send({
    //       from: wallet.address,
    //     });
    // }

    yield ticketContract.methods
      .updateNameAndTransfer(tokenId, to, name)
      .send({
        from: wallet.address,
      });

    yield put(transferTicketSuccess(tokenId)) // TODO: Add data to change state

    toast.success(`Transferred ticket #${tokenId} success`)

    callback?.()
  } catch (error: any) {
    toast.warning(error.message)
    yield put(transferTicketFailure(error.message))
  }
}

// Update
function* handleUpdateTicket(action: UpdateTicketRequestAction) {
  try {
    const { name, tokenId, callback } = action.payload

    const wallet: ReturnType<typeof getWallet> = yield select(getWallet)

    if (!wallet) {
      throw new Error('A defined wallet is required to update a ticket')
    }

    const { ticketContract } = getContracts();

    // const isAllowance: string = yield LOMContract.methods
    //   .allowance(wallet.address, process.env.REACT_APP_TICKET_ADDRESS).call()

    // if (isAllowance === '0') {
      // yield LOMContract.methods
      //   .approve(process.env.REACT_APP_TICKET_ADDRESS, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
      //   .send({
      //     from: wallet.address,
      //   })
    // }

    yield ticketContract.methods
      .changeName(tokenId, name)
      .send({
        from: wallet.address,
      })

    yield put(updateTicketSuccess(tokenId))

    callback?.()
  } catch (error: any) {
    toast.warning(error.message)
    yield put(updateTicketFailure(error.message))
  }
}

// Fetch
function* handleFetchTicketsRequest(action: any) {
  const { address, page = 1 } = action.payload

  try {
    const { data } = yield axios.get(`${process.env.REACT_APP_TICKET_API_URL}/tickets/owner/${address.toUpperCase()}?page=${page}`);

    yield put(fetchTicketsSuccess(data.payload))
  } catch (error: any) {
    yield put(fetchTicketsFailure(error.message))
  }
}

// Check wallet is on claim list
function* handleCheckWalletIsOnClaimList()  {
  const wallet: ReturnType<typeof getWallet> = yield select(getWallet)

  if (!wallet) {
    throw new Error('A defined wallet is required to generate a ticket')
  }

  try {
    const { data } = yield axios.get(`${process.env.REACT_APP_TICKET_API_URL}/tickets/check-airdrop?address=${wallet.address}`)

    yield put(checkWalletIsOnClaimListSuccess(data.payload))
  } catch (error: any) {
    yield put(checkWalletIsOnClaimListFailure(error.message))
  }
}

// Check wallet is mint airdrop
function* handleCheckWalletIsMintAirdop() {
  const { ticketContract } = getContracts();
  const wallet: ReturnType<typeof getWallet> = yield select(getWallet)

  if (!wallet) {
    throw new Error('A defined wallet is required to generate a ticket')
  }

  try {
    const isMintBefore:boolean = yield ticketContract.methods.isMintAirdrop(wallet.address).call()

    yield put(checkWalletIsMintAirdropSuccess(isMintBefore))
  } catch (error) {
    yield put(checkWalletIsMintAirdropFailure(error.message))
  }
}

function* handleMintTicketAirdrop(action: MintTicketAirdropRequestAction) {
  const { ticketContract } = getContracts();
  const wallet: ReturnType<typeof getWallet> = yield select(getWallet)
  const {firstName, lastName} = action.payload

  if (!wallet) {
    throw new Error('A defined wallet is required to generate a ticket')
  }

  try {
    const { data } = yield axios.post(`${process.env.REACT_APP_TICKET_API_URL}/tickets/generate-sig-mint`, { address: wallet.address  })

    const { signature }= data;

    yield ticketContract.methods.mintAirdrop(`${firstName} ${lastName}`, signature).send({
      from: wallet.address,
    });

    yield put(mintTicketAirdropSuccess())
    yield put(checkWalletIsMintAirdropSuccess(true))
  } catch (error) {
    yield put(mintTicketAirdropFailure())
  }
}

function* handleFetchSingleTicket(action: FetchSingleTicketRequestAction) {
  const { ticketId } = action.payload

  try {
    const { data } = yield axios.get(`${process.env.REACT_APP_TICKET_API_URL}/tickets/${ticketId}`)

    yield put(fetchSingleTicketSuccess(data))
  } catch (error) {
    yield put(fetchSingleTicketFailure(error.message))
  }
}
