import React, { useState, useCallback } from 'react'
import { Header, Form, Field, Button } from '@wiicamp/decentraland-ui'
import { t, T } from '@wiicamp/decentraland-dapps/dist/modules/translation/utils'

import { getAssetName } from '../../../../modules/asset/utils'

import { Column } from '../../../Layout/Column'

import { Props } from './TransferModal.types'

import styles from './TransferModal.module.scss'

const TransferModal = (props: Props) => {
  const {
    nft,
    isOwner,
    onCloseModal,
    transferItem,
    transferItemLoading
  } = props

  const [address, setAddress] = useState('')
  const [isInvalidAddress, setIsInvalidAddress] = useState(false)

  let subtitle
  let isDisabled = !address || isInvalidAddress || transferItemLoading
  let canTransfer = true
  const subtitleClasses = ['subtitle']
  const name = getAssetName(nft)

  if (!isOwner) {
    isDisabled = true
    canTransfer = false
    subtitleClasses.push('error')
    subtitle = (
      <T
        id="transfer_page.invalid_owner"
        values={{ name: <b>{name}</b> }}
      />
    )
  } else {
    subtitle = (
      <T
        id="transfer_page.subtitle"
        values={{ name: <b>{name}</b> }}
      />
    )
  }

  const handleSubmit = useCallback(() => {
    transferItem(nft, address)
  },[nft, address])

  return (
    <Column align="right">
      <div className={styles.ColumnWrapper}>
        <Header size="large">
          {t('transfer_page.title', {
            category: '' // TODO: give this a name title
          })}
        </Header>

        <div className={subtitleClasses.join(' ')}>{subtitle}</div>

        <Form onSubmit={handleSubmit}>
          <div className="form-fields">
            <Field
              type="address"
              error={isInvalidAddress}
              message={
                isInvalidAddress
                  ? t('transfer_page.invalid_address')
                  : undefined
              }
              label={t('transfer_page.recipient')}
              value={address}
              placeholder="0x..."
              disabled={!canTransfer}
              onChange={(_event, props) => {
                setAddress(props.value)
                const isValid =
                  !props.value ||
                  /^0x[a-fA-F0-9]{40}$/g.test(props.value)
                setIsInvalidAddress(!isValid)
              }}
            />
          </div>

          {canTransfer ? (
            <div className="warning">
              <T
                id="transfer_page.warning"
                values={{ br: <br /> }}
              />
            </div>
          ) : null}

          <div className="buttons">
            <Button
              as="div"
              className="LOM-cancel-btn"
              onClick={onCloseModal}
            >
              {t('global.cancel')}
            </Button>
            <Button
              className="LOM-submit-btn"
              type="submit"
              primary
              loading={transferItemLoading}
              disabled={isDisabled}
            >
              {t('transfer_page.submit')}
            </Button>
          </div>
        </Form>
      </div>
    </Column>
  )
}

export default React.memo(TransferModal)
