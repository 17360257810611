import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Container, Modal } from '@wiicamp/decentraland-ui'

import { getAssetName } from '../../../../modules/asset/utils'

import { PageHeader } from '../../../PageHeader'
import { Row } from '../../../Layout/Row'
import { Column } from '../../../Layout/Column'

import { AssetImage } from '../../AssetImage'
import Loading from '../../Loading'

import { Title } from '../Title'
import { Owner } from '../Owner'
import { Badge } from '../Badge'
import { Description } from '../Description'
import { Network } from '../Network'
import { OrderDetails } from '../OrderDetails'
import { Actions } from '../Actions'
import { ProximityHighlights } from '../ProximityHighlights'
import { TransactionHistory } from '../TransactionHistory'
import { Bids } from '../Bids'

import { ParcelCoordinates } from './ParcelCoordinates'

import { ReactComponent as IconClose } from '../../../../images/ic-close.svg'

import { Props } from './EstateDetail.types'

import styles from './EstateDetail.module.scss'
import LandCard from '../../EstateBuilderPage/LandCard'

const EstateDetail = (props: Props) => {
  const {
    nft,
    wallet,
    onSetLandToEstate,
    onRemoveLandFromEstate,

    isLoadingGetLandsByWalletAddress,
    lands,
    pageLands,
    totalLands,
    dispatchGetLandsByWalletAddress,
  } = props
  const [isShowModal, setIsShowModal] = useState(false)
  const [isAddLand, setIsAddLand] = useState(true)
  const [listSelectedLands, setListSelectedLands] = useState<any[]>([])
  const selectedLandIds = useMemo(() => listSelectedLands.map(item => item.id), [listSelectedLands])
  const estate = nft.data.estate!
  const landsOfEstate = useMemo(() => estate.parcels, [estate])

  const onClickToggleModal = useCallback((toggleModal, isAddNewLand = false, isUpdateEstate = false) => () => {
    setIsShowModal(toggleModal)
    if (isAddLand !== isAddNewLand) setIsAddLand(isAddNewLand)

    if (isUpdateEstate) {
      if (isAddNewLand) {
        onSetLandToEstate({ lands: listSelectedLands, estateId: nft.tokenId })
      } else {
        onRemoveLandFromEstate({ lands: listSelectedLands, estateId: nft.tokenId })
      }
    }

    setListSelectedLands([])
  }, [isAddLand, listSelectedLands, nft, onRemoveLandFromEstate, onSetLandToEstate])

  const onToggleLandToNewEstate = useCallback((landItem: any) => () => {
    if (isAddLand) {
      if (selectedLandIds.includes(landItem.id)) {
        setListSelectedLands(
          [...listSelectedLands.filter(item => item.id !== landItem.id)]
        )
      } else {
        setListSelectedLands([...listSelectedLands, landItem])
      }
    } else {
      if (listSelectedLands.findIndex((item: any) => item.x === landItem.x && item.y === landItem.y) !== -1) {
        setListSelectedLands(
          [...listSelectedLands.filter((item: any) => item.x !== landItem.x || item.y !== landItem.y)]
        )
      } else {
        setListSelectedLands([...listSelectedLands, landItem])
      }
    }
  }, [isAddLand, listSelectedLands, selectedLandIds])

  const onLoadmoreLands = useCallback((page) => () => {
    dispatchGetLandsByWalletAddress({ page, isLoadMore: true })
  }, [dispatchGetLandsByWalletAddress])

  useEffect(() => {
    if (wallet && wallet.address) {
      dispatchGetLandsByWalletAddress({ page: 1 })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet])

  return (
    <>
      <div className={styles.detail}>
        <section className={styles.sectionIntroduction}>
          <div>
            <Title>
              {getAssetName(nft)}

              <Badge className={styles.mgL} color="#37333d">
                {estate.size.toLocaleString()} LAND
              </Badge>
            </Title>

            {/* {estate.size > 0 ? (
            <JumpIn
              className="estate-title-badge estate-title-jump-in"
              x={x}
              y={y}
            />
          ) : null} */}
          </div>

          <Owner asset={nft} />
        </section>

        <PageHeader>
          <AssetImage
            asset={nft}
            isDraggable={true}
            withNavigation={true}
            hasPopup={true}
            zoom={1.5}
          />
        </PageHeader>

        <Container className={styles.detailMarketInformation}>
          <Description text={estate.description} />

          <Row>
            <Column align="left" grow={true}>
              <Network asset={nft} />
              <OrderDetails nft={nft} />
            </Column>

            <Column align="right">
              <div className={styles.btnGroup}>
                {nft.owner === wallet?.address && (
                  <div className={styles.estateUpdate}>
                    <Button
                      className={`LOM-cancel-btn`}
                      onClick={onClickToggleModal(true, true)}
                      disabled={!lands.length}
                    >
                      Add lands
                    </Button>

                    <Button
                      className={`LOM-cancel-btn`}
                      onClick={onClickToggleModal(true, false)}
                    >
                      Remove lands
                    </Button>
                  </div>
                )}

                <Actions nft={nft} />
              </div>
            </Column>
          </Row>

          <ProximityHighlights nft={nft} />

          <Bids nft={nft} />

          <ParcelCoordinates estateId={nft.tokenId} />

          <TransactionHistory nft={nft} />
        </Container>
      </div>
      <Modal
        size="large"
        open={isShowModal}
        className={styles.modal_create_new_estate}
      >
        <div className="relative">
          <div className="absolute right-0 top-0 p-2">
            <IconClose
              fill="#fff"
              className="h-8 w-8 cursor-pointer"
              onClick={onClickToggleModal(false)}
            />
          </div>
        </div>

        <Modal.Header>
          {isAddLand ? 'Add land to estate' : 'Remove land from estate'}
        </Modal.Header>
        <Modal.Content className={styles.modal_content}>
          <div className={styles.modal_list}>
            {isAddLand
              ? lands.map((item: any) => (
                <div
                  key={`${item.id}-${item.tokenId}`}
                  className={styles.modal_item}
                  onClick={onToggleLandToNewEstate(item)}
                >
                  <LandCard
                    name={item.name}
                    item={item}
                    isChecked={selectedLandIds.includes(item.id)}
                  />
                </div>
              ))
              : landsOfEstate.map((landPosition: any) => (
                <div
                  key={`${landPosition.x}-${landPosition.y}`}
                  className={styles.modal_item}
                  onClick={onToggleLandToNewEstate(landPosition)}
                >
                  <LandCard
                    name="Parcel"
                    landPosition={landPosition}
                    isLandInEstate
                    isChecked={listSelectedLands.findIndex((item) => item?.x === landPosition.x && item?.y === landPosition.y) !== -1}
                  />
                </div>
              ))}


          </div>

          {(totalLands !== lands.length && !isLoadingGetLandsByWalletAddress) &&
            <div className="flex justify-center mt-4">
              <Button
                className="LOM-submit-btn"
                onClick={onLoadmoreLands(pageLands! + 1)}
              >
                Load More
              </Button>
            </div>
          }

          {isLoadingGetLandsByWalletAddress &&
            <div className={`flex justify-center ${!lands.length ? 'flex-1' : 'mt-4'}`}>
              <Loading />
            </div>
          }
        </Modal.Content>
        <Modal.Actions className={styles.modal_actions}>
          <Button
            className="LOM-cancel-btn"
            onClick={onClickToggleModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="LOM-submit-btn"
            onClick={onClickToggleModal(false, isAddLand, true)}
            // disabled={!listSelectedLands.length || (!isAddLand && (landsOfEstate.length - listSelectedLands.length < 2))}
            disabled={!listSelectedLands.length}
          >
            Confirm
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default React.memo(EstateDetail)
