// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WearableRarity_detailRarity__2o5pj {\n  color: rgba(255, 255, 255, 0.7);\n  font-size: 1.083rem;\n  line-height: 1.083rem;\n  text-transform: uppercase;\n  letter-spacing: 0.26em;\n}\n\n.WearableRarity_detailRarity__2o5pj span:first-child {\n  color: var(--primary);\n}\n", "",{"version":3,"sources":["webpack://src/components/LOM/AssetPage/WearableRarity/WearableRarity.module.scss"],"names":[],"mappings":"AACE;EACE,+BAA+B;EAC/B,mBAAmB;EACnB,qBAAqB;EACrB,yBAAyB;EACzB,sBAAsB;AAAA;;AALvB;EASK,qBAAqB;AAAA","sourcesContent":[".detail {\n  &Rarity {\n    color: rgba(255, 255, 255, 0.7);\n    font-size: 1.083rem;\n    line-height: 1.083rem;\n    text-transform: uppercase;\n    letter-spacing: 0.26em;\n\n    span {\n      &:first-child {\n        color: var(--primary);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"detailRarity": "WearableRarity_detailRarity__2o5pj"
};
export default ___CSS_LOADER_EXPORT___;
