import { useEffect, useMemo, useCallback } from 'react'
import Web3 from 'web3'
import { toast } from 'react-toastify'
import { Button } from '@wiicamp/decentraland-ui'

declare let window: any

export const randomKey = () =>
  Math.random()
    .toString(36)
    .substr(2)

function useWatchNetwork() {
  const toastChangeChainId = useMemo(() => `toast_change_chain_${randomKey()}`, [])

  const handleChangeNetwork = useCallback(async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${process.env.REACT_APP_CHAIN_ID}` }]
      })
    } catch (error) {
      if (process.env.REACT_APP_ENVIRONMENT === 'development') {
        console.log(error) // eslint-disable-line no-console
      }
    }
  }, [])

  const toastContent = useCallback(
    (chainName: string) => (
      <div>
        <h2 className="capitalize text-center mb-3 text-black">
          Wrong network
        </h2>

        <p className="my-3">
          You need to be connected to{' '}
          <b className="capitalize">
            {process.env.REACT_APP_NETWORK?.toLowerCase()}
          </b>{' '}
          to use this app, but you are currently connected to{' '}
          <b className="capitalize">{chainName.toLowerCase()}</b>.
        </p>

        <div className="flex justify-center">
          <Button
            className="LOM-submit-btn uppercase"
            onClick={handleChangeNetwork}
          >
            switch to {process.env.REACT_APP_NETWORK} network
          </Button>
        </div>
      </div>
    ),
    [handleChangeNetwork]
  )

  const showToast = useCallback(
    chainName => {
      if (!toast.isActive(toastChangeChainId)) {
        toast(toastContent(chainName), {
          toastId: toastChangeChainId,
        })
      } else {
        toast.update(toastChangeChainId, {
          render: toastContent(chainName)
        })
      }
    },
    [toastChangeChainId, toastContent]
  )

  const fetchNetworkInfo = useCallback(async () => {
    try {
      let chainId = null
      let chainName = null
      const web3 = new Web3(Web3.givenProvider || 'ws://localhost:8545')

      await web3.eth.getChainId((_err: Error, _chainId: number | string) => {
        chainId = _chainId
      })

      await web3.eth.net.getNetworkType((_err: Error, _chainName: string) => {
        chainName = _chainName
      })

      if (chainId !== Number(process.env.REACT_APP_CHAIN_ID)) {
        showToast(chainName)
      } else {
        toast.dismiss()
      }
    } catch (error) {
      if (process.env.REACT_APP_ENVIRONMENT === 'development') {
        console.log(error) // eslint-disable-line no-console
      }
    }
  }, [showToast])

  useEffect(() => {
    window.toast = toast

    try {
      if (typeof window.ethereum !== 'undefined') {
        fetchNetworkInfo()

        window.ethereum?.on?.('chainChanged', fetchNetworkInfo)

        window.ethereum?.on?.('accountsChanged', window.location.reload)

        return () => {
          toast.dismiss()
          window.ethereum?.removeListener?.('chainChanged', fetchNetworkInfo)
          window.ethereum?.removeListener?.(
            'accountsChanged',
            window.location.reload
          )
        }
      }
    } catch (error) {
      if (process.env.REACT_APP_ENVIRONMENT === 'development') {
        console.log(error) // eslint-disable-line no-console
      }
    }
  }, [fetchNetworkInfo])
}

export default useWatchNetwork
