
import React from 'react'
import classNames from 'classnames'

import { Props } from './Loader.types'

const Loader = (props: Props) => {
  const { className, color, width, height } = props

  return (
    <svg
      className={classNames(
        'animate-spin border-t-2 h-4 w-4 rounded-50',
        color && `border-${color}`,
        width && `w-${width}`,
        height && `w-${height}`,
        className
      )}
      viewBox="0 0 24 24"
    />
  )
}

Loader.defaultProps = {
  color: 'primary'
}

export default React.memo(Loader)
