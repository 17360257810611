import React from 'react'
import { useHistory } from 'react-router-dom'

import LayoutPage from '../Layout/LayoutPage/LayoutPage'

import Concept from './Concept'
import Introduction from './Introduction'

import { Props } from './StoryPage.types'
import { Breadcrumb } from '../Breadcrumb/Breadcrumb.types'
import './StoryPage.scss'

import { BREADCRUMBS } from '../../../lib/constant'

// import Team from "./Team";

const StoryPage = (props: Props) => {
  const history = useHistory()
  const { slug } = props
  const acceptPath = ['introduction', 'concept', 'team']
  if (acceptPath.includes(slug)) {
    const breadcrumb: Breadcrumb[] = [
      { name: 'Home', link: '/' },
      { name: 'Story' },
      {
        name: BREADCRUMBS?.[slug],
        active: true,
        isBreakHere: true,
      }
    ]
    return (
      <LayoutPage viewMode="white">
        {slug === 'introduction' && <Introduction breadcrumb={breadcrumb} />}
        {slug === 'concept' && <Concept breadcrumb={breadcrumb} />}
        {/* {slug === "team" && <Team breadcrumb={breadcrumb} />} */}
      </LayoutPage>
    )
  } else {
    history.push('/')
    return <div />
  }
}

export default React.memo(StoryPage)
