import { connect } from 'react-redux'
import { isLoadingType } from '@wiicamp/decentraland-dapps/dist/modules/loading/selectors'

import ItemNFTDetailPage from './ItemNFTDetailPage'

import { RootState } from '../../../modules/reducer'
import {
  FETCH_ITEM_NFT_DETAIL_REQUEST,
  fetchItemNFTDetailRequest,
  buyItemUserCreationRequest,
  BUY_ITEM_USER_CREATION_REQUEST,
  fetchItemNFTListingDetailRequest,
  listForSaleItemRequest,
  LIST_FOR_SALE_ITEM_REQUEST,
  transferItemRequest,
  TRANSFER_ITEM_REQUEST,
  CANCEL_SALE_ITEM_REQUEST,
  cancelSaleItemRequest,
  BUY_RESOLD_ITEM_REQUEST,
  buyResoldItemRequest,
  fetchTransactionHistoriesItemNFTRequest,
} from '../../../modules/itemNFT/actions'
import {
  getBuyItemUserCreationLoading,
  getItemNFT,
  getLoading,
  getItemNFTListingLoading,
  getListForSaleItemLoading,
  getTransferItemLoading,
  getCancelSaleItemLoading,
  getBuyResoldItemLoading,
  getTransactionHistoriesItemNFT,
  getTransactionHistoriesItemNFTLoading,
  getCategoriesItemsNFT,
} from '../../../modules/itemNFT/selectors'

import { getWallet } from '../../../modules/wallet/selectors'

import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps
} from './ItemNFTDetailPage.types'
import { FetchTransactionHistoriesItemNFTQuery } from '../../../modules/itemNFT/types'

const mapState = (state: RootState): MapStateProps => ({
  wallet: getWallet(state),

  item: getItemNFT(state),
  transactionHistoriesItemNFT: getTransactionHistoriesItemNFT(state),
  categoriesItemsNFT: getCategoriesItemsNFT(state),

  itemNFTLoading: isLoadingType(getLoading(state), FETCH_ITEM_NFT_DETAIL_REQUEST),

  buyItemUserCreationLoading: isLoadingType(getBuyItemUserCreationLoading(state), BUY_ITEM_USER_CREATION_REQUEST),
  buyResoldItemLoading: isLoadingType(getBuyResoldItemLoading(state), BUY_RESOLD_ITEM_REQUEST),
  listForSaleItemLoading: isLoadingType(getListForSaleItemLoading(state), LIST_FOR_SALE_ITEM_REQUEST),
  transferItemLoading: isLoadingType(getTransferItemLoading(state), TRANSFER_ITEM_REQUEST),
  cancelSaleItemLoading: isLoadingType(getCancelSaleItemLoading(state), CANCEL_SALE_ITEM_REQUEST),

  itemNFTListingDetailLoading: getItemNFTListingLoading(state),
  transactionHistoriesItemNFTLoading: getTransactionHistoriesItemNFTLoading(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  fetchItemNFT: query => dispatch(fetchItemNFTDetailRequest(query)),
  fetchItemNFTListing: (id: string, contractAddress: string) => dispatch(fetchItemNFTListingDetailRequest(id, contractAddress)),
  fetchTransactionHistoriesItemNFT: (query: FetchTransactionHistoriesItemNFTQuery) => dispatch(fetchTransactionHistoriesItemNFTRequest(query)),

  buyItemUserCreation: (item: any) => dispatch(buyItemUserCreationRequest(item)),
  buyResoldItem: (item: any) => dispatch(buyResoldItemRequest(item)),
  listForSaleItem:(item: any, price: number) => dispatch(listForSaleItemRequest(item, price)),
  transferItem:(item: any, toWalletAddress: string) => dispatch(transferItemRequest(item, toWalletAddress)),
  cancelSaleItem:(item: any, callback: () => void) => dispatch(cancelSaleItemRequest(item, callback)),
})

export default connect(mapState, mapDispatch)(ItemNFTDetailPage)
