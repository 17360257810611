import { memo, useMemo } from 'react'
import { Rarity } from '@dcl/schemas'

import { ReactComponent as IconRarity } from '../../../asset/icons/ic-rarity.svg'
import IconLand from '../../../images/icon-left-aside/Icon01.svg'

import cssStyles from './EstateBuilderPage.module.scss'
import { AssetImage } from '../AssetImage'

const LandCard = (props: any) => {
  const {
    item,
    className,
    name,
    isEstate,
    isChecked,
    isLandInEstate,
    landPosition,
  } = props
  const thumbnail = useMemo(() => isLandInEstate && `https://atlas.lifeonmars.org/v1/parcels/${landPosition.x}/${landPosition.y}/map.png`, [isLandInEstate, landPosition])
  const subName = useMemo(() => {
    switch (true) {
      case isEstate:
        return `${item.data.estate.size} LAND`

      case isLandInEstate:
        return `${landPosition.x}, ${landPosition.y}`

      default:
        return `${item.data.parcel.x}, ${item.data.parcel.y}`
    }
  }, [isEstate, isLandInEstate, item, landPosition])

  const cardColor = useMemo(() => {
    switch (true) {
      case isChecked:
        return Rarity.UNIQUE

      case isEstate:
        return Rarity.UNCOMMON

      default:
        return Rarity.COMMON
    }
  }, [isChecked, isEstate])
  const [light, dark] = Rarity.getGradient(cardColor)
  const background = `linear-gradient(-125deg,${light} 0%,${dark} 100%)`

  const cssStyle = { '--fillColor': dark } as React.CSSProperties

  return (
    <div className={`${cssStyles.AssetCardLink} ${className}`}>
      <div className={`${cssStyles.AssetCard} cursor-pointer`} style={cssStyle}>
        <div className={cssStyles.AssetCardContainer} style={{ background }}>
          <div className={cssStyles.AssetCardWrapper}>
            <div className={cssStyles.AssetCardContent}>
              <div className={cssStyles.AssetCardInfo}>
                <div className={cssStyles.AssetCardTime}>
                  <span>
                    <div className={cssStyles.AssetCardExpired} />
                  </span>
                  <div className={cssStyles.AssetCardHolder}>
                    {/* <Profile address="" textOnly inline={false} /> */}
                    {/* <sup>[martian]</sup> */}
                  </div>
                </div>

                <div className={cssStyles.AssetCardName}>
                  {name.toUpperCase()}
                </div>
                <div className={cssStyles.AssetCardMaterial}>
                  <div>
                    {subName}
                  </div>
                </div>
              </div>

              <div className={cssStyles.AssetCardImage}>
                <div
                  className="absolute top-0 left-0 w-full h-full"
                >
                  {isLandInEstate
                    ? <img src={thumbnail} alt={name} className="w-full h-full" />
                    : (!!item && !!item.data && <AssetImage asset={item} showMonospace />)
                  }
                </div>
              </div>

              <div className={cssStyles.AssetCardQuality}>
                <div className={cssStyles.AssetCardIconMint}>
                  <div>
                    <img src={IconLand} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={cssStyles.AssetCardRarity}>
            <div className={cssStyles.AssetCardRarityWrapper}>
              <div className={cssStyles.AssetCardRarityContent}>
                <IconRarity />
              </div>

              <div className={cssStyles.AssetCardRarityText}>
                <span>{isEstate ? 'ESTATE' : 'LAND'}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(LandCard)
