import { useCallback, memo } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { Link } from 'react-router-dom'

import { customScroll, customTrackHorizontal } from '../../../lib/customScrollbars'

import { ItemNFTCard } from '../ItemNFTPage/ItemNFTCard'

import { locations } from '../../../modules/routing/locations'

import { ReactComponent as IconArrow } from '../../../asset/icons/ic-arrow.svg'
import ArrowIcon from '../../../asset/images/tools/arrow-right-bottom.svg'

import { MarketplaceType } from '../ItemNFTPage/ItemNFTCard/ItemNFTCard.types'

const Section = (props: any) => {
  const {
    title,
    slug,
    thumbClassName,
    thumbVisible,
    itemsNFT,
  } = props

  const renderNfts = () =>
    itemsNFT?.map((item: any) => (
      <ItemNFTCard
        key={`${item.id}_${item.name}`}
        type={MarketplaceType.MARKETPLACE}
        {...item}
      />
    ))

  const renderThumb = useCallback((thumbProps) => {
    if (!thumbVisible) return <div />;

    return customScroll({ ...thumbProps, className: thumbClassName });
  }, [thumbVisible, thumbClassName]);

  const renderTrackHorizontal = useCallback((trackProps) => {
    if (!thumbVisible) return <div />;

    return customTrackHorizontal(trackProps);
  }, [thumbVisible]);

  return (
    <div className="Marketplace__section Marketplace__estates-featured text-white">
      <div className="relative">
        <div className="Marketplace__sectionHead flex items-center">
          <div className="flex items-center">
            <img
              src={ArrowIcon}
              alt="arrow"
              className="Marketplace__sectionImage"
            />
            <h2>{title}</h2>
          </div>
          <div className="ml-auto flex justify-end">
            <Link to={locations.itemsNFTPage(!!slug ? slug : '')}>
              See all <span className="ml-1 text-xl"><IconArrow /></span>
            </Link>
          </div>
        </div>

        <Scrollbars
          renderThumbVertical={renderThumb}
          renderThumbHorizontal={renderThumb}
          renderTrackHorizontal={renderTrackHorizontal}
          style={{ width: '100%', height: 'calc(22rem)' }}
        >
          <div className="Marketplace__listItems flex flex-nowarp">
            {itemsNFT.length > 0 && renderNfts()}
          </div>
        </Scrollbars>
      </div>
    </div>
  )
}

export default memo(Section)
