import { RootState } from '../reducer'


export const getState = (state: RootState) => state.authentication
export const getLoadingCheckUserExisted = (state: RootState) => getState(state).isLoadingCheckUserExisted
export const getUserExistedStatus = (state: RootState) => getState(state).userExistedStatus
export const getUser = (state: RootState) => getState(state).user
export const getToken = (state: RootState) => getState(state).token
export const getSignature = (state: RootState) => getState(state).signature
export const getMessage = (state: RootState) => getState(state).message
export const getLoadingUpdateEmailAndUsernameOfUser = (state: RootState) => getState(state).isLoadingUpdateEmailAndUsernameOfUser
export const getLoadingGetUserSignMessage = (state: RootState) => getState(state).isLoadingGetUserSignMessage
export const getLoadingSetUserSignMetamask = (state: RootState) => getState(state).isLoadingSetUserSignMetamask
export const getLoadingSetVerifyWalletToAccount = (state: RootState) => getState(state).isLoadingSetVerifyWalletToAccount
export const getLoadingUpdateUserPassword = (state: RootState) => getState(state).isLoadingUpdateUserPassword
