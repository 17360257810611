import React from 'react'
import classNames from 'classnames'

import { ContentItemProps } from '../RoadmapPage.types'

const CircleTitle = (props: ContentItemProps) => {
  const { index, item } = props;

  const active = Array.isArray(item?.active) ? item?.active : [];

  if (!item?.value) {
    return null;
  }

  return (
    <div key={index} className="flex justify-center">
      <div
        className={classNames(
          "Roadmap__circle-title",
          active?.length > 0 ? "text-green" : "text-gray",
        )}
      >
        {item?.value}
        <div
          className={`Roadmap__circle-title__left ${active?.includes("left") ? "active" : ""
            }`}
        />
        <div
          className={`Roadmap__circle-title__right ${active?.includes("right") ? "active" : ""
            }`}
        />
      </div>
    </div>
  );
};

export default React.memo(CircleTitle);
