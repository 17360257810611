import { connect } from 'react-redux'

import ItemNFTCard from './ItemNFTCard'

import { RootState } from '../../../../modules/reducer'

import { getRaritiesItemsNFT } from '../../../../modules/itemNFT/selectors'

import {
  MapStateProps
} from './ItemNFTCard.types'

const mapState = (state: RootState): MapStateProps => ({
  raritiesItemsNFT: getRaritiesItemsNFT(state),
})

export default connect(mapState, null)(ItemNFTCard)
