import { connect } from 'react-redux'
import { replace } from 'connected-react-router'
import { RouteComponentProps } from 'react-router'

import { RootState } from '../../../modules/reducer'
import { getIsFullscreen, getVendor } from '../../../modules/routing/selectors'
import { getWallet, isConnecting } from '../../../modules/wallet/selectors'
import { getTickets, getTransferLoading, getFetchLoading, getUpdateLoading } from '../../../modules/ticket/selectors'

import { fetchTicketsRequest, transferTicketRequest, updateTicketRequest } from '../../../modules/ticket/actions'

import {
  Params,
  MapStateProps,
  MapDispatch,
  MapDispatchProps
} from './MyTicketPage.types'

import MyTicketPage from './MyTicketPage'

const mapState = (
  state: RootState,
  ownProps: RouteComponentProps<Params>
): MapStateProps => {
  const { address } = ownProps.match.params

  return {
    address: address?.toLowerCase(),
    vendor: getVendor(state),
    wallet: getWallet(state),
    tickets: getTickets(state),
    transferTicketLoading: getTransferLoading(state),
    updateTicketLoading: getUpdateLoading(state),
    fetchTicketsLoading: getFetchLoading(state),
    isConnecting: isConnecting(state),
    isFullscreen: getIsFullscreen(state)
  }
}

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onRedirect: path => dispatch(replace(path)),
  onFetchTickets: params => dispatch(fetchTicketsRequest(params)),
  onTransferTicket: params => dispatch(transferTicketRequest(params)),
  onUpdateTicket: params => dispatch(updateTicketRequest(params)),
})

export default connect(mapState, mapDispatch)(MyTicketPage)
