import { action } from 'typesafe-actions'

import { NewModalState } from './types'

export const FETCH_TOGGLE_MODAL_DETAIL_ESTATE_REQUEST =
  '[Request] Fetch Open Modal Detail Estate'

export const fetchToggleModalDetailEstateRequest = (newModalState : NewModalState) =>
  action(FETCH_TOGGLE_MODAL_DETAIL_ESTATE_REQUEST, newModalState)

export type FetchToggleModalDetailEstateRequestAction = ReturnType<
  typeof fetchToggleModalDetailEstateRequest
>
