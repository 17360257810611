// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Title_detailTitle__2lsCt {\n  display: flex;\n  align-items: center;\n  color: white !important;\n  font-size: 1.666rem;\n  font-weight: 700;\n  line-height: 1.666rem;\n  text-transform: uppercase;\n}\n", "",{"version":3,"sources":["webpack://src/components/LOM/AssetPage/Title/Title.module.scss"],"names":[],"mappings":"AACE;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,qBAAqB;EACrB,yBAAyB;AAAA","sourcesContent":[".detail {\n  &Title {\n    display: flex;\n    align-items: center;\n    color: white !important;\n    font-size: 1.666rem;\n    font-weight: 700;\n    line-height: 1.666rem;\n    text-transform: uppercase;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"detailTitle": "Title_detailTitle__2lsCt"
};
export default ___CSS_LOADER_EXPORT___;
