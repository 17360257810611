import { connect } from 'react-redux'
import {
  // enableWalletRequest,
  disconnectWallet
} from '@wiicamp/decentraland-dapps/dist/modules/wallet/actions'
// import { getUserSignMessageRequest, setUserSignMetamaskRequest } from '../../../../modules/authentication/actions'
// import { getError, isEnabling, isConnecting } from '@wiicamp/decentraland-dapps/dist/modules/wallet/selectors'
// import { isEnabled } from '@wiicamp/decentraland-dapps/dist/modules/translation/selectors'

import MenuBarRight from './MenuBarRight'

import { getWallet } from '../../../../modules/wallet/selectors'
import {
  // getMessage,
  getToken,
  // getUser,
  // getUserExistedStatus,
} from '../../../../modules/authentication/selectors'

import {
  MapStateProps,
  MapDispatchProps,
  MapDispatch
} from './MenuBarRight.types'

import { RootState } from '../../../../modules/reducer'

const mapState = (state: RootState): MapStateProps => ({
  // hasError: !!getError(state),
  // isLoading: isEnabling(state) || isConnecting(state),
  // hasTranslations: isEnabled(state),
  wallet: getWallet(state),
  // userExistedStatus: getUserExistedStatus(state),
  token: getToken(state),
  // userConnectWithWallet: getUser(state),
  // message: getMessage(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  // onConnect: providerType => dispatch(enableWalletRequest(providerType)),
  onSignOut: () => dispatch(disconnectWallet()),
  // dispatchGetUserSignMessageRequest: (payload: any) => dispatch(getUserSignMessageRequest(payload)),
  // setUserSignMetamaskRequest: (payload: any) => dispatch(setUserSignMetamaskRequest(payload)),
})

export default connect(mapState, mapDispatch)(MenuBarRight)
