import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Network } from '@dcl/schemas'
import { t } from '@wiicamp/decentraland-dapps/dist/modules/translation/utils'
import { isMobile } from '@wiicamp/decentraland-dapps/dist/lib/utils'
import { AuthorizationType } from '@wiicamp/decentraland-dapps/dist/modules/authorization/types'
import { Page, Grid, Blockie, Loader, Form } from '@wiicamp/decentraland-ui'
import { ContractName } from '@wiicamp/decentraland-transactions'

import Breadcrumb from '../Breadcrumb'
import LayoutPage from '../Layout/LayoutPage/LayoutPage'
import { shortenAddress } from '../../../modules/wallet/utils'
import { Authorization } from './Authorization'
import { getContractNames } from '../../../modules/vendor'
import { getContract } from '../../../modules/contract/utils'
import { useTimer } from '../../../lib/timer'

import { Props } from './SettingsPage.types'
import { Breadcrumb as BreadcrumbType } from '../Breadcrumb/Breadcrumb.types'

import './SettingsPage.scss'

const SettingsPage = (props: Props) => {
  const {
    wallet,
    authorizations,
    isLoadingAuthorization,
    isConnecting,
    hasError
  } = props

  const breadcrumb: BreadcrumbType[] = [
    { name: 'Home', link: '/' },
    { name: 'Settings', active: true, isBreakHere: true }
  ]

  const [hasCopiedText, setHasCopiedAddress] = useTimer(1200)

  const contractNames = getContractNames()

  const marketplaceEthereum = getContract({
    name: contractNames.MARKETPLACE,
    network: Network.ETHEREUM
  })

  // const marketplaceMatic = getContract({
  //   name: contractNames.MARKETPLACE,
  //   network: Network.MATIC
  // })

  const marketplaceAdapter = getContract({
    name: contractNames.MARKETPLACE_ADAPTER
  })

  const bids = getContract({
    name: contractNames.BIDS
  })

  const manaEthereum = getContract({
    name: contractNames.MANA,
    network: Network.ETHEREUM
  })

  // const manaMatic = getContract({
  //   name: contractNames.MANA,
  //   network: Network.MATIC
  // })

  const authorizationsForSelling = authorizations.filter(authorization => {
    const contract = getContract({ address: authorization.contractAddress })
    return contract.category != null
  })

  return (
    <LayoutPage viewMode="white">
      <div className="flex-grow min-h-full overflow-auto Story__background SettingsPageCustom">
        <Page>
          {isConnecting ? (
            <Loader size="massive" active />
          ) : wallet ? (
            <>
              <div className="Page__header mt-14 lg:mt-28 mb-14">
                <Breadcrumb breadcrumb={breadcrumb} viewMode="dark" />
              </div>
              <Grid>
                <Grid.Row>
                  <Grid.Column
                    className="left-column secondary-text"
                    computer={4}
                    mobile={16}
                  >
                    {t('global.address')}
                  </Grid.Column>
                  <Grid.Column computer={12} mobile={16}>
                    <div className="blockie-container">
                      <Blockie seed={wallet.address} scale={12} />
                    </div>
                    <div className="address-container">
                      <div className="address">
                        {isMobile()
                          ? shortenAddress(wallet.address)
                          : wallet.address}
                      </div>
                      <CopyToClipboard
                        text={wallet.address}
                        onCopy={setHasCopiedAddress}
                      >
                        {hasCopiedText ? (
                          <span className="copy-text">
                            {t('settings_page.copied')}
                          </span>
                        ) : (
                          <span className="copy-text link">
                            {t('settings_page.copy_address')}
                          </span>
                        )}
                      </CopyToClipboard>
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column
                    className="left-column secondary-text"
                    computer={4}
                    mobile={16}
                  >
                    {t('settings_page.authorizations')}
                  </Grid.Column>
                  <Grid.Column computer={12} mobile={16}>
                    {isLoadingAuthorization ? (
                      <Loader size="massive" active />
                    ) : (
                      <div className="authorization-checks-container">
                        {hasError ? (
                          <div className="authorization-checks">
                            <p className="danger-text">
                              {t('settings_page.authorization_error')}
                              <br />
                              {t('settings_page.authorization_error_contact')}
                            </p>
                          </div>
                        ) : (
                          <Form>
                            <div className="authorization-checks">
                              <label className="secondary-text">
                                {t('settings_page.for_buying')}
                              </label>
                              <Authorization
                                authorization={{
                                  address: wallet.address,
                                  authorizedAddress:
                                    marketplaceEthereum.address,
                                  contractAddress: manaEthereum.address,
                                  contractName: ContractName.MANAToken,
                                  chainId: manaEthereum.chainId,
                                  type: AuthorizationType.ALLOWANCE
                                }}
                              />
                              <Authorization
                                authorization={{
                                  address: wallet.address,
                                  authorizedAddress: marketplaceAdapter.address,
                                  contractAddress: manaEthereum.address,
                                  contractName: ContractName.MANAToken,
                                  chainId: manaEthereum.chainId,
                                  type: AuthorizationType.ALLOWANCE
                                }}
                              />
                              {/* <Authorization
                                authorization={{
                                  address: wallet.address,
                                  authorizedAddress: marketplaceMatic.address,
                                  contractAddress: manaMatic.address,
                                  contractName: ContractName.MANAToken,
                                  chainId: manaMatic.chainId,
                                  type: AuthorizationType.ALLOWANCE
                                }}
                              /> */}
                            </div>

                            <div className="authorization-checks">
                              <label className="secondary-text">
                                {t('settings_page.for_bidding')}
                              </label>
                              <Authorization
                                authorization={{
                                  address: wallet.address,
                                  authorizedAddress: bids.address,
                                  contractAddress: manaEthereum.address,
                                  contractName: ContractName.MANAToken,
                                  chainId: manaEthereum.chainId,
                                  type: AuthorizationType.ALLOWANCE
                                }}
                              />
                            </div>

                            {authorizationsForSelling.length > 0 ? (
                              <div className="authorization-checks">
                                <label className="secondary-text">
                                  {t('settings_page.for_selling')}
                                </label>

                                {authorizationsForSelling.map(authorization => {
                                  return (
                                    <Authorization
                                      key={
                                        authorization.authorizedAddress +
                                        authorization.contractAddress
                                      }
                                      authorization={authorization}
                                    />
                                  )
                                })}
                              </div>
                            ) : null}
                          </Form>
                        )}
                      </div>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </>
          ) : null}
        </Page>
      </div>
    </LayoutPage>
  )
}

export default React.memo(SettingsPage)
