import { memo } from 'react'
import {
  Container,
  HeaderMenu,
  Table,
  Mana
} from '@wiicamp/decentraland-ui'

import { sliceStringToSmallCharacter } from '../../../../lib/utils'

import styles from './TransactionHistoryItemNFT.module.scss'

import { Props } from './TransactionHistoryItemNFT.types'

const TransactionHistoryItemNFT = (props: Props) => {
  const { histories } = props

  if (!histories) return null

  return (
    <Container className={styles.tableContainer}>
      <HeaderMenu>
        <HeaderMenu.Left>
          <div className="ui sub header">Transaction History</div>
        </HeaderMenu.Left>
      </HeaderMenu>

      <Table basic="very">
        <Table.Header>
          <Table.Row className='tableRow'>
            <Table.HeaderCell id="From">From</Table.HeaderCell>
            <Table.HeaderCell id="To">To</Table.HeaderCell>
            <Table.HeaderCell id="Type">Type</Table.HeaderCell>
            <Table.HeaderCell id="When">When</Table.HeaderCell>
            <Table.HeaderCell id="Price">Price</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body className='tableBody'>
          {histories.map(({ from, to, type, createdAt, price, id }) => (
            <Table.Row key={id} className="tableRow">
              <Table.Cell id="From">
                {sliceStringToSmallCharacter(from)}
              </Table.Cell>

              <Table.Cell id="To">
                {sliceStringToSmallCharacter(to)}
              </Table.Cell>

              <Table.Cell id="Type">
                {type}
              </Table.Cell>

              <Table.Cell id="When">
                <span>{new Date(Number(createdAt) * 1000).toDateString()}</span>
              </Table.Cell>

              <Table.Cell id="Price">
                <Mana inline />

                <span>{(Number(price) / 10 ** 18).toLocaleString()}</span>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Container>
  )
}

export default memo(TransactionHistoryItemNFT)
