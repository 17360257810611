import React from 'react'

import Breadcrumb from '../Breadcrumb'
import LayoutPage from '../Layout/LayoutPage/LayoutPage'

import './TokenomicPage.scss'
import cssStyles from './styles.module.scss'

import LOMCoinImg from '../../../asset/images/LOM-coin.png'
import chartImg from '../../../asset/images/tokenomic/tokenomics.svg'

const TokenomicPage = () => {
  const breadcrumb = [
    { name: 'Home', link: '/' },
    { name: 'Tokenomics', active: true, isBreakHere: true },
  ];

  return (
    <LayoutPage viewMode="white">
      <div className={`Tokenomic__background dark ${cssStyles.tokenomic}`}>
        <div className="Page__header mt-10 lg:mt-28 ">
          <Breadcrumb breadcrumb={breadcrumb} viewMode="dark" />
        </div>

        <div className="flex flex-col lg:flex-row mt-8">
          <div
            style={{ minWidth: "24rem", width: "24rem" }}
            className="flew-none"
          >
            <div className="flex items-center">
              <img
                src={LOMCoinImg}
                alt="lom-coin"
                className="w-10 h-10 mr-2"
              />
              <div className="flex-col flex justify-end">
                <p className={cssStyles.totalSupply}>Total supply</p>
                <p className={cssStyles.totalSupplyNumber}>
                  <b className="font-bold">144.800.000 </b>LOM
                </p>
              </div>
            </div>

            <p className={`mt-8 ${cssStyles.pageContent}`}>
              Why did we choose this number as the total supply?
              <br />
              It is the area surface of Mars in km2.
              <br />
              This is the number of dreams.
            </p>
          </div>

          {/* <div className="flex-grow flex items-end justify-center">
            <img src={chartImg} alt="chart" width="100%" height="auto" />
          </div> */}
        </div>

        <div className="flex-grow flex items-end justify-center mt-8">
          <img src={chartImg} alt="chart" width="100%" height="auto" />
        </div>

        {/* <div className={`my-8 flex justify-between sm:block ${cssStyles.divisionTable}`}>
          <table className="border-separate transform sm:transform-none scale-90">
            <tbody>
              <tr>
                <td>
                  <div className=" mr-5 sm:mr-15"> Staking reward:</div>
                </td>
                <td className="text-green">29%</td>
              </tr>
              <tr>
                <td>Team : </td>
                <td className="text-green">21%</td>
              </tr>
              <tr>
                <td>Play to earn: </td>
                <td className="text-green">20%</td>
              </tr>
              <tr>
                <td>IDO {"&"} Presale: </td>
                <td className="text-green">11%</td>
              </tr>
            </tbody>
          </table>

          <table className="border-separate transform sm:transform-none scale-90">
            <tbody>
              <tr>
                <td>
                  <div className=" mr-5 sm:mr-10">Ecosysteam Fund:</div>
                </td>
                <td className="text-green">8%</td>
              </tr>
              <tr>
                <td>Advisors: </td>
                <td className="text-green">7%</td>
              </tr>
              <tr>
                <td>Private sale: </td>
                <td className="text-green">4%</td>
              </tr>
            </tbody>
          </table>
        </div> */}
      </div>
    </LayoutPage>
  );
};

export default React.memo(TokenomicPage);
