import React, { useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Network } from '@dcl/schemas'
import { Modal, Button } from '@wiicamp/decentraland-ui'
import {
  T,
  t
} from '@wiicamp/decentraland-dapps/dist/modules/translation/utils'

import { isOwnedBy } from '../../../../modules/asset/utils'
import { locations } from '../../../../modules/routing/locations'
// import { AssetType } from '../../../../modules/asset/types'
import { VendorFactory } from '../../../../modules/vendor'

import { ReactComponent as CloseIcon } from '../../../../images/ic-close.svg'

import { Props } from './Actions.types'
import styles from './Actions.module.scss'

const Actions = (props: Props) => {
  const { wallet, nft, order, bids } = props
  const { vendor, contractAddress, tokenId } = nft
  // const { wearable } = nft.data
  // const textName = wearable ? 'Item' : 'Land'

  const [showLeavingSiteModal, setShowLeavingSiteModal] = useState(false)
  const [showNotifyOpeningDate, setShowNotifyOpeningDate] = useState(false)

  const { bidService, orderService } = VendorFactory.build(nft.vendor)
  const isBiddable = bidService !== undefined

  const isOwner = isOwnedBy(nft, wallet)

  const canSell = orderService.canSell()
  const canBid =
    !isOwner &&
    isBiddable &&
    nft.network === Network.ETHEREUM &&
    (!wallet || !bids.some(bid => bid.bidder === wallet.address))

  const toggleModalNotifyOpeningDate = useCallback(() => {
    setShowNotifyOpeningDate(prevState => !prevState)
  }, [])

  return (
    <div className="flex flex-col">
      {order ? (
        isOwner && canSell ? (
          <>
            <Button
              className={styles.detailBuyButton}
              as={Link}
              to={locations.sellLOM(contractAddress, tokenId)}
              primary
            >
              {t('asset_page.actions.update')}
            </Button>

            <Button
              className={styles.detailBuyButton}
              as={Link}
              to={locations.cancelLOM(contractAddress, tokenId)}
            >
              {t('asset_page.actions.cancel_sale')}
            </Button>
          </>
        ) : !isOwner ? (
          <>
            <Button
              className={styles.detailBuyButton}
              onClick={toggleModalNotifyOpeningDate}
              primary
            >
              {t('asset_page.actions.buy')}
            </Button>

            {canBid ? (
              <Button
                className={styles.detailBuyButton}
                // onClick={toggleModalNotifyOpeningDate}
                as={Link}
                to={locations.bidLOM(contractAddress, tokenId)}
              >
                {t('asset_page.actions.bid')}
              </Button>
            ) : null}
          </>
        ) : (
          <Button
            className={styles.detailBuyButton}
            onClick={() => setShowLeavingSiteModal(true)}
            primary
          >
            {t('asset_page.actions.see_listing')}
          </Button>
        )
      ) : isOwner && canSell ? (
        <Button
          className={styles.detailBuyButton}
          as={Link}
          to={locations.sellLOM(contractAddress, tokenId)}
          primary
        >
          {t('asset_page.actions.sell')}
        </Button>
      ) : isOwner && !canSell ? (
        <Button
          className={styles.detailBuyButton}
          onClick={() => setShowLeavingSiteModal(true)}
          primary
        >
          {t('asset_page.actions.sell')}
        </Button>
      ) : canBid ? (
        <Button
          className={styles.detailBuyButton}
          onClick={toggleModalNotifyOpeningDate}
          primary
        >
          {t('asset_page.actions.bid')}
        </Button>
      ) : null}
      {isOwner && !order ? (
        <Button
          className={styles.detailBuyButton}
          as={Link}
          to={locations.transferLOM(contractAddress, tokenId)}
        >
          {t('asset_page.actions.transfer')}
        </Button>
      ) : null}

      <Modal
        className={styles.showOpeningDate}
        open={showNotifyOpeningDate}
        onClose={toggleModalNotifyOpeningDate}
      >
        <div className="relative">
          <div className="absolute right-0 top-0 p-2">
            <CloseIcon
              fill="#fff"
              className="h-8 w-8 cursor-pointer"
              onClick={toggleModalNotifyOpeningDate}
            />
          </div>
        </div>

        <Modal.Header>Coming soon...</Modal.Header>

        {/* <Modal.Content>
          {textName} {t('asset_page.actions.time_launch')}
        </Modal.Content> */}
      </Modal>

      <Modal
        className="LeavingSiteModal"
        size="small"
        open={showLeavingSiteModal}
        onClose={() => setShowLeavingSiteModal(false)}
      >
        <Modal.Header>
          {t('asset_page.actions.leaving_decentraland')}
        </Modal.Header>

        <Modal.Content>
          <p>
            <T
              id="asset_page.actions.leaving_decentraland_description"
              values={{
                vendor: t(`vendors.${vendor}`),
                vendor_link: (
                  <a href={nft.url} target="_blank" rel="noopener noreferrer">
                    {nft.url}
                  </a>
                )
              }}
            />
            <br />
            <br />

            <small>
              <i>{t('asset_page.actions.leaving_decentraland_disclaimer')}</i>
            </small>
          </p>
        </Modal.Content>

        <Modal.Actions>
          <Button
            className={styles.detailBuyButton}
            onClick={() => setShowLeavingSiteModal(false)}
          >
            {t('global.cancel')}
          </Button>

          <Button
            className={styles.detailBuyButton}
            primary
            as="a"
            href={nft.url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => setShowLeavingSiteModal(false)}
          >
            {t('global.proceed')}
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  )
}

export default React.memo(Actions)
