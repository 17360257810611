import { BaseAPI } from '@wiicamp/decentraland-dapps/dist/lib/api'
import {
  UploadImageS3Body,
  UploadImageS3Response,
  CreateItemUserCreationBody,
  CreateItemUserCreationResponse,
  DeleteItemUserCreationBody,
  DeleteItemUserCreationResponse,
  EditItemUserCreationBody,
  EditItemUserCreationResponse,
  FetchItemUserCreationItemBody,
  FetchItemUserCreationItemResponse

} from './types'

export const ARTICLE_API_URL = process.env.REACT_APP_ARTICLE_API_URL!

class UploadItemAPI extends BaseAPI {
  uploadImageS3 = async (
    body: UploadImageS3Body
  ):Promise<UploadImageS3Response> => {
    const response = await this.request('post', `/media/image`, body)

    return response
  }

  createItemUserCreation = async (
    body: CreateItemUserCreationBody
  ):Promise<CreateItemUserCreationResponse> => {
    const response = await this.request('post', `/items/create`, body)

    return response
  }

  deleteItemUserCreation = async (
    body: DeleteItemUserCreationBody
  ):Promise<DeleteItemUserCreationResponse> => {
    const response = await this.request('delete', `/items/delete`, body)
    return response
  }

  editItemUserCreation = async (
    body: EditItemUserCreationBody
  ):Promise<EditItemUserCreationResponse> => {
    const response = await this.request('put', `/items/update`, body)
    return response
  }

  fetchItemUserCreationEdit = async (
    body: FetchItemUserCreationItemBody
  ):Promise<FetchItemUserCreationItemResponse> => {
    const response = await this.request('get', `/items/my-items/${body.id}`)
    return response
  }
}

export const uploadItemApi = new UploadItemAPI(ARTICLE_API_URL)
