// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../../images/plaza.svg";
import ___CSS_LOADER_URL_IMPORT_1___ from "../../../../images/road.svg";
import ___CSS_LOADER_URL_IMPORT_2___ from "../../../../images/district.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProximityHighlights {\n  margin-bottom: 2.25rem;\n}\n\n.ProximityHighlights .Highlight .plaza {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n.ProximityHighlights .Highlight .road {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-size: 80% 80%;\n}\n\n.ProximityHighlights .Highlight .district {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n  background-size: 60% 60%;\n}\n\n.ProximityHighlights:empty {\n  margin: 0;\n}\n", "",{"version":3,"sources":["webpack://src/components/LOM/AssetPage/ProximityHighlights/ProximityHighlights.scss"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AAAA;;AADxB;EAKM,yDAAmD;AAAA;;AALzD;EASM,yDAAkD;EAClD,wBAAwB;AAAA;;AAV9B;EAcM,yDAAsD;EACtD,wBAAwB;AAAA;;AAf9B;EAoBI,SAAS;AAAA","sourcesContent":[".ProximityHighlights {\n  margin-bottom: 2.25rem;\n\n  .Highlight {\n    .plaza {\n      background-image: url(../../../../images/plaza.svg);\n    }\n\n    .road {\n      background-image: url(../../../../images/road.svg);\n      background-size: 80% 80%;\n    }\n\n    .district {\n      background-image: url(../../../../images/district.svg);\n      background-size: 60% 60%;\n    }\n  }\n\n  &:empty {\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
