import { connect } from 'react-redux'

import Menu from './Menu'

import { getCategoriesItemsNFT } from '../../../modules/itemNFT/selectors'

import { RootState } from '../../../modules/reducer'

const mapState = (state: RootState, ownProps: any): any => {
  const { pathname } = state.router.location
  const { viewMode, isShowMenu } = ownProps

  return {
    viewMode: viewMode,
    pathname: pathname,
    isShowMenu: isShowMenu,
    categoriesItemNFT: getCategoriesItemsNFT(state),
  }
}

export default connect(mapState)(Menu)
