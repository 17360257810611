import { BaseAPI } from '@wiicamp/decentraland-dapps/dist/lib/api'

import {
  ItemNFTQueryType,
  ItemsNFTResponse,
  RaritiesNFTResponse,
  SignatureSaleMyItemCreationQueryType,
  SignatureSaleMyItemCreationResponse,
  MyItemsCreationType,
  FetchTransactionHistoriesItemNFTApiQuery,
  FetchTransactionHistoriesItemNFTResponse,
  FetchMyItemsQueryType
} from './types'

export const ARTICLE_API_URL = process.env.REACT_APP_ARTICLE_API_URL!

class ItemNFTAPI extends BaseAPI {
  fetchItemsNFT = async (
    defaultQuery: ItemNFTQueryType = {}
  ): Promise<ItemsNFTResponse> => {
    const attributes = defaultQuery?.attributes || {}
    let query = { ...defaultQuery, attributes: undefined }

    Object.keys(attributes).forEach((key: string) => {
      if (attributes[key]) {
        query[key] = attributes[key].toString()
      }
    })

    // const response = await this.request('get', '/items', query)
    const response = await this.request('get', '/items/selling', query)
    return response
  }

  fetchResoldItemsNFT = async (
    defaultQuery: ItemNFTQueryType = {}
  ): Promise<ItemsNFTResponse> => {
    const attributes = defaultQuery?.attributes || {}
    let query = { ...defaultQuery, attributes: undefined }

    Object.keys(attributes).forEach((key: string) => {
      if (attributes[key]) {
        query[key] = attributes[key].toString()
      }
    })

    const response = await this.request('get', '/nfts?isOnSale=true', query)
    return response
  }

  fetchCategoriesItemsNFT = async (query: any): Promise<any> => {
    const response = await this.request('get', '/categories', query)
    return response
  }

  fetchItemNFTDetail = async (id: any): Promise<any> => {
    const response = await this.request('get', `/items/creation-items/${id}`)
    return response
  }

  fetchUserCreationItems = async (
    defaultQuery: ItemNFTQueryType = {}
  ): Promise<ItemsNFTResponse> => {
    const attributes = defaultQuery?.attributes || {}
    let query = { ...defaultQuery, attributes: undefined }

    Object.keys(attributes).forEach((key: string) => {
      if (attributes[key]) {
        query[key] = attributes[key].toString()
      }
    })

    const response = await this.request('get', '/items/selling', query)
    return response
  }

  fetchMyItems = async (
    defaultQuery: FetchMyItemsQueryType
  ): Promise<ItemsNFTResponse> => {
    const response = await this.request('get', '/nfts', defaultQuery)
    return response
  }

  fetchItemNFTListingDetail = async (defaultQuery: {id: string, contractAddress: string}): Promise<any> => {
    const response = await this.request('get', `/nfts/${defaultQuery.contractAddress}/${defaultQuery.id}`)
    return response
  }

  fetchMyItemsCreation = async (
    defaultQuery: MyItemsCreationType
  ): Promise<any> => {
    const response = await this.request('get', `/items/my-items`, defaultQuery)
    return response
  }

  fetchRaritiesItemNFT = async (): Promise<RaritiesNFTResponse> => {
    const response = await this.request('get', `/rarities`)
    return response
  }

  getSignatureSaleItemCreation = async (
    query: SignatureSaleMyItemCreationQueryType
  ):Promise<SignatureSaleMyItemCreationResponse> => {
    const response = await this.request('post', `/items/creation-items/create`, query)

    return response
  }

  fetchTransactionHistoriesItemNFT = async (
    query: FetchTransactionHistoriesItemNFTApiQuery
  ):Promise<FetchTransactionHistoriesItemNFTResponse> => {
    const response = await this.request('get', `/nfts/histories`, query)

    return response
  }
}

export const itemNFTApi = new ItemNFTAPI(ARTICLE_API_URL)
