import { locations } from '../modules/routing/locations'

// LOM's Library
import TelegramImage from '../asset/icons/telegram.svg'
import DiscordImage from '../asset/icons/discord.svg'
import TwitterImage from '../asset/icons/twitter.svg'
import MediumImage from '../asset/icons/medium.svg'
import YoutubeImage from '../asset/icons/youtube-icon.svg'
import GithubImage from '../asset/icons/github.svg'

// import { ReactComponent as IconSubMenuBotany } from '../asset/icons/ic-menu-botany.svg'
// import { ReactComponent as IconSubMenuEngineering } from '../asset/icons/ic-menu-engineering.svg'
// import { ReactComponent as IconSubMenuExploring } from '../asset/icons/ic-menu-exploring.svg'
// import { ReactComponent as IconSubMenuMinning } from '../asset/icons/ic-menu-minning.svg'
// import { ReactComponent as IconSubMenuSpacesuits } from '../asset/icons/ic-menu-spacesuits.svg'
// import { ReactComponent as IconSubMenuSupplies } from '../asset/icons/ic-menu-supplies.svg'
import { ReactComponent as IconUploadNFT } from '../asset/icons/ic-upload.svg'
import { ReactComponent as IconSubMenuEstates } from '../asset/icons/ic-menu-estates.svg'
import { ReactComponent as IconSubMenuTicket } from '../asset/icons/ic-category-ticket.svg'

export const BREADCRUMBS: {
  [key: string]: string;
} = {
  introduction: "Introduction",
  team: "My team",
  concept: "The concept",
};

export const MENU = [
  {
    name: 'Story',
    value: 'story',
    link: locations.story('introduction'),
    children: [
      {
        name: 'Introduction',
        value: 'introduction',
        link: locations.story('introduction')
      },
      {
        name: 'The Concept',
        value: 'concept',
        link: locations.story('concept')
      }
      // { name: 'THE TEAM', value: 'team', link: '/story/team' },
    ]
  },
  {
    name: 'Marketplace',
    value: 'marketplace',
    link: locations.marketplace(),
    children: [
      {
        name: 'Estates',
        value: 'estates',
        link: locations.landsLOM(),
        icon: IconSubMenuEstates
      },
      {
        name: 'Tickets',
        value: 'tickets',
        link: locations.ticketsLOM(),
        icon: IconSubMenuTicket
      },
    ]
  },
  {
    name: 'Create',
    value: 'create-nft-item',
    link: locations.createNFTItemLOM(),
    icon: IconUploadNFT,
    isOnlyDevelopment: false,
  },
  {
    name: 'Tokenomics',
    value: 'tokenomics',
    link: locations.tokenomics()
  },
  {
    name: 'Roadmap',
    value: 'roadmap',
    link: locations.roadmap()
  },
  {
    name: 'Whitepaper',
    value: 'LOM_Whitepaper.pdf',
    link: locations.whitepaper()
  },
  // {
  //   name: 'Newsfeed',
  //   value: 'newsfeed',
  //   link: locations.newsfeed()
  // }
]

export const SOCIAL_ICONS_ARRAY = [
  { iconSrc: TelegramImage, link: 'https://t.me/LifeOnMars_Global' },
  { iconSrc: DiscordImage, link: 'https://discord.com/invite/CDRvNqBSsk' },
  { iconSrc: TwitterImage, link: 'https://twitter.com/LifeOnMars_Org' },
  { iconSrc: MediumImage, link: 'https://lifeonmarsorg.medium.com/' },
  { iconSrc: YoutubeImage, link: 'https://www.youtube.com/channel/UCZt8TqtlbmF2c20u3fGZ_9g' },
  // { iconSrc: '/icon/reddit.svg', link: 'https://www.reddit.com/r/LifeOnMars_Org/' },
  { iconSrc: GithubImage, link: 'https://github.com/LifeOnMarsOrg' },
]
export const ITEM_STATUS = {
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',

  APPROVE: 'approve',
  REJECT: 'reject'
}

export const RARITY = {
  UNIQUE: "unique",
  MYTHIC: "mythic",
  LEGENDARY: "legendary",
  EPIC: "epic",
  RARE: "rare",
  UNCOMMON: "uncommon",
  COMMON: "common",
}

export const mapNumberToRarity = ['', RARITY.UNIQUE, RARITY.MYTHIC, RARITY.LEGENDARY, RARITY.EPIC, RARITY.RARE, RARITY.UNCOMMON, RARITY.COMMON]
