import React, { useCallback } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { Loader } from '@wiicamp/decentraland-ui'

import { AssetCard } from '../AssetCard'
// import { Asset } from '../../../modules/asset/types'

import { sectionDefaultProps, SectionProps } from './MarketplacePage.types'

import { customScroll, customTrackHorizontal } from '../../../lib/customScrollbars'

import ArrowIcon from '../../../asset/images/tools/arrow-right-bottom.svg'
import { ReactComponent as IconArrow } from '../../../asset/icons/ic-arrow.svg'

const Section = (props: SectionProps) => {
  const {
    title, assets, isLoading, onViewAll,
    thumbClassName, thumbVisible
    // onFetchNFT, onFetchItem, toggleModalDetailEstate
  } = props

  // const onOpenModalDetailEstate = (asset?: Asset) => () => {
  //   if (asset && asset.id.length) {
  //     if ('tokenId' in asset) {
  //       onFetchNFT(asset.contractAddress, asset.tokenId)
  //       toggleModalDetailEstate({
  //         isOpenModalDetailEstate: true,
  //         isNFTToken: true,
  //         assetId: asset.id
  //       })
  //     } else if ('itemId' in asset) {
  //       onFetchItem(asset.contractAddress, asset.itemId)
  //       toggleModalDetailEstate({
  //         isOpenModalDetailEstate: true,
  //         isNFTToken: false,
  //         assetId: asset.id
  //       })
  //     }
  //   }
  // }

  const renderNfts = () =>
    assets?.map((asset, index) => (
      <AssetCard
        key={index}
        asset={asset}
      // onClick={onOpenModalDetailEstate(asset)} // Disable Popup
      />
    ))

  const renderThumb = useCallback((thumbProps) => {
    if (!thumbVisible) return <div />;

    return customScroll({ ...thumbProps, className: thumbClassName });
  }, [thumbVisible, thumbClassName]);

  const renderTrackHorizontal = useCallback((trackProps) => {
    if (!thumbVisible) return <div />;

    return customTrackHorizontal(trackProps);
  }, [thumbVisible]);

  return (
    <div className="Marketplace__section Marketplace__estates-featured text-white">
      <div className="relative">
        <div className="Marketplace__sectionHead flex items-center">
          <div className="flex items-center">
            <img
              src={ArrowIcon}
              alt="arrow"
              className="Marketplace__sectionImage"
            />
            <h2>{title}</h2>
          </div>
          <div className="ml-auto flex justify-end">
            <button onClick={onViewAll}>
              See all <span className="ml-1 text-xl"><IconArrow /></span>
            </button>
          </div>
        </div>

        <Scrollbars
          renderThumbVertical={renderThumb}
          renderThumbHorizontal={renderThumb}
          renderTrackHorizontal={renderTrackHorizontal}
          style={{ width: '100%', height: 'calc(22rem)' }}
        >
          <div className="Marketplace__listItems flex flex-nowarp">
            {isLoading ? (
              assets?.length === 0 ? (
                <Loader active size="massive" />
              ) : (
                renderNfts()
              )
            ) : assets?.length !== 0 ? (
              renderNfts()
            ) : null}
          </div>
        </Scrollbars>
      </div>
    </div>
  )
}

Section.defaultProps = sectionDefaultProps;

export default React.memo(Section)
