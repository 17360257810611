import { RootState } from '../reducer'

export const getState = (state: RootState) => state.itemNFT
export const getItemsNFT = (state: RootState) => getState(state).data
export const getItemNFT = (state: RootState) => getState(state).itemDetail
export const getLoading = (state: RootState) => getState(state).loading
export const getFetchItemsMarketplaceLoading = (state: RootState) => getState(state).fetchItemsMarketplaceLoading
export const getCategoriesItemsNFT = (state: RootState) => getState(state).categories
export const getCategoriesItemsNFTLoading = (state: RootState) => getState(state).categoriesLoading
export const getError = (state: RootState) => getState(state).error
export const getPage = (state: RootState) => getState(state).page

export const getBuyItemUserCreationLoading = (state: RootState) => getState(state).isBuyItemUserCreationLoading
export const getBuyResoldItemLoading = (state: RootState) => getState(state).buyResoldItemLoading
export const getListForSaleItemLoading = (state: RootState) => getState(state).listForSaleItemLoading
export const getTransferItemLoading = (state: RootState) => getState(state).transferItemLoading
export const getCancelSaleItemLoading = (state: RootState) => getState(state).cancelSaleItemLoading

export const getMyItems = (state: RootState) => getState(state).myItemsData
export const getMyItemsLoading = (state: RootState) => getState(state).myItemsLoading

export const getItemNFTListingLoading = (state: RootState) => getState(state).itemNFTListingDetailLoading

export const getItemsUserCreationOnSaleNeedLoading = (state: RootState) => getState(state).itemsUserCreationOnSaleNeedLoading
export const getSaleItemUserCreationLoading = (state: RootState) => getState(state).saleItemUserCreationLoading

export const getRaritiesItemsNFT = (state: RootState) => getState(state).rarities
export const getRaritiesItemsNFTLoading = (state: RootState) => getState(state).raritiesLoading

export const getTransactionHistoriesItemNFT = (state: RootState) => getState(state).transactionHistories
export const getTransactionHistoriesItemNFTLoading = (state: RootState) => getState(state).transactionHistoriesLoading
