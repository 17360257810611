import { connect } from 'react-redux'
import { isLoadingType } from '@wiicamp/decentraland-dapps/dist/modules/loading/selectors'

import { RootState } from '../../../modules/reducer'
import { setView } from '../../../modules/ui/actions'
import { browse, fetchAssetsFromRoute } from '../../../modules/routing/actions'
import { FETCH_NFTS_REQUEST } from '../../../modules/nft/actions'
import { getLoading as getLoadingNFTs } from '../../../modules/nft/selectors'
import { getLoading as getLoadingItems } from '../../../modules/item/selectors'
import {
  getIsMap,
  getOnlyOnSale,
  getAssetType,
  getSection
} from '../../../modules/routing/selectors'
import { getView } from '../../../modules/ui/browse/selectors'
import { FETCH_ITEMS_REQUEST } from '../../../modules/item/actions'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
  OwnProps,
  Props
} from './AssetBrowse.types'
import AssetBrowse from './AssetBrowse'
import { Section } from '../../../modules/vendor/decentraland'
import { getTotalNFTItems, getIsLoadingFetchMyItemsCreation } from '../../../modules/uploadItem/selectors'
import { fetchMyItemsCreationRequest } from '../../../modules/uploadItem/actions'
import { fetchMyItemsRequest, FETCH_MY_ITEMS_REQUEST } from '../../../modules/itemNFT/actions'
import { getMyItems, getMyItemsLoading } from '../../../modules/itemNFT/selectors'

const mapState = (state: RootState): MapStateProps => ({
  isMap: getIsMap(state),
  onlyOnSale: getOnlyOnSale(state),
  section: getSection(state) as Section,
  isLoading:
    isLoadingType(getLoadingNFTs(state), FETCH_NFTS_REQUEST) ||
    isLoadingType(getLoadingItems(state), FETCH_ITEMS_REQUEST),
  assetType: getAssetType(state),
  viewInState: getView(state),

  // LOM NFT Items
  nftItems: getTotalNFTItems(state),
  nftItemsLoading: getIsLoadingFetchMyItemsCreation(state),
  // Account my items
  myItemsLoading: isLoadingType(getMyItemsLoading(state), FETCH_MY_ITEMS_REQUEST),
  myItems: getMyItems(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onSetView: view => dispatch(setView(view)),
  onFetchAssetsFromRoute: (options, callblack) => dispatch(fetchAssetsFromRoute(options, callblack)),
  onBrowse: options => dispatch(browse(options)),
  // Account fetch my items creation
  fetchMyItemsCreation: (option) => dispatch(fetchMyItemsCreationRequest(option)),
  // Account my items
  fetchMyItems: (query) => dispatch(fetchMyItemsRequest(query)),
})

const mergeProps = (
  stateProps: MapStateProps,
  dispatchProps: MapDispatchProps,
  ownProps: OwnProps
): Props => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  section: ownProps.section ?? stateProps.section,
  isMap: stateProps.isMap ?? ownProps.isMap,
})

export default connect(mapState, mapDispatch, mergeProps)(AssetBrowse)
