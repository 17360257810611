import Web3 from 'web3'
import { put, takeEvery, select } from '@redux-saga/core/effects'

import { toast } from 'react-toastify'

import { call } from 'redux-saga/effects'

import getContracts from '../../lib/contracts'

import { itemNFTApi } from '../vendor/itemNFT'

import { getWallet } from '../wallet/selectors'
import { getCategoriesItemsNFT, getRaritiesItemsNFT } from './selectors'

import { addressEquals } from '../wallet/utils'
import { convertCategoryStringToNumber, convertSubCategoryStringToNumber, getObjectFromApiCategories } from './utils'

import {
  // Items NFT
  FETCH_ITEMS_NFT_REQUEST,
  FetchItemsNFTRequestAction,
  fetchItemsNFTSuccess,
  fetchItemsNFTFailure,

  // Resold Items NFT
  FETCH_RESOLD_ITEMS_NFT_REQUEST,
  FetchResoldItemsNFTRequestAction,
  fetchResoldItemsNFTSuccess,
  fetchResoldItemsNFTFailure,

  // Categories
  FETCH_CATEGORIES_ITEMS_NFT_REQUEST,
  FetchCategoriesItemsNFTRequestAction,
  fetchCategoriesItemsNFTSuccess,
  fetchCategoriesItemsNFTFailure,

  // Detail ItemNFT
  FETCH_ITEM_NFT_DETAIL_REQUEST,
  FetchItemNFTDetailRequestAction,
  fetchItemNFTDetailSuccess,
  fetchItemNFTDetailFailure,

  // User creation items
  FETCH_USER_CREATION_ITEMS_REQUEST,
  FetchUserCreationItemsRequestAction,
  fetchUserCreationItemsSuccess,
  fetchUserCreationItemsFailure,

  // Buy Item User Creation
  BUY_ITEM_USER_CREATION_REQUEST,
  BuyItemUserCreationRequestAction,
  buyItemUserCreationSuccess,
  buyItemUserCreationFailure,

  // Buy resold item
  BUY_RESOLD_ITEM_REQUEST,
  BuyResoldItemRequestAction,
  buyResoldItemSuccess,
  buyResoldItemFailure,

  // Fetch my items
  FETCH_MY_ITEMS_REQUEST,
  // FetchMyItemsRequestAction,
  fetchMyItemsSuccess,
  fetchMyItemsFailure,

  // Fetch itemNFT listing detail
  FETCH_ITEM_NFT_LISTING_DETAIL_REQUEST,
  FetchItemNFTListingDetailRequestAction,
  fetchItemNFTListingDetailSuccess,
  fetchItemNFTListingDetailFailure,

  // List for sale item
  LIST_FOR_SALE_ITEM_REQUEST,
  ListForSaleItemRequestAction,
  listForSaleItemSuccess,
  listForSaleItemFailure,

  // Transfer item
  TRANSFER_ITEM_REQUEST,
  TransferItemRequestAction,
  transferItemSuccess,
  transferItemFailure,

  // Cancel sale item
  CANCEL_SALE_ITEM_REQUEST,
  CancelSaleItemRequestAction,
  cancelSaleItemSuccess,
  cancelSaleItemFailure,

  // Sale item user creation
  SALE_ITEM_USER_CREATION_REQUEST,
  SaleItemUserCreationRequestAction,
  saleItemUserCreationSuccess,
  saleItemUserCreationFailure,

  // fetch rarities
  FETCH_RARITIES_ITEMS_NFT_REQUEST,
  fetchRaritiesItemsNFTSuccess,
  fetchRaritiesItemsNFTFailure,

  // fetch transaction history item nft
  FETCH_TRANSACTION_HISTORIES_ITEM_NFT_REQUEST,
  fetchTransactionHistoriesItemNFTSuccess,
  fetchTransactionHistoriesItemNFTFailure,
  FetchTransactionHistoriesItemNFTRequestAction,
  FetchMyItemsRequestAction,
} from './actions'

import { CategoriesType, ItemNFTType, RarityNFTType, TransactionHistoryItemNFTType } from './types'

export const defaultWalletAddress = '0x0000000000000000000000000000000000000000';

export function* itemNFTSaga() {
  yield takeEvery(FETCH_ITEMS_NFT_REQUEST, handleFetchItemsNFT)
  yield takeEvery(FETCH_RESOLD_ITEMS_NFT_REQUEST, handleFetchResoldItemsNFT)
  yield takeEvery(FETCH_CATEGORIES_ITEMS_NFT_REQUEST, handleFetchCategoriesItemsNFT)
  yield takeEvery(FETCH_ITEM_NFT_DETAIL_REQUEST, handleFetchItemsNFTDetail)
  yield takeEvery(FETCH_USER_CREATION_ITEMS_REQUEST, handleFetchUserCreationItems)
  yield takeEvery(BUY_ITEM_USER_CREATION_REQUEST, handleBuyItemUserCreation)
  yield takeEvery(BUY_RESOLD_ITEM_REQUEST, handleBuyResoldItem)
  yield takeEvery(FETCH_MY_ITEMS_REQUEST, handleFetchMyItems)
  yield takeEvery(FETCH_ITEM_NFT_LISTING_DETAIL_REQUEST, handleFetchItemsNFTListingDetail)
  yield takeEvery(LIST_FOR_SALE_ITEM_REQUEST, handleListForSaleItem)
  yield takeEvery(TRANSFER_ITEM_REQUEST, handleTransferItem)
  yield takeEvery(CANCEL_SALE_ITEM_REQUEST, handleCancelSaleItem)
  yield takeEvery(SALE_ITEM_USER_CREATION_REQUEST, handleSaleItemUserCreation)
  yield takeEvery(FETCH_RARITIES_ITEMS_NFT_REQUEST, handleFetchRaritiesItemsNFT)
  yield takeEvery(FETCH_TRANSACTION_HISTORIES_ITEM_NFT_REQUEST, handleFetchTransactionHistoriesItemsNFT)
}

function* handleFetchTransactionHistoriesItemsNFT(action: FetchTransactionHistoriesItemNFTRequestAction) {
  const { callback, itemId, contractAddress, isRefreshed } = action.payload

  const limit = action.payload.limit || 10
  const page = action.payload.page || 1

  const query = {
    itemId, contractAddress, limit, page
  }

  try {
    const { payload: histories }: { payload: TransactionHistoryItemNFTType[] } = yield call(
      [itemNFTApi, 'fetchTransactionHistoriesItemNFT'], query)

    yield put(fetchTransactionHistoriesItemNFTSuccess(histories, isRefreshed))
    callback?.(histories)
  } catch (error) {
    yield put(fetchTransactionHistoriesItemNFTFailure(error))
  }
}

function* handleFetchRaritiesItemsNFT() {
  try {
    const { payload: rarities }: { payload: RarityNFTType[] } = yield call(
      [itemNFTApi, 'fetchRaritiesItemNFT'])

    yield put(fetchRaritiesItemsNFTSuccess(rarities))
  } catch (error) {
    yield put(fetchRaritiesItemsNFTFailure(error))
  }
}

function* handleSaleItemUserCreation(action: SaleItemUserCreationRequestAction) {
  const { item, price } = action.payload

  try {
    const wallet: ReturnType<typeof getWallet> = yield select(getWallet)

    if (!wallet) {
      throw new Error('Please connect your wallet!')
    }

    let rarities: ReturnType<typeof getRaritiesItemsNFT> = yield select(getRaritiesItemsNFT)

    if (rarities.length === 0) {
      const { payload }: { payload: RarityNFTType[] } = yield call(
        [itemNFTApi, 'fetchRaritiesItemNFT'])

      rarities = payload
    }

    const maxItem = rarities.find((x: RarityNFTType) => x.id === +(item.rarity ?? -1))?.maxItem;

    const { signature }: { signature: string } = yield call(
      [itemNFTApi, 'getSignatureSaleItemCreation'], {
      itemId: item._id, itemPrice: price
    })

    const formattedPrice = Web3.utils.toWei(price.toString())

    const { lomItemContract } = getContracts();

    yield lomItemContract.methods
      .addCustoItemType(
        item.name,
        parseInt(item.category.id, 10),
        parseInt(item.assetType, 10) || 0,
        parseInt(item.rarity, 10),
        parseInt(item.attribute, 10) || 0,
        maxItem,
        formattedPrice,
        item.creator,
        signature,
      )
      .send({ from: wallet.address });

    toast.success('On sale item successfully!');

    yield put(saleItemUserCreationSuccess(item))
  } catch (error) {
    yield put(saleItemUserCreationFailure(error, item))
  }
}

function* handleCancelSaleItem(action: CancelSaleItemRequestAction) {
  const { item, callback } = action.payload

  try {
    const wallet: ReturnType<typeof getWallet> = yield select(getWallet)

    if (!wallet) {
      throw new Error('Please connect your wallet!')
    }

    let newItem: any = { ...item }

    if (!item.listingId) {
      const { payload }: { payload: ItemNFTType } = yield call(
        [itemNFTApi, 'fetchItemNFTListingDetail'],
        { id: item.tokenId, contractAddress: item.tokenAddress })

      newItem = { ...payload }

      if (!newItem.listingId) {
        throw new Error('Something went wrong! Please try again.')
      }
    }

    const { lomMarketplaceContract } = getContracts()

    yield lomMarketplaceContract.methods.cancel(newItem.listingId).send({
      from: wallet.address,
    });

    toast.success('Cancel sale item successfully!');

    yield put(cancelSaleItemSuccess(item))

    // close modal when finished
    callback()
  } catch (error) {
    yield put(cancelSaleItemFailure(error))
  }
}

function* handleTransferItem(action: TransferItemRequestAction) {
  const { item, toWalletAddress } = action.payload

  try {
    const wallet: ReturnType<typeof getWallet> = yield select(getWallet)

    if (!wallet) {
      throw new Error('Please connect your wallet!')
    }

    const { lomItemContract } = getContracts();

    const itemOwner: string = yield lomItemContract.methods.ownerOf(item.tokenId).call();

    if (!addressEquals(wallet.address, itemOwner)) {
      const error = "You're not own this item."
      toast.error(error);
      yield put(transferItemFailure(error));
      return;
    }

    const sendModel = { from: wallet.address };

    yield lomItemContract.methods.safeTransferFrom(
      wallet.address,
      toWalletAddress,
      item.tokenId,
    ).send(sendModel);

    toast.success('Transfer item successfully!');

    yield put(transferItemSuccess(item, toWalletAddress))
  } catch (error) {
    yield put(transferItemFailure(error))
  }
}

function* handleListForSaleItem(action: ListForSaleItemRequestAction) {
  const { item, price } = action.payload;

  try {
    const wallet: ReturnType<typeof getWallet> = yield select(getWallet)

    if (!wallet) {
      throw new Error('Please connect your wallet!')
    }

    const { lomItemContract, lomMarketplaceContract } = getContracts();

    const itemOwner: string = yield lomItemContract.methods.ownerOf(item.tokenId).call();

    if (!addressEquals(wallet.address, itemOwner)) {
      const error = "You're not own this item."
      toast.error(error);
      yield put(listForSaleItemFailure(error));
      return;
    }
    const sendModel = { from: wallet.address };

    const approvedAddress: string = yield lomItemContract.methods.getApproved(item.tokenId).call();

    const isUnApproved = addressEquals(approvedAddress, defaultWalletAddress);

    if (isUnApproved) {
      yield lomItemContract.methods
        .approve(process.env.REACT_APP_LOM_MARKETPLACE_ADDRESS, item.tokenId)
        .send(sendModel);
    }

    if (+price <= parseInt(process.env.REACT_APP_ON_SALE_MIN_PRICE || '0', 10) || +price >= parseInt(process.env.REACT_APP_ON_SALE_MAX_PRICE || '0', 10)) {
      const error = `Price must greater than ${process.env.REACT_APP_ON_SALE_MIN_PRICE} and less than ${process.env.REACT_APP_ON_SALE_MAX_PRICE}.`
      toast.error(error);
      yield put(listForSaleItemFailure(error));
      return;
    }

    const formattedPrice = BigInt(+price * (10 ** 18)).toString();

    yield lomMarketplaceContract.methods
      .listToken(item.tokenAddress, item.tokenId, formattedPrice)
      .send(sendModel);

    const { payload }: { payload: ItemNFTType[] } = yield call(
      [itemNFTApi, 'fetchItemNFTListingDetail'],
      { id: item.tokenId, contractAddress: item.tokenAddress })

    yield put(listForSaleItemSuccess(payload, price))

    toast.success('List for sale successfully!')
  } catch (error) {
    yield put(listForSaleItemFailure(error))
  }
}

function* handleFetchItemsNFTListingDetail(action: FetchItemNFTListingDetailRequestAction) {
  const { id, contractAddress } = action?.payload || {}

  try {
    const { payload: item }: { payload: ItemNFTType[] } = yield call([itemNFTApi, 'fetchItemNFTListingDetail'], { id, contractAddress })

    yield put(fetchItemNFTListingDetailSuccess(item))
  } catch (error) {
    yield put(fetchItemNFTListingDetailFailure(error))
  }
}

function* handleFetchMyItems(action: FetchMyItemsRequestAction) {
  const {
    isRefreshed = true,
    limit,
    page = 1,
    callback
  } = action?.payload || {}

  try {
    const wallet: ReturnType<typeof getWallet> = yield select(getWallet)

    if (!wallet) {
      throw new Error('Please connect your wallet!')
    }

    const { payload: items }: { payload: ItemNFTType[] } = yield call(
      [itemNFTApi, 'fetchMyItems'],
      { limit, page, owner: wallet.address }
    )

    yield put(fetchMyItemsSuccess(items, isRefreshed))
    callback?.(items)
  } catch (error: any) {
    yield put(fetchMyItemsFailure(error.message))
  }
}

function* handleBuyResoldItem(action: BuyResoldItemRequestAction) {
  const { item } = action.payload

  try {
    const wallet: ReturnType<typeof getWallet> = yield select(getWallet)

    if (!wallet) {
      throw new Error('Please connect your wallet!')
    }

    const { LOMContract, lomMarketplaceContract } = getContracts();

    // const itemOwner: string = yield lomItemContract.methods.ownerOf(item.tokenId).call();

    // if (addressEquals(wallet.address, itemOwner)) {
    if (item.creator === wallet.address) {
      const error = "You're own this item."
      toast.error(error);
      yield put(buyResoldItemFailure(error));
      return;
    }

    const allowanceResult: string = yield LOMContract.methods
      .allowance(wallet.address, process.env.REACT_APP_LOM_MARKETPLACE_ADDRESS)
      .call();

    const sendModel = { from: wallet.address }

    if (allowanceResult === '0') {
      // const formattedPrice = BigInt(item.price * (10 ** 18)).toString();

      yield LOMContract.methods
        .approve(process.env.REACT_APP_LOM_MARKETPLACE_ADDRESS,
          '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff') // unlimited price
        .send(sendModel);
    }

    yield lomMarketplaceContract.methods
      .buyToken(item.listingId)
      .send(sendModel);

    toast.success('Buy item successfully!');

    yield put(buyResoldItemSuccess(item, wallet.address))
  } catch (error: any) {
    yield put(buyResoldItemFailure(error.message))
  }
}

function* handleBuyItemUserCreation(action: BuyItemUserCreationRequestAction) {
  try {
    const wallet: ReturnType<typeof getWallet> = yield select(getWallet)

    if (!wallet) {
      throw new Error('A defined wallet is required to generate a ticket')
    }

    const { LOMContract, lomItemContract} = getContracts();

    const { item } = action.payload;

    const isAllowance: string = yield LOMContract.methods
    .allowance(
      wallet.address,
      process.env.REACT_APP_LOM_ITEM_ADDRESS,
    ).call();

    if (isAllowance === '0') {
      yield LOMContract.methods
        .approve(
          process.env.REACT_APP_LOM_ITEM_ADDRESS,
          '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
        )
        .send({ from: wallet.address });
    }

    yield lomItemContract.methods.mint(item.itemId).send({ from: wallet.address });

    toast.success('Buy item successfully!');

    yield put(buyItemUserCreationSuccess())
  } catch (error: any) {
    yield put(buyItemUserCreationFailure(error.message))
  }
}

function* handleFetchResoldItemsNFT(action: FetchResoldItemsNFTRequestAction) {
  const {
    page = 1,
    limit,
    category,
    subCategory,
    isRefreshed = true,
    q,
    attributes,
    callback
  } = action?.payload || {}

  try {
    let categories: ReturnType<typeof getCategoriesItemsNFT> = yield select(getCategoriesItemsNFT)

    if (categories.length === 0) {
      const { payload: items }: { payload: any } = yield call(
        [itemNFTApi, 'fetchCategoriesItemsNFT'],
        { type: {} }
      )

      categories = items
    }

    const newCategoryNumber = convertCategoryStringToNumber(categories, category)
    const newSubCategoryNumber = convertSubCategoryStringToNumber(categories, category, subCategory)

    const { payload: items }: { payload: ItemNFTType[] } = yield call(
      [itemNFTApi, 'fetchResoldItemsNFT'],
      { page, category: newCategoryNumber, assetType: newSubCategoryNumber, q, attributes, limit }
      )

    yield put(fetchResoldItemsNFTSuccess(items, isRefreshed))
    callback?.(items)
  } catch (error: any) {
    yield put(fetchResoldItemsNFTFailure(error.message))
  }
}

function* handleFetchItemsNFT(action: FetchItemsNFTRequestAction) {
  const {
    page = 1,
    category,
    subCategory,
    isRefreshed = true,
    q,
    attributes,
    callback
  } = action?.payload || {}

  try {
    let categories: ReturnType<typeof getCategoriesItemsNFT> = yield select(getCategoriesItemsNFT)

    if (categories.length === 0) {
      const { payload: items }: { payload: CategoriesType[] } = yield call(
        [itemNFTApi, 'fetchCategoriesItemsNFT'],
        { type: {} }
      )

      categories = items
    }

    const newCategoryNumber = convertCategoryStringToNumber(categories, category)
    const newSubCategoryNumber = convertSubCategoryStringToNumber(categories, category, subCategory)

    const { payload: items }: { payload: ItemNFTType[] } = yield call(
      [itemNFTApi, 'fetchItemsNFT'],
      { page, category: newCategoryNumber, assetType: newSubCategoryNumber, q, attributes }
    )

    const newItems = items.map((item: any) => {
      const { category, subCategory } = getObjectFromApiCategories(categories, item.category, item.assetType)

      return ({
        ...item,
        itemId: item.itemType,
        category,
        subCategory,
      })
    });

    yield put(fetchItemsNFTSuccess(newItems, isRefreshed))
    callback?.(newItems)
  } catch (error: any) {
    yield put(fetchItemsNFTFailure(error.message))
  }
}

function* handleFetchCategoriesItemsNFT(
  action: FetchCategoriesItemsNFTRequestAction
) {
  const { type } = action?.payload || {}

  try {
    const { payload: items } : { payload: any } = yield call(
      [itemNFTApi, 'fetchCategoriesItemsNFT'],
      { type }
    )

      yield put(fetchCategoriesItemsNFTSuccess(items))
  } catch (error: any) {
    yield put(fetchCategoriesItemsNFTFailure(error.message))
  }
}

function* handleFetchItemsNFTDetail(action: FetchItemNFTDetailRequestAction) {
  const { id } = action?.payload || {}

  try {
    const { payload: item }: { payload: ItemNFTType[] } = yield call([itemNFTApi, 'fetchItemNFTDetail'], id)

    yield put(fetchItemNFTDetailSuccess(item))
  } catch (error) {
    yield put(fetchItemNFTDetailFailure(error))
  }
}

function* handleFetchUserCreationItems(action: FetchUserCreationItemsRequestAction) {
  const {
    page = 1,
    limit,
    category,
    subCategory,
    isRefreshed = true,
    q,
    attributes,
    callback
  } = action?.payload || {}

  try {
    let categories: ReturnType<typeof getCategoriesItemsNFT> = yield select(getCategoriesItemsNFT)

    if (categories.length === 0) {
      const { payload: items }: { payload: any } = yield call(
        [itemNFTApi, 'fetchCategoriesItemsNFT'],
        { type: {} }
      )

      categories = items
    }

    const newCategoryNumber = convertCategoryStringToNumber(categories, category)
    const newSubCategoryNumber = convertSubCategoryStringToNumber(categories, category, subCategory)

    const { payload: items }: { payload: ItemNFTType[] } = yield call(
      [itemNFTApi, 'fetchUserCreationItems'],
      { limit, page, category: newCategoryNumber, assetType: newSubCategoryNumber, q, attributes }
      )

    yield put(fetchUserCreationItemsSuccess(items, isRefreshed))
    callback?.(items)
  } catch (error: any) {
    yield put(fetchUserCreationItemsFailure(error.message))
  }
}
