import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import { RootState } from '../../../../modules/reducer'

import {
  MapStateProps,
  MapDispatchProps,
  MapDispatch
} from './EstateDetail.types'
import {
  setLandToEstateRequest,
  removeLandFromEstateRequest,
  GET_LANDS_BY_WALLET_ADDRESS_REQUEST,
  getLandsByWalletAddressRequest,
} from '../../../../modules/estate/actions'

import EstateDetail from './EstateDetail'
import { getWallet } from '../../../../modules/wallet/selectors'
import { isLoadingType } from '@wiicamp/decentraland-dapps/dist/modules/loading/selectors'
import {
  getIsLoadingGetLandsByWalletAddress,
  getLands,
  getPageLands,
  getTotalLands,
} from '../../../../modules/estate/selectors'

const mapState = (state: RootState): MapStateProps => ({
  wallet: getWallet(state),

  isLoadingGetLandsByWalletAddress: isLoadingType(getIsLoadingGetLandsByWalletAddress(state), GET_LANDS_BY_WALLET_ADDRESS_REQUEST),
  lands: getLands(state),
  pageLands: getPageLands(state),
  totalLands: getTotalLands(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onNavigate: path => dispatch(push(path)),

  onSetLandToEstate: (payload: any) => dispatch(setLandToEstateRequest(payload)),
  onRemoveLandFromEstate: (payload: any) => dispatch(removeLandFromEstateRequest(payload)),
  dispatchGetLandsByWalletAddress: (state: RootState) => dispatch(getLandsByWalletAddressRequest(state)),
})

export default connect(mapState, mapDispatch)(EstateDetail)
