import { connect } from 'react-redux'
import { isLoadingType } from '@wiicamp/decentraland-dapps/dist/modules/loading/selectors'

import AccountSettingsPage from './AccountSettingsPage'

import { RootState } from '../../../modules/reducer'
import { getWallet } from '../../../modules/wallet/selectors'
import {
  getLoadingGetUserSignMessage,
  getLoadingSetUserSignMetamask,
  getLoadingSetVerifyWalletToAccount,
  getLoadingUpdateEmailAndUsernameOfUser,
  getLoadingUpdateUserPassword,
  getToken,
  getUser,
} from '../../../modules/authentication/selectors'
import {
  GET_USER_SIGN_MESSAGE_REQUEST,
  setUpdateUserPasswordRequest,
  SET_UPDATE_USER_PASSWORD_REQUEST,
  SET_USER_SIGN_METAMASK_REQUEST,
  SET_VERIFY_WALLET_TO_ACCOUNT_REQUEST,
  updateEmailAndUsernameOfUserRequest,
  UPDATE_EMAIL_AND_USERNAME_OF_USER_REQUEST
} from '../../../modules/authentication/actions'

import { UpdateEmailAndUsernameOfUserPayload } from '../../../modules/authentication/types'
import { MapDispatch, MapDispatchProps, MapStateProps } from './AccountSettingsPage.types'

const mapState = (state: RootState): MapStateProps => ({
  wallet: getWallet(state),
  token: getToken(state),
  userConnectWithWallet: getUser(state),
  isLoadingUpdateEmailAndUsernameOfUser : isLoadingType(getLoadingUpdateEmailAndUsernameOfUser(state), UPDATE_EMAIL_AND_USERNAME_OF_USER_REQUEST),
  isLoadingGetUserSignMessage : isLoadingType(getLoadingGetUserSignMessage(state), GET_USER_SIGN_MESSAGE_REQUEST),
  isLoadingSetUserSignMetamask : isLoadingType(getLoadingSetUserSignMetamask(state), SET_USER_SIGN_METAMASK_REQUEST),
  isLoadingSetVerifyWalletToAccount : isLoadingType(getLoadingSetVerifyWalletToAccount(state), SET_VERIFY_WALLET_TO_ACCOUNT_REQUEST),
  isLoadingUpdateUserPassword : isLoadingType(getLoadingUpdateUserPassword(state), SET_UPDATE_USER_PASSWORD_REQUEST),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  dispatchSetUpdateUserPasswordRequest: (payload: any) => dispatch(setUpdateUserPasswordRequest(payload)),
  updateEmailAndUsernameOfUser: (payload: UpdateEmailAndUsernameOfUserPayload) => dispatch(updateEmailAndUsernameOfUserRequest(payload)),
})

export default connect(mapState, mapDispatch)(AccountSettingsPage)
