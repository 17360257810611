import { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import classNames from 'classnames'

import { locations } from '../../../modules/routing/locations'

import { ReactComponent as IconDefault } from '../../../asset/icons/ic-menu-spacesuits.svg'

import { MENU } from '../../../lib/constant'
import { isDevelopment } from '../../../lib/environment'

import { MenuItem, MenuItemChild, Props } from './Menu.types'
import './Menu.scss'

const DEFAULT_SUB_MARKET_MENU_LENGTH = MENU[1].children?.length

const Menu = (props: Props & any) => {
  const { viewMode, pathname, isShowMenu, categoriesItemNFT } = props
  const history = useHistory()
  const location = useLocation()
  const currentCategory = new URLSearchParams(location.search).get('category')
  const path = pathname?.split('/')
  const [finalMENU, setFinalMenu] = useState<any[]>([...MENU])
  const [fetchedCategoriesIcon, setFetchedCategoriesIcon] = useState<any>(null)
  const [isFetchSVG, setIsFetchSVG] = useState(false)

  const handleChangePage = useCallback((link: string) => {
    if (link) {
      history.push(link)
    }
  }, [history])

  const renderIcon = useCallback((menuItemChild: any) => {
    if (menuItemChild.icon === 'default') {
      return (<IconDefault className="w-4 h-4 mr-2" />)
    }

    if (menuItemChild.icon === 'api') {
      return (<div className="w-4 h-4 mr-2 api-svg" dangerouslySetInnerHTML={{ __html: fetchedCategoriesIcon?.[menuItemChild.value] }} />)
    }

    return (<menuItemChild.icon className="w-4 h-4 mr-2" />)
  }, [fetchedCategoriesIcon])

  const onFetchCategoriesIcon = async () => {
    let listPromises: any[] = []
    let listKeys: any[] = []
    const listCategoriesIcon: any = {}

    categoriesItemNFT.forEach((category: any) => {
      const resSVG = !!category?.icon?.length ? fetch(category.icon).then(res => res.text()) : undefined
      listPromises.push(resSVG)
      listKeys.push(category.slug)
    })

    const listFetchedIcon = await Promise.all(listPromises)
    listKeys.forEach((key, index) => listCategoriesIcon[key] = listFetchedIcon[index])

    setFetchedCategoriesIcon(listCategoriesIcon)

    const subbCategoriesFromAPI: any[] = categoriesItemNFT.map((category: any) => {
      return ({
        name: category.name,
        value: category.slug,
        link: locations.itemsNFTPage(category.slug),
        icon: !!listCategoriesIcon?.[category.slug]?.length ? 'api' : 'default',
      })
    })

    if (MENU[1]?.children?.length === DEFAULT_SUB_MARKET_MENU_LENGTH) {
      MENU[1]?.children?.push?.(...subbCategoriesFromAPI)
      setFinalMenu([...MENU])
    }
  }

  useEffect(() => {
    if (!!categoriesItemNFT?.length && !isFetchSVG) {
      setIsFetchSVG(true)
      onFetchCategoriesIcon()
    }
  }, [categoriesItemNFT]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={classNames(
        'lg:flex hidden',
        'MenuLOM__menuCol',
        'flex items-end max-w-lg transition-width duration-500 overflow-hidden',
        isShowMenu && 'show'
      )}
    >
      <div className="MenuLOM__menu">
        <ul>
        {finalMENU.map((menuItem: MenuItem, i: number) => {
            const isActiveMenu = path[1] === menuItem?.value ? 'active' : ''
            const isShowMenu =
              path[1] === menuItem?.value && path?.length === 2 ? 'show' : ''

              if (menuItem.isOnlyDevelopment && !isDevelopment) {
                return null
              }

            return (
              <li key={`menuItem-${i}`}>
                <div onClick={() => handleChangePage(menuItem?.link || '')}>
                  <p className={`${viewMode} ${isActiveMenu} ${isShowMenu}`}>
                    {menuItem?.name}
                  </p>
                </div>

                <div className="flex">
                  <ul
                    className={`${
                      menuItem?.children && (menuItem?.children?.length > 0 )
                        ? isActiveMenu
                        : ''
                    }`}
                  >
                    {menuItem?.children?.map(
                      (menuItemChild: MenuItemChild, j: number) => {
                        const isActiveMenuSub =
                          path[2] === menuItemChild?.value || currentCategory === menuItemChild?.value ? 'active' : ''

                        return (
                          <li
                            key={`menuItemChild-${j}`}
                            className={`ml-6 flex items-center ${isActiveMenuSub}`}
                            onClick={() => {
                              handleChangePage(menuItemChild?.link || '')
                            }}
                          >
                            {menuItemChild?.icon && (
                              renderIcon(menuItemChild)
                            )}

                            <p className={`${viewMode} ${isActiveMenuSub}`}>
                              {menuItemChild?.name}
                            </p>
                          </li>
                        )
                      }
                    )}
                  </ul>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default (Menu)
