// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__1cMJ9 {\n  margin-top: 1.75rem!important;\n}\n\n.styles_header__2VMr3 {\n  margin-top: 0px!important;\n}\n", "",{"version":3,"sources":["webpack://src/components/LOM/Vendor/NFTFilters/CustomArrayFilter/styles.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".container {\n  margin-top: 1.75rem!important;\n}\n\n.header {\n  margin-top: 0px!important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__1cMJ9",
	"header": "styles_header__2VMr3"
};
export default ___CSS_LOADER_EXPORT___;
