import React, { useCallback, useMemo } from 'react'
import { Button, Header } from '@wiicamp/decentraland-ui'
import { Network } from '@dcl/schemas'
import { t,T } from '@wiicamp/decentraland-dapps/dist/modules/translation/utils'

import { Mana } from '../../../Mana'

import { Column } from '../../../Layout/Column'

import { formatMANA } from '../../../../lib/mana'
import { getAssetName } from '../../../../modules/asset/utils'

import { Props } from './CancelSaleModal.types'

import styles from './CancelSaleModal.module.scss'

const CancelSaleModal = (props: Props) => {
  const {
    nft,
    isOwner,
    onCloseModal,
    cancelSaleItem,
    cancelSaleItemLoading,
  } = props

  const name = useMemo(() => getAssetName(nft) , [nft])

  const modal: {
    subtitle: any,
    isDisabled: boolean
  } = useMemo(() => {
    if (!isOwner) {
      return {
        isDisabled: true,
        subtitle: (
          <T
            id="cancel_sale_page.invalid_owner"
            values={{ name: <b>{name}</b> }}
          />
        )
      }
    } else {
      return {
        isDisabled: false,
        subtitle: (
          <T
            id="cancel_sale_page.subtitle"
            values={{
              name: <b>{name}</b>,
              amount: (
                <Mana network={nft.network || Network.ETHEREUM} inline>
                  {formatMANA(nft.price)}
                </Mana>
              )
            }}
          />
        )
      }
    }
  }, [nft, isOwner, name])

  const handleSubmit = useCallback(() => {
    cancelSaleItem(nft, onCloseModal)
  }, [nft]);

  return (
    <Column align="right">
      <Header size="large">{t('cancel_sale_page.title')}</Header>
      <div className={['subtitle', isOwner ? '' : styles.SubtitleError].join(' ')}>{modal.subtitle}</div>

      <div className="buttons">
        <Button className="LOM-cancel-btn" onClick={onCloseModal}>
          {t('global.cancel')}
        </Button>

        <Button
          className="LOM-cancel-btn"
          primary
          loading={cancelSaleItemLoading}
          disabled={modal.isDisabled || cancelSaleItemLoading}
          onClick={handleSubmit}
        >
          {t('cancel_sale_page.submit')}
        </Button>
      </div>
    </Column>
  )
}

export default React.memo(CancelSaleModal)
