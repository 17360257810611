import React from 'react';
import classNames from 'classnames';

import { ContentItemProps } from '../RoadmapPage.types';

const Blank = (props: ContentItemProps) => {
  const { index, item } = props;
  const itemActiveArray = Array.isArray(item?.active) ? item.active : [];

  return (
    <div
      key={index}
      className={classNames(
        'flex Roadmap__map',
        itemActiveArray?.[0] && 'active-left',
        itemActiveArray?.[1] && 'active-right'
      )}
    >
      <div className="flex-1 justify-end flex">
        <div className={`${item.className}`} />
      </div>
    </div>
  );
};

export default React.memo(Blank);
