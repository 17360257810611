import { ITEM_STATUS } from "../../lib/constant"

import { CategoriesType, SubCategory } from "./types"

export const getObjectFromApiCategories = (
  categories: CategoriesType[],
  idCategory: string | number,
  idSubcategory: string | number
) => {
  const objectEmpty = { name: '', slug: ''}
  const IDCategory = typeof idCategory === 'string' ? Number(idCategory) : idCategory
  const IDSubCategory = typeof idSubcategory === 'string' ? Number(idSubcategory) : idSubcategory

  const categoryFound = categories.find((item: CategoriesType) => Number(item.id) === IDCategory)

  if (!categoryFound) return { category: objectEmpty, subCategory: objectEmpty }

  const subCategories: SubCategory[] = categoryFound.subCategories

  if (subCategories.length === 0 )return { category: categoryFound, subCategory: objectEmpty }

  const subCategoriesFound = subCategories.find((item: SubCategory) => Number(item.id) === IDSubCategory)

  return {
    category: categoryFound,
    subCategory: subCategoriesFound,
  }
}

export const getStatusName = (statusString: string): string => {
  switch (statusString) {
    case ITEM_STATUS.PENDING.toUpperCase():
      return ITEM_STATUS.PENDING

    case ITEM_STATUS.APPROVE.toUpperCase():
      return ITEM_STATUS.APPROVED

    case ITEM_STATUS.REJECT.toUpperCase():
      return ITEM_STATUS.REJECTED
    default:
      return '';
  }
}

export const convertCategoryStringToNumber = (categories: CategoriesType[], category: string): any => {
  if (!category) return category

  const categoryFound = categories.find((item: CategoriesType) => item.slug === category)

  if (!categoryFound) return category

  return categoryFound.id
}

export const convertSubCategoryStringToNumber = (categories: CategoriesType[], category: string, subCategory: string): any => {
  if (!category || !subCategory) return undefined

  const categoryFound: CategoriesType | undefined = categories.find((item: CategoriesType) => item.slug === category)

  if (!categoryFound) return undefined

  const subCategories = categoryFound.subCategories

  const subCategoryFound = subCategories.find((item: SubCategory) => item.slug === subCategory)

  return subCategoryFound ? subCategoryFound.id : undefined
}
