import React, { useState, useCallback, useEffect } from 'react'
import classNames from 'classnames'
import { X } from 'react-feather'

import { ConnectWalletModal } from '../../ConnectWalletModal'
import { MenuDropdown } from '../../MenuDropdown'

import { Props } from './MenuBarRight.types'

import { SOCIAL_ICONS_ARRAY } from '../../../../lib/constant'

const MenuBarRight = (props: Props) => {
  const {
    token,
    wallet,
    isShowMenu,
    viewMode,
    isRendered,
    // hasError,
    // isLoading,
    // userExistedStatus,
    // userConnectWithWallet,
    // message,
    onToggle,
    // onConnect,
    onSignOut,
    // dispatchGetUserSignMessageRequest,
    // setUserSignMetamaskRequest,
  } = props
  const [isLogin, setIsLogin] = useState(!!wallet?.address)
  const [isShowLoginModal, setShowLoginModal] = useState(false)

  useEffect(() => {
    if (token && wallet && wallet.address) {
      setIsLogin(true)
    } else {
      setIsLogin(false)
    }

    setShowLoginModal(false)
  }, [wallet, token])

  const onToggleLoginModal = useCallback(() => {
    if (isLogin) {
      onSignOut()
      setIsLogin(false)
    } else {
      setShowLoginModal(prevState => !prevState)
    }
  }, [isLogin, onSignOut])

  // const onConnectWallet = (loginType: LoginModalOptionType) => {
  //   let providerType: ProviderType

  //   switch (loginType) {
  //     case LoginModalOptionType.METAMASK:
  //     case LoginModalOptionType.DAPPER:
  //     case LoginModalOptionType.SAMSUNG:
  //       providerType = ProviderType.INJECTED
  //       break
  //     case LoginModalOptionType.FORTMATIC:
  //       providerType = ProviderType.FORTMATIC
  //       break
  //     case LoginModalOptionType.WALLET_CONNECT:
  //       providerType = ProviderType.WALLET_CONNECT
  //       break
  //     default:
  //       throw new Error(`Invalid login type ${loginType}`)
  //   }

  //   onConnect(providerType)
  // }

  return (
    <div
      className={classNames(
        isShowMenu ? 'justify-between show' : 'justify-center',
        'LayoutHome__menu-bar',
        'LayoutHome__bar-right',
        isRendered ? 'rendered' : '',
        viewMode,
      )}
    >
      <div className="flex h-full">
        {isShowMenu && isLogin && (
          <div className={`LayoutHome__action-btn ${viewMode}`}>
            <MenuDropdown />
          </div>
        )}

        {isShowMenu && !isLogin && (
          <div onClick={onToggleLoginModal} className={`LayoutHome__action-btn ${viewMode}`}>
            <button>Connect Wallet</button>
          </div>
        )}
      </div>

      <div className={`LayoutHome__close-btn ${viewMode || ''}`}>
        <button className={isShowMenu ? '' : 'closing'} onClick={onToggle}>
          <X size={'3rem'} strokeWidth={0.6} />
        </button>
      </div>

      {isShowMenu && (
        <div className={`LayoutHome__social h-full ${viewMode}`}>
          {SOCIAL_ICONS_ARRAY.map((item, index) => (
            <div key={`icon-social-pc-${index}`} className={`LayoutHome__action-btn ${viewMode || ''}`}>
              <a href={item.link} rel="noreferrer" target="_blank">
                <img className="2xl:w-5 2xl:h-5" src={item.iconSrc} alt={item.iconSrc} height="16" width="16" />
              </a>
            </div>
          ))}
        </div>
      )}

      <ConnectWalletModal
        isOpen={isShowLoginModal}
        handleOpen={onToggleLoginModal}
      />
    </div>
  )
}

export default React.memo(MenuBarRight)
