import { action } from 'typesafe-actions'
import { UpdateEmailAndUsernameOfUserPayload } from './types'

// Check User Existed
export const CHECK_USER_EXISTED_REQUEST = '[Request] Check user existed'
export const CHECK_USER_EXISTED_SUCCESS = '[Success] Check user existed'
export const CHECK_USER_EXISTED_FAILURE = '[Failure] Check user existed'

export const checkUserExistedRequest = (payload: any) => action(CHECK_USER_EXISTED_REQUEST, payload)
export const checkUserExistedSuccess = (payload: any) => action(CHECK_USER_EXISTED_SUCCESS, payload)
export const checkUserExistedFailure = (error: string) => action(CHECK_USER_EXISTED_FAILURE, error)

export type CheckUserExistedRequestAction = ReturnType<typeof checkUserExistedRequest>
export type CheckUserExistedSuccessAction = ReturnType<typeof checkUserExistedSuccess>
export type CheckUserExistedFailureAction = ReturnType<typeof checkUserExistedFailure>

// Get Sign Messages
export const GET_USER_SIGN_MESSAGE_REQUEST = '[Request] Get user sign message'
export const GET_USER_SIGN_MESSAGE_SUCCESS = '[Success] Get user sign message'
export const GET_USER_SIGN_MESSAGE_FAILURE = '[Failure] Get user sign message'

export const getUserSignMessageRequest = (payload: any) => action(GET_USER_SIGN_MESSAGE_REQUEST, payload)
export const getUserSignMessageSuccess = (payload: any) => action(GET_USER_SIGN_MESSAGE_SUCCESS, payload)
export const getUserSignMessageFailure = (error: any) => action(GET_USER_SIGN_MESSAGE_FAILURE, error)

export type GetUserSignMessageRequestAction = ReturnType<typeof getUserSignMessageRequest>
export type GetUserSignMessageSuccessAction = ReturnType<typeof getUserSignMessageSuccess>
export type GetUserSignMessageFailureAction = ReturnType<typeof getUserSignMessageFailure>

// Set User Sign Metamask
export const SET_USER_SIGN_METAMASK_REQUEST = '[Request] Set user sign metamask'
export const SET_USER_SIGN_METAMASK_SUCCESS = '[Success] Set user sign metamask'
export const SET_USER_SIGN_METAMASK_FAILURE = '[Failure] Set user sign metamask'

export const setUserSignMetamaskRequest = (payload: any) => action(SET_USER_SIGN_METAMASK_REQUEST, payload)
export const setUserSignMetamaskSuccess = (payload: any) => action(SET_USER_SIGN_METAMASK_SUCCESS, payload)
export const setUserSignMetamaskFailure = (error: any) => action(SET_USER_SIGN_METAMASK_FAILURE, error)

export type SetUserSignMetamaskRequestAction = ReturnType<typeof setUserSignMetamaskRequest>
export type SetUserSignMetamaskSuccessAction = ReturnType<typeof setUserSignMetamaskSuccess>
export type SetUserSignMetamaskFailureAction = ReturnType<typeof setUserSignMetamaskFailure>

// Set Verify Wallet To Account
export const SET_VERIFY_WALLET_TO_ACCOUNT_REQUEST = '[Request] Set verify wallet to account'
export const SET_VERIFY_WALLET_TO_ACCOUNT_SUCCESS = '[Success] Set verify wallet to account'
export const SET_VERIFY_WALLET_TO_ACCOUNT_FAILURE = '[Failure] Set verify wallet to account'

export const setVerifyWalletToAccountRequest = (payload: any) => action(SET_VERIFY_WALLET_TO_ACCOUNT_REQUEST, payload)
export const setVerifyWalletToAccountSuccess = (payload: any) => action(SET_VERIFY_WALLET_TO_ACCOUNT_SUCCESS, payload)
export const setVerifyWalletToAccountFailure = (error: any) => action(SET_VERIFY_WALLET_TO_ACCOUNT_FAILURE, error)

export type SetVerifyWalletToAccountRequestAction = ReturnType<typeof setVerifyWalletToAccountRequest>
export type SetVerifyWalletToAccountSuccessAction = ReturnType<typeof setVerifyWalletToAccountSuccess>
export type SetVerifyWalletToAccountFailureAction = ReturnType<typeof setVerifyWalletToAccountFailure>

// Set Update User Password
export const SET_UPDATE_USER_PASSWORD_REQUEST = '[Request] Set update user password'
export const SET_UPDATE_USER_PASSWORD_SUCCESS = '[Success] Set update user password'
export const SET_UPDATE_USER_PASSWORD_FAILURE = '[Failure] Set update user password'

export const setUpdateUserPasswordRequest = (payload: any) => action(SET_UPDATE_USER_PASSWORD_REQUEST, payload)
export const setUpdateUserPasswordSuccess = () => action(SET_UPDATE_USER_PASSWORD_SUCCESS)
export const setUpdateUserPasswordFailure = (error: any) => action(SET_UPDATE_USER_PASSWORD_FAILURE, error)

export type SetUpdateUserPasswordRequestAction = ReturnType<typeof setUpdateUserPasswordRequest>
export type SetUpdateUserPasswordSuccessAction = ReturnType<typeof setUpdateUserPasswordSuccess>
export type SetUpdateUserPasswordFailureAction = ReturnType<typeof setUpdateUserPasswordFailure>

// Set Logout Website
export const SET_LOGOUT_WEBSITE_REQUEST = '[Request] Set logout website'

export const setLogoutWebsiteRequest = () => action(SET_LOGOUT_WEBSITE_REQUEST)

export type SetLogoutWebsiteRequestAction = ReturnType<typeof setLogoutWebsiteRequest>

// Update Email and Username of user
export const UPDATE_EMAIL_AND_USERNAME_OF_USER_REQUEST = '[Request] Update email and username of user'
export const UPDATE_EMAIL_AND_USERNAME_OF_USER_SUCCESS = '[Success] Update email and username of user'
export const UPDATE_EMAIL_AND_USERNAME_OF_USER_FAILURE = '[Failure] Update email and username of user'

export const updateEmailAndUsernameOfUserRequest = (payload: UpdateEmailAndUsernameOfUserPayload) => action(UPDATE_EMAIL_AND_USERNAME_OF_USER_REQUEST, payload)
export const updateEmailAndUsernameOfUserSuccess = (payload: { email: string, username: string }) => action(UPDATE_EMAIL_AND_USERNAME_OF_USER_SUCCESS, payload)
export const updateEmailAndUsernameOfUserFailure = (error: any) => action(UPDATE_EMAIL_AND_USERNAME_OF_USER_FAILURE, error)

export type UpdateEmailAndUsernameOfUserRequestAction = ReturnType<typeof updateEmailAndUsernameOfUserRequest>
export type UpdateEmailAndUsernameOfUserSuccessAction = ReturnType<typeof updateEmailAndUsernameOfUserSuccess>
export type UpdateEmailAndUsernameOfUserFailureAction = ReturnType<typeof updateEmailAndUsernameOfUserFailure>

