import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { Props, InterverRefType } from './RandomNumber.types'

const RandomNumber = (props: Props) => {
  const { length, className, speed = 150 } = props;
  const number = Math.random().toString(2).substr(2)
  const numberAnother = Math.random().toString(2).substr(2)

  const [state, setState] = useState('')
  const interverRef = useRef<InterverRefType| null | any>(null) // TODO: delete any

  useEffect(() => {
    interverRef.current = setInterval(() => {
      const from = Math.trunc(Math.random() * number.length)
      const f = from > number.length - length ? number.length - length : from
      const t = from >= number.length - length ? number.length : from + length
      let n = number.substring(f, t)
      if (state === n) {
        n = numberAnother.substring(f, t)
      }
      setState(n)
    }, speed)
    setTimeout(() => {
      clearInterval(interverRef.current)
    }, 3000)
    return () => {
      clearInterval(interverRef.current)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return length ? (
    <span className={`${className} inline-block mx-2`} style={{ width: `calc(0.6rem * ${length})` }}>
      {` ${state} `}
    </span>
  ) : null
}

RandomNumber.propTypes = {
  length:PropTypes.number.isRequired,
  className:PropTypes.string,
  speed: PropTypes.number
}

RandomNumber.defaultProps = {
  className: '',
  speed: 150,
}

export default RandomNumber
