import {
  loadingReducer,
  LoadingState,
} from '@wiicamp/decentraland-dapps/dist/modules/loading/reducer'

import {
  // Post new estate
  POST_NEW_ESTATE_REQUEST,
  POST_NEW_ESTATE_SUCCESS,
  POST_NEW_ESTATE_FAILURE,
  PostNewEstateRequestAction,
  PostNewEstateSuccessAction,
  PostNewEstateFailureAction,

  // Set land to estate
  SET_LAND_TO_ESTATE_REQUEST,
  SET_LAND_TO_ESTATE_SUCCESS,
  SET_LAND_TO_ESTATE_FAILURE,
  SetLandToEstateRequestAction,
  SetLandToEstateSuccessAction,
  SetLandToEstateFailureAction,

  // Remove land from estate
  REMOVE_LAND_FROM_ESTATE_REQUEST,
  REMOVE_LAND_FROM_ESTATE_SUCCESS,
  REMOVE_LAND_FROM_ESTATE_FAILURE,
  RemoveLandFromEstateRequestAction,
  RemoveLandFromEstateSuccessAction,
  RemoveLandFromEstateFailureAction,

  // Get estates by wallet address
  GET_ESTATES_BY_WALLET_ADDRESS_REQUEST,
  GET_ESTATES_BY_WALLET_ADDRESS_SUCCESS,
  GET_ESTATES_BY_WALLET_ADDRESS_FAILURE,
  GetEstatesByWalletAddressRequestAction,
  GetEstatesByWalletAddressSuccessAction,
  GetEstatesByWalletAddressFailureAction,

  // Get lands by wallet address
  GET_LANDS_BY_WALLET_ADDRESS_REQUEST,
  GET_LANDS_BY_WALLET_ADDRESS_SUCCESS,
  GET_LANDS_BY_WALLET_ADDRESS_FAILURE,
  GetLandsByWalletAddressRequestAction,
  GetLandsByWalletAddressSuccessAction,
  GetLandsByWalletAddressFailureAction,
} from './actions'

export type EstateStateType = {
  page: number | null

  isLoadingGetEstatesByWalletAddress: LoadingState
  pageEstates: number | null
  estates: any[]
  totalEstates: number | null

  isLoadingGetLandsByWalletAddress: LoadingState
  pageLands: number | null
  lands: any[]
  totalLands: number | null

  isLoadingPostNewEstate: LoadingState

  isLoadingSetLandToEstate: LoadingState

  isLoadingRemoveLandFromEstate: LoadingState
}

export const INITIAL_STATE: EstateStateType = {
  page: null,

  isLoadingGetEstatesByWalletAddress: [],
  pageEstates: null,
  estates: [],
  totalEstates: null,

  isLoadingGetLandsByWalletAddress: [],
  pageLands: null,
  lands: [],
  totalLands: null,

  isLoadingPostNewEstate: [],
  isLoadingSetLandToEstate: [],
  isLoadingRemoveLandFromEstate: [],
}

type EstateReducerAction =
  | PostNewEstateRequestAction
  | PostNewEstateSuccessAction
  | PostNewEstateFailureAction
  | SetLandToEstateRequestAction
  | SetLandToEstateSuccessAction
  | SetLandToEstateFailureAction
  | RemoveLandFromEstateRequestAction
  | RemoveLandFromEstateSuccessAction
  | RemoveLandFromEstateFailureAction
  | GetEstatesByWalletAddressRequestAction
  | GetEstatesByWalletAddressSuccessAction
  | GetEstatesByWalletAddressFailureAction
  | GetLandsByWalletAddressRequestAction
  | GetLandsByWalletAddressSuccessAction
  | GetLandsByWalletAddressFailureAction

export function estateReducer(
  state = INITIAL_STATE,
  action: EstateReducerAction
): EstateStateType {
  switch (action.type) {
    case POST_NEW_ESTATE_REQUEST:
    case POST_NEW_ESTATE_FAILURE: {
      return {
        ...state,
        isLoadingPostNewEstate: loadingReducer(state.isLoadingPostNewEstate, action),
      }
    }

    case POST_NEW_ESTATE_SUCCESS: {
      return {
        ...state,
        page: 1,
        isLoadingPostNewEstate: loadingReducer(state.isLoadingPostNewEstate, action),
      }
    }

    case SET_LAND_TO_ESTATE_REQUEST:
    case SET_LAND_TO_ESTATE_FAILURE: {
      return {
        ...state,
        isLoadingSetLandToEstate: loadingReducer(state.isLoadingSetLandToEstate, action),
      }
    }

    case SET_LAND_TO_ESTATE_SUCCESS: {
      return {
        ...state,
        page: 1,
        isLoadingSetLandToEstate: loadingReducer(state.isLoadingSetLandToEstate, action),
      }
    }

    case REMOVE_LAND_FROM_ESTATE_REQUEST:
    case REMOVE_LAND_FROM_ESTATE_FAILURE: {
      return {
        ...state,
        isLoadingRemoveLandFromEstate: loadingReducer(state.isLoadingRemoveLandFromEstate, action),
      }
    }

    case REMOVE_LAND_FROM_ESTATE_SUCCESS: {
      return {
        ...state,
        page: 1,
        isLoadingRemoveLandFromEstate: loadingReducer(state.isLoadingRemoveLandFromEstate, action),
      }
    }

    case GET_ESTATES_BY_WALLET_ADDRESS_REQUEST: {
      if (action.payload.isLoadMore) {
        return {
          ...state,
          isLoadingGetEstatesByWalletAddress: loadingReducer(state.isLoadingGetEstatesByWalletAddress, action),
        }
      }

      return {
        ...state,
        isLoadingGetEstatesByWalletAddress: loadingReducer(state.isLoadingGetEstatesByWalletAddress, action),
        estates: [],
      }
    }

    case GET_ESTATES_BY_WALLET_ADDRESS_FAILURE: {
      return {
        ...state,
        isLoadingGetEstatesByWalletAddress: loadingReducer(state.isLoadingGetEstatesByWalletAddress, action),
      }
    }

    case GET_ESTATES_BY_WALLET_ADDRESS_SUCCESS: {
      const {
        estates = [],
        isLoadMore,
        total,
        page,
      } = action.payload

      if (isLoadMore) {
        return {
          ...state,
          estates: [...state.estates, ...estates],
          totalEstates: total,
          pageEstates: page,
          isLoadingGetEstatesByWalletAddress: loadingReducer(state.isLoadingGetEstatesByWalletAddress, action),
        }
      }

      return {
        ...state,
        estates,
        totalEstates: total,
        pageEstates: 1,
        isLoadingGetEstatesByWalletAddress: loadingReducer(state.isLoadingGetEstatesByWalletAddress, action),
      }
    }

    case GET_LANDS_BY_WALLET_ADDRESS_REQUEST: {
      if (action.payload.isLoadMore) {
        return {
          ...state,
          isLoadingGetLandsByWalletAddress: loadingReducer(state.isLoadingGetLandsByWalletAddress, action),
        }
      }

      return {
        ...state,
        isLoadingGetLandsByWalletAddress: loadingReducer(state.isLoadingGetLandsByWalletAddress, action),
        lands: [],
      }
    }

    case GET_LANDS_BY_WALLET_ADDRESS_FAILURE: {
      return {
        ...state,
        isLoadingGetLandsByWalletAddress: loadingReducer(state.isLoadingGetLandsByWalletAddress, action),
      }
    }

    case GET_LANDS_BY_WALLET_ADDRESS_SUCCESS: {
      const {
        lands = [],
        isLoadMore,
        total,
        page,
      } = action.payload

      if (isLoadMore) {
        return {
          ...state,
          lands: [...state.lands, ...lands],
          totalLands: total,
          pageLands: page,
          isLoadingGetLandsByWalletAddress: loadingReducer(state.isLoadingGetLandsByWalletAddress, action),
        }
      }

      return {
        ...state,
        lands: lands,
        totalLands: total,
        pageLands: 1,
        isLoadingGetLandsByWalletAddress: loadingReducer(state.isLoadingGetLandsByWalletAddress, action),
      }
    }

    default:
      return state
  }
}
