import { memo } from 'react'

import { AspectRatioType } from './AspectRatio.type'

import cssStyles from './AspectRatio.module.scss'

const AspectRatio = (props: AspectRatioType) => {
  const { children, width = 1, className, height = 1 } = props
  const cssVariables = { '--width': width, '--height': height } as React.CSSProperties

  return (
    <div className={`${cssStyles.AspectRatioContainer} ${className}`} style={cssVariables}>
      <div className={cssStyles.AspectRatioWrapper}>
        <div className={cssStyles.AspectRatioContent}>{children}</div>
      </div>
    </div>
  )
}

export default memo(AspectRatio)
