// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WearableCollection_detailCollectionTitle__bI1nb {\n  display: block;\n  margin: 1rem 0 0.833rem;\n  color: #ff5e00 !important;\n  font-size: 1.083rem;\n  font-weight: 700;\n  line-height: 1.083rem;\n  text-transform: uppercase;\n}\n", "",{"version":3,"sources":["webpack://src/components/LOM/AssetPage/WearableCollection/WearableCollection.module.scss"],"names":[],"mappings":"AACE;EACE,cAAc;EACd,uBAAuB;EACvB,yBAAyB;EACzB,mBAAmB;EACnB,gBAAgB;EAChB,qBAAqB;EACrB,yBAAyB;AAAA","sourcesContent":[".detail {\n  &CollectionTitle {\n    display: block;\n    margin: 1rem 0 0.833rem;\n    color: #ff5e00 !important;\n    font-size: 1.083rem;\n    font-weight: 700;\n    line-height: 1.083rem;\n    text-transform: uppercase;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"detailCollectionTitle": "WearableCollection_detailCollectionTitle__bI1nb"
};
export default ___CSS_LOADER_EXPORT___;
