import { BaseAPI } from '@wiicamp/decentraland-dapps/dist/lib/api'

import axios from 'axios'

export const AUTHENTICATION_API_URL = process.env.REACT_APP_SIGNATURE_METAMASK!

class AuthenticationAPI extends BaseAPI {
  checkUserExisted = async ({ wallet }: { wallet: string }): Promise<any> => {
    const response = await this.request('get', `/auth/find/${wallet}`)
    return response
  }
  getSignMessage = async ({ email, username }: { [key: string]: any }): Promise<any> => {
    const response = await this.request('post', `/auth/builtin`, {
      email, username
    })

    return response
  }
  verifyUserBySignature = async ({ address, message, signature }: { [key: string]: any }): Promise<any> => {
    const response = await this.request('post', '/auth/verify', {
      address, message, signature
    })

    return response
  }
  updateUserPassword = async ({ password, token }: { [key: string]: any }): Promise<any> => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

    const response = await axios.post(`${AUTHENTICATION_API_URL}/me/change-password`, {
      newPassword: password,
      confirmPassword: password
    })

    return response;
  }

  updateEmailAndUsernameOfUser = async ({ username, email, token }: { [key: string]: any }): Promise<any> => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

    const response = await axios.post(`${AUTHENTICATION_API_URL}/me`, {
      username,
      email
    })

    return response
  }
}

export const authenticationApi = new AuthenticationAPI(AUTHENTICATION_API_URL)
