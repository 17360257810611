// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media screen and (max-width: 500px) {\n  .TransferModal_ColumnWrapper__3kVIQ {\n    width: calc((343 / 375) * 100vw);\n    max-width: 420px;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/LOM/ItemNFTDetailPage/TransferModal/TransferModal.module.scss"],"names":[],"mappings":"AAAA;EACE;IACE,gCAAgC;IAChC,gBAAgB;EAAA;AACjB","sourcesContent":["@media screen and (max-width: 500px) {\n  .ColumnWrapper {\n    width: calc((343 / 375) * 100vw);\n    max-width: 420px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ColumnWrapper": "TransferModal_ColumnWrapper__3kVIQ"
};
export default ___CSS_LOADER_EXPORT___;
