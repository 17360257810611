import React from 'react'
import { Badge } from '@wiicamp/decentraland-ui'
import { Props } from './Coordinate.types'

import { ReactComponent as IconPin } from '../../../asset/icons/ic-pin.svg'

import cssStyles from './styles.module.scss'


const Coordinate = (props: Props) => {
  const { x, y, className } = props

  return (
    <Badge className={`${className} ${cssStyles.badge}`} color="#37333D">
      <IconPin />
      {`${x},${y}`}
    </Badge>
  )
}

export default React.memo(Coordinate)
