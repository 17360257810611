import menuPartImg from '../../../asset/images/tools/menu-part.svg'

const MenuButton = (props: { isOpen: boolean; onToggle: () => void }) => {
  const { isOpen, onToggle } = props;

  return (
    <div
      className={`MenuMobile__menu-mobile-bar__menu-button flex-none flex`}
      onClick={onToggle}
    >
      <img
        className={isOpen ? "" : "close"}
        src={menuPartImg}
        alt="menu-part"
      />
      <img
        className={isOpen ? "" : "close"}
        src={menuPartImg}
        alt="menu-part"
      />
      <img
        className={isOpen ? "" : "close"}
        src={menuPartImg}
        alt="menu-part"
      />
    </div>
  );
};

export default MenuButton;
