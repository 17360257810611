import React, { useRef, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars'

import { Menu } from '../../Menu'
import { MenuBarLeft, MenuBarRight } from '../../MenuBar'
import { MenuMobile } from '../../MenuMobile'
import { ScrollToTopMobile } from '../../ScrollToTopMobile'

import { Props } from './LayoutPage.types'
import './LayoutPage.scss'
import '../LayoutMain/LayoutMain.scss'

import { customScroll } from '../../../../lib/customScrollbars'

const LayoutPage = (props: Props) => {
  const { children, viewMode } = props
  const scrollbar = useRef(null)
  const [isShowMenu, setShowMenu] = useState(true)
  const handleToggleMenu = () => {
    setShowMenu(!isShowMenu)
  }
  return (
    <div
      className={
        viewMode === 'white' ? 'bg-white text-black' : 'LayoutMain__banner'
      }
    >
      <MenuBarLeft isRendered isShowMenu={isShowMenu} viewMode={viewMode} />
      <div className="hidden lg:block flex-auto LayoutMain__content">
        <div className="flex w-full">
          <Menu viewMode={viewMode} isShowMenu={isShowMenu} />
          <Scrollbars
            renderThumbVertical={(props: {}) => customScroll(props)}
            renderThumbHorizontal={(props: {}) => customScroll(props)}
            ref={scrollbar}
            className="Page__scrollbar"
          >
            {children}
          </Scrollbars>
        </div>
      </div>
      <MenuBarRight
        onToggle={handleToggleMenu}
        isShowMenu={isShowMenu}
        viewMode={viewMode}
        isRendered
      />

      <MenuMobile />
      <div className="lg:hidden Page__scrollbar__cover relative flex flex-col">
        <div className="flex-1 flex">
          {children}
        </div>
        <ScrollToTopMobile />
      </div>
    </div>
  )
}

export default React.memo(LayoutPage)
