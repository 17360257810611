import { action } from 'typesafe-actions'

// Create new estate
export const POST_NEW_ESTATE_REQUEST = '[Request] Post new estate'
export const POST_NEW_ESTATE_SUCCESS = '[Success] Post new estate'
export const POST_NEW_ESTATE_FAILURE = '[Failure] Post new estate'

export const postNewEstateRequest = (payload: any) => action(POST_NEW_ESTATE_REQUEST, payload)
export const postNewEstateSuccess = (payload: any) => action(POST_NEW_ESTATE_SUCCESS, payload)
export const postNewEstateFailure = (error: any) => action(POST_NEW_ESTATE_FAILURE, error)

export type PostNewEstateRequestAction = ReturnType<typeof postNewEstateRequest>
export type PostNewEstateSuccessAction = ReturnType<typeof postNewEstateSuccess>
export type PostNewEstateFailureAction = ReturnType<typeof postNewEstateFailure>

// Add Land To Estate
export const SET_LAND_TO_ESTATE_REQUEST = '[Request] Set land to estate'
export const SET_LAND_TO_ESTATE_SUCCESS = '[Success] Set land to estate'
export const SET_LAND_TO_ESTATE_FAILURE = '[Failure] Set land to estate'

export const setLandToEstateRequest = (payload: any) => action(SET_LAND_TO_ESTATE_REQUEST, payload)
export const setLandToEstateSuccess = (payload: any) => action(SET_LAND_TO_ESTATE_SUCCESS, payload)
export const setLandToEstateFailure = (error: any) => action(SET_LAND_TO_ESTATE_FAILURE, error)

export type SetLandToEstateRequestAction = ReturnType<typeof setLandToEstateRequest>
export type SetLandToEstateSuccessAction = ReturnType<typeof setLandToEstateSuccess>
export type SetLandToEstateFailureAction = ReturnType<typeof setLandToEstateFailure>

// Remove Land From Estate
export const REMOVE_LAND_FROM_ESTATE_REQUEST = '[Request] Remove land from estate'
export const REMOVE_LAND_FROM_ESTATE_SUCCESS = '[Success] Remove land from estate'
export const REMOVE_LAND_FROM_ESTATE_FAILURE = '[Failure] Remove land from estate'

export const removeLandFromEstateRequest = (payload: any) => action(REMOVE_LAND_FROM_ESTATE_REQUEST, payload)
export const removeLandFromEstateSuccess = (payload: any) => action(REMOVE_LAND_FROM_ESTATE_SUCCESS, payload)
export const removeLandFromEstateFailure = (error: any) => action(REMOVE_LAND_FROM_ESTATE_FAILURE, error)

export type RemoveLandFromEstateRequestAction = ReturnType<typeof removeLandFromEstateRequest>
export type RemoveLandFromEstateSuccessAction = ReturnType<typeof removeLandFromEstateSuccess>
export type RemoveLandFromEstateFailureAction = ReturnType<typeof removeLandFromEstateFailure>

// Get estates by wallet address
export const GET_ESTATES_BY_WALLET_ADDRESS_REQUEST = '[Request] Get estates by wallet address'
export const GET_ESTATES_BY_WALLET_ADDRESS_SUCCESS = '[Success] Get estates by wallet address'
export const GET_ESTATES_BY_WALLET_ADDRESS_FAILURE = '[Failure] Get estates by wallet address'

export const getEstatesByWalletAddressRequest = (payload: any) => action(GET_ESTATES_BY_WALLET_ADDRESS_REQUEST, payload)
export const getEstatesByWalletAddressSuccess = (payload: any) => action(GET_ESTATES_BY_WALLET_ADDRESS_SUCCESS, payload)
export const getEstatesByWalletAddressFailure = (error: any) => action(GET_ESTATES_BY_WALLET_ADDRESS_FAILURE, error)

export type GetEstatesByWalletAddressRequestAction = ReturnType<typeof getEstatesByWalletAddressRequest>
export type GetEstatesByWalletAddressSuccessAction = ReturnType<typeof getEstatesByWalletAddressSuccess>
export type GetEstatesByWalletAddressFailureAction = ReturnType<typeof getEstatesByWalletAddressFailure>

// Get lands by wallet address
export const GET_LANDS_BY_WALLET_ADDRESS_REQUEST = '[Request] Get lands by wallet address'
export const GET_LANDS_BY_WALLET_ADDRESS_SUCCESS = '[Success] Get lands by wallet address'
export const GET_LANDS_BY_WALLET_ADDRESS_FAILURE = '[Failure] Get lands by wallet address'

export const getLandsByWalletAddressRequest = (payload: any) => action(GET_LANDS_BY_WALLET_ADDRESS_REQUEST, payload)
export const getLandsByWalletAddressSuccess = (payload: any) => action(GET_LANDS_BY_WALLET_ADDRESS_SUCCESS, payload)
export const getLandsByWalletAddressFailure = (error: any) => action(GET_LANDS_BY_WALLET_ADDRESS_FAILURE, error)

export type GetLandsByWalletAddressRequestAction = ReturnType<typeof getLandsByWalletAddressRequest>
export type GetLandsByWalletAddressSuccessAction = ReturnType<typeof getLandsByWalletAddressSuccess>
export type GetLandsByWalletAddressFailureAction = ReturnType<typeof getLandsByWalletAddressFailure>
