// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Main_thumb__3bar6 {\n  margin-left: 0.833rem;\n}\n", "",{"version":3,"sources":["webpack://src/components/LOM/MarketplacePage/Main.module.scss"],"names":[],"mappings":"AAAA;EACE,qBACF;AAAA","sourcesContent":[".thumb {\n  margin-left: 0.833rem\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thumb": "Main_thumb__3bar6"
};
export default ___CSS_LOADER_EXPORT___;
