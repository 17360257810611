import React from 'react'
import classNames from 'classnames'

import { Props } from './ContentItem.types'

const ContentItem = (props: Props) => {
  const { side, data, active } = props;
  const { type, value, className } = data;

  const isRight = side === 'right';
  const arrayValue = Array.isArray(value) ? [...value] : [value];

  const renderContent = () => {
    if (type === 'image') {
      return (
        <div
          className={classNames(
            `flex items-center`,
            !isRight ? 'pr-10' : 'pl-10',
            className
          )}
        >
          {value}
        </div>
      );
    }

    if (type === 'text') {
      return arrayValue?.map((valueItem, index) => (
        <div
          key={`roadmap-${index}`}
          className={classNames(
            `flex-1 justify-end flex`,
            !isRight ? '' : 'flex-row-reverse'
          )}
        >
          <div className={`${className}`}>
            <div
              className={classNames(
                active && isRight
                  ? 'text-orange border-orange'
                  : active && !isRight
                    ? 'text-green border-green'
                    : 'text-gray border-gray',
                !isRight ? 'border-r-4' : 'border-l-4',
                'border-solid py-2',
                !isRight ? ' pr-4 lg:pr-8 text-right' : 'pl-4 lg:pl-8'
              )}
            >
              {valueItem}
            </div>
          </div>
          <div
            className={classNames(
              'Roadmap__line-sharp w-10 lg:w-20',
              !isRight ? 'right' : 'left',
              active && 'active'
            )}
          />
        </div>
      ));
    }

    return null;
  };

  return (
    <div className="relative flex-1 align-end flex flex-col">
      {renderContent()}
    </div>
  );
};

ContentItem.propsDefault = {
  side: 'left',
  data: {
    type: '',
    value: '',
    className: '',
  },
  active: false,
};

export default React.memo(ContentItem);
