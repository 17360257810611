import React from 'react'
import classNames from 'classnames'

import { Button } from '../Button'

import { NavigationItem, Props } from './Navigation.types'

const Navigation = (props: Props) => {
  const { left, right, className } = props

  const leftRender = Array.isArray(left) ? [...left] : left ? [left] : []
  const rightRender = Array.isArray(right) ? [...right] : right ? [right] : []

  return (
    <div className={classNames(`flex align-center justify-between`, className)}>
      <div className="flex">
        {leftRender?.map((leftItem: NavigationItem, index: number) =>
          leftItem ? (
            <Button
              key={`navigator-${index}`}
              color={leftItem?.color || 'white'}
              className={leftItem?.className || ''}
              onClick={() => {
                leftItem?.onClick && leftItem?.onClick()
              }}
            >
              {`‹ ${leftItem?.text}`}
            </Button>
          ) : null
        )}
      </div>
      <div className="flex">
        {rightRender?.map((rightItem: NavigationItem, index: number) =>
          rightItem ? (
            <Button
              key={`navigator-${index}`}
              color={rightItem?.color || 'white'}
              className={rightItem?.className || ''}
              onClick={() => {
                rightItem?.onClick && rightItem?.onClick()
              }}
            >
              {`${rightItem?.text} ›`}
            </Button>
          ) : null
        )}
      </div>
    </div>
  )
}

export default React.memo(Navigation)
