import { memo, useEffect, useMemo, useCallback } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Page, Loader } from '@wiicamp/decentraland-ui'
import { Icon } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import { Profile } from '@wiicamp/decentraland-dapps/dist/containers'
import { isMobile } from '@wiicamp/decentraland-dapps/dist/lib/utils'
import { t } from '@wiicamp/decentraland-dapps/dist/modules/translation/utils'
import { useParams } from 'react-router-dom'

import { TransferParams, UpdateTicketParams } from '../../../modules/ticket/actions'
import { Ticket as TicketType } from '../../../modules/ticket/types'

import { useTimer } from '../../../lib/timer'
import { PageHeader } from '../../PageHeader'
import { Column } from '../../Layout/Column'

import LayoutPage from '../Layout/LayoutPage/LayoutPage'
import Breadcrumb from '../Breadcrumb'
import { Breadcrumb as BreadcrumbType } from '../Breadcrumb/Breadcrumb.types'

import { TicketItem } from './TicketItem'

import { Props } from './MyTicketPage.types'
import styles from './MyTicketPage.module.scss'

const MyTicketPage = (props: Props) => {
  const {
    wallet,
    isConnecting,
    tickets,
    fetchTicketsLoading,
    transferTicketLoading,
    updateTicketLoading,
    onFetchTickets,
    onTransferTicket,
    onUpdateTicket,
  } = props
  const { address }: { [key: string]: any } = useParams()

  const isCurrentAccount =
    address === undefined || (wallet && wallet.address === address)

  const [hasCopiedAddress, setHasCopiedAddress] = useTimer(1200)

  useEffect(() => {
    onFetchTickets({ address });
  }, [onFetchTickets, address])

  const breadcrumb: BreadcrumbType[] = [
    { name: 'Home', link: '/' },
    {
      name: 'Tickets',
      active: true,
      isBreakHere: true
    }
  ]

  const _onTransferTicket = useCallback((params: TransferParams) => {
    if (!params.to) {
      toast.warning('Please enter the sender address')

      return
    }

    onTransferTicket(params)
  }, [onTransferTicket])

  const _onUpdateTicket = useCallback((params: UpdateTicketParams) => {
    onUpdateTicket(params)
  }, [onUpdateTicket])

  // Redirect to signIn if trying to access current account without a wallet
  // useEffect(() => {
  //   if (isCurrentAccount && !isConnecting && !wallet) {
  //     onRedirect(locations.signIn())
  //   }
  // }, [isCurrentAccount, isConnecting, wallet, onRedirect])

  const ticketList = useMemo(() => {
    return (
      <div className="relative -mx-4 -mt-8 Marketplace-Estates__grid">
        {
          tickets.map((ticket: TicketType) => (
            <TicketItem
              className="mt-8 w-full"
              key={ticket.token_id}
              ticketId={ticket.token_id}
              name={ticket.name}
              ownerName={ticket.ownerName}
              description={''}
              image={ticket.image}
              isCurrentAccount={isCurrentAccount || false}
              onTransfer={_onTransferTicket}
              transferTicketLoading={transferTicketLoading}
              onUpdate={_onUpdateTicket}
              updateTicketLoading={updateTicketLoading}
            />
          ))
        }
      </div>
    )
  }, [tickets, isCurrentAccount, _onTransferTicket, transferTicketLoading, _onUpdateTicket, updateTicketLoading])

  return (
    <LayoutPage viewMode="white">
      <div className={`flex-grow min-h-full overflow-auto ActivityPage__container ${styles.assetsWrapper}`}>
        <div className="Page__header mt-14 lg:mt-28 mb-14">
          <Breadcrumb breadcrumb={breadcrumb} viewMode="dark" />
        </div>

        {
          fetchTicketsLoading ? (
            <Loader size="massive" active />
          ) : null
        }

        <div className="MyTicketPage mb-10">
          {isCurrentAccount ? (
            isConnecting || !wallet ? (
              <Page>
                <Loader size="massive" active />
              </Page>
            ) : (
              ticketList
            )
          ) : address !== undefined ? (
            <>
              <PageHeader>
                <Column>
                  <Profile
                    address={address}
                    size="massive"
                    imageOnly
                    inline={false}
                  />

                  <div className="profile-name">
                    <Profile address={address} textOnly inline={false} />
                  </div>

                  <div className="profile-address">
                    <div className="profile-address-hash">{address}</div>

                    {!isMobile() && (
                      <div>
                        <CopyToClipboard
                          text={address}
                          onCopy={setHasCopiedAddress}
                        >
                          <Icon
                            aria-label="Copy address"
                            aria-hidden="false"
                            className="copy"
                            name="copy outline"
                          />
                        </CopyToClipboard>

                        {hasCopiedAddress && (
                          <span className="profile-copied-text-desktop copied">
                            {t('account_page.copied')}
                          </span>
                        )}
                      </div>
                    )}
                  </div>

                  {isMobile() && (
                    <div className="profile-copy-text-mobile">
                      <CopyToClipboard
                        text={address}
                        onCopy={setHasCopiedAddress}
                      >
                        {hasCopiedAddress ? (
                          <span className="copied">
                            {t('account_page.copied_capitalized')}
                          </span>
                        ) : (
                          <span className="copy">
                            {t('account_page.copy_address')}
                          </span>
                        )}
                      </CopyToClipboard>
                    </div>
                  )}
                </Column>
              </PageHeader>

              {ticketList}
            </>
          ) : null}
        </div>
      </div>
    </LayoutPage>
  )
}

export default memo(MyTicketPage)
