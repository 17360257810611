import { action } from 'typesafe-actions'

import { TicketBrowseOptions, Ticket } from './types'

// Generate Ticket
export const GENERATE_TICKET_REQUEST = '[Request] Generate Ticket'
export const GENERATE_TICKET_SUCCESS = '[Success] Generate Ticket'
export const GENERATE_TICKET_FAILURE = '[Failure] Generate Ticket'
export const GENERATE_TICKET_PENDING = '[Pending] Generate Ticket'

type GenerateParams = {
  name: string
  callback: Function
}

export const generateTicketRequest = (params: GenerateParams) => action(GENERATE_TICKET_REQUEST, { name: params.name, callback: params.callback })

export const generateTicketSuccess = () =>
  action(GENERATE_TICKET_SUCCESS)
export const generateTicketFailure = (error: string) =>
  action(GENERATE_TICKET_FAILURE, { error })
export const generateTicketPending = () =>
  action(GENERATE_TICKET_PENDING)

export type GenerateTicketRequestAction = ReturnType<typeof generateTicketRequest>
export type GenerateTicketSuccessAction = ReturnType<typeof generateTicketSuccess>
export type GenerateTicketFailureAction = ReturnType<typeof generateTicketFailure>
export type GenerateTicketPendingAction = ReturnType<typeof generateTicketPending>

// Fetch Tickets
export const FETCH_TICKETS_REQUEST = '[Request] Fetch Tickets'
export const FETCH_TICKETS_SUCCESS = '[Success] Fetch Tickets'
export const FETCH_TICKETS_FAILURE = '[Failure] Fetch Tickets'

export const fetchTicketsRequest = (options: TicketBrowseOptions) =>
  action(FETCH_TICKETS_REQUEST, options)

export const fetchTicketsSuccess = (
  items: Ticket[],
) => action(FETCH_TICKETS_SUCCESS, { items })

export const fetchTicketsFailure = (error: string) =>
  action(FETCH_TICKETS_FAILURE, { error })

export type FetchTicketsRequestAction = ReturnType<typeof fetchTicketsRequest>
export type FetchTicketsSuccessAction = ReturnType<typeof fetchTicketsSuccess>
export type FetchTicketsFailureAction = ReturnType<typeof fetchTicketsFailure>


// Transfer Ticket
export const TRANSFER_TICKET_REQUEST = '[Request] Transfer Ticket'
export const TRANSFER_TICKET_SUCCESS = '[Success] Transfer Ticket'
export const TRANSFER_TICKET_FAILURE = '[Failure] Transfer Ticket'
export const TRANSFER_TICKET_PENDING = '[Pending] Transfer Ticket'

export type TransferParams = {
  to: string
  tokenId: number
  name: string
  callback: Function
}

export const transferTicketRequest = (params: TransferParams) => action(TRANSFER_TICKET_REQUEST, {
  to: params.to,
  tokenId: params.tokenId,
  name: params.name,
  callback: params.callback,
})

export const transferTicketSuccess = (tokenId: number) =>
  action(TRANSFER_TICKET_SUCCESS, { tokenId })
export const transferTicketFailure = (error: string) =>
  action(TRANSFER_TICKET_FAILURE, { error })
export const transferTicketPending = () =>
  action(TRANSFER_TICKET_PENDING)

export type TransferTicketRequestAction = ReturnType<typeof transferTicketRequest>
export type TransferTicketSuccessAction = ReturnType<typeof transferTicketSuccess>
export type TransferTicketFailureAction = ReturnType<typeof transferTicketFailure>
export type TransferTicketPendingAction = ReturnType<typeof transferTicketPending>

// Update ticket
export const UPDATE_TICKET_REQUEST = '[Request] Update ticket'
export const UPDATE_TICKET_SUCCESS = '[Success] Update ticket'
export const UPDATE_TICKET_FAILURE = '[Failure] Update ticket'
export const UPDATE_TICKET_PENDING = '[Pending] Update ticket'

export type UpdateTicketParams = {
  tokenId: number,
  name: string,
  callback: Function,
}

export const updateTicketRequest = (params: UpdateTicketParams) => action(UPDATE_TICKET_REQUEST, {
  tokenId: params.tokenId,
  name: params.name,
  callback: params.callback,

})

export const updateTicketSuccess = (tokenId: number) => action(UPDATE_TICKET_SUCCESS, { tokenId })
export const updateTicketFailure = (error: string) => action(UPDATE_TICKET_FAILURE, { error })
export const updateTicketPending = () => action(UPDATE_TICKET_PENDING)

export type UpdateTicketRequestAction = ReturnType<typeof updateTicketRequest>
export type UpdateTicketSuccessAction = ReturnType<typeof updateTicketSuccess>
export type UpdateTicketFailureAction = ReturnType<typeof updateTicketFailure>
export type UpdateTicketPendingAction = ReturnType<typeof updateTicketPending>

// Check wallet is on claim list

export const CHECK_WALLET_IS_ON_CLAIM_LIST = '[Request] Check wallet is on claim list'
export const CHECK_WALLET_IS_ON_CLAIM_LIST_SUCCESS = '[Success] Check wallet is on claim list'
export const CHECK_WALLET_IS_ON_CLAIM_LIST_FAILURE = '[Failure] Check wallet is on claim list'
export const CHECK_WALLET_IS_ON_CLAIM_LIST_PENDING = '[Pending] Check wallet is on claim list'

export const checkWalletIsOnClaimListRequest = () => action(CHECK_WALLET_IS_ON_CLAIM_LIST)

export const checkWalletIsOnClaimListSuccess = (isOnClaimList: boolean) => action(CHECK_WALLET_IS_ON_CLAIM_LIST_SUCCESS, { isOnClaimList })
export const checkWalletIsOnClaimListFailure = (error: string) => action(CHECK_WALLET_IS_ON_CLAIM_LIST_FAILURE, { error })
export const checkWalletIsOnClaimListPending = () => action(CHECK_WALLET_IS_ON_CLAIM_LIST_PENDING)

export type CheckWalletIsOnClaimListRequestAction = ReturnType<typeof checkWalletIsOnClaimListRequest>
export type CheckWalletIsOnClaimListSuccessAction = ReturnType<typeof checkWalletIsOnClaimListSuccess>
export type CheckWalletIsOnClaimListFailureAction = ReturnType<typeof checkWalletIsOnClaimListFailure>
export type CheckWalletIsOnClaimListPendingAction = ReturnType<typeof checkWalletIsOnClaimListPending>

// Check wallet is mint(ed) airdrop

export const CHECK_WALLET_IS_MINT_AIRDROP = '[Request] Check wallet is mint(ed) airdrop'
export const CHECK_WALLET_IS_MINT_AIRDROP_SUCCESS = '[Success] Check wallet is mint(ed) airdrop'
export const CHECK_WALLET_IS_MINT_AIRDROP_FAILURE = '[Failure] Check wallet is mint(ed) airdrop'
export const CHECK_WALLET_IS_MINT_AIRDROP_PENDING = '[Pending] Check wallet is mint(ed) airdrop'

export const checkWalletIsMintAirdropRequest = () =>  action(CHECK_WALLET_IS_MINT_AIRDROP)

export const checkWalletIsMintAirdropSuccess = (isMintAirdropBefore: boolean) => action(CHECK_WALLET_IS_MINT_AIRDROP_SUCCESS, { isMintAirdropBefore })
export const checkWalletIsMintAirdropFailure = (error: string) => action(CHECK_WALLET_IS_MINT_AIRDROP_FAILURE, { error })
export const checkWalletIsMintAirdropPending = () => action(CHECK_WALLET_IS_MINT_AIRDROP_PENDING)

export type CheckWalletIsMintAirdropRequestAction = ReturnType<typeof checkWalletIsMintAirdropRequest>
export type CheckWalletIsMintAirdropSuccessAction = ReturnType<typeof checkWalletIsMintAirdropSuccess>
export type CheckWalletIsMintAirdropFailureAction = ReturnType<typeof checkWalletIsMintAirdropFailure>
export type CheckWalletIsMintAirdropPendingAction = ReturnType<typeof checkWalletIsMintAirdropPending>

// Mint ticket airdrop
export const MINT_TICKET_AIRDROP = '[Request] Mint ticket airdrop'
export const MINT_TICKET_AIRDROP_SUCCESS = '[Success] Mint ticket airdrop'
export const MINT_TICKET_AIRDROP_FAILURE = '[Failure] Mint ticket airdrop'
export const MINT_TICKET_AIRDROP_PENDING = '[Pending] Mint ticket airdrop'

export const mintTicketAirdropRequest = (firstName: string, lastName: string) => action(MINT_TICKET_AIRDROP, { firstName, lastName })
export const mintTicketAirdropSuccess = () => action(MINT_TICKET_AIRDROP_SUCCESS)
export const mintTicketAirdropFailure = () => action(MINT_TICKET_AIRDROP_FAILURE)
export const mintTicketAirdropPending = () => action(MINT_TICKET_AIRDROP_PENDING)

export type MintTicketAirdropRequestAction = ReturnType<typeof mintTicketAirdropRequest>
export type MintTicketAirdropSuccessAction = ReturnType<typeof mintTicketAirdropSuccess>
export type MintTicketAirdropFailureAction = ReturnType<typeof mintTicketAirdropFailure>
export type MintTicketAirdropPendingAction = ReturnType<typeof mintTicketAirdropPending>

// Fetch single ticket

export const FETCH_SINGLE_TICKET_REQUEST = '[Request] Fetch Single Ticket'
export const FETCH_SINGLE_TICKET_SUCCESS = '[Success] Fetch Single Ticket'
export const FETCH_SINGLE_TICKET_FAILURE = '[Failure] Fetch Single Ticket'

export const fetchSingleTicketRequest = (ticketId: string | number) => action(FETCH_SINGLE_TICKET_REQUEST, { ticketId })
export const fetchSingleTicketSuccess = (ticket: Ticket) => action(FETCH_SINGLE_TICKET_SUCCESS, { ticket })
export const fetchSingleTicketFailure = (error: string) => action(FETCH_SINGLE_TICKET_FAILURE, { error })

export type FetchSingleTicketRequestAction = ReturnType<typeof fetchSingleTicketRequest>
export type FetchSingleTicketSuccessAction = ReturnType<typeof fetchSingleTicketSuccess>
export type FetchSingleTicketFailureAction = ReturnType<typeof fetchSingleTicketFailure>
