import React, { memo, useCallback, useEffect, useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { t,T } from '@wiicamp/decentraland-dapps/dist/modules/translation/utils'
import { Button, Form, Modal, Dropdown } from '@wiicamp/decentraland-ui'
import { Network } from '@dcl/schemas'
import { Rarity } from '@dcl/schemas'

import { fromMANA, toMANA } from '../../../../lib/mana'
import { ITEM_STATUS, mapNumberToRarity } from "../../../../lib/constant"

import { ManaField } from '../../../ManaField'

import { getStatusName } from '../../../../modules/itemNFT/utils'
import { locations } from '../../../../modules/routing/locations'

import { ReactComponent as IconRarity } from '../../../../asset/icons/ic-rarity.svg'
import { ReactComponent as IconDots } from '../../../../asset/icons/ic-dots.svg'

import cssStyles from './UploadedNFTList.module.scss'

import { Props } from './UploadedNFTCard.types'

const UploadedNFTCard = (props: Props) => {
  const {
    item,
    saleItemUserCreation,
    saleItemUserCreationLoading,
    deleteItemUserCreation,
    isLoadingDeleteItemUserCreation,
    setItemUserCreationEdit,
  } = props

  const [confirmPrice, setConfirmPrice] = useState('')
  const [showConfirm, setShowConfirm] = useState(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)

  const history = useHistory()

  const handleSubmit = useCallback(() => {
    saleItemUserCreation(item, Number(confirmPrice))
  }, [item, confirmPrice, saleItemUserCreation])

  const handleEditItemUserCreation = useCallback(() => {
    setItemUserCreationEdit(item)
    history.push(locations.editNFTItemLOM(item._id))
  }, [setItemUserCreationEdit, item])

  const handleSubmitDelete = useCallback(() => {
    deleteItemUserCreation(item._id, handleSetState<boolean>(false, setShowConfirmDelete))
  }, [item, deleteItemUserCreation])

  const handleSetState = useCallback(<T extends {}> (
    value: T,
    setState:  React.Dispatch<React.SetStateAction<T>>,
    callback?: Function,
  ) => () => {
    setState(value)
    callback?.()
  }, [])

  useEffect(() => {
    // Clear confirm price when closing the confirm modal
    if (!showConfirm) {
      setConfirmPrice('')
    }
  }, [item, showConfirm, setConfirmPrice])

  const rarityName = useMemo(() => mapNumberToRarity[Number(item.rarity)] || '',[item.rarity])
  const statusName = useMemo(() => getStatusName(item.status),[item.status])
  const [light, dark] = Rarity.getGradient(rarityName as Rarity)
  const background = `linear-gradient(-125deg,${light} 0%,${dark} 100%)`
  const cssStyle = { '--fillColor': dark } as React.CSSProperties

  return (
    <>
      <div className={`${cssStyles.AssetCardLink} mt-8`}>
        <div className={cssStyles.AssetCard} style={cssStyle}>
          <div className={cssStyles.AssetCardContainer} style={{ background }}>
            <div className={cssStyles.AssetCardWrapper}>
              <div className={cssStyles.AssetCardContent}>
                <div className={cssStyles.AssetCardInfo}>
                  <div className={cssStyles.AssetCardTime}>
                    <span>
                      {/* {order && parcel && (
                      <div className={cssStyles.AssetCardExpired}>
                        {formatDistanceToNow(+order?.expiresAt || 0, {
                          addSuffix: true
                        })}
                      </div>
                    )} */}
                    </span>
                    <div className={cssStyles.AssetCardHolder}>
                      {/* <Profile
                      address={asset.contractAddress}
                      textOnly
                      inline={false}
                    /> */}
                      {/* {shortenAddress(asset.contractAddress, 5, 4)} */}
                      {/* <sup>[martian]</sup> */}
                    </div>
                  </div>

                  <div className='flex items-center justify-between'>
                    <div>
                      <div className={cssStyles.AssetCardName}>
                        {item.name.toUpperCase()}
                      </div>
                      <div className={cssStyles.AssetCardMaterial}>
                        {item.subCategory?.name || item.category.name || ''}
                        {/* {wearable && <div>iron</div>}
                  {parcel && (
                    <>
                      <i className={cssStyles.AssetCardMaterialIcon} />
                      {`${parcel.x},${parcel.y}`}
                    </>
                  )}
                  {estate && (
                    <>
                      <i className={cssStyles.AssetCardMaterialIcon} />
                      {`${estate.parcels[0].x},${estate.parcels[0].y}`}
                    </>
                  )} */}
                      </div>
                    </div>

                    <div className={cssStyles.AssetCardTopDropdown}>
                      <Dropdown direction="left" icon={<IconDots className={cssStyles.Icon}/>}>
                        <Dropdown.Menu>
                          <Dropdown.Item text="Edit" onClick={handleEditItemUserCreation}/>
                          <Dropdown.Item text="Delete" onClick={handleSetState<boolean>(true, setShowConfirmDelete)}/>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>

                <div className={cssStyles.AssetCardImage}>
                  <div className="absolute top-0 left-0 w-full h-full">
                    {/* <AssetImage
                    asset={asset}
                    showMonospace
                    mapHeight={102 * width / 135}
                  /> */}
                    <img src={item.image} alt="" />
                  </div>
                </div>

                <div className={cssStyles.AssetCardQuality}>
                  <div className={cssStyles.AssetCardIconMint}>
                    {statusName}
                    {/* {(parcel || estate) && (
                    <div>
                      <img src={IconLand} alt="" />
                    </div>
                  )} */}

                    {/* <div>
                    <img src={IconMint} alt="" />
                  </div>
                  <div>
                    <img src={IconMint} alt="" />
                  </div>
                  <div>
                    <img src={IconMint} alt="" />
                  </div>
                  <div>
                    <img src={IconMint} alt="" />
                  </div>
                  <div>
                    <img src={IconMint} alt="" />
                  </div>
                  <div>
                    <img src={IconMint} alt="" />
                  </div>
                  <div>
                    <img src={IconMint} alt="" />
                  </div>
                  <div>
                    <img src={IconMint} alt="" />
                  </div>
                  <div>
                    <img src={IconMint} alt="" />
                  </div> */}
                  </div>

                  <Button
                    inverted
                    primary
                    size='tiny'
                    className={item.status === ITEM_STATUS.APPROVE.toUpperCase() ? cssStyles.show : cssStyles.hidden}
                    onClick={handleSetState<boolean>(true, setShowConfirm)}
                    loading={saleItemUserCreationLoading}
                    disabled={saleItemUserCreationLoading}
                  >
                    Sale
                  </Button>

                  {/* <div className={cssStyles.AssetCardValue}>
                    <span>
                      {price && (
                      <Mana
                        network={asset.network}
                        inline
                        className={classOfMana()}
                      >
                        {formatMANA(price)}
                      </Mana>
                    )}
                    </span>
                    {order && parcel && (
                    <div className={cssStyles.AssetCardExpired}>
                      {formatDistanceToNow(+order?.expiresAt || 0, {
                        addSuffix: true
                      })}
                    </div>
                  )}
                  </div> */}
                </div>
              </div>
            </div>

            <div className={cssStyles.AssetCardRarity}>
              <div className={cssStyles.AssetCardRarityWrapper}>
                <div className={cssStyles.AssetCardRarityContent}>
                  <IconRarity />
                </div>

                <div className={cssStyles.AssetCardRarityText}>
                  <span>{rarityName}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="small"
        open={showConfirm}
        className='ConfirmPriceModal'
        onClose={handleSetState<boolean>(false, setShowConfirm)}
      >
        <Modal.Header size="large">
          {t('sell_page.title')}
        </Modal.Header>

        <Form onSubmit={handleSubmit}>
          <Modal.Content>
            <p className={['subtitle'].join(' ')}>
              <T
                id={'sell_page.subtitle'}
                values={{ name: <b className="primary-text">{item.name}</b> }}
              />
            </p>

            <br />

            <ManaField
              className="mana-input"
              label={t('sell_page.price')}
              network={Network.ETHEREUM}
              placeholder='1000'
              value={confirmPrice}
              onChange={(_event, props) => {
                const newPrice = fromMANA(props.value)
                setConfirmPrice(toMANA(newPrice))
              }}
            />
          </Modal.Content>

          <Modal.Actions>
            <div
              className="ui button LOM-cancel-btn"
              onClick={handleSetState<boolean>(false, setShowConfirm, handleSetState<string>('', setConfirmPrice))}
            >
              {t('global.cancel')}
            </div>

            <Button
              className="LOM-submit-btn"
              type="submit"
              primary
              loading={saleItemUserCreationLoading}
              disabled={saleItemUserCreationLoading || !fromMANA(confirmPrice)}
            >
              {t('global.proceed')}
            </Button>
          </Modal.Actions>
        </Form>
      </Modal>

      {/* Modal delete item user creation */}
      <Modal
        size="small"
        open={showConfirmDelete}
        className='ConfirmPriceModal'
        onClose={handleSetState<boolean>(false, setShowConfirmDelete)}
      >
        <Modal.Header>Are you sure?</Modal.Header>

        <Modal.Content>
          You are about to <span className={cssStyles.ModalDeleteError}>Delete</span> your item{' '}. Do you want to proceed?
        </Modal.Content>

        <Modal.Actions>
          <div
            className="ui button LOM-cancel-btn"
            onClick={handleSetState<boolean>(false, setShowConfirmDelete)}
          >
            {t('global.cancel')}
          </div>

          <Button
            className="LOM-submit-btn"
            primary
            loading={isLoadingDeleteItemUserCreation}
            disabled={isLoadingDeleteItemUserCreation}
            onClick={handleSubmitDelete}
          >
            {t('global.proceed')}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default memo(UploadedNFTCard)
