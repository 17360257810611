import { RootState } from '../reducer'

export const getState = (state: RootState) => state.uploadItem
export const getTotalNFTItems = (state: RootState) => getState(state).data
export const getItemUserCreationEdit = (state: RootState) => getState(state).itemUserCreationEdit

export const getIsLoadingCreateNewItemNFT = (state: RootState) => getState(state).isLoadingCreateNewItemNFT
export const getIsLoadingDeleteItemUserCreation = (state: RootState) => getState(state).isLoadingDeleteItemUserCreation
export const getIsLoadingEditItemUserCreation = (state: RootState) => getState(state).isLoadingEditItemUserCreation
export const getIsLoadingFetchItemUserCreationEdit = (state: RootState) => getState(state).isLoadingFetchItemUserCreationEdit
export const getIsLoadingGetItemsNFTFromLocalStorage = (state: RootState) => getState(state).isLoadingGetItemsNFTFromLocalStorage
export const getIsLoadingFetchMyItemsCreation = (state: RootState) => getState(state).isLoadingFetchMyItemsCreation
