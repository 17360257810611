import React from 'react'
import { LazyImage } from 'react-lazy-images'

import { Props } from './LazyImage.types'

const LOMLazyImage = (props: Props) => {
  const { src, alt, className, style } = props
  return (
    <LazyImage
      src={src}
      alt={alt}
      placeholder={({ imageProps, ref }) => (
        <img
          ref={ref}
          className={`h-full w-full ${className}`}
          height="100%"
          width="100%"
          src="/images/placeholder.jpg"
          alt={imageProps.alt}
          style={style}
        />
      )}
      actual={({ imageProps }) => (
        <img
          className={className}
          height="auto"
          width="100%"
          alt={imageProps.alt}
          style={style}
          {...imageProps}
        />
      )}
    />
  )
}

export default React.memo(LOMLazyImage)
