import React from 'react'
import classNames from 'classnames'

import LayoutPage from '../Layout/LayoutPage/LayoutPage'
import Breadcrumb from '../Breadcrumb'
import { ContentItem } from './ContentItem'
import { CircleTitle } from './CircleTitle'
import { Title } from './Title'
import { Blank } from './Blank'

import { ContentItemType, RenderContentItem } from './RoadmapPage.types'

import earthImg from '../../../asset/images/roadmap/earth.png'
import marImg from '../../../asset/images/roadmap/mar.png'
import brandBlack from '../../../asset/images/brand.svg'

import cssStyles from './styles.module.scss'

const RoadmapPage = () => {
  const breadcrumb = [
    { name: 'Home', link: '/' },
    { name: 'Roadmap', active: true, isBreakHere: true },
  ];
  const roadmapData = [
    { type: 'circle-title', value: '2021', active: ['left', 'right'] },
    {
      type: 'content',
      active: ['left', 'right'],
      className: 'pt-10 pb-6',
      children: [
        {
          type: 'image',
          className: 'absolute right-0 bottom-0 transform translate-y-1/3 ',
          value: (
            <img src={earthImg} alt="earth" className=" animation-flow-3 " />
          ),
        },
        {
          type: 'image',
          className: 'absolute  bottom-0 transform translate-y-1/3',
          value: <img src={marImg} alt="mar" className=" animation-flow-2" />,
        },
      ],
    },
    { type: 'title', value: 'Q3', active: true },
    {
      type: 'content',
      active: ['left', 'right'],
      children: [
        {
          type: 'text',
          className: 'lg:my-4 my-2',
          value: (
            <>
              <p>Concept and Idea</p>
              <p>development begins</p>
            </>
          ),
        },
      ],
    },
    {
      type: 'content',
      active: ['left', 'right'],
      children: [
        {
          type: 'text',
          className: "lg:my-4 my-2 flex items-center",
          value: (
            <>
              <p>Gameplay, Artstyle</p>
            </>
          ),
        },
        {
          type: 'text',
          className: 'lg:my-4 my-2',
          value: (
            <>
              <p>Blockchain research</p>
              <p>to design a blockchain for game</p>
            </>
          ),
        },
      ],
    },
    { type: 'blank', className: 'pb-4', active: ['left', 'right'] },
    { type: 'title', value: 'Q4', active: true },
    {
      type: 'content',
      active: ['left', 'right'],
      children: [
        {
          type: 'text',
          value: (
            <>
              <p>Core game development begins</p>
            </>
          ),
        },
      ],
    },
    {
      type: 'content',
      active: ['left', 'right'],
      children: [
        {
          type: 'text',
          className: 'lg:my-4 my-2',
          value: (
            <>
              <p>Release Tokenomics & Whitepaper</p>
            </>
          ),
        },
      ],
    },
    {
      type: 'content',
      active: ['left', 'right'],
      children: [
        {
          type: 'text',
          className: 'lg:my-4 my-2',
          value: (
            <>
              <p>Raise seed and private sales</p>
            </>
          ),
        },
      ],
    },
    {
      type: 'content',
      active: ['left', 'right'],
      // isNow: ['right'], // WORKING UNTIL NOW
      children: [
        {},
        {
          type: 'text',
          className: 'lg:my-4 my-2',
          value: (
            <>
              <img src={brandBlack} alt="brandBlack" className="h-4" />
              <p>Website</p>
              <p>is being built</p>
            </>
          ),
        },
      ],
    },
    {
      type: 'content',
      active: ['left', 'right'],
      children: [
        {},
        {
          type: 'text',
          value: (
            <>
              <p>2D map's completed,</p>
              <p>estates situation published</p>
            </>
          ),
        },
      ],
    },
    { type: 'circle-title', value: '2022', active: ['left', 'right'] },
    {
      type: 'content',
      active: ['left'],
      isNow: ['left'],
      children: [

      ],
    },
    {
      type: 'content',
      // active: ['left', 'right'],
      children: [
        {},
        {
          type: 'text',
          className: 'lg:my-4 my-2',
          value: (
            <>
              <p>Launch sale Ticket to join L.O.M</p>
            </>
          ),
        },
      ],
    },
    {
      type: 'content',
      // active: ['left', 'right'],
      children: [
        {},
        {
          type: 'text',
          className: 'lg:my-4 my-2',
          value: (
            <>
              <p>Launch marketplace (sale Lands, Items)</p>
            </>
          ),
        },
      ],
    },
    {
      type: 'content',
      // active: ['left', 'right'],
      isNow: ['left'], // WORKING UNTIL NOW
      children: [
        {},
        {
          type: 'text',
          className: 'lg:my-4 my-2',
          value: (
            <>
              <p>Launch Create to Earn</p>
            </>
          ),
        },
      ],
    },
    {
      type: 'content',
      children: [
        {
          type: 'text',
          value: (
            <>
              <p>
                <b>Recruit</b>
              </p>
              <p>Designers, artists,</p>
              <p>technicians</p>
            </>
          ),
        },
      ],
    },
    { type: 'title', value: 'Q2' },
    // {
    //   type: 'content',
    //   children: [
    //     {
    //       type: 'text',
    //       className: 'flex items-center',
    //       value: (
    //         <>
    //           <p className="font-bold uppercase">IDO & Listing on DEX</p>
    //         </>
    //       ),
    //     },
    //   ],
    // },
    {
      type: 'content',
      children: [
        {},
        {
          type: 'text',
          value: (
            <>
              <p>
                <b>DEFI</b>
              </p>
              <p>Launch Farming </p>
              <p>& Staking LOM, NFT</p>
            </>
          ),
        },
        {
          type: 'text',
          className: 'flex items-center',
          value: (
            <>
              <p>Build game begins</p>
            </>
          ),
        },
      ],
    },
    {
      type: 'content',
      children: [
        {
          type: 'text',
          className: 'flex items-center',
          value: (
            <>
              <p>Builder land</p>
            </>
          ),
        },
        {
          type: 'text',
          className: 'flex items-center lg:my-4 my-2',
          value: (
            <>
              <p>Launch builder avatar (Character)</p>
            </>
          ),
        },
      ],
    },
    {
      type: 'content',
      children: [
        {
          type: 'text',
          className: 'lg:my-4 my-2',
          value: (
            <>
              <p>Storyboard's completed</p>
            </>
          ),
        },
      ],
    },
    { type: 'title', value: 'Q3' },
    {
      type: 'content',
      children: [
        {
          type: 'text',
          value: (
            <>
              <p>
                {' '}
                <b>Items Sales 1</b>{' '}
              </p>
              <p>
                {' '}
                <i>Special Event Items -</i>
              </p>
              <p>
                {' '}
                <i>Apparel -</i>
              </p>
              <p>
                {' '}
                <i>Technology -</i>
              </p>
            </>
          ),
        },
        {
          type: 'text',
          className: ' flex items-center',
          value: (
            <>
              <p> Mars’ ecosystem </p>
              <p> is being built </p>
            </>
          ),
        },
      ],
    },
    {
      type: 'content',
      children: [
        {
          type: 'text',
          className: 'lg:my-4 my-2',
          value: (
            <>
              <p>
                <b>Items Sales 2</b>{' '}
              </p>
              <p>
                {' '}
                <i>Farming -</i>
              </p>
              <p>
                {' '}
                <i>Mining & Cooking -</i>
              </p>
            </>
          ),
        },
        {
          type: 'text',
          className: ' flex items-center',
          value: (
            <>
              <p>Early prototypes</p>
            </>
          ),
        },
      ],
    },
    {
      type: 'content',
      children: [
        {},
        {
          type: 'text',
          className: ' flex items-center',
          value: (
            <>
              <p>
                <b>BUILDER PORTAL,</b>
              </p>
              <p>
                <b>METAVERSE</b>
              </p>
            </>
          ),
        },
      ],
    },
    { type: 'title', value: 'Q4' },
    {
      type: 'content',
      children: [
        {
          type: 'text',
          // className: 'lg:my-4 my-2',
          value: (
            <>
              <p>
                <b>Items Sales 3</b>
              </p>
              <p>
                <i>Construction & others -</i>
              </p>
            </>
          ),
        },
      ],
    },
    {
      type: 'content',
      children: [
        {},
        {
          type: 'text',
          // className: 'lg:my-4 my-2',
          value: (
            <>
              <p>Modeling</p>
            </>
          ),
        },
      ],
    },
    {
      type: 'content',
      children: [
        {},
        {
          type: 'text',
          className: "lg:my-4 my-2 flex items-center",
          value: (
            <>
              <p>Audio</p>
            </>
          ),
        },
      ],
    },
    { type: 'blank', className: 'py-4' },
    {
      type: 'title',
      className: 'rounded-full px-4',
      value: 'DEC 8th, 2022',
    },
    {
      type: 'event',
      value: (
        <div className="my-4 text-center text-gray">
          <p> “Mars Close Approach” day. </p>
          <p> Mars and Earth Aligns </p>
          <p className="uppercase font-bold text-2xl"> VR GAME RELEASES </p>
          <p className="pb-14">
            <b className="uppercase"> ... Moving forward</b>: continue to
            improve the graphic, sound, and gameplay{' '}
          </p>
        </div>
      ),
    },
  ];

  return (
    <LayoutPage viewMode="white">
      <div
        className={classNames(
          "flex-grow overflow-auto Roadmap__background",
          "min-h-full  dark",
          cssStyles.roadmapPage
        )}
      >
        <div className="Roadmap__section">
          <div className="Page__header mt-16 lg:mt-28">
            <Breadcrumb breadcrumb={breadcrumb} viewMode="dark" />
          </div>

          <div className={cssStyles.roadmapDescription}>
            <p>
              We have planned out our promotional efforts, coin launch, and game
              development to maximize sustainable long-term growth. It takes
              time to create a high-quality product, and we won’t be cutting
              corners.
            </p>
          </div>

          <div>
            {roadmapData.map(
              (item: ContentItemType, index: number): RenderContentItem => {
                if (item?.type === "circle-title") {
                  return <CircleTitle key={index} index={index} item={item} />;
                }

                if (item?.type === "title") {
                  return <Title key={index} index={index} item={item} />;
                }

                if (item.type === "blank") {
                  return <Blank key={index} index={index} item={item} />;
                }

                if (item.type === "content") {
                  const itemActiveArray = Array.isArray(item?.active)
                    ? item?.active
                    : [];

                  return (
                    <div
                      key={index}
                      className={classNames(
                        "flex Roadmap__map",
                        itemActiveArray?.includes("left") && "active-left",
                        itemActiveArray?.includes("right") && "active-right",
                        item?.isNow?.includes("left") && "now-left",
                        item?.isNow?.includes("right") && "now-right",
                        item?.className
                      )}
                    >
                      <ContentItem
                        data={item?.children?.[0] || {}}
                        side="left"
                        active={itemActiveArray?.includes("left")}
                      />
                      <ContentItem
                        data={item?.children?.[1] || {}}
                        side="right"
                        active={itemActiveArray?.includes("right")}
                      />
                    </div>
                  );
                }

                if (item?.type === "event") {
                  return (
                    <React.Fragment key={index}>{item?.value}</React.Fragment>
                  );
                }

                return null;
              }
            )}
          </div>
        </div>
      </div>
    </LayoutPage>
  );
};

export default React.memo(RoadmapPage);
