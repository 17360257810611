// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_tokenomic__RsxMp {\n  flex-grow: 1;\n  min-height: 100%;\n  overflow: auto;\n  color: white;\n  background-color: black;\n}\n\n.styles_tokenomic__RsxMp .styles_totalSupply__3OLGj {\n  margin-bottom: 0.5rem;\n  color: var(--color-white-1);\n  font-size: 0.85rem;\n  line-height: 1.233rem;\n}\n\n.styles_tokenomic__RsxMp .styles_totalSupplyNumber__V5Fe_ {\n  color: var(--color-green);\n  text-transform: uppercase;\n  font-size: 1.083rem;\n  line-height: 1.566rem;\n}\n\n.styles_tokenomic__RsxMp .styles_totalSupplyNumber__V5Fe_ b {\n  font-size: 1.25rem;\n}\n\n.styles_tokenomic__RsxMp .styles_pageContent__2Almy {\n  font-size: 0.85rem;\n  line-height: 1.233rem;\n}\n\n.styles_tokenomic__RsxMp .styles_divisionTable__2tdly table {\n  font-size: 0.708rem;\n  line-height: 1.233rem;\n}\n", "",{"version":3,"sources":["webpack://src/components/LOM/TokenomicPage/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,cAAc;EACd,YAAY;EACZ,uBAAuB;AAAA;;AALzB;EAQI,qBAAqB;EACrB,2BAA2B;EAC3B,kBAAkB;EAClB,qBAAqB;AAAA;;AAXzB;EAcM,yBAAyB;EACzB,yBAAyB;EACzB,mBAAmB;EACnB,qBAAqB;AAAA;;AAjB3B;EAoBQ,kBAAkB;AAAA;;AApB1B;EA0BI,kBAAkB;EAClB,qBAAqB;AAAA;;AA3BzB;EAgCM,mBAAmB;EACnB,qBAAqB;AAAA","sourcesContent":[".tokenomic {\n  flex-grow: 1;\n  min-height: 100%;\n  overflow: auto;\n  color: white;\n  background-color: black;\n\n  .totalSupply {\n    margin-bottom: 0.5rem;\n    color: var(--color-white-1);\n    font-size: 0.85rem;\n    line-height: 1.233rem;\n\n    &Number {\n      color: var(--color-green);\n      text-transform: uppercase;\n      font-size: 1.083rem;\n      line-height: 1.566rem;\n\n      b {\n        font-size: 1.25rem;\n      }\n    }\n  }\n\n  .pageContent {\n    font-size: 0.85rem;\n    line-height: 1.233rem;\n  }\n\n  .divisionTable {\n    table {\n      font-size: 0.708rem;\n      line-height: 1.233rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tokenomic": "styles_tokenomic__RsxMp",
	"totalSupply": "styles_totalSupply__3OLGj",
	"totalSupplyNumber": "styles_totalSupplyNumber__V5Fe_",
	"pageContent": "styles_pageContent__2Almy",
	"divisionTable": "styles_divisionTable__2tdly"
};
export default ___CSS_LOADER_EXPORT___;
