// import { useMemo } from 'react'
import PropTypes from 'prop-types'

import { BackgroundLine } from '../BackgroundLine'
import { RandomNumber } from '../RandomNumber'

import { Props } from './BrandAnimation.types'

import imgPart2 from '../../../asset/images/brand/brand-loading-part-2.svg'
import imgPartA from '../../../asset/images/brand/brand-A.svg'
import imgPartR from '../../../asset/images/brand/brand-R.svg'
import imgPartL from '../../../asset/images/brand/brand-L.svg'
import imgPartE from '../../../asset/images/brand/brand-E.svg'
import imgPartF from '../../../asset/images/brand/brand-F.svg'
import imgPartI from '../../../asset/images/brand/brand-I.svg'
import imgPartM from '../../../asset/images/brand/brand-M.svg'
import imgPartS from '../../../asset/images/brand/brand-S.svg'
// import classNames from 'classnames'

const BrandAnimation = (props: Props) => {
  const { isRendered } = props
  // const isDevelopmentMode = useMemo(() => process.env.REACT_APP_ENVIRONMENT === 'development', [])

  return (
    <div
      className={`
        LayoutHome__banner-loading
        ${isRendered ? 'rendered' : ''} show-new-loading-background
      `}
    >
      <div className="mx-auto flex lg:items-end LayoutHome__content-loading">
        <div className="flex flex-col items-center lg:flex-row lg:justify-center">
          <div className="flex justify-center flex-none w-4/5 mt-40 md:justify-start sm:w-3/5 lg:w-3/5 lg:mt-0 LayoutHome__brand-loading ">
            <div className="relative brand-L">
              <BackgroundLine line={2} timeLife={6} />
              <img
                src={imgPartL}
                className="h-full"
                height="100%"
                width="auto"
                alt=""
              />
            </div>
            <div className="relative brand-I">
              <BackgroundLine line={2} timeLife={6} />
              <img
                src={imgPartI}
                className="h-full"
                height="100%"
                width="auto"
                alt=""
              />
            </div>
            <div className="relative brand-F">
              <BackgroundLine line={2} timeLife={6} />
              <img
                src={imgPartF}
                className="h-full"
                height="100%"
                width="auto"
                alt=""
              />
            </div>
            <div className="relative brand-E">
              <BackgroundLine line={2} timeLife={6} />
              <img
                src={imgPartE}
                className="h-full"
                height="100%"
                width="auto"
                alt=""
              />
            </div>

            {imgPart2 && (
              <div className="flex justify-center w-20 brand-O">
                <img src={imgPart2} className="w-10" alt="" />
                <svg className="progress-ring">
                  <circle
                    className="progress-ring__circle"
                    stroke="#B1E269"
                    strokeWidth="14"
                    fill="transparent"
                    r="35"
                    cx="34"
                    cy="34"
                  />
                </svg>
              </div>
            )}

            <div className="relative brand-M">
              <BackgroundLine line={2} timeLife={6} />
              <img
                src={imgPartM}
                className="h-full"
                height="100%"
                width="auto"
                alt=""
              />
            </div>
            <div className="relative brand-A">
              <BackgroundLine line={2} timeLife={6} />
              <img
                src={imgPartA}
                className="h-full"
                height="100%"
                width="auto"
                alt=""
              />
            </div>
            <div className="relative brand-R">
              <BackgroundLine line={2} timeLife={6} />
              <img
                src={imgPartR}
                className="h-full"
                height="100%"
                width="auto"
                alt=""
              />
            </div>
            <div className="relative brand-S">
              <BackgroundLine line={2} timeLife={6} />
              <img
                src={imgPartS}
                className="h-full"
                height="100%"
                width="auto"
                alt=""
              />
            </div>
          </div>

          <div className="relative flex-none mt-20 uppercase lg:flex-auto LayoutHome__banner-loading__content lg:mt-0 sm:px-8">
            <BackgroundLine line={7} timeLife={3} />
            <div className="px-10 lg:px-0">
              <RandomNumber length={6} />
              shaping the economy
              <RandomNumber length={3} />
              <RandomNumber length={8} />
              <RandomNumber length={10} />
              <RandomNumber length={7} />
              <RandomNumber length={3} />
              influencing the politic
              <RandomNumber length={6} />
              venturing out
              <RandomNumber length={4} />
              satisfying my wanderlust
              <RandomNumber length={5} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

BrandAnimation.propTypes = {
  isRendered: PropTypes.bool.isRequired
}

export default BrandAnimation
