import { put, takeEvery, all } from '@redux-saga/core/effects'
import { select, call } from 'redux-saga/effects'
import { NFTCategory } from '@dcl/schemas'
import { toast } from 'react-toastify'

import {
  // Post new estate
  POST_NEW_ESTATE_REQUEST,
  PostNewEstateRequestAction,
  postNewEstateSuccess,
  postNewEstateFailure,

  // Set land to estate
  SET_LAND_TO_ESTATE_REQUEST,
  SetLandToEstateRequestAction,
  setLandToEstateSuccess,
  setLandToEstateFailure,

  // Remove land from estate
  REMOVE_LAND_FROM_ESTATE_REQUEST,
  RemoveLandFromEstateRequestAction,
  removeLandFromEstateSuccess,
  removeLandFromEstateFailure,

  // Get NFTs by wallet address
  GET_ESTATES_BY_WALLET_ADDRESS_REQUEST,
  GetEstatesByWalletAddressRequestAction,
  getEstatesByWalletAddressSuccess,
  getEstatesByWalletAddressFailure,

  // Get lands by wallet address
  GET_LANDS_BY_WALLET_ADDRESS_REQUEST,
  GetLandsByWalletAddressRequestAction,
  getLandsByWalletAddressSuccess,
  getLandsByWalletAddressFailure,
} from './actions'

import getContracts from '../../lib/contracts'
import { isDevelopment } from '../../lib/environment'
import { getWallet } from '../wallet/selectors'
import { estateBuilderAPI } from '../vendor/estateBuilder'
import { SortBy } from '../routing/types'

const LIMIT_PAGE_SIZE = 10

export function* estateSaga() {
  yield takeEvery(POST_NEW_ESTATE_REQUEST, handlePostNewEstate)
  yield takeEvery(SET_LAND_TO_ESTATE_REQUEST, handleSetLandToEstate)
  yield takeEvery(REMOVE_LAND_FROM_ESTATE_REQUEST, handleRemoveLandFromEstate)
  yield takeEvery(GET_ESTATES_BY_WALLET_ADDRESS_REQUEST, handleGetEstatesByWalletAddress)
  yield takeEvery(GET_LANDS_BY_WALLET_ADDRESS_REQUEST, handleGetLandsByWalletAddress)
}

function* handlePostNewEstate(action: PostNewEstateRequestAction) {
  try {
    const wallet: ReturnType<typeof getWallet> = yield select(getWallet)

    if (!wallet) {
      throw new Error('A defined wallet is required!')
    }

    const { lands, name, description } = action.payload
    const { landRegistryContract } = getContracts();

    const xArray = lands.map((item: any) => item.data.parcel.x)
    const yArray = lands.map((item: any) => item.data.parcel.y)

    const fetchCreateEstateWithMetadata: { [key: string]: any } = yield landRegistryContract.methods
      .createEstateWithMetadata(xArray, yArray, wallet.address, `[0, ${name}, ${description}]`).send({
        from: wallet.address
      })

    if (isDevelopment) {
      console.log(`🆘 src/modules/estate/sagas.ts`); // eslint-disable-line no-console
      console.log(fetchCreateEstateWithMetadata); // eslint-disable-line no-console
      console.log('%c => fetchCreateEstateWithMetadata ', 'background: #f40; color: #fff'); // eslint-disable-line no-console
    }

    // TODO: reload nfts (function: onBrowse)

    toast.success(`Create estate successfully!`)

    yield put(postNewEstateSuccess({}));
  } catch (error) {
    yield put(postNewEstateFailure({}))
  }
}

function* handleSetLandToEstate(action: SetLandToEstateRequestAction) {
  try {
    const wallet: ReturnType<typeof getWallet> = yield select(getWallet)
    const { landRegistryContract } = getContracts();
    const { lands, estateId } = action.payload
    let fetchTransferManyLandToEstate: { [key: string]: any }

    if (!wallet) {
      throw new Error('A defined wallet is required!')
    }

    if (lands.length > 1) {
      const xArray = lands.map((item: any) => item.data.parcel.x)
      const yArray = lands.map((item: any) => item.data.parcel.y)

      fetchTransferManyLandToEstate = yield landRegistryContract.methods
        .transferManyLandToEstate(xArray, yArray, estateId).send({
          from: wallet.address
        })
    } else {
      fetchTransferManyLandToEstate = yield landRegistryContract.methods
        .transferLandToEstate(lands[0].data.parcel.x, lands[0].data.parcel.y, estateId).send({
          from: wallet.address
        })
    }

    if (isDevelopment) {
      console.log(`🆘 src/modules/estate/sagas.ts`); // eslint-disable-line no-console
      console.log(fetchTransferManyLandToEstate); // eslint-disable-line no-console
      console.log('%c => fetchTransferManyLandToEstate ', 'background: #f40; color: #fff'); // eslint-disable-line no-console
    }

    toast.success(`Update estate successfully!`)

    yield put(setLandToEstateSuccess({}))
  } catch (error) {
    yield put(setLandToEstateFailure({}))
  }
}

function* handleRemoveLandFromEstate(action: RemoveLandFromEstateRequestAction) {
  try {
    const wallet: ReturnType<typeof getWallet> = yield select(getWallet)
    const { lands, estateId } = action.payload
    const { landRegistryContract, estateRegistryContract } = getContracts();
    let fetchRemoveManyLandToEstate: { [key: string]: any }

    if (!wallet) {
      throw new Error('A defined wallet is required!')
    }

    const tokenIds: { [key: string]: any } = yield all(
      lands.map(({ x, y }: { [key: string]: any }) => landRegistryContract.methods.encodeTokenId(x, y).call())
    )

    if (lands.length === 1) {
      fetchRemoveManyLandToEstate = yield estateRegistryContract.methods
        .transferLand(estateId, tokenIds[0], wallet.address).send({
          from: wallet.address
        })
    } else {
      fetchRemoveManyLandToEstate = yield estateRegistryContract.methods
        .transferManyLands(estateId, tokenIds, wallet.address).send({
          from: wallet.address
        })
    }

    if (isDevelopment) {
      console.log(`🆘 src/modules/estate/sagas.ts`); // eslint-disable-line no-console
      console.log(fetchRemoveManyLandToEstate); // eslint-disable-line no-console
      console.log('%c => fetchRemoveManyLandToEstate ', 'background: #f40; color: #fff'); // eslint-disable-line no-console
    }

    toast.success(`Remove land${lands.length !== 1 ? 's' : ''} successfully!`)

    yield put(removeLandFromEstateSuccess({}))
  } catch (error) {
    yield put(removeLandFromEstateFailure({}))
  }
}

function* handleGetEstatesByWalletAddress(action: GetEstatesByWalletAddressRequestAction) {
  try {
    const wallet: ReturnType<typeof getWallet> = yield select(getWallet)

    if (!wallet) {
      throw new Error('A defined wallet is required!')
    }

    const { page, isLoadMore } = action.payload
    const filter = {
      owner: wallet.address,
      first: LIMIT_PAGE_SIZE,
      skip: (page - 1) * LIMIT_PAGE_SIZE,
      sortBy: SortBy.NEWEST,
      category: NFTCategory.ESTATE,
    }

    const { data, total }: { data: any[]; total: number } = yield call(
      [estateBuilderAPI, 'fetch'],
      filter
    )

    yield put(getEstatesByWalletAddressSuccess({
      isLoadMore,
      estates: data.map(item => item.nft),
      total,
      page,
    }))
  } catch (error) {
    yield put(getEstatesByWalletAddressFailure({}))
  }
}

function* handleGetLandsByWalletAddress(action: GetLandsByWalletAddressRequestAction) {
  try {
    const wallet: ReturnType<typeof getWallet> = yield select(getWallet)

    if (!wallet) {
      throw new Error('A defined wallet is required!')
    }

    const { page, isLoadMore } = action.payload
    const filter = {
      owner: wallet.address,
      first: LIMIT_PAGE_SIZE,
      skip: (page - 1) * LIMIT_PAGE_SIZE,
      sortBy: SortBy.NEWEST,
      category: NFTCategory.PARCEL,
    }

    const { data, total }: { data: any[]; total: number } = yield call(
      [estateBuilderAPI, 'fetch'],
      filter
    )

    yield put(getLandsByWalletAddressSuccess({
      isLoadMore,
      lands: data.map(item => item.nft),
      total,
      page,
    }))
  } catch (error) {
    yield put(getLandsByWalletAddressFailure({}))
  }
}
