// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../asset/images/banner-marketplace.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Story__background {\n  background-attachment: fixed;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 90% 120%;\n  background-repeat: no-repeat;\n  background-size: 30rem;\n  padding: 0 75px;\n  animation: fadeIn 0.3s;\n}\n\n.Story__background.dark {\n  background-blend-mode: multiply;\n}\n\n.Story__background .text-white {\n  color: white !important;\n}\n\n@media (max-width: 1024px) {\n  .Story__background {\n    padding: 0 30px;\n  }\n}\n\n@media (max-width: 767.9px) {\n  .Story__background {\n    padding: 0 15px;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/LOM/StoryPage/StoryPage.scss"],"names":[],"mappings":"AACE;EACE,4BAA4B;EAC5B,yDAAqE;EACrE,6BAA6B;EAC7B,4BAA4B;EAC5B,sBAAsB;EACtB,eAAe;EAKf,sBAAsB;AAAA;;AAXvB;EAQG,+BAA+B;AAAA;;AARlC;EAcG,uBAAuB;AAAA;;AAGzB;EAjBF;IAkBI,eAAe;EAAA;AAMlB;;AAHC;EArBF;IAsBI,eAAe;EAAA;AAElB","sourcesContent":[".Story {\n  &__background {\n    background-attachment: fixed;\n    background-image: url(\"../../../asset/images/banner-marketplace.svg\");\n    background-position: 90% 120%;\n    background-repeat: no-repeat;\n    background-size: 30rem;\n    padding: 0 75px;\n    &.dark {\n      background-blend-mode: multiply;\n      // background-image: url(\"../../../asset/images/story/background.png\");\n    }\n    animation: fadeIn 0.3s;\n\n    .text-white {\n      color: white !important;\n    }\n\n    @media (max-width: 1024px) {\n      padding: 0 30px;\n    }\n\n    @media (max-width: 767.9px) {\n      padding: 0 15px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
