import {
  loadingReducer,
  LoadingState
} from '@wiicamp/decentraland-dapps/dist/modules/loading/reducer'

import {
  // Fetch items nft
  FETCH_ITEMS_NFT_REQUEST,
  FETCH_ITEMS_NFT_SUCCESS,
  FETCH_ITEMS_NFT_FAILURE,
  FetchItemsNFTRequestAction,
  FetchItemsNFTSuccessAction,
  FetchItemsNFTFailureAction,

  // Fetch resold items nft
  FETCH_RESOLD_ITEMS_NFT_REQUEST,
  FETCH_RESOLD_ITEMS_NFT_SUCCESS,
  FETCH_RESOLD_ITEMS_NFT_FAILURE,
  FetchResoldItemsNFTRequestAction,
  FetchResoldItemsNFTSuccessAction,
  FetchResoldItemsNFTFailureAction,

  // Fetch categories items nft
  FETCH_CATEGORIES_ITEMS_NFT_REQUEST,
  FETCH_CATEGORIES_ITEMS_NFT_SUCCESS,
  FETCH_CATEGORIES_ITEMS_NFT_FAILURE,
  FetchCategoriesItemsNFTRequestAction,
  FetchCategoriesItemsNFTSuccessAction,
  FetchCategoriesItemsNFTFailureAction,
  //  Fetch item nft detail
  FETCH_ITEM_NFT_DETAIL_REQUEST,
  FETCH_ITEM_NFT_DETAIL_SUCCESS,
  FETCH_ITEM_NFT_DETAIL_FAILURE,
  FetchItemNFTDetailRequestAction,
  FetchItemNFTDetailSuccessAction,
  FetchItemNFTDetailFailureAction,

  // Fetch user creation items
  FETCH_USER_CREATION_ITEMS_REQUEST,
  FETCH_USER_CREATION_ITEMS_SUCCESS,
  FETCH_USER_CREATION_ITEMS_FAILURE,
  FetchUserCreationItemsRequestAction,
  FetchUserCreationItemsSuccessAction,
  FetchUserCreationItemsFailureAction,

  // Buy item user creation
  BUY_ITEM_USER_CREATION_REQUEST,
  BUY_ITEM_USER_CREATION_SUCCESS,
  BUY_ITEM_USER_CREATION_FAILURE,
  BuyItemUserCreationRequestAction,
  BuyItemUserCreationSuccessAction,
  BuyItemUserCreationFailureAction,

  // Buy resold item
  BUY_RESOLD_ITEM_REQUEST,
  BUY_RESOLD_ITEM_SUCCESS,
  BUY_RESOLD_ITEM_FAILURE,
  BuyResoldItemRequestAction,
  BuyResoldItemSuccessAction,
  BuyResoldItemFailureAction,

  // Fetch my items
  FETCH_MY_ITEMS_REQUEST,
  FETCH_MY_ITEMS_SUCCESS,
  FETCH_MY_ITEMS_FAILURE,
  FetchMyItemsRequestAction,
  FetchMyItemsSuccessAction,
  FetchMyItemsFailureAction,

  // Fetch item nft listing detail,
  FETCH_ITEM_NFT_LISTING_DETAIL_REQUEST,
  FETCH_ITEM_NFT_LISTING_DETAIL_SUCCESS,
  FETCH_ITEM_NFT_LISTING_DETAIL_FAILURE,
  FetchItemNFTListingDetailRequestAction,
  FetchItemNFTListingDetailSuccessAction,
  FetchItemNFTListingDetailFailureAction,

  // List for sale item
  LIST_FOR_SALE_ITEM_REQUEST,
  LIST_FOR_SALE_ITEM_SUCCESS,
  LIST_FOR_SALE_ITEM_FAILURE,
  ListForSaleItemRequestAction,
  ListForSaleItemSuccessAction,
  ListForSaleItemFailureAction,

  // Tranfer item
  TRANSFER_ITEM_REQUEST,
  TRANSFER_ITEM_SUCCESS,
  TRANSFER_ITEM_FAILURE,
  TransferItemRequestAction,
  TransferItemSuccessAction,
  TransferItemFailureAction,

  // Cancel sale item
  CANCEL_SALE_ITEM_REQUEST,
  CANCEL_SALE_ITEM_SUCCESS,
  CANCEL_SALE_ITEM_FAILURE,
  CancelSaleItemRequestAction,
  CancelSaleItemSuccessAction,
  CancelSaleItemFailureAction,

  // Sale item user creation
  SALE_ITEM_USER_CREATION_REQUEST,
  SALE_ITEM_USER_CREATION_FAILURE,
  SALE_ITEM_USER_CREATION_SUCCESS,
  SaleItemUserCreationRequestAction,
  SaleItemUserCreationSuccessAction,
  SaleItemUserCreationFailureAction,

  // fetch rarities
  FETCH_RARITIES_ITEMS_NFT_REQUEST,
  FETCH_RARITIES_ITEMS_NFT_SUCCESS,
  FETCH_RARITIES_ITEMS_NFT_FAILURE,
  FetchRaritiesItemsNFTRequestAction,
  FetchRaritiesItemsNFTSuccessAction,
  FetchRaritiesItemsNFTFailureAction,

  // fetch transaction history item nft
  FETCH_TRANSACTION_HISTORIES_ITEM_NFT_REQUEST,
  FETCH_TRANSACTION_HISTORIES_ITEM_NFT_SUCCESS,
  FETCH_TRANSACTION_HISTORIES_ITEM_NFT_FAILURE,
  FetchTransactionHistoriesItemNFTRequestAction,
  FetchTransactionHistoriesItemNFTSuccessAction,
  FetchTransactionHistoriesItemNFTFailureAction,
} from './actions'

import { getObjectFromApiCategories } from './utils'

import { CategoriesType, RarityNFTType, TransactionHistoryItemNFTType } from './types'

// import { ItemNFTType } from './types'

export type ItemsNFTStateType = {
  data: any[] // fake
  // data: ItemNFTType[]
  itemDetail: any
  loading: LoadingState
  categories: CategoriesType[]
  categoriesLoading: LoadingState
  page: number
  error: string | null
  isRefreshed?: boolean

  myItemsData: any[],
  myItemsLoading: LoadingState,

  isBuyItemUserCreationLoading: LoadingState
  buyResoldItemLoading: LoadingState
  listForSaleItemLoading: LoadingState
  transferItemLoading: LoadingState
  cancelSaleItemLoading: LoadingState
  itemNFTListingDetailLoading: boolean

  fetchItemsMarketplaceLoading: boolean

  itemsUserCreationOnSaleNeedLoading: string[]
  saleItemUserCreationLoading: LoadingState

  rarities: RarityNFTType[]
  raritiesLoading: LoadingState

  transactionHistories: TransactionHistoryItemNFTType[]
  transactionHistoriesLoading : Boolean
}

export const INITIAL_STATE: ItemsNFTStateType = {
  data: [],
  itemDetail: null,
  loading: [],
  categories: [],
  categoriesLoading: [],
  page: 0,
  error: null,
  isRefreshed: false,

  myItemsData: [],
  myItemsLoading: [],

  isBuyItemUserCreationLoading: [],
  buyResoldItemLoading: [],
  listForSaleItemLoading: [],
  transferItemLoading: [],
  cancelSaleItemLoading: [],

  itemNFTListingDetailLoading: false,

  fetchItemsMarketplaceLoading: true,

  itemsUserCreationOnSaleNeedLoading: [],
  saleItemUserCreationLoading: [],

  rarities: [],
  raritiesLoading: [],

  transactionHistories: [],
  transactionHistoriesLoading: true
}

type ItemNFTReducerAction =
  | FetchItemsNFTRequestAction
  | FetchItemsNFTSuccessAction
  | FetchItemsNFTFailureAction
  | FetchResoldItemsNFTRequestAction
  | FetchResoldItemsNFTSuccessAction
  | FetchResoldItemsNFTFailureAction
  | FetchCategoriesItemsNFTRequestAction
  | FetchCategoriesItemsNFTSuccessAction
  | FetchCategoriesItemsNFTFailureAction
  | FetchItemNFTDetailRequestAction
  | FetchItemNFTDetailSuccessAction
  | FetchItemNFTDetailFailureAction
  | FetchUserCreationItemsRequestAction
  | FetchUserCreationItemsSuccessAction
  | FetchUserCreationItemsFailureAction
  | BuyItemUserCreationRequestAction
  | BuyItemUserCreationSuccessAction
  | BuyItemUserCreationFailureAction
  | FetchMyItemsRequestAction
  | FetchMyItemsSuccessAction
  | FetchMyItemsFailureAction
  | FetchItemNFTListingDetailRequestAction
  | FetchItemNFTListingDetailSuccessAction
  | FetchItemNFTListingDetailFailureAction
  | ListForSaleItemRequestAction
  | ListForSaleItemSuccessAction
  | ListForSaleItemFailureAction
  | TransferItemRequestAction
  | TransferItemSuccessAction
  | TransferItemFailureAction
  | CancelSaleItemRequestAction
  | CancelSaleItemSuccessAction
  | CancelSaleItemFailureAction
  | BuyResoldItemRequestAction
  | BuyResoldItemSuccessAction
  | BuyResoldItemFailureAction
  | SaleItemUserCreationRequestAction
  | SaleItemUserCreationSuccessAction
  | SaleItemUserCreationFailureAction
  | FetchRaritiesItemsNFTRequestAction
  | FetchRaritiesItemsNFTSuccessAction
  | FetchRaritiesItemsNFTFailureAction
  | FetchTransactionHistoriesItemNFTRequestAction
  | FetchTransactionHistoriesItemNFTSuccessAction
  | FetchTransactionHistoriesItemNFTFailureAction

export function itemNFTReducer(
  state = INITIAL_STATE,
  action: ItemNFTReducerAction
): ItemsNFTStateType {
  switch (action.type) {
    case FETCH_ITEMS_NFT_SUCCESS:
      {
        const { isRefreshed, items } = action.payload

        if (isRefreshed) {
          return {
            ...state,
            data: items,
            loading: loadingReducer(state.loading, action),
            page: 1,
            error: null
          }
        }

        return {
          ...state,
          data: [...state.data, ...items],
          page: state.page + 1,
          loading: loadingReducer(state.loading, action),
          error: null
        }
      }

    case FETCH_RESOLD_ITEMS_NFT_SUCCESS: {
      const { isRefreshed, items } = action.payload

      const itemsClone = [...items].map((item: any) => {
        const { category, subCategory } = getObjectFromApiCategories(state.categories, item.category, item.assetType)

        return ({
          ...item,
          itemId: item.tokenId,
          category,
          subCategory,
          contractAddress: item.tokenAddress,
        })
      });

      if (isRefreshed) {
        return {
          ...state,
          data: itemsClone,
          fetchItemsMarketplaceLoading: false,
          page: 1,
          error: null
        }
      }

      return {
        ...state,
        data: [...state.data, ...itemsClone],
        page: state.page + 1,
        fetchItemsMarketplaceLoading: false,
        error: null
      }
    }
    case FETCH_USER_CREATION_ITEMS_SUCCESS:
      {
        const { isRefreshed, items } = action.payload

        const itemsClone = [...items].map((item: any) => {
          const { category, subCategory } = getObjectFromApiCategories(state.categories, item.category, item.assetType)

          return ({
            ...item,
            itemId: item.itemType,
            category,
            subCategory,
          })
        });

        if (isRefreshed) {
          return {
            ...state,
            data: itemsClone,
            fetchItemsMarketplaceLoading: false,
            page: 1,
            error: null
          }
        }

        return {
          ...state,
          data: [...state.data, ...itemsClone],
          page: state.page + 1,
          fetchItemsMarketplaceLoading: false,
          error: null
        }
      }

    case FETCH_CATEGORIES_ITEMS_NFT_SUCCESS: {
      const { items } = action.payload

      return {
        ...state,
        categoriesLoading: loadingReducer(state.categoriesLoading, action),
        categories: items
      }
    }

    case FETCH_ITEM_NFT_DETAIL_SUCCESS: {
      const { item } = action.payload

      return {
        ...state,
        itemDetail: {...item, itemId: item.itemType},
        loading: loadingReducer(state.loading, action),
      }
    }
    case FETCH_ITEMS_NFT_FAILURE:
    case FETCH_CATEGORIES_ITEMS_NFT_FAILURE:
    case FETCH_ITEM_NFT_DETAIL_FAILURE:
      const { error } = action.payload

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        categoriesLoading: loadingReducer(state.categoriesLoading, action),
        error: error
      }

    case FETCH_CATEGORIES_ITEMS_NFT_REQUEST:
      return {
        ...state,
        categoriesLoading: loadingReducer(state.categoriesLoading, action)
      }

    case FETCH_ITEM_NFT_DETAIL_REQUEST:
    case FETCH_ITEMS_NFT_REQUEST:
      return {
        ...state,
        loading: loadingReducer(state.loading, action)
      }

    case BUY_ITEM_USER_CREATION_REQUEST:
    case BUY_ITEM_USER_CREATION_FAILURE:
    case BUY_ITEM_USER_CREATION_SUCCESS:
      return{
        ...state,
        isBuyItemUserCreationLoading: loadingReducer(state.isBuyItemUserCreationLoading, action)
      }

    case FETCH_MY_ITEMS_SUCCESS: {
      const { items, isRefreshed } = action.payload

      const itemsClone = [...items].map((item: any) => {
        const { category, subCategory } = getObjectFromApiCategories(state.categories, item.category, item.assetType)

        return ({
          ...item,
          itemId: item.tokenId,
          category,
          subCategory,
          contractAddress: item.tokenAddress,
        })
      });

      if (isRefreshed) {
        return {
          ...state,
          myItemsData: itemsClone,
          myItemsLoading: loadingReducer(state.myItemsLoading, action),
        }
      }

      return {
        ...state,
        myItemsData: [...state.myItemsData, ...itemsClone],
        myItemsLoading: loadingReducer(state.myItemsLoading, action),
      }
    }

    case FETCH_MY_ITEMS_REQUEST:
    case FETCH_MY_ITEMS_FAILURE:{
      return {
        ...state,
        myItemsLoading: loadingReducer(state.myItemsLoading, action),
      }
    }

    // FETCH ITEM NFT LISTING DETAIL
    case FETCH_ITEM_NFT_LISTING_DETAIL_REQUEST: {
      return {
        ...state,
        itemNFTListingDetailLoading: true,
      }
    }

    case FETCH_ITEM_NFT_LISTING_DETAIL_FAILURE: {
      return {
        ...state,
        itemDetail: null,
        itemNFTListingDetailLoading: false,
      }
    }

    case FETCH_ITEM_NFT_LISTING_DETAIL_SUCCESS: {
      const { item } = action.payload

      return {
        ...state,
        itemDetail: item,
        itemNFTListingDetailLoading: false,
      }
    }

    // LIST FOR SALE ITEM REQUEST
    case LIST_FOR_SALE_ITEM_REQUEST:
    case LIST_FOR_SALE_ITEM_FAILURE: {
      return {
        ...state,
        listForSaleItemLoading: loadingReducer(state.listForSaleItemLoading, action)
      }
    }

    case LIST_FOR_SALE_ITEM_SUCCESS: {
      const { item, price } = action.payload

      const newItemDetailState = {
        ...state.itemDetail,
        price: BigInt(+price * (10 ** 18)).toString(),
        isOnSale: true,
        listingId: item.listingId,
      }

      return {
        ...state,
        itemDetail: newItemDetailState,
        listForSaleItemLoading: loadingReducer(state.listForSaleItemLoading, action)
      }
    }

    // TRANSFER ITEM REQUEST
    case TRANSFER_ITEM_REQUEST:
    case TRANSFER_ITEM_FAILURE: {
      return {
        ...state,
        transferItemLoading: loadingReducer(state.transferItemLoading, action)
      }
    }

    case TRANSFER_ITEM_SUCCESS: {
      const { item, toWalletAddress } = action.payload

      const newMyItemsData = state.myItemsData.filter((x) => x.id !== item.id);

      const newItemDetailState = {
        ...state.itemDetail,
        owner: toWalletAddress,
      };

      return {
        ...state,
        myItemsData: newMyItemsData,
        itemDetail: newItemDetailState,
        transferItemLoading: loadingReducer(state.transferItemLoading, action)
      }
    }

    // CANCEL_SALE ITEM REQUEST
    case CANCEL_SALE_ITEM_REQUEST:
    case CANCEL_SALE_ITEM_FAILURE: {
      return {
        ...state,
        cancelSaleItemLoading: loadingReducer(state.cancelSaleItemLoading, action)
      }
    }

    case CANCEL_SALE_ITEM_SUCCESS: {
      const { item } = action.payload;
      const newItemDetail = {
        ...state.itemDetail,
        isOnSale: false,
      };
      const newMyItemsData = [...state.myItemsData]
      const itemIndex = state.myItemsData.findIndex((x) => x.id === item.id);

      if (itemIndex > -1) {
       newMyItemsData[itemIndex].isOnSale = false;
      }

      return {
        ...state,
        myItemsData: newMyItemsData,
        itemDetail: newItemDetail,
        cancelSaleItemLoading: loadingReducer(state.cancelSaleItemLoading, action)
      }
    }

    // BUY RESOLD ITEM
    case BUY_RESOLD_ITEM_REQUEST:
    case BUY_RESOLD_ITEM_FAILURE: {
      return {
        ...state,
        buyResoldItemLoading: loadingReducer(state.buyResoldItemLoading, action)
      }
    }

    case BUY_RESOLD_ITEM_SUCCESS: {
      const { item, toWalletAddress } = action.payload;
      const newItemDetail = {
        ...state.itemDetail,
        isOnSale: false,
        owner: toWalletAddress,
      };
      const itemIndex = state.data.findIndex((x) => x.id === item.id);
      let newData = [...state.data];

      if (itemIndex > -1) {
        newData[itemIndex].isOnSale = false;
        newData[itemIndex].owner = toWalletAddress;
      }

      return {
        ...state,
        data: newData,
        itemDetail: newItemDetail,
      };
    }

    case FETCH_USER_CREATION_ITEMS_REQUEST:
    case FETCH_RESOLD_ITEMS_NFT_REQUEST: {
      return {
        ...state,
        fetchItemsMarketplaceLoading: true,
      }
    }

    case FETCH_USER_CREATION_ITEMS_FAILURE:
    case FETCH_RESOLD_ITEMS_NFT_FAILURE: {
      return {
        ...state,
        fetchItemsMarketplaceLoading: false,
      }
    }

    // Sale item user creation
    case SALE_ITEM_USER_CREATION_REQUEST: {
      const { item } = action.payload

      return {
        ...state,
        itemsUserCreationOnSaleNeedLoading: [...state.itemsUserCreationOnSaleNeedLoading, item._id],
        saleItemUserCreationLoading: loadingReducer(state.saleItemUserCreationLoading, action),
      }
    }

    case SALE_ITEM_USER_CREATION_FAILURE:
    case SALE_ITEM_USER_CREATION_SUCCESS: {
      const { item } = action.payload
      const newData = [...state.itemsUserCreationOnSaleNeedLoading].filter((i: string) => i !== item._id)

      return {
        ...state,
        itemsUserCreationOnSaleNeedLoading: newData,
        saleItemUserCreationLoading: loadingReducer(state.saleItemUserCreationLoading, action),
      }
    }

    // Fetch rarities
    case FETCH_RARITIES_ITEMS_NFT_FAILURE:
    case FETCH_RARITIES_ITEMS_NFT_REQUEST: {
      return {
        ...state,
        raritiesLoading: loadingReducer(state.raritiesLoading, action),
      }
    }

    case FETCH_RARITIES_ITEMS_NFT_SUCCESS: {
      return {
        ...state,
        rarities: action.payload.rarities,
        raritiesLoading: loadingReducer(state.raritiesLoading, action),
      }
    }

    // fetch transaction history item nft
    case FETCH_TRANSACTION_HISTORIES_ITEM_NFT_FAILURE: {
      return {
        ...state,
        transactionHistoriesLoading: false,
      }
    }

    case FETCH_TRANSACTION_HISTORIES_ITEM_NFT_REQUEST: {
      return {
        ...state,
        transactionHistoriesLoading: true,
      }
    }

    case FETCH_TRANSACTION_HISTORIES_ITEM_NFT_SUCCESS: {
      const { histories, isRefreshed } = action.payload

      if (isRefreshed) {
        return {
          ...state,
          transactionHistories: histories,
          transactionHistoriesLoading: false,
        }
      }

      return {
        ...state,
        transactionHistories: [...state.transactionHistories, ...histories],
        transactionHistoriesLoading: false,
      }
    }

    default:
      return state
  }
}
