// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CancelSaleModal_SubtitleError__3bFxA {\n  color: var(--danger);\n}\n", "",{"version":3,"sources":["webpack://src/components/LOM/ItemNFTDetailPage/CancelSaleModal/CancelSaleModal.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AAAA","sourcesContent":[".SubtitleError {\n  color: var(--danger);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SubtitleError": "CancelSaleModal_SubtitleError__3bFxA"
};
export default ___CSS_LOADER_EXPORT___;
