import Image1 from '../../../asset/images/ticket/image-1.jpeg'
import Image2 from '../../../asset/images/ticket/image-2.jpeg'
import Image3 from '../../../asset/images/ticket/image-3.jpeg'

import { TICKET_TYPE } from './constants'

export const ticketsData = {
  [TICKET_TYPE.ENTRANCE]: {
    startColor: '#E78B4D',
    middleColor: '#e08244',
    endColor: '#CC6728',
    backgroundTicket: Image1,
    launchSite: 'earth',
    destination: 'L.O.M City',
  },
  [TICKET_TYPE.TRAVELING]: {
    startColor: '#D7E6A3',
    middleColor: '#B5D56B',
    endColor: '#9BBB60',
    backgroundTicket:  Image2,
    launchSite: '',
    destination: 'DEEP MINES III',
  },
  [TICKET_TYPE.SPECIAL]: {
    startColor: '#EC7C83',
    middleColor: '#E83C70',
    endColor: '#C62970',
    backgroundTicket: Image3,
    launchSite: '',
    destination: 'CRASH SITE X',
  },
}
