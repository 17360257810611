// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AssetProviderPage_center__cEH_S {\n  position: relative;\n  top: 100px;\n  bottom: 0px;\n  left: 0px;\n  right: 0px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: calc(100vh - 350px);\n}\n", "",{"version":3,"sources":["webpack://src/components/LOM/AssetProviderPage/AssetProviderPage.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,SAAS;EACT,UAAU;EACV,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,2BAA2B;AAC7B","sourcesContent":[".center {\n  position: relative;\n  top: 100px;\n  bottom: 0px;\n  left: 0px;\n  right: 0px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: calc(100vh - 350px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"center": "AssetProviderPage_center__cEH_S"
};
export default ___CSS_LOADER_EXPORT___;
