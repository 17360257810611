import React, { useCallback } from 'react'
import { Button, Loader } from '@wiicamp/decentraland-ui'
import { Item } from '@dcl/schemas'
import { t } from '@wiicamp/decentraland-dapps/dist/modules/translation/utils'
import { getAnalytics } from '@wiicamp/decentraland-dapps/dist/modules/analytics/utils'

import { getMaxQuerySize, MAX_PAGE, PAGE_SIZE } from '../../../modules/vendor/api'
import { AssetType } from '../../../modules/asset/types'
import { NFT } from '../../../modules/nft/types'
import { AssetCard } from '../AssetCard'
import { Props } from './AssetList.types'

import './styles.scss'
import cssStyles from './AssetList.module.scss'
import { Section } from '../../../modules/vendor/decentraland'

const AssetList = (props: Props) => {
  const {
    vendor,
    assetType,
    items,
    nfts,
    page,
    count,
    isLoading,
    onBrowse,
    // mapHeight
    // toggleModalDetailEstate
  } = props

  const assets: (NFT | Item)[] = assetType === AssetType.ITEM ? items : nfts

  const handleLoadMore = useCallback(() => {
    const newPage = page + 1
    onBrowse({ page: newPage, section: Section.LAND })
    getAnalytics().track('Load more', { page: newPage })
  }, [onBrowse, page])

  const maxQuerySize = getMaxQuerySize(vendor)

  const hasExtraPages =
    (assets.length !== count || count === maxQuerySize) && page <= MAX_PAGE

  const isLoadingNewPage = isLoading && nfts.length >= PAGE_SIZE

  // const handleOpenModalDetail = (asset: Asset) => () => {
  //   if ('tokenId' in asset) {
  //     toggleModalDetailEstate({
  //       isOpenModalDetailEstate: true,
  //       isNFTToken: true,
  //       assetId: asset.id
  //     })
  //   } else {
  //     toggleModalDetailEstate({
  //       isOpenModalDetailEstate: true,
  //       isNFTToken: false,
  //       assetId: asset.itemId
  //     })
  //   }
  // }

  return (
    <>
      <div className="relative -mx-4 -mt-8 Marketplace-Estates__grid">
        {assets.length > 0
          ? assets.map((asset, index) => (
            <AssetCard
              key={assetType + '-' + asset.id + '-' + index}
              asset={asset}
              className="mt-8"
              // mapHeight={mapHeight}
            // onClick={handleOpenModalDetail(asset)}
            />
          ))
          : null}

        {isLoading ? (
          <div className="mt-56">
            <div className="overlay" />
            <Loader size="massive" active />
          </div>
        ) : null}
      </div>

      {assets.length === 0 && !isLoading ? (
        <div className="empty text-center w-full">{t('nft_list.empty')}</div>
      ) : null}

      {assets.length > 0 &&
        hasExtraPages &&
        (!isLoading || isLoadingNewPage) ? (
        <div className="load-more mt-8 flex justify-center">
          <Button className={`${cssStyles.AssetListBtnLoadMore} LOM-submit-btn`} loading={isLoading} inverted primary onClick={handleLoadMore}>
            {t('global.load_more')}
          </Button>
        </div>
      ) : null}
    </>
  )
}

export default React.memo(AssetList)
