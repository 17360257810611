import { toast } from 'react-toastify';

import { put, takeEvery, select, call } from '@redux-saga/core/effects'

import { LOCALSTORAGE_KEYS } from '../../constants/localStorageKeys';

import {
  CREATE_NEW_ITEM_NFT_REQUEST,
  CreateNewItemNFTRequestAction,
  createNewItemNFTSuccess,
  createNewItemNFTFailure,

  GET_ITEMS_NFT_FROM_LOCALSTORAGE_REQUEST,
  getItemsNFTFromLocalstorageSuccess,
  getItemsNFTFromLocalstorageFailure,

  // Fetch my items creation
  FETCH_MY_ITEMS_CREATION_REQUEST,
  FetchMyItemsCreationRequestAction,
  fetchMyItemsCreationSuccess,
  fetchMyItemsCreationFailure,

  // Delete item user creation
  DELETE_ITEM_USER_CREATION_REQUEST,
  DeleteItemUserCreationRequestAction,
  deleteItemUserCreationSuccess,
  deleteItemUserCreationFailure,

  // Edit item user creation
  EDIT_ITEM_USER_CREATION_REQUEST,
  EditItemUserCreationRequestAction,
  editItemUserCreationSuccess,
  editItemUserCreationFailure,

  // Fetch item user creation edit
  FETCH_ITEM_USER_CREATION_EDIT_REQUEST,
  FetchItemUserCreationEditRequestAction,
  fetchItemUserCreationEditSuccess,
  fetchItemUserCreationEditFailure,
} from './actions'
import { getWallet } from '../wallet/selectors';
import { getCategoriesItemsNFT } from '../itemNFT/selectors';

import { itemNFTApi } from '../vendor/itemNFT'
import { uploadItemApi } from '../vendor/uploadItem';

import { ItemUserCreationType } from '../itemNFT/types';

export function* uploadItemSaga() {
  yield takeEvery(CREATE_NEW_ITEM_NFT_REQUEST, handleCreateNewItemRequest)
  yield takeEvery(GET_ITEMS_NFT_FROM_LOCALSTORAGE_REQUEST, handleGetItemsNFTFromLocalStorageRequest)
  yield takeEvery(FETCH_MY_ITEMS_CREATION_REQUEST, handleFetchMyItemsCreation)
  yield takeEvery(DELETE_ITEM_USER_CREATION_REQUEST, handleDeleteItemUserCreation)
  yield takeEvery(EDIT_ITEM_USER_CREATION_REQUEST, handleEditItemUserCreation)
  yield takeEvery(FETCH_ITEM_USER_CREATION_EDIT_REQUEST, handleFetchItemUserCreationEdit)
}

function* handleFetchItemUserCreationEdit(action: FetchItemUserCreationEditRequestAction) {
  const { id, onRedirect } = action.payload

  try {
    const categories: ReturnType<typeof getCategoriesItemsNFT> = yield select(getCategoriesItemsNFT)

    const { payload: item }: { payload: ItemUserCreationType } = yield call(
      [uploadItemApi, 'fetchItemUserCreationEdit'],
      { id })

    yield put(fetchItemUserCreationEditSuccess(item, categories))

  } catch (error) {
    toast.error('Item not found!')
    onRedirect()
    yield put(fetchItemUserCreationEditFailure(error))
  }
}

function* handleEditItemUserCreation(action: EditItemUserCreationRequestAction) {
  const { form } = action.payload

  const {
    id,
    name,
    description,
    image,
    // texturesImage,
    rarity,
    category,
    subCategory,
    // file3d
  } = form

  try {
    const categories: ReturnType<typeof getCategoriesItemsNFT> = yield select(getCategoriesItemsNFT)

    let finalImage = image[0]

    if (typeof finalImage !== 'string') { // checking file is old or new, old is string
      const formDataImage = new FormData();

      formDataImage.set('image', image[0]);

      const { payload: imageResponse }: { payload: string } = yield call(
        [uploadItemApi, 'uploadImageS3'],
        formDataImage)

      finalImage = imageResponse
    }

    yield call(
      [uploadItemApi, 'editItemUserCreation'],
      {
        id,
        name,
        description,
        image: finalImage,
        rarity: rarity.toString(),
        assetType: subCategory ? subCategory.toString() : undefined,
        category: category.toString(),
        // textures: textureImageResponse.data.payload,
        // file3d: file3DResponse.data.payload,
      })

    yield put(editItemUserCreationSuccess({ ...form, image: [finalImage] }, categories))

    toast.success('Edit item successfully!')

  } catch (error) {
    yield put(editItemUserCreationFailure(error))
  }
}

function* handleDeleteItemUserCreation(action: DeleteItemUserCreationRequestAction) {
  const { id, callback } = action.payload

  try {
    yield call([uploadItemApi, 'deleteItemUserCreation'],{ id })

    yield put(deleteItemUserCreationSuccess(id))

    toast.success('Delete item successfully!')

    callback?.()
  } catch (error) {
    yield put(deleteItemUserCreationFailure(error))
  }
}

function* handleFetchMyItemsCreation(action: FetchMyItemsCreationRequestAction) {
  const {
    page = 1,
    limit = 20,
    isRefreshed = true,
    callback,
  } = action?.payload || {}

  try {
    const wallet: ReturnType<typeof getWallet> = yield select(getWallet)
    const categories: ReturnType<typeof getCategoriesItemsNFT> = yield select(getCategoriesItemsNFT)

    if (!wallet) {
      throw new Error('A defined wallet is required to generate a ticket')
    }

    const { payload: items }: { payload: any } = yield call(
      [itemNFTApi, 'fetchMyItemsCreation'],
      { limit, page, walletAddress: wallet.address })

    yield put(fetchMyItemsCreationSuccess({ items: items.data, categories, isRefreshed }))
    callback?.(items.data)
  } catch (error) {
    yield put(fetchMyItemsCreationFailure(error))
  }
}

function* handleCreateNewItemRequest(action: CreateNewItemNFTRequestAction): any {
  try {
    const { item, callback } = action.payload

    const {
      name,
      description,
      image,
      // texturesImage,
      rarity,
      category,
      subCategory,
      // file3d
    } = item
    const wallet: ReturnType<typeof getWallet> = yield select(getWallet)

    if (!wallet) {
      throw new Error('Please connect your wallet!')
    }

    // // S3 File 3D
    // const formDataFile3D = new FormData();
    // formDataFile3D.set('file', file3d[0]);
    // const file3DResponse = yield axios.post('/media/file3d', formDataFile3D);

    // S3 Image
    const formDataImage = new FormData();
    formDataImage.set('image', image[0]);

    const { payload: imageResponse }: { payload: string } = yield call(
      [uploadItemApi, 'uploadImageS3'],
      formDataImage)

    // S3 Textures
    // const texturesPayload = [];

    // for (let index = 0; index < texturesImage.length; index += 1) {
    //   let textureName = '';
    //   const element = texturesImage[index];
    //   const formDataTextures = new FormData();
    //   formDataTextures.set('image', element);

    //   const texturesResponse = yield axios.post('http://45.118.145.84:9999/api/v1.0/media/image', formDataTextures);

    //   const arrayName = element.name.split('.');
    //   arrayName.pop();
    //   textureName = arrayName.join('.');

    //   texturesPayload.push({
    //     name: textureName,
    //     url: texturesResponse.data.payload,
    //   });
    // }

    yield call(
      [uploadItemApi, 'createItemUserCreation'],
      {
        name,
        description,
        image: imageResponse,
        rarity: rarity.toString(),
        assetType: subCategory ? subCategory.toString() : undefined,
        category: category.toString(),
        walletAddress: wallet.address,
        // textures: textureImageResponse.data.payload,
        // file3d: file3DResponse.data.payload,
      })

    // const existedItems: any = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEYS.UPLOADED_NFT_ITEMS) || 'null') || []

    // const newListItems = [item, ...existedItems]

    // localStorage.setItem(LOCALSTORAGE_KEYS.UPLOADED_NFT_ITEMS, JSON.stringify(newListItems))

    toast.success('Create item successfully!')

    yield put(createNewItemNFTSuccess({ items: [] }))

    callback()
  } catch (error) {
    yield put(createNewItemNFTFailure(error))
  }
}

function* handleGetItemsNFTFromLocalStorageRequest() {
  try {
    const existedItems: any[] = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEYS.UPLOADED_NFT_ITEMS) || '')

    yield put(getItemsNFTFromLocalstorageSuccess({ items: existedItems }))
  } catch (error) {
    yield put(getItemsNFTFromLocalstorageFailure(error))
  }
}
