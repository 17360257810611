// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AssetList_AssetListBtnLoadMore__3TOap {\n  color: white !important;\n}\n", "",{"version":3,"sources":["webpack://src/components/LOM/AssetList/AssetList.module.scss"],"names":[],"mappings":"AACE;EACE,uBAAuB;AAAA","sourcesContent":[".AssetList {\n  &BtnLoadMore {\n    color: white !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AssetListBtnLoadMore": "AssetList_AssetListBtnLoadMore__3TOap"
};
export default ___CSS_LOADER_EXPORT___;
