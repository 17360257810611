import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import { walletReducer as wallet } from '@wiicamp/decentraland-dapps/dist/modules/wallet/reducer'
import { translationReducer as translation } from '@wiicamp/decentraland-dapps/dist/modules/translation/reducer'
import { storageReducer as storage } from '@wiicamp/decentraland-dapps/dist/modules/storage/reducer'
import { transactionReducer as transaction } from '@wiicamp/decentraland-dapps/dist/modules/transaction/reducer'
import { profileReducer as profile } from '@wiicamp/decentraland-dapps/dist/modules/profile/reducer'
import { authorizationReducer as authorization } from '@wiicamp/decentraland-dapps/dist/modules/authorization/reducer'
import { toastReducer as toast } from '@wiicamp/decentraland-dapps/dist/modules/toast/reducer'

import { accountReducer as account } from './account/reducer'
import { bidReducer as bid } from './bid/reducer'
import { itemReducer as item } from './item/reducer'
import { nftReducer as nft } from './nft/reducer'
import { orderReducer as order } from './order/reducer'
import { proximityReducer as proximity } from './proximity/reducer'
import { routingReducer as routing } from './routing/reducer'
import { tileReducer as tile } from './tile/reducer'
import { uiReducer as ui } from './ui/reducer'
import { newsfeedReducer as newsfeed } from './newsfeed/reducer'
import { ticketReducer as ticket } from './ticket/reducer'
import { itemNFTReducer as itemNFT } from './itemNFT/reducer'
import { authenticationReducer as authentication } from './authentication/reducer'
import { estateReducer as estate } from './estate/reducer'
import { uploadItemReducer as uploadItem } from './uploadItem/reducer'

export const createRootReducer = (history: History) =>
  combineReducers({
    account,
    authorization,
    bid,
    item,
    nft,
    order,
    profile,
    proximity,
    routing,
    storage,
    tile,
    toast,
    transaction,
    translation,
    ui,
    wallet,
    newsfeed,
    ticket,
    itemNFT,
    router: connectRouter(history),
    authentication,
    estate,
    uploadItem,
  })

export type RootState = ReturnType<ReturnType<typeof createRootReducer>>
