import { useEffect, useCallback, useMemo } from 'react'
import { useParams } from 'react-router'
import { Container, Loader, Row, Header } from '@wiicamp/decentraland-ui'
import { Helmet } from 'react-helmet'

import LayoutPage from '../Layout/LayoutPage/LayoutPage'
import { Title } from '../AssetPage/Title'
import { Description } from '../AssetPage/Description'
import Breadcrumb from '../Breadcrumb'
import { Breadcrumb as BreadcrumbType } from '../Breadcrumb/Breadcrumb.types'
import { PageHeader } from '../../PageHeader'

import { ReactComponent as DownloadIcon } from '../../../images/ic-download.svg'
import { ReactComponent as FacebookIcon } from '../../../images/ic-social-facebook.svg'
import { ReactComponent as TelegramIcon } from '../../../images/ic-social-telegram.svg'
import { ReactComponent as TwitterIcon } from '../../../images/ic-social-twitter.svg'

import cssStyles from './TicketDetailPage.module.scss'

import { downloadImage } from '../../../constants'

const TicketDetailPage = (props: any) => {
  const { ticketId }: { [key: string]: any } = useParams()
  const { fetchSingleTicket, ticketDetail, loading, wallet } = props
  const isCurrentWallet = useMemo(
    () => wallet?.address?.toLowerCase() === ticketDetail?.owner?.toLowerCase(),
    [ticketDetail, wallet]
  )

  const breadcrumb: BreadcrumbType[] = [
    { name: 'Home', link: '/' },
    { name: 'Marketplace', link: '/marketplace' },
    {
      name: 'TICKET',
      active: true,
      isBreakHere: true
    }
  ]

  const onClickDownloadImage = useCallback(async () => {
    downloadImage(ticketDetail.image, ticketDetail.name)
  }, [ticketDetail])

  const onClickShareTicket = useCallback(
    shareKey => () => {
      const linkTicket = `${window.location.origin}/ticket/${ticketId}`

      switch (shareKey) {
        case 'facebook':
          window.open(
            `https://www.facebook.com/sharer/sharer.php?u=${linkTicket}`
          )
          break

        case 'twitter':
          window.open(`http://twitter.com/share?url=${linkTicket}`)
          break

        case 'telegram':
          window.open(`https://t.me/share?url=${linkTicket}`)
          break

        default:
          break
      }
    },
    [ticketId]
  )

  useEffect(() => {
    fetchSingleTicket(ticketId)
  }, [ticketId, fetchSingleTicket])

  return (
    <>
      <Helmet>
        <title>Ticket Life on Mars (#{ticketId})</title>
        <meta
          name="description"
          content="Get an entrance ticket to join Life on Mars."
        />
        <meta
          name="og:image"
          content={ticketDetail?.image || ''}
        />
      </Helmet>
      <div className={cssStyles.detailNFTPage}>
        <LayoutPage viewMode="white">
          <div className="flex-grow min-h-full overflow-auto Story__background">
            <div className="Page__header mt-14 lg:mt-28 mb-14">
              <Breadcrumb breadcrumb={breadcrumb} viewMode="dark" />
            </div>

            {loading && (
              <div className="mt-56">
                <div className="overlay bg-transparent" />
                <Loader size="massive" active />
              </div>
            )}

            {!!ticketDetail && (
              <div className="mb-10">
                <div className={cssStyles.detail}>
                  <section className={cssStyles.sectionIntroduction}>
                    <div>
                      <Title>
                        {ticketDetail?.name}
                      </Title>
                    </div>
                  </section>

                  <PageHeader className="relative">
                    {isCurrentWallet && (
                      <div
                        className={cssStyles.imageDownload}
                        onClick={onClickDownloadImage}
                      >
                        <DownloadIcon />
                      </div>
                    )}

                    <img
                      src={ticketDetail?.image}
                      alt=""
                      className="block h-full mx-auto object-contain"
                    />
                  </PageHeader>

                  <Container className={cssStyles.detailMarketInformation}>
                    <Row>
                      <Description text={ticketDetail?.description} />
                    </Row>
                    <Row>
                      <div className="Description">
                        <Header sub>Share</Header>

                        <div className={cssStyles.imageShareWrapper}>
                          <div
                            className={cssStyles.imageShare}
                            onClick={onClickShareTicket('facebook')}
                          >
                            <FacebookIcon />
                          </div>
                          <div
                            className={cssStyles.imageShare}
                            onClick={onClickShareTicket('twitter')}
                          >
                            <TwitterIcon />
                          </div>
                          <div
                            className={cssStyles.imageShare}
                            onClick={onClickShareTicket('telegram')}
                          >
                            <TelegramIcon />
                          </div>
                        </div>
                      </div>
                    </Row>
                  </Container>
                </div>
              </div>
            )}
          </div>
        </LayoutPage>
      </div>
    </>
  )
}
export default TicketDetailPage
