import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import MarketplacePage from './MarketplacePage'
import {
  MapStateProps,
  MapDispatchProps,
  MapDispatch
} from './MarketplacePage.types'

import { RootState } from '../../../modules/reducer'
import { fetchAssetsFromRoute } from '../../../modules/routing/actions'
import { fetchToggleModalDetailEstateRequest } from '../../../modules/ui/modal/actions'
import {
  getHomepage,
  getHomepageLoading
} from '../../../modules/ui/asset/homepage/selectors'
import { fetchNFTRequest } from '../../../modules/nft/actions'
import { fetchItemRequest } from '../../../modules/item/actions'
import { fetchItemsNFTRequest } from '../../../modules/itemNFT/actions'
import { getCategoriesItemsNFT } from '../../../modules/itemNFT/selectors'

const mapState = (state: RootState): MapStateProps => ({
  homepage: getHomepage(state),
  homepageLoading: getHomepageLoading(state),
  categoriesItemNFT: getCategoriesItemsNFT(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onNavigate: path => dispatch(push(path)),
  onFetchAssetsFromRoute: options => dispatch(fetchAssetsFromRoute(options)),
  onFetchNFT: (contractAddress: string, tokenId: string) =>
    dispatch(fetchNFTRequest(contractAddress, tokenId)),
  onFetchItem: (contractAddress: string, tokenId: string) =>
    dispatch(fetchItemRequest(contractAddress, tokenId)),
  toggleModalDetailEstate: newModalState => dispatch(fetchToggleModalDetailEstateRequest(newModalState)),
  onFetchCollectionItems: (query: any) => dispatch(fetchItemsNFTRequest(query)),
})

export default connect(mapState, mapDispatch)(MarketplacePage)
