import React from 'react'

import LayoutPage from '../Layout/LayoutPage/LayoutPage';

import { AssetBrowse } from '../AssetBrowse'
import Breadcrumb from '../Breadcrumb'

import useWatchNetwork from '../../../customHooks/useWatchNetwork'

import { Props } from './LandsPage.types'
import { VendorName } from '../../../modules/vendor/types'
import { View } from '../../../modules/ui/types'
import { Section } from '../../../modules/vendor/decentraland'

import './style.scss'

const LandsPage = (props: Props) => {
  const { isMap } = props

  const breadcrumb = [
    { name: 'Home', link: '/' },
    { name: 'Marketplace', link: '/marketplace' },
    { name: 'Lands', active: true, icon: true, isBreakHere: true }
  ]

  useWatchNetwork()

  return (
    <LayoutPage viewMode="white">
      <div className="flex-grow min-h-full overflow-auto Marketplace__background dark bg-black text-white">
        <div className="Page__header mt-8">
          <Breadcrumb breadcrumb={breadcrumb} viewMode="dark" />
        </div>

        {/* <div className="pb-16 px-8 lg:px-0 border-b-2 border-gray">
          <img src={mapImg} alt="map" className="w-full" />
          <p className="text-xs-sm pl-8 mt-4">
            * New settlement - York colony - will be available in 2022. Please
            follow our Newsfeed for more information.
          </p>
        </div> */}

        <div className="border-b-2 border-gray lands-page__map-custom pb-8 mb-8">
          <AssetBrowse
            vendor={VendorName.DECENTRALAND}
            view={View.MARKET}
            isFullscreen={false}
            isMap={isMap}
            sections={[Section.LAND]}
            // mapHeight={152}
          />

          {/* <ModalDetailEstate open={isOpen} onToggle={onToggle} /> */}
        </div>
      </div>
    </LayoutPage>
  )
}

export default React.memo(LandsPage)
