import React, { useEffect, useState, useCallback } from 'react'
import { Page, Loader, HeaderMenu, Header, Button } from '@wiicamp/decentraland-ui'
import { t } from '@wiicamp/decentraland-dapps/dist/modules/translation/utils'

import LayoutPage from '../Layout/LayoutPage/LayoutPage'
import Breadcrumb from '../Breadcrumb'
import { Bid } from '../Bid'

import { Breadcrumb as BreadcrumbType } from '../Breadcrumb/Breadcrumb.types'
import { Props } from './MyBidsPage.types'

import './MyBidsPage.scss'

const MyBidsPage = (props: Props) => {
  const {
    wallet,
    isConnecting,
    isLoading,
    bidderBids,
    sellerBids,
    archivedBidIds,
    onFetchBids
  } = props

  const [showArchived, setShowArchivedSeller] = useState(false)

  const handleToggleSeller = useCallback(
    () => setShowArchivedSeller(!showArchived),
    [showArchived, setShowArchivedSeller]
  )

  const breadcrumb: BreadcrumbType[] = [
    { name: 'Home', link: '/' },
    {
      name: 'My Bids',
      active: true,
      isBreakHere: true
    }
  ]

  useEffect(() => {
    if (wallet) {
      onFetchBids(wallet.address)
    }
  }, [wallet, onFetchBids])

  const archived = sellerBids.filter(bid => archivedBidIds.includes(bid.id))
  const unarchived = sellerBids.filter(bid => !archivedBidIds.includes(bid.id))
  const filteredSeller = showArchived ? archived : unarchived

  return (
    <LayoutPage viewMode="white">
      <div className="flex-grow min-h-full overflow-auto ActivityPage__container">
        <div className="Page__header mt-14 lg:mt-28 mb-14">
          <Breadcrumb breadcrumb={breadcrumb} viewMode="dark" />
        </div>

        <div className="MyBidsPage mb-10">
          <Page>
            {isConnecting || !wallet ? (
              <Loader size="massive" active />
            ) : (
              <>
                <HeaderMenu>
                  <HeaderMenu.Left>
                    <Header sub>
                      {t(
                        showArchived
                          ? 'my_bids_page.bids_archived'
                          : 'my_bids_page.bids_received'
                      )}
                    </Header>
                  </HeaderMenu.Left>
                  <HeaderMenu.Right>
                    {showArchived || archived.length > 0 ? (
                      <Button basic onClick={handleToggleSeller}>
                        {showArchived
                          ? t('my_bids_page.show_received', {
                            amount: unarchived.length
                          })
                          : t('my_bids_page.show_archived', {
                            amount: archived.length
                          })}
                      </Button>
                    ) : null}
                  </HeaderMenu.Right>
                </HeaderMenu>
                <div className="bids">
                  {filteredSeller.length === 0 && isLoading ? (
                    <div className="center">
                      <Loader active />
                    </div>
                  ) : null}
                  {filteredSeller.length === 0 && !isLoading ? (
                    <div className="center">
                      <div className="empty">
                        {t(
                          showArchived
                            ? 'my_bids_page.empty_archived'
                            : 'my_bids_page.empty_received'
                        )}
                      </div>
                    </div>
                  ) : null}
                  {filteredSeller.length > 0
                    ? filteredSeller.map(bid => <Bid key={bid.id} bid={bid} />)
                    : null}
                </div>
                <HeaderMenu>
                  <HeaderMenu.Left>
                    <Header sub>{t('my_bids_page.bids_placed')}</Header>
                  </HeaderMenu.Left>
                </HeaderMenu>
                <div className="bids">
                  {bidderBids.length === 0 && isLoading ? (
                    <div className="center">
                      <Loader active />
                    </div>
                  ) : null}
                  {bidderBids.length === 0 && !isLoading ? (
                    <div className="center">
                      <div className="empty">
                        {t('my_bids_page.empty_placed')}
                      </div>
                    </div>
                  ) : null}
                  {bidderBids.length > 0
                    ? bidderBids.map(bid => <Bid key={bid.id} bid={bid} />)
                    : null}
                </div>
              </>
            )}
          </Page>
        </div>
      </div>
    </LayoutPage>
  )
}

export default React.memo(MyBidsPage)
