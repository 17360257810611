import {
  useState,
  useCallback,
  useMemo,
  useEffect,
  // memo
} from 'react'
import { Field, Button } from '@wiicamp/decentraland-ui'
import { toast } from 'react-toastify'

import LayoutPage from '../Layout/LayoutPage/LayoutPage'
import Breadcrumb from '../Breadcrumb'

// import { USER_EXISTED_STATUS } from '../../../modules/authentication/reducer'

import styles from './AccountSettingsPage.module.scss'

import { Props } from './AccountSettingsPage.types'

const PASSWORD_FORM = {
  password: '',
  confirmPassword: ''
}

const VIEW = {
  ACCOUNT: 'account',
  PASSWORD: 'password',
}

const AccountSettingsPage = (props: Props) => {
  const {
    token,
    wallet,
    userConnectWithWallet,
    isLoadingUpdateEmailAndUsernameOfUser,
    isLoadingGetUserSignMessage,
    isLoadingSetUserSignMetamask,
    isLoadingUpdateUserPassword,
    isLoadingSetVerifyWalletToAccount,
    dispatchSetUpdateUserPasswordRequest,
    updateEmailAndUsernameOfUser
  } = props
  const [passwordForm, setPasswordForm] = useState({ ...PASSWORD_FORM })
  const [email, setEmail] = useState('')
  const [username, setUsername] = useState('')
  const [view, setView] = useState(VIEW.ACCOUNT);

  const pageTitle = useMemo(() => {
    if (!wallet || !wallet.address) {
      return 'You must login first!'
    }

    switch (view) {
      case VIEW.PASSWORD:
        return `For your security, we highly recommend that you choose a unique
        password that you don’t use for any other online account.`

      case VIEW.ACCOUNT:
        // return `Create your account`
        return `Update your account`

      default:
        return `For your security, we highly recommend that you choose a unique
        password that you don’t use for any other online account`
    }
  }, [wallet, view])

  const breadcrumb = useMemo(() => [
    { name: 'Home', link: '/' },
    {
      name: 'ACCOUNT SETTINGS',
      active: true,
      isBreakHere: true
    }
  ], [])

  const isButtonDisabled = useMemo(() => {
    if (view === VIEW.PASSWORD) return isLoadingUpdateUserPassword

    return isLoadingUpdateEmailAndUsernameOfUser || isLoadingGetUserSignMessage || isLoadingSetUserSignMetamask || isLoadingSetVerifyWalletToAccount
  }, [
    view,
    isLoadingUpdateUserPassword,
    isLoadingUpdateEmailAndUsernameOfUser,
    isLoadingGetUserSignMessage,
    isLoadingSetUserSignMetamask,
    isLoadingSetVerifyWalletToAccount,
  ])

  const onClickUpdateUser = useCallback(() => {
    updateEmailAndUsernameOfUser({ email, username})
  }, [updateEmailAndUsernameOfUser, email, username])

  const onChangePasswordForm = useCallback((key: string, value: string) => {
    setPasswordForm(prevState => ({ ...prevState, [key]: value }))
  }, [])

  const onClickUpdatePassword = useCallback(() => {
    const { password, confirmPassword } = passwordForm

    if (!password || !confirmPassword) {
      const errors: string [] = []

      if (!password) errors.push('password')
      if (!confirmPassword) errors.push('confirm password')

      return toast.error(`Field ${errors.join(', ')} is empty!`)
    }

    if (password !== confirmPassword) return toast.error('Confirm password incorrect!')

    dispatchSetUpdateUserPasswordRequest({
      password,
      callback: {
        success: () => {
          setPasswordForm({ ...PASSWORD_FORM })
        }
      }
    })

  }, [passwordForm, dispatchSetUpdateUserPasswordRequest])

  const onChangeStateByInput = useCallback((setState, value) => setState(value), [])

  const onChangeView = useCallback(() => {
    setView((prevView) => prevView === VIEW.PASSWORD ? VIEW.ACCOUNT : VIEW.PASSWORD)
  }, [setView])

  const handleSubmitChangeAccount = useCallback(() => {
    // Checking user f12 delete modal to sign metamask
    if (!token) return toast.error('Something when wrong. Please reload your website!')

    return view === VIEW.PASSWORD ? onClickUpdatePassword() : onClickUpdateUser()
  }, [view, onClickUpdatePassword, onClickUpdateUser, token])

  useEffect(() => {
    if (!userConnectWithWallet || !token) return

    setEmail(userConnectWithWallet.email)
    setUsername(userConnectWithWallet.username)
  } ,[userConnectWithWallet, token, view])


  if (!wallet || !wallet.address) {
    return (
      <div className={styles.wrapper}>
        <LayoutPage viewMode="white">
          <div className={styles.content}>
            <div className="Page__header mt-16 lg:mt-28">
              <Breadcrumb breadcrumb={breadcrumb} viewMode="dark" />

              <div className={styles.summary}>
                {pageTitle}
              </div>
            </div>
          </div>
        </LayoutPage>
      </div>
    )
  }

  return (
    <div className={styles.wrapper}>
      <LayoutPage viewMode="white">
        <div className={styles.content}>
          <div className="Page__header mt-16 lg:mt-28">
            <Breadcrumb breadcrumb={breadcrumb} viewMode="dark" />

            <div className={styles.summary}>
              {pageTitle}
            </div>
          </div>

          {
            view === VIEW.ACCOUNT &&
            <div className={styles.emailGroup}>
              <div>
                <Field
                  label="Email"
                  placeholder="/email"
                  onChange={(_event, props) => onChangeStateByInput(setEmail, props.value)}
                  value={email}
                />
              </div>

              <div>
                <Field
                  label="Username"
                  placeholder="/username"
                  onChange={(_event, props) => onChangeStateByInput(setUsername, props.value)}
                  value={username}
                />
              </div>
            </div>}

          <div className={styles.inputGroup}>
            {view === VIEW.PASSWORD &&
              <>
                <div>
                  <Field
                    label="New Password"
                    placeholder="/new password"
                    onChange={(_event, props) =>
                      onChangePasswordForm('password', props.value)
                    }
                    value={passwordForm.password}
                  />
                </div>

                <div>
                  <Field
                    label="Confirm Password"
                    placeholder="/confirm password"
                    onChange={(_event, props) =>
                      onChangePasswordForm('confirmPassword', props.value)
                    }
                    value={passwordForm.confirmPassword}
                  />
                </div>
              </>
            }

            <div className="flex justify-between flex-wrap gap-8">
              <div>
                <Button
                  primary
                  loading={isButtonDisabled}
                  disabled={isButtonDisabled}
                  onClick={handleSubmitChangeAccount}
                >Update</Button>
              </div>

              <div>
                <Button
                  secondary
                  onClick={onChangeView}
                  >
                  {view === VIEW.PASSWORD ? 'Go to update Account' : 'Go to Update Password'}
                </Button>
              </div>

              {/* {userExistedStatus === USER_EXISTED_STATUS.EXISTED &&
                <div>
                  <Button onClick={onClickUpdatePassword}>Update Password</Button>
                </div>
              }

              {userExistedStatus === USER_EXISTED_STATUS.NOT_YET &&
                <div>
                  <Button onClick={onClickCreateNewUser}>continue</Button>
                </div>
              } */}
            </div>
          </div>
        </div>
      </LayoutPage>
    </div>
  )
}

// export default memo(AccountSettingsPage)
export default AccountSettingsPage
