import * as yup from 'yup';

const IMAGE_SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png']
// const FILE_3D_SUPPORTED_FORMATS = ['application/json']

const schema = yup.object().shape({
  name: yup.string()
    .required('Name is required !')
    .min(2, 'Name must be at least 2 characters!'),
  description: yup.string()
  .required('Description is required!'),
  image: yup.mixed()
    .test('required', 'Image is required !', (value: any) => value.length > 0)
    .test('fileSize', 'The file is too large! Maximum 15mb ', (value: any) => {
      if (!value.length) return false
      if (typeof value[0] === 'string') return true
      return value[0].size <= 15 * 1024 * 1024;
    })
    .test(
      'fileFormat',
      'Please upload only *.jpg, *.jpeg, *.png',
      (value) => {
        if (typeof value[0] === 'string') return true
        const isWrongFormat = value.some((v: any) => !IMAGE_SUPPORTED_FORMATS.includes(v.type));
        return !isWrongFormat;
      },
    ),
  texturesImage: yup.mixed(),
    // .test('required', 'Texture image is required !', (value: any) => value.length > 0)
    // .test('fileSize', 'The file is too large! Maximum 15mb ', (value: any) => {
    //   if (!value.name) return true; // attachment is optional
    //   return value.size <= 15 * 1024 * 1024;
    // })
    // .test(
    //   'fileFormat',
    //   'Please upload only *.jpg, *.jpeg, *.png',
    //   (value) => {
    //     const isWrongFormat = value.some((v: any) => !IMAGE_SUPPORTED_FORMATS.includes(v.type));
    //     return !isWrongFormat;
    //   },
    // ),
  rarity: yup.string().required().required('Rarity is required!'),
  category: yup.string().required().required('Category is required!'),
  subCategory: yup.string().nullable(true),
  file3d: yup.mixed()
    // .test('required', 'File 3D is required!', (value) => value.length > 0)
    // .test(
    //   'fileFormat',
    //   'Please upload only *.json',
    //   (value) => {
    //     const isWrongFormat = value.some((v: any) => !FILE_3D_SUPPORTED_FORMATS.includes(v.type));
    //     return !isWrongFormat;
    //   },
    // ),
});

export { schema }
