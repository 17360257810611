import React from 'react'
import { Container } from '@wiicamp/decentraland-ui'
import { t } from '@wiicamp/decentraland-dapps/dist/modules/translation/utils'

import { getAssetName } from '../../../../modules/asset/utils'

import { PageHeader } from '../../../PageHeader'
import { AssetImage } from '../../../AssetImage'
import { Row } from '../../../Layout/Row'
import { Column } from '../../../Layout/Column'

import { Title } from '../Title'
import { Owner } from '../Owner'
import { Badge } from '../Badge'
import { Network } from '../Network'
import { OrderDetails } from '../OrderDetails'
import { Actions } from '../Actions'
import { Bids } from '../Bids'
import { TransactionHistory } from '../TransactionHistory'

import { Props } from './ENSDetail.types'
import styles from './ENSDetail.module.scss'

const ENSDetail = (props: Props) => {
  const { nft } = props

  return (
    <div className={styles.detail}>
      <section className={styles.sectionIntroduction}>
        <div>
          <Title>
            {getAssetName(nft)}

            <Badge color="#37333d" className={styles.mgL}>{t('global.ens')}</Badge>
          </Title>
        </div>

        <Owner asset={nft} />
      </section>

      <PageHeader>
        <AssetImage asset={nft} showMonospace />
      </PageHeader>

      <Container className={styles.detailMarketInformation}>
        <Row>
          <Column align="left" grow={true}>
            <Network asset={nft} />
            <OrderDetails nft={nft} />
          </Column>

          <Column align="right">
            <Actions nft={nft} />
          </Column>
        </Row>

        <Bids nft={nft} />

        <TransactionHistory nft={nft} />
      </Container>
    </div>
  )
}

export default React.memo(ENSDetail)
