import React from 'react'
import classNames from 'classnames'

import { Loader } from '../Loader'

import { Props } from './Button.types'

const Button = (props: Props) => {
  const {
    children,
    onClick,
    color = 'primary',
    outline = false,
    className,
    disabled,
    isLoading,
    size = 'md'
  } = props

  return (
    <button
      {...props}
      disabled={disabled}
      className={classNames(
        'flex items-center justify-center',
        size === 'md' && 'py-2 px-5',
        size === 'sm' && 'p-2 h-10',
        size === 'xs' && 'p-1',
        color === 'primary' && 'bg-primary hover:opacity-80',
        color === 'secondary' && 'bg-white1 text-white hover:opacity-80',
        color === 'white' &&
        'bg-white text-black border uppercase hover:bg-black hover:text-white transition',
        color === 'blue' && 'bg-blue1 text-white hover:opacity-80',
        color === 'danger' && 'bg-red-600 text-white hover:opacity-80',
        color === 'black' &&
        'bg-black border uppercase text-gray hover:bg-white hover:text-black transition',
        color === 'black1' &&
        'bg-black2 text-gray hover:bg-white hover:text-black transition',
        outline && 'text-primary bg-blue1',
        disabled && 'opacity-50 cursor-default !hover:opacity-80',
        className
      )}
      onClick={onClick}
    >
      {children} {isLoading && <Loader className="ml-2" color="white" />}
    </button>
  )
}

Button.propsDefault = {
  color: 'primary',
  outline: false,
  className: '',
  disabled: false,
  isLoading: false,
  size: 'md'
}

export default React.memo(Button)
