import React, { useState, useCallback } from 'react'
import { useHistory } from 'react-router'
import { Page, Header, Form, Field, Button } from '@wiicamp/decentraland-ui'
import { t, T } from '@wiicamp/decentraland-dapps/dist/modules/translation/utils'

import { locations } from '../../../modules/routing/locations'
import { getAssetName, isOwnedBy } from '../../../modules/asset/utils'
import { AssetType } from '../../../modules/asset/types'

import { Wallet } from '../Wallet'
import { AssetProviderPage } from '../AssetProviderPage'
import { AssetAction } from '../AssetAction'

import useWatchNetwork from '../../../customHooks/useWatchNetwork'

import { Props } from './TransferPage.types'
import './TransferPage.scss'

const TransferPage = (props: Props) => {
  const { onNavigate, onTransfer, isTransfering } = props
  const history = useHistory()

  const [address, setAddress] = useState('')
  const [isInvalidAddress, setIsInvalidAddress] = useState(false)

  const onCheckGoBack = useCallback((nft) => () => {
    if (history.length === 2) {
      onNavigate(locations.nftLOM(nft.contractAddress, nft.tokenId))
    } else {
      history.goBack()
    }
  }, [history, onNavigate])

  useWatchNetwork()

  return (
    <Page className="TransferPage">
      <Wallet>
        {wallet => (
          <AssetProviderPage type={AssetType.NFT}>
            {(nft, order) => {
              let subtitle
              let isDisabled = !address || isInvalidAddress || isTransfering
              let canTransfer = true
              const subtitleClasses = ['subtitle']
              const name = getAssetName(nft)
              if (order) {
                isDisabled = true
                canTransfer = false
                subtitleClasses.push('error')
                subtitle = (
                  <T
                    id="transfer_page.for_sale"
                    values={{ name: <b>{name}</b> }}
                  />
                )
              } else if (!isOwnedBy(nft, wallet)) {
                isDisabled = true
                canTransfer = false
                subtitleClasses.push('error')
                subtitle = (
                  <T
                    id="transfer_page.invalid_owner"
                    values={{ name: <b>{name}</b> }}
                  />
                )
              } else {
                subtitle = (
                  <T
                    id="transfer_page.subtitle"
                    values={{ name: <b>{name}</b> }}
                  />
                )
              }
              return (
                <AssetAction asset={nft}>
                  <Header size="large">
                    {t('transfer_page.title', {
                      category: t(`global.${nft.category}`)
                    })}
                  </Header>
                  <div className={subtitleClasses.join(' ')}>{subtitle}</div>
                  <Form onSubmit={() => onTransfer(nft, address)}>
                    <div className="form-fields">
                      <Field
                        type="address"
                        error={isInvalidAddress}
                        message={
                          isInvalidAddress
                            ? t('transfer_page.invalid_address')
                            : undefined
                        }
                        label={t('transfer_page.recipient')}
                        value={address}
                        placeholder="0x..."
                        disabled={!canTransfer}
                        onChange={(_event, props) => {
                          setAddress(props.value)
                          const isValid =
                            !props.value ||
                            /^0x[a-fA-F0-9]{40}$/g.test(props.value)
                          setIsInvalidAddress(!isValid)
                        }}
                      />
                    </div>
                    {canTransfer ? (
                      <div className="warning">
                        <T
                          id="transfer_page.warning"
                          values={{ br: <br /> }}
                        />
                      </div>
                    ) : null}
                    <div className="buttons">
                      <Button
                        as="div"
                        className="LOM-cancel-btn"
                        onClick={onCheckGoBack(nft)}
                      >
                        {t('global.cancel')}
                      </Button>
                      <Button
                        className="LOM-submit-btn"
                        type="submit"
                        primary
                        loading={isTransfering}
                        disabled={isDisabled}
                      >
                        {t('transfer_page.submit')}
                      </Button>
                    </div>
                  </Form>
                </AssetAction>
              )
            }}
          </AssetProviderPage>
        )}
      </Wallet>
    </Page>
  )
}

export default React.memo(TransferPage)
