import { connect } from 'react-redux'
import { disconnectWallet } from '@wiicamp/decentraland-dapps/dist/modules/wallet/actions'

import { getWallet } from '../../../modules/wallet/selectors'
import MenuDropdown from './MenuDropdown'

import { setLogoutWebsiteRequest } from '../../../modules/authentication/actions'

import {
  MapStateProps,
  MapDispatchProps,
  MapDispatch
} from './MenuDropdown.types'

import { RootState } from '../../../modules/reducer'

const mapState = (state: RootState): MapStateProps => ({
  wallet: getWallet(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onSignOut: () => dispatch(disconnectWallet()),
  dispatchSetLogoutWebsiteRequest: () => dispatch(setLogoutWebsiteRequest()),
})

export default connect(mapState, mapDispatch)(MenuDropdown)
