import {
  LoadingState,
  loadingReducer
} from '@wiicamp/decentraland-dapps/dist/modules/loading/reducer'

import {
  FETCH_LIST_ARTICLES_FAILURE,
  FETCH_LIST_ARTICLES_REQUEST,
  FETCH_LIST_ARTICLES_SUCCESS,
  FetchListArticlesFailureAction,
  FetchListArticlesRequestAction,
  FetchListArticlesSuccessAction,
  FETCH_ARTICLE_DETAIL_FAILURE,
  FETCH_ARTICLE_DETAIL_REQUEST,
  FETCH_ARTICLE_DETAIL_SUCCESS,
  FetchArticleDetailFailureAction,
  FetchArticleDetailRequestAction,
  FetchArticleDetailSuccessAction
} from './actions'

import { SinglePostType } from './types'

export type ArticleState = {
  listArticles: SinglePostType[]
  articleDetail: SinglePostType | null
  loading: LoadingState
  error: string | null
  isRefreshed?: boolean
  page: number
}

const INITIAL_STATE: ArticleState = {
  listArticles: [],
  loading: [],
  articleDetail: null,
  error: null,
  page: 0
}

type ArticleStateAction =
  | FetchListArticlesRequestAction
  | FetchListArticlesSuccessAction
  | FetchListArticlesFailureAction
  | FetchArticleDetailRequestAction
  | FetchArticleDetailSuccessAction
  | FetchArticleDetailFailureAction

export function newsfeedReducer(
  state = INITIAL_STATE,
  action: ArticleStateAction
): ArticleState {
  switch (action.type) {
    case FETCH_LIST_ARTICLES_SUCCESS: {
      const { listArticles, isRefreshed } = action.payload

      if (isRefreshed) {
        return {
          ...state,
          listArticles,
          page: 1,
          loading: loadingReducer(state.loading, action),
          error: null
        }
      }

      return {
        ...state,
        listArticles: [...state.listArticles, ...listArticles],
        page: state.page + 1,
        loading: loadingReducer(state.loading, action),
        error: null
      }
    }

    case FETCH_ARTICLE_DETAIL_SUCCESS: {
      const { articleDetail } = action.payload

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        articleDetail,
        error: null
      }
    }

    case FETCH_ARTICLE_DETAIL_FAILURE:
    case FETCH_LIST_ARTICLES_FAILURE: {
      const { error } = action.payload

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: error
      }
    }

    case FETCH_ARTICLE_DETAIL_REQUEST:
    case FETCH_LIST_ARTICLES_REQUEST: {
      return {
        ...state,
        loading: loadingReducer(state.loading, action)
      }
    }

    default:
      return state
  }
}
