import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useHistory, useParams } from "react-router-dom"
import {
  Dropdown,
  Field,
  TextArea,
  Loader,
} from "@wiicamp/decentraland-ui"
import { ValidationError } from "yup"

import Breadcrumb from "../Breadcrumb"
import LayoutPage from "../Layout/LayoutPage/LayoutPage"

import { schema } from "./schema"

import Loading from "../Loading"

import { VIEW_MODE } from "../AssetBrowse/AssetBrowse"

import { locations } from "../../../modules/routing/locations"

import { ReactComponent as UploadIcon } from "../../../asset/icons/ic-upload.svg"
import { ReactComponent as IconPostImage } from "../../../asset/icons/ic_post_image.svg"

import { FormInitType, Props } from './UploadItemPage.types'
import { CategoriesType, RarityNFTType, SubCategory } from "../../../modules/itemNFT/types"

import styles from "./UploadItemPage.module.scss"

// import { RARITIES, CATEGORIES } from "./fakers"
// import { toast } from "react-toastify"
// import toBase64 from "../../../helpers/toBase64"
// import { ITEM_STATUS } from "../../../lib/constant"

// const DEFAULT_FILE_DATA = {
//   name: '',
//   file: null,
// }

const FORM_INIT: FormInitType = {
  name: "",
  description: "",
  image: [],
  texturesImage: [],
  rarity: '',
  category: '',
  subCategory: '',
  file3d: [],
  // status: ITEM_STATUS.PENDING,
}

const UploadItemPage = (props: Props) => {
  const {
    // wallet,
    // edit
    itemUserCreationEdit,
    isEditItemUserCreation,
    isLoadingEditItemUserCreation,
    editItemUserCreation,
    isLoadingFetchItemUserCreationEdit,
    fetchItemUserCreationEdit,
    // create
    createNewItemNFT,
    createNewItemNFTLoading,
    categoriesItemsNFT,
    raritiesItemsNFT
  } = props
  const [form, setForm] = useState({ ...FORM_INIT })
  const [formErrors, setFormErrors] = useState<ValidationError[]>([])

  const history = useHistory()
  const params: { id: string } = useParams()

  const rarities = useMemo(() => raritiesItemsNFT.map((item: RarityNFTType) => ({ text: item.name, value: item.id })), [raritiesItemsNFT])
  const categories = useMemo(() => categoriesItemsNFT.map((item: CategoriesType) => ({ text: item.name, value: item.id })), [categoriesItemsNFT])

  const breadcrumb = useMemo(
    () => [
      { name: "Home", link: "/" },
      {
        name: isEditItemUserCreation ? "Edit NFT Item" : "Create NFT Item",
        active: true,
        isBreakHere: true
      }
    ], [isEditItemUserCreation])

  const listSubCategories = useMemo(() => {
    if (form.category && categoriesItemsNFT.length > 0) {
      const selectedCategory = categoriesItemsNFT.find((category: CategoriesType) => category.id === form.category)

      if (selectedCategory?.subCategories.length === 0) return []

      const subCategories = selectedCategory?.subCategories.map((item: SubCategory) => ({...item, text: item.name, value: item.id }))

      return subCategories
    }

    return []
  }, [form.category, categoriesItemsNFT])

  // const getFileName = useCallback((filePath) => filePath.replace("C:\\fakepath\\", ''), [])

  const onRemoveErrorWhenInputFieldChange = useCallback((keyValue: string) => {
    const newFormErrors = [...formErrors].filter(err => err.path !== keyValue)
    setFormErrors(newFormErrors)
  }, [formErrors])

  const onUpdateForm = useCallback((value, keyValue) => {
    onRemoveErrorWhenInputFieldChange(keyValue)

    if (keyValue === 'category') {
      setForm((prevForm: FormInitType) => ({ ...prevForm, [keyValue]: value, subCategory: '' }))
      return
    }

    setForm((prevForm: FormInitType) => ({ ...prevForm, [keyValue]: value }))
  }, [onRemoveErrorWhenInputFieldChange])

  const onChangeUpdateFormImage = useCallback((keyValue) => async (event: React.ChangeEvent<HTMLInputElement>) => {
    // const file = await toBase64(event.target.files[0])
    // const newImage = {
    //   name: getFileName(event.target.value),
    //   file,
    // }
    onRemoveErrorWhenInputFieldChange(keyValue)

    if (!event.target.files || event.target.files.length === 0) {
      setForm((prevForm: FormInitType) => ({ ...prevForm, [keyValue]: [] }))
      return
    }

    const files: File[] = [];

    for (let index = 0; index < event.target.files.length; index += 1) {
      files.push(event.target.files[index]);
    }

    setForm((prevForm: FormInitType) => ({ ...prevForm, [keyValue]: files }))
  }, [onRemoveErrorWhenInputFieldChange])

  const onClickSubmitForm = useCallback(async () => {
    // setIsLoading(true)
    const errors = await schema.validate(form, { abortEarly: false })
      .catch((err) => err.inner.map((e: ValidationError) => ({ message: e.message, path: e.path })));

    if (errors.length > 0) {
      setFormErrors(errors);
    } else {
      setFormErrors([]);

      const callback = () => {
        setForm({ ...FORM_INIT })
      };

      if (isEditItemUserCreation) {
        editItemUserCreation({ ...form, id: itemUserCreationEdit?._id || '' })
      } else {
        createNewItemNFT({ item: form, callback });
      }
    }
  }, [createNewItemNFT, form, editItemUserCreation, isEditItemUserCreation])

  // useEffect(() => {
  //   if (form.category) {
  //     onUpdateForm(null, 'subCategory')
  //   }
  // }, [form.category]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // if (!wallet) return
    if (!isEditItemUserCreation || !categoriesItemsNFT.length) return

    if (itemUserCreationEdit) {
      setForm({
        name: itemUserCreationEdit.name,
        description: itemUserCreationEdit.description,
        category: Number(itemUserCreationEdit.category.id),
        rarity: Number(itemUserCreationEdit.rarity),
        subCategory: itemUserCreationEdit.subCategory?.id || '',
        image: [itemUserCreationEdit.image],
        texturesImage: [],
        file3d: []
      })
    } else {
      const onRedirect = () => history.push(locations.currentAccountLOM({ type: VIEW_MODE.CREATIONS }))

      fetchItemUserCreationEdit(params?.id, onRedirect)
    }

    return () => {
      setForm({ ...FORM_INIT })
      setFormErrors([])
    }
  }, [history, params, itemUserCreationEdit, isEditItemUserCreation, categoriesItemsNFT])

  const onFindErrorMessage = useCallback((fieldName: string) => {
    if (formErrors.length === 0) return '';

    const errorsFound = formErrors.filter((error: ValidationError) => error.path === fieldName);

    if (errorsFound.length === 0) return '';

    return errorsFound[0].message;
  }, [formErrors]);

  const nameOfFile = useCallback((files) => {
    if (!files.length) return 'No file selected yet.'

    if (typeof files[0] === 'string') return files[0]

    return files[0].name
  }, [])

  const srcPreviewImage = useCallback((files: File[] | string[]) => {
    if (typeof files[0] === 'string') return files[0]

    return URL.createObjectURL(files[0])
  },[])

  return (
    <div className={styles.wrapper}>
      <LayoutPage viewMode="white">
        <div className={styles.container}>
          <div className="Page__header mt-16 lg:mt-28">
            <Breadcrumb breadcrumb={breadcrumb} viewMode="dark" />

            <div className={styles.summary}>
              Building items for the Metaverse
            </div>
          </div>

          {isLoadingFetchItemUserCreationEdit || (!itemUserCreationEdit && isEditItemUserCreation) ? (
            <div className="mt-56">
              <div className="overlay bg-transparent" />
              <Loader size="massive" active />
            </div>
          ) : (
            <div className="flex flex-1 flex-col">
              <div className={styles.formTitle}>
                <h2>
                  Please provide information about your item:
                </h2>

                <div className={styles.form}>
                  <div className={styles.formCol}>
                    <div className={styles.formItem}>
                      <div className={styles.formLabel}>
                        name
                      </div>

                      <Field
                        value={form.name}
                        onChange={(_, { value }) => onUpdateForm(value, "name")}
                      />

                      <span className={styles.errorMessage}>{onFindErrorMessage('name')}</span>
                    </div>

                    <div className={styles.formItem}>
                      <div className={styles.formLabel}>
                        description
                      </div>

                      <TextArea
                        value={form.description}
                        onChange={(_, { value }) => onUpdateForm(value, "description")}
                      />

                      <span className={styles.errorMessage}>{onFindErrorMessage('description')}</span>
                    </div>

                    <div className={styles.formItem}>
                      <div className={styles.formLabel}>
                        Image
                      </div>

                      <label htmlFor="image" className={styles.formLabelFor}>
                        <IconPostImage />

                        <span>
                          {/* {!!form.image.length ? form.image[0].name : 'No file selected yet.'} */}
                          {nameOfFile(form.image)}
                        </span>

                      </label>

                      <input
                        id="image"
                        className="hidden"
                        type="file"
                        onChange={onChangeUpdateFormImage('image')}
                      />

                      <span className={styles.errorMessage}>{onFindErrorMessage('image')}</span>

                      {!!form.image.length && <img src={srcPreviewImage(form.image)} alt="preview" />}
                    </div>

                    <div className={styles.formItem}>
                      <div className={styles.formLabel}>
                        Textures Image
                      </div>

                      <label htmlFor="Textures_Image" className={styles.formLabelFor}>
                        <IconPostImage />

                        <span>
                          {/* {!!form.texturesImage.length ? form.texturesImage[0].name : 'No file selected yet.'} */}
                          {nameOfFile(form.texturesImage)}
                        </span>

                      </label>

                      <input
                        id="Textures_Image"
                        className="hidden"
                        type="file"
                        onChange={onChangeUpdateFormImage('texturesImage')}
                      />

                      <span className={styles.errorMessage}>{onFindErrorMessage('texturesImage')}</span>
                    </div>
                  </div>

                  <div className={styles.formCol}>
                    <div className={styles.formItem}>
                      <div className={styles.formLabel}>
                        Rarity
                      </div>

                      <Dropdown
                        direction="right"
                        placeholder="Rarity"
                        value={form.rarity}
                        options={rarities}
                        onChange={(_, { value }) => onUpdateForm(value, "rarity")}
                      />

                      <span className={styles.errorMessage}>{onFindErrorMessage('rarity')}</span>
                    </div>

                    <div className={styles.formItem}>
                      <div className={styles.formLabel}>
                        Categories
                      </div>

                      <Dropdown
                        direction="right"
                        placeholder="Categories"
                        value={form?.category || ''}
                        options={categories}
                        onChange={(_, { value }) => onUpdateForm(value, "category")}
                      />

                      <span className={styles.errorMessage}>{onFindErrorMessage('category')}</span>
                    </div>

                    <div className={styles.formItem}>
                      <div className={styles.formLabel}>
                        Sub Categories
                      </div>

                      <Dropdown
                        direction="right"
                        placeholder="Sub Categories"
                        value={form?.subCategory || ''}
                        options={listSubCategories}
                        onChange={(_, { value }) => onUpdateForm(value, "subCategory")}
                        disabled={!form.category || !listSubCategories?.length}
                      />

                      <span className={styles.errorMessage}>{onFindErrorMessage('subCategory')}</span>
                    </div>

                    <div className={styles.formItem}>
                      <div className={styles.formLabel}>
                        File 3D
                      </div>

                      <label htmlFor="File_3D" className={styles.formLabelFor}>
                        <IconPostImage />

                        <span>
                          {/* {!!form.file3d.length ? form.file3d[0].name : 'No file selected yet.'} */}
                          {nameOfFile(form.file3d)}
                        </span>

                      </label>

                      <input
                        type="file"
                        id="File_3D"
                        className="hidden"
                        onChange={onChangeUpdateFormImage('file3d')}
                      />

                      <span className={styles.errorMessage}>{onFindErrorMessage('file3d')}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-center mt-20">
                {createNewItemNFTLoading || isLoadingEditItemUserCreation
                  ? <Loading />
                  : <div className={styles.btn_create_new_btn} onClick={onClickSubmitForm}>
                    <UploadIcon />
                    {" "}
                    <span>{isEditItemUserCreation ? "Edit" : "Create New"}</span>
                  </div>
                }
              </div>
            </div>
          )}
        </div>
      </LayoutPage>
    </div>
  )
}

export default UploadItemPage
