import {
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react'
import { Modal, Button, Field } from '@wiicamp/decentraland-ui'
import { Link } from 'react-router-dom'

import LayoutPage from '../Layout/LayoutPage/LayoutPage'
import Breadcrumb from '../Breadcrumb'

import LandCard from './LandCard'
import Loading from '../Loading'

import { ReactComponent as IconPlus } from '../../../asset/icons/ic-plus.svg'
import { ReactComponent as IconClose } from '../../../images/ic-close.svg'

import useWatchNetwork from '../../../customHooks/useWatchNetwork'
import { getAssetUrl } from '../../../modules/asset/utils'

import styles from './EstateBuilderPage.module.scss'

type ESTATE_FORM_TYPE = {
  name: string
  description: string
}

const ESTATE_FORM: ESTATE_FORM_TYPE = {
  name: '',
  description: ''
}

const EstateBuilderPage = (pageProps: any) => {
  const {
    wallet,
    postNewEstateRequest,

    isLoadingGetEstatesByWalletAddress,
    estates,
    pageEstates,
    totalEstates,
    dispatchGetEstatesByWalletAddress,

    isLoadingGetLandsByWalletAddress,
    lands,
    pageLands,
    totalLands,
    dispatchGetLandsByWalletAddress,
  } = pageProps;
  const [isShowCreateNewEstateModal, setIsShowCreateNewEstateModal] = useState(false)
  const [listLandsOfNewEstate, setListLandsOfNewEstate] = useState<any[]>([])
  const selectedLands = useMemo(() => listLandsOfNewEstate.map(item => item.id), [listLandsOfNewEstate])
  const [estateForm, setEstateForm] = useState<ESTATE_FORM_TYPE>({ ...ESTATE_FORM })

  const breadcrumb = useMemo(
    () => [
      { name: 'Home', link: '/' },
      {
        name: 'estate builder',
        active: true,
        isBreakHere: true
      }
    ], [])

  const onUpdateEstateForm = useCallback((key, value) => {
    setEstateForm((previousState: ESTATE_FORM_TYPE) => ({ ...previousState, [key]: value }))
  }, [setEstateForm])

  const onUpdateCurrentPage = useCallback((page, dispatchGetData) => () => {
    dispatchGetData({ page, isLoadMore: true })
  }, [])

  const onToggleLandToNewEstate = useCallback((landItem: any) => () => {
    if (selectedLands.includes(landItem.id)) {
      setListLandsOfNewEstate(listLandsOfNewEstate.filter(item => item.id !== landItem.id))
    } else {
      setListLandsOfNewEstate([...listLandsOfNewEstate, landItem])
    }
  }, [listLandsOfNewEstate, selectedLands])

  const onClickOpenCreateNewEstateModal = useCallback((toggleValue, isCreateEstate = false) => () => {
    if (isCreateEstate) {
      const { name, description } = estateForm

      postNewEstateRequest({
        name,
        description,
        address: wallet.address,
        lands: listLandsOfNewEstate
      })
    }

    setIsShowCreateNewEstateModal(toggleValue)
    setListLandsOfNewEstate([])
    setEstateForm({ ...ESTATE_FORM })
  }, [estateForm, listLandsOfNewEstate, postNewEstateRequest, wallet])

  useEffect(() => {
    if (wallet && wallet.address) {
      dispatchGetEstatesByWalletAddress({ page: 1 })
      dispatchGetLandsByWalletAddress({ page: 1 })
    }
  }, [wallet]) // eslint-disable-line

  useWatchNetwork()

  return (
    <>
      <div className={styles.wrapper}>
        <LayoutPage viewMode="white">
          <div className={styles.content}>
            <div className="Page__header mt-16 lg:mt-28">
              <Breadcrumb breadcrumb={breadcrumb} viewMode="dark" />

              {/* <div className={styles.summary}>
              Build your own estate.
            </div> */}
            </div>

            <div className="flex flex-1 flex-col">
              <div className={styles.estate_header}>
                <div className={styles.estate_create_new_btn} onClick={onClickOpenCreateNewEstateModal(true)}>
                  <IconPlus />
                  {' '}
                  <span>Create New</span>
                </div>
              </div>

              <div className={styles.estate_list}>
                {estates.map((item: any) =>
                  <Link
                    to={`/marketplace${getAssetUrl(item)}`}
                    key={`estate_list_${item.id}`}
                    className={styles.estate_item}>
                    <LandCard
                      name={item.name}
                      item={item}
                      isEstate
                    />
                  </Link>
                )}
              </div>

              {isLoadingGetEstatesByWalletAddress &&
                <div className={`flex justify-center ${!estates.length ? 'flex-1' : 'mt-4'}`}>
                  <Loading />
                </div>
              }

              {
                totalEstates !== estates.length && !isLoadingGetEstatesByWalletAddress &&
                <div className="flex justify-center mt-4">
                  <Button
                    className="LOM-submit-btn"
                    onClick={onUpdateCurrentPage(pageEstates + 1, dispatchGetEstatesByWalletAddress)}
                  >
                    Load More
                  </Button>
                </div>
              }
            </div>
          </div>
        </LayoutPage>
      </div>
      <Modal
        size="large"
        open={isShowCreateNewEstateModal}
        className={styles.modal_create_new_estate}
      >
        <div className="relative">
          <div className="absolute right-0 top-0 p-2">
            <IconClose
              fill="#fff"
              className="h-8 w-8 cursor-pointer"
              onClick={onClickOpenCreateNewEstateModal(false)}
            />
          </div>
        </div>

        <Modal.Header>
          Create New Estate
        </Modal.Header>
        <Modal.Content className={styles.modal_content}>
          <div className={styles.inputGroups}>
            <Field
              label="Estate Name"
              placeholder="/estate name"
              onChange={(_event, props) => onUpdateEstateForm('name', props.value)}
              value={estateForm.name}
            />

            <Field
              label="Estate Description"
              placeholder="/estate description"
              onChange={(_event, props) => onUpdateEstateForm('description', props.value)}
              value={estateForm.description}
            />
          </div>

          <div className={styles.estate_list}>
            {lands.map((item: any) =>
              <div
                key={`${item.id}-${item.tokenId}`}
                className={styles.estate_item}
                onClick={onToggleLandToNewEstate(item)}
              >
                <LandCard
                  name={item.name}
                  item={item}
                  isChecked={selectedLands.includes(item.id)}
                />
              </div>
            )}
          </div>

          {
            totalLands !== lands.length && !isLoadingGetLandsByWalletAddress &&
            <div className="flex justify-center mt-4">
              <Button
                className="LOM-submit-btn"
                onClick={onUpdateCurrentPage(pageLands + 1, dispatchGetLandsByWalletAddress)}
              >
                Load More
              </Button>
            </div>
          }

          {isLoadingGetLandsByWalletAddress &&
            <div className={`flex justify-center ${!lands.length ? 'flex-1' : 'mt-4'}`}>
              <Loading />
            </div>
          }
        </Modal.Content>
        <Modal.Actions className={styles.modal_actions}>
          <Button
            className="LOM-cancel-btn"
            onClick={onClickOpenCreateNewEstateModal(false)}>
            Cancel
          </Button>
          <Button
            className="LOM-submit-btn"
            onClick={onClickOpenCreateNewEstateModal(false, true)}
            disabled={!listLandsOfNewEstate.length || !estateForm.name.length}
          >
            Create New Estate
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default EstateBuilderPage
