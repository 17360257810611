import { action } from 'typesafe-actions'
import { CategoriesType, ItemUserCreationType } from '../itemNFT/types'
import { FormInitType } from '../../components/LOM/UploadItemPage/UploadItemPage.types'

// Create new item nft
export const CREATE_NEW_ITEM_NFT_REQUEST = '[Request] Create new item NFT'
export const CREATE_NEW_ITEM_NFT_SUCCESS = '[Success] Create new item NFT'
export const CREATE_NEW_ITEM_NFT_FAILURE = '[Failure] Create new item NFT'

export const createNewItemNftRequest = (payload: any) => action(CREATE_NEW_ITEM_NFT_REQUEST, payload)
export const createNewItemNFTSuccess = (payload: any) => action(CREATE_NEW_ITEM_NFT_SUCCESS, payload)
export const createNewItemNFTFailure = (error: any) => action(CREATE_NEW_ITEM_NFT_FAILURE, error)

export type CreateNewItemNFTRequestAction = ReturnType<typeof createNewItemNftRequest>
export type CreateNewItemNFTSuccessAction = ReturnType<typeof createNewItemNFTSuccess>
export type CreateNewItemNFTFailureAction = ReturnType<typeof createNewItemNFTFailure>


// Get items nft from localstorage
export const GET_ITEMS_NFT_FROM_LOCALSTORAGE_REQUEST = '[Request] Get items NFT from localStorage'
export const GET_ITEMS_NFT_FROM_LOCALSTORAGE_SUCCESS = '[Success] Get items NFT from localStorage'
export const GET_ITEMS_NFT_FROM_LOCALSTORAGE_FAILURE = '[Failure] Get items NFT from localStorage'

export const getItemsNFTFromLocalStorageRequest = (payload: any) => action(GET_ITEMS_NFT_FROM_LOCALSTORAGE_REQUEST, payload)
export const getItemsNFTFromLocalstorageSuccess = (payload: any) => action(GET_ITEMS_NFT_FROM_LOCALSTORAGE_SUCCESS, payload)
export const getItemsNFTFromLocalstorageFailure = (error: any) => action(GET_ITEMS_NFT_FROM_LOCALSTORAGE_FAILURE, error)

export type GetItemsNFTFromLocalStorageRequestAction = ReturnType<typeof getItemsNFTFromLocalStorageRequest>
export type GetItemsNFTFromLocalstorageSuccessAction = ReturnType<typeof getItemsNFTFromLocalstorageSuccess>
export type GetItemsNFTFromLocalstorageFailureAction = ReturnType<typeof getItemsNFTFromLocalstorageFailure>

// Fetch my items creation
export const FETCH_MY_ITEMS_CREATION_REQUEST = '[Request] Fetch my items creation'
export const FETCH_MY_ITEMS_CREATION_SUCCESS = '[Success] Fetch my items creation'
export const FETCH_MY_ITEMS_CREATION_FAILURE = '[Failure] Fetch my items creation'

export const fetchMyItemsCreationRequest = (payload: any) => action(FETCH_MY_ITEMS_CREATION_REQUEST, payload)
export const fetchMyItemsCreationSuccess = (payload: any) => action(FETCH_MY_ITEMS_CREATION_SUCCESS, payload)
export const fetchMyItemsCreationFailure = (error: any) => action(FETCH_MY_ITEMS_CREATION_FAILURE, error)

export type FetchMyItemsCreationRequestAction = ReturnType<typeof fetchMyItemsCreationRequest>
export type FetchMyItemsCreationSuccessAction = ReturnType<typeof fetchMyItemsCreationSuccess>
export type FetchMyItemsCreationFailureAction = ReturnType<typeof fetchMyItemsCreationFailure>

// Delete item user creation
export const DELETE_ITEM_USER_CREATION_REQUEST = '[Request] Delete item user creation'
export const DELETE_ITEM_USER_CREATION_SUCCESS = '[Success] Delete item user creation'
export const DELETE_ITEM_USER_CREATION_FAILURE = '[Failure] Delete item user creation'

export const deleteItemUserCreationRequest = (id: string, callback: Function) => action(DELETE_ITEM_USER_CREATION_REQUEST, { id, callback })
export const deleteItemUserCreationSuccess = (id: string) => action(DELETE_ITEM_USER_CREATION_SUCCESS, { id })
export const deleteItemUserCreationFailure = (error: any) => action(DELETE_ITEM_USER_CREATION_FAILURE, error)

export type DeleteItemUserCreationRequestAction = ReturnType<typeof deleteItemUserCreationRequest>
export type DeleteItemUserCreationSuccessAction = ReturnType<typeof deleteItemUserCreationSuccess>
export type DeleteItemUserCreationFailureAction = ReturnType<typeof deleteItemUserCreationFailure>

// Set item user creation edit
export const SET_ITEM_USER_CREATION_EDIT_REQUEST = '[Request] Set item user creation edit'

export const setItemUserCreationEditRequest = (item: ItemUserCreationType) => action(SET_ITEM_USER_CREATION_EDIT_REQUEST, { item })

export type SetItemUserCreationRequestAction = ReturnType<typeof setItemUserCreationEditRequest>

// Edit item user creation
export const EDIT_ITEM_USER_CREATION_REQUEST = '[Request] Edit item user creation'
export const EDIT_ITEM_USER_CREATION_SUCCESS = '[Success] Edit item user creation'
export const EDIT_ITEM_USER_CREATION_FAILURE = '[Failure] Edit item user creation'

export const editItemUserCreationRequest = (form: FormInitType & { id : string}) => action(EDIT_ITEM_USER_CREATION_REQUEST, { form })
export const editItemUserCreationSuccess = (form: FormInitType & { id : string}, categories: CategoriesType []) => action(EDIT_ITEM_USER_CREATION_SUCCESS, { form, categories })
export const editItemUserCreationFailure = (error: any) => action(EDIT_ITEM_USER_CREATION_FAILURE, error)

export type EditItemUserCreationRequestAction = ReturnType<typeof editItemUserCreationRequest>
export type EditItemUserCreationSuccessAction = ReturnType<typeof editItemUserCreationSuccess>
export type EditItemUserCreationFailureAction = ReturnType<typeof editItemUserCreationFailure>

// Fetch item user creation edit
export const FETCH_ITEM_USER_CREATION_EDIT_REQUEST = '[Request] Fetch item user creation edit'
export const FETCH_ITEM_USER_CREATION_EDIT_SUCCESS = '[Success] Fetch item user creation edit'
export const FETCH_ITEM_USER_CREATION_EDIT_FAILURE = '[Failure] Fetch item user creation edit'

export const fetchItemUserCreationEditRequest = (id: string, onRedirect: Function) => action(FETCH_ITEM_USER_CREATION_EDIT_REQUEST, { id, onRedirect })
export const fetchItemUserCreationEditSuccess = (item: any, categories: CategoriesType []) => action(FETCH_ITEM_USER_CREATION_EDIT_SUCCESS, { item, categories })
export const fetchItemUserCreationEditFailure = (error: any) => action(FETCH_ITEM_USER_CREATION_EDIT_FAILURE, error)

export type FetchItemUserCreationEditRequestAction = ReturnType<typeof fetchItemUserCreationEditRequest>
export type FetchItemUserCreationEditSuccessAction = ReturnType<typeof fetchItemUserCreationEditSuccess>
export type FetchItemUserCreationEditFailureAction = ReturnType<typeof fetchItemUserCreationEditFailure>
