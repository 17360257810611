import { forwardRef, memo } from 'react'

import { ReactComponent as IconArrow } from '../../../../asset/icons/ic-arrow-2.svg'
import { ReactComponent as LogoTicket } from '../../../../asset/images/ticket/logo sideway.svg'
import QRCode from '../../../../asset/images/ticket/photo_2021-11-09 16.20.24.jpeg'

import { TicketUIProps } from '../TicketPage.types'

import moduleStyles from './Ticket.module.scss'

const TicketUI = forwardRef<HTMLDivElement, TicketUIProps>(
  (props: TicketUIProps, ticketRef) => {
    const {
      divStyle,
      isShowContentTicket,
      backgroundTicket,
      launchSite,
      destination,
      firstName,
      lastName,
      toggleShowContentTicket
    } = props

    return (
      <div className="relative w-full">
        <div>
          <div
            className={`${moduleStyles.ticket} ${isShowContentTicket &&
              moduleStyles.isShowingContent}`}
            onClick={toggleShowContentTicket(!isShowContentTicket)}
            style={divStyle}
            ref={ticketRef}
          >
            <div className={moduleStyles.ticketInner}>
              <div className={moduleStyles.ticketFront}>
                <div className={moduleStyles.ticketFrontWrapper}>
                  <div className={moduleStyles.ticketFrontCoverLeft} />
                  <div className="relative h-full w-full flex">
                    <img
                      src={backgroundTicket}
                      alt=""
                      className={moduleStyles.ticketBackground}
                    />
                    <div className={moduleStyles.ticketFrontSmall}>
                      <div className={moduleStyles.ticketFrontSmallContent}>
                        <div className="flex-1" />
                      </div>
                    </div>
                    <div className={moduleStyles.ticketFrontBig}>
                      <div className={moduleStyles.ticketFrontBigContent}>
                        <div className="flex-1" />
                      </div>
                    </div>
                    <div className={moduleStyles.ticketFrontText}>
                      <div className={moduleStyles.ticketFrontBooking}>
                        {launchSite && (
                          <>
                            <div className={moduleStyles.ticketFrontSource}>
                              <div
                                className={moduleStyles.ticketFrontBookingTitle}
                              >
                                launch site
                              </div>
                              <div
                                className={moduleStyles.ticketFrontBookingPlace}
                              >
                                {launchSite}
                              </div>
                            </div>
                            <div
                              className={moduleStyles.ticketFrontBookingIcon}
                            >
                              <IconArrow fill="#fff" />
                            </div>
                          </>
                        )}
                        <div className={moduleStyles.ticketFrontDestination}>
                          <div className={moduleStyles.ticketFrontBookingTitle}>
                            destination
                          </div>
                          <div className={moduleStyles.ticketFrontBookingPlace}>
                            {destination}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={moduleStyles.ticketFrontCoverRight} />
                </div>
              </div>
              <div className={moduleStyles.ticketBack}>
                <div className={moduleStyles.ticketBackWrapper}>
                  <div className={moduleStyles.ticketBackCoverLeft} />
                  <div className="relative h-full w-full flex">
                    <div className={moduleStyles.ticketBackBig}>
                      <div className={moduleStyles.ticketBackBigContent}>
                        <div
                          className={moduleStyles.ticketBackBigContentWrapper}
                        >
                          <LogoTicket
                            viewBox="0 0 52 59"
                            className={moduleStyles.ticketBackLogoTicket}
                          />

                          <div className={moduleStyles.ticketBackTitle}>
                            <span>TICKET TO MARS</span>
                            <img src={QRCode} alt="" />
                          </div>

                          <div className={moduleStyles.ticketBackPassenger}>
                            <div className={moduleStyles.ticketBackSubtitle}>
                              passenger
                            </div>
                            <div className={moduleStyles.ticketBackFilledText}>
                              {firstName || lastName
                                ? `${firstName} ${lastName}`
                                : '_'}
                            </div>
                          </div>

                          <div className={moduleStyles.ticketBackInformation}>
                            <div className={moduleStyles.ticketBackDestination}>
                              <div className={moduleStyles.ticketBackSubtitle}>
                                destination
                              </div>
                              <div className={moduleStyles.ticketBackFilledText}                              >
                                {destination}
                              </div>
                            </div>

                            <div className={moduleStyles.ticketBackClass}>
                              <div className={moduleStyles.ticketBackSubtitle}>
                                class
                              </div>
                              <div className={moduleStyles.ticketBackFilledText}                              >
                                PIONEER CLASS
                              </div>
                            </div>

                            <div className={moduleStyles.ticketBackLaunch}>
                              <div className={moduleStyles.ticketBackSubtitle}>
                                launch
                              </div>
                              <div className={moduleStyles.ticketBackFilledText}                              >
                                12/2022
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={moduleStyles.ticketBackSmall}>
                      <div className={moduleStyles.ticketBackSmallContent}>
                        <div className={moduleStyles.ticketBackSmallContentWrapper}                        >
                          <div className={moduleStyles.ticketBackShipInfor}>
                            <div className={moduleStyles.ticketBackShipInforImage}                            >
                              <img src={QRCode} alt="" />
                            </div>

                            <div
                              className={moduleStyles.ticketBackShipInforQRText}
                            >
                              LOM-234
                            </div>

                            <div className={moduleStyles.ticketBackShipInforShipCode}                            >
                              <div className={moduleStyles.ticketBackShipInforShipCodeTitle}                              >
                                starship
                              </div>

                              <div className={moduleStyles.ticketBackShipInforShipCodeText}>
                                g-234
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={moduleStyles.ticketBackCoverRight} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
)

export default memo(TicketUI)
