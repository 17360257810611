import { memo, useCallback, useMemo } from 'react'
import { Page } from '@wiicamp/decentraland-ui'

import { AssetImage } from '../AssetImage'

import { Column } from '../../../Layout/Column'
import { Row } from '../../../Layout/Row'

import { ReactComponent as IconArrow } from '../../../../asset/images/tools/arrow-right-bottom.svg'
import { SellModal } from '../SellModal'
import { TransferModal } from '../TransferModal'
import { CancelSaleModal } from '../CancelSaleModal'

import styles from './LayoutModal.module.scss'

import { Props, ModalType } from './LayoutModal.types'

const LayoutModal = (props: Props) => {
  const {
    isOwner,
    nft,
    layoutType,
    onCloseModal,
    // sell
    listForSaleItem,
    listForSaleItemLoading,
    // transfer
    transferItem,
    transferItemLoading,
    // cancel sale
    cancelSaleItem,
    cancelSaleItemLoading
  } = props

  const RenderHeaderButtonBack = useCallback(() => (
    <button onClick={onCloseModal} className="go-back-arrow" >
      <IconArrow className="icon-arrow" />
      <span>Go back</span>
    </button >
  ), [onCloseModal])

  const modal: {
    modalComponent: any,
    parentClasses: string
  }  = useMemo(() => {
    switch (true) {
      case layoutType === ModalType.SELL:
        return {
          parentClasses: ['SellPage', styles.SellModal].join(' '),
          modalComponent: (
            <SellModal
              nft={nft}
              isOwner={isOwner}
              onCloseModal={onCloseModal}
              listForSaleItem={listForSaleItem}
              listForSaleItemLoading={listForSaleItemLoading}
            />
          )
        }
      case layoutType === ModalType.TRANSFER:
        return {
          parentClasses: ['TransferPage', styles.TransferModal].join(' '),
          modalComponent: (
            <TransferModal
              nft={nft}
              isOwner={isOwner}
              onCloseModal={onCloseModal}
              transferItem={transferItem}
              transferItemLoading={transferItemLoading}
            />
          )
        }
      case layoutType === ModalType.CANCEL:
        return {
          parentClasses: ['CancelSalePage', styles.CancelSaleModal].join(' '),
          modalComponent: (
            <CancelSaleModal
              nft={nft}
              isOwner={isOwner}
              onCloseModal={onCloseModal}
              cancelSaleItem={cancelSaleItem}
              cancelSaleItemLoading={cancelSaleItemLoading}
            />
          )
        }
      default:
        return {
          parentClasses: '',
          modalComponent: null,
        }
    }
  }, [
    layoutType,
    onCloseModal,
    listForSaleItem,
    listForSaleItemLoading,
    transferItem,
    transferItemLoading,
    cancelSaleItem,
    cancelSaleItemLoading,
  ])

  return (
    <Page className={modal.parentClasses}>
      <RenderHeaderButtonBack />

      <div className="AssetAction">
        <Row>
          <Column align="left">
            <div className="asset-image-wrapper">
              <AssetImage imageUrl={nft.thumbnail} />
            </div>
          </Column>

          {modal.modalComponent}
        </Row>
      </div>
    </Page>
  )
}

export default memo(LayoutModal)

