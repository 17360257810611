export const TICKET_TYPE = {
  ENTRANCE: "entrance",
  TRAVELING: "traveling",
  SPECIAL: "special",
}

export const initFormTicketData = {
  firstName: '',
  lastName: '',
}
